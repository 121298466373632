
import { defineComponent, PropType, ref, Ref } from "vue";
import axios from "axios";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { getCurrencySymbol } from "@/core/utils";
import { Switch as KSwitch } from "@progress/kendo-vue-inputs";

export default defineComponent({
  name: "CustomerProducts",
  components: {
    Grid: Grid,
    GridToolbar: GridToolbar,
    KSwitch,
  },
  props: {
    userId: {
      type: Object as PropType<number>,
      required: false,
      default: null,
    },
  },
  setup() {
    const products: Ref<any[]> = ref([]);
    const isLoading: Ref<boolean> = ref<boolean>(false);

    return {
      products,
      isLoading,
    };
  },
  data() {
    return {
      currencySymbol: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          title: "Product Name",
          cell: "productName",
        },
        {
          title: "Description",
          cell: "description",
        },
        {
          title: "Variants",
          cell: "variants",
        },
        {
          title: "SKU",
          cell: "sku",
        },
        {
          title: "Do Not Print",
          cell: "doNotPrint",
        },
        {
          title: "Additional Pricing",
          cell: "additionalPricing",
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;

    const productsRequest = axios.post(
      "/form/Integration/GetProductListForCustomer",
      { userId: this.userId },
    );
    const customerDetailsRequest = axios.post(
      "/form/pms/GetCustomerNameAndShippingAddresses",
      { customerId: this.userId },
    );

    const response = await Promise.all([
      productsRequest,
      customerDetailsRequest,
    ]);

    const productsResponse = response[0].data;
    const customerDetailsResponse = response[1].data;

    this.currencySymbol = getCurrencySymbol(
      customerDetailsResponse.userBranchId,
    );
    this.products = productsResponse.Data;

    this.isLoading = false;
  },
  methods: {
    async onChange(event: any, variant: any): Promise<void> {
      const response = axios.post("/form/pms/SetProductDoNotPrintFlag", {
        id: variant.Id,
        value: event.value,
      });

      if ((await response).status !== 200) {
        alert("error");
      } else {
        variant.DoNotPrint = event.value;
      }
    },
    onRowClick(event: any, dataItem: any): void {
      this.$router.push(
        `/customers/${this.userId}/products/${dataItem.Id}/variants`,
      );
    },
    downloadCsv(): void {
      const rows: string[][] = this.products.reduce(
        (result, p) => [
          ...result,
          ...p.Variants.map((v: any) => [
            this.prepareValue(p.FileName),
            this.prepareValue(v.VariantDescription),
            this.prepareValue(v.Variant),
            this.prepareValue(v.SKU),
            this.prepareValue(
              v.AdditionalPricing
                ? v.AdditionalPricing + this.currencySymbol
                : "-",
            ),
          ]),
        ],
        [],
      );

      // Add heading to CSV.
      rows.unshift([
        "Product Name",
        "Description",
        "Variants",
        "SKU",
        "Additional Pricing",
      ]);

      const fileName = `customer_${this.userId}_products_${Date.now()}.csv`;

      const csvContent =
        "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    prepareValue(value: string): string {
      if (!value) {
        return value;
      }

      value = value.replaceAll('"', '""');

      if (value.includes(",")) {
        return `"${value}"`;
      }

      return value;
    },
  },
});
