import {PresetSizeModel} from "@/core/types/presetSizeModel";

export const PresetSizes: PresetSizeModel[] = [
  {
    name: "A0",
    long: 46.8,
    short: 33.1,
  },
  {
    name: "A1",
    long: 33.1,
    short: 23.4,
  },
  {
    name: "A2",
    long: 23.4,
    short: 16.5,
  },
  {
    name: "A3",
    long: 16.5,
    short: 11.7,
  },
  {
    name: "A4",
    long: 11.7,
    short: 8.3,
  },
  {
    name: "30x30cm",
    long: 11.81,
    short: 11.81,
  },
  {
    name: "40x40cm",
    long: 15.75,
    short: 15.75,
  },
  {
    name: "50x50cm",
    long: 19.69,
    short: 19.69,
  },
  {
    name: "20x20 inches",
    long: 20,
    short: 20,
  },
  {
    name: "30x30 inches",
    long: 30,
    short: 30,
  },
  {
    name: "40x40 inches",
    long: 40,
    short: 40,
  },
  {
    name: "18x24 inches",
    long: 24,
    short: 18,
  },
  {
    name: "30x40 inches",
    long: 40,
    short: 30,
  },
  {
    name: "28x35cm",
    long: 350 / 25.4,
    short: 280 / 25.4,
  },
  {
    name: "30x40cm",
    long: 400 / 25.4,
    short: 300 / 25.4,
  },
  {
    name: "40x50cm",
    long: 500 / 25.4,
    short: 400 / 25.4,
  },
  {
    name: "60x80cm",
    long: 800 / 25.4,
    short: 600 / 25.4,
  },
  {
    name: "70x100cm",
    long: 1000 / 25.4,
    short: 700 / 25.4,
  },
  {
    name: "30x45cm",
    long: 450 / 25.4,
    short: 300 / 25.4,
  },
  {
    name: "40x60cm",
    long: 600 / 25.4,
    short: 400 / 25.4,
  },
];
