<template>
  <div class="wrapper">
    <div>
      <multiselect
        :data-items="optns"
        :text-field="'Value'"
        :data-item-key="'Id'"
        :auto-close="false"
        :filterable="true"
        @filterchange="onFilterChange"
        :item-render="itemRender"
        :tags="[]"
        :value="val"
        @change="handleChange"
      >
        <template v-slot:Template="{ props }">
          <custom
            :data-item="props.dataItem"
            :selected="props.selected"
            :index="props.index"
            @click="(ev) => props.onClick(ev)"
          />
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import { filterBy } from '@progress/kendo-data-query';
import { MultiSelect } from '@progress/kendo-vue-dropdowns';
import ItemRender from './ItemRender';
export default {
name: "CustomMultiselect",
  components: {
    multiselect: MultiSelect,
    custom: ItemRender,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default:() =>([])
    },
  },
  watch: {
    options:{
      handler: function () {
        this.optns = this.options
      },
      immediate:true
    },
    value:{
      handler: function () {
        this.val = this.value
      },
      immediate:true
    },
  },
  data() {
    return {
      itemRender: "Template",
      optns: [],
      val: [],
    };
  },
  methods: {
    handleChange(evt){
      this.val = evt.value
      this.$emit("change", this.val)
    },
    onFilterChange(event) {
      this.optns = filterBy(this.options, event.filter);
    }
  },
}
</script>

<style scoped>

</style>
