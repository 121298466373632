
import { defineComponent, ref } from "vue";
declare var $: any;
export default defineComponent({
  name: "PromoCodesList",
  setup() {
    const grid: any = ref(null);
    return {
      grid,
    };
  },
  data() {
    return {
      importVisible: false,
      importValue: "",
    };
  },
  async mounted() {
    const _w = window as any;
    const _d = document as any;
    const _this = this as any;
    if (!_w.routeruleselect) {
      _w.orderstatusversionselect = function (e: any) {
        const id = this.dataItem(this.select()).Id;
        _this.$router.push(`/order-state-manager/${id}`);
      };
      _w.published = function (e: any) {
        return e.Status != 1;
      };
      _w.exportOrderStatus = async function (e: any) {
        var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
        _d.getElementById("export").value = dataItem.JSONData;
        _d.getElementById("export-modal").style.display = "block";
      };
      _w.publishOrderStatus = async function (e: any) {
        e.preventDefault();
        var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
        await $.ajax({
          type: "POST",
          url: "/form/grid/PublishOrderStatus",
          data: JSON.stringify({ Id: dataItem.Id }),
          dataType: "json",
          contentType: "application/json",
        });
        this.dataSource.read();
      };
      _w.previewOrderStatus = async function (e: any) {
        e.preventDefault();
        var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
        await $.ajax({
          type: "POST",
          url: "/form/grid/PreviewOrderStatus",
          data: JSON.stringify({ Id: dataItem.Id }),
          dataType: "json",
          contentType: "application/json",
        });
        this.dataSource.read();
      };
    }
    const grid = this.grid;
    $.get("/form/grid/OrderStatusVersionsPartial", {}, (html: string) => {
      html = html.replace(/\/grid\//gi, "/form$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
  methods: {
    closeModal() {
      const _d = document as any;
      _d.getElementById("export-modal").style.display = "none";
    },
    async importVals() {
      const ref = this.$refs["import-area"] as any;
      var iav = ref.value;
      if (iav) {
        var { Id } = await $.ajax({
          type: "POST",
          url: "/form/grid/PreviewImport",
          data: JSON.stringify({ jsonData: iav }),
          dataType: "json",
          contentType: "application/json",
        });
        this.$router.push(`/order-state-manager/${Id}`);
      }
      this.importVisible = false;
    },
  },
});
