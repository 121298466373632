export function downloadFile(
  jqXQR: any,
  data: any,
  isBase64Encoded: boolean,
  wnd: any = undefined,
  doc: any = undefined
): void {
  wnd = wnd || window;
  doc = doc || document;

  const tk = "filename=";
  const cdh = jqXQR.getResponseHeader("content-disposition");
  const filename = cdh.substr(cdh.lastIndexOf(tk) + tk.length);
  const bytes = isBase64Encoded ? base64ToArrayBuffer(data, wnd) : data;
  const blob = new Blob([bytes], {
    type: jqXQR.getResponseHeader("content-type"),
  });
  const blobUrl = wnd.URL.createObjectURL(blob);
  const link = doc.createElement("a");
  link.href = blobUrl;
  link.download = filename;
  link.click();
  link.remove();
  wnd.URL.revokeObjectURL(blobUrl);
}

export function base64ToArrayBuffer(
  base64: string,
  wnd: any = undefined
): Uint8Array {
  const binaryString = wnd.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function dowloadUrl(
  jqXQR: any,
  doc: any,
  url: string
) {
  const a = doc.createElement('a');
  a.href = url;

  doc.body.appendChild(a);
  a.click();
  doc.body.removeChild(a);
}
