import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3edcf0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader-container"
}
const _hoisted_2 = { class: "grid-container" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "refund-redo-report-wrapper"
}
const _hoisted_8 = { class: "m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_k_notification = _resolveComponent("k-notification")!
  const _component_k_card_title = _resolveComponent("k-card-title")!
  const _component_k_card_subtitle = _resolveComponent("k-card-subtitle")!
  const _component_k_card_header = _resolveComponent("k-card-header")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_card_actions = _resolveComponent("k-card-actions")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_drop_down_list = _resolveComponent("k-drop-down-list")!
  const _component_kendo_daterangepicker = _resolveComponent("kendo-daterangepicker")!
  const _component_k_panel_bar = _resolveComponent("k-panel-bar")!
  const _component_k_card_body = _resolveComponent("k-card-body")!
  const _component_k_card = _resolveComponent("k-card")!
  const _component_k_tab_strip_tab = _resolveComponent("k-tab-strip-tab")!
  const _component_k_tab_strip = _resolveComponent("k-tab-strip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoaderShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_loader, {
            size: 'large',
            type: "infinite-spinner"
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_k_notification, { ref: "PopupNotification" }, null, 512),
      _createVNode(_component_k_tab_strip, {
        class: "tabs",
        selected: _ctx.selected,
        onSelect: _ctx.onSelect
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DataViewGroups, (group, gi) => {
            return (_openBlock(), _createBlock(_component_k_tab_strip_tab, {
              key: gi,
              title: group.Name
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(group.Description), 1),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.Views, (dataView, dvi) => {
                    return (_openBlock(), _createBlock(_component_k_card, {
                      key: dvi,
                      class: "item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_k_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_k_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(dataView.Title), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_k_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(dataView.Description), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_k_card_actions, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_k_button, {
                              onClick: _withModifiers(($event: any) => (_ctx.dispatchAction(gi, dvi)), ["prevent"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(dataView.ActionLabel), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        (dataView.Parameters.length > 0)
                          ? (_openBlock(), _createBlock(_component_k_card_body, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_k_panel_bar, {
                                  "expand-mode": 'single',
                                  items: _ctx.getPanelBarItems(gi, dvi)
                                }, {
                                  first: _withCtx(({ props }) => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.item.parms, (param, pi) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: pi,
                                        class: "params-container"
                                      }, [
                                        _createElementVNode("label", {
                                          for: param.Name
                                        }, _toDisplayString(param.Label), 9, _hoisted_3),
                                        _createElementVNode("span", {
                                          title: param.Description,
                                          class: "k-icon k-i-question help"
                                        }, null, 8, _hoisted_4),
                                        (param.ParamType == 1)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                              _createVNode(_component_k_input, {
                                                id: param.Name,
                                                modelValue: param.ParamValues[0].Value,
                                                "onUpdate:modelValue": ($event: any) => ((param.ParamValues[0].Value) = $event),
                                                class: _normalizeClass('param'),
                                                type: "email",
                                                required: param.Required
                                              }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "required"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (param.ParamType == 6 && !param.AllowMultiple)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                              _createVNode(_component_k_drop_down_list, {
                                                modelValue: param.ParamValues[0].ValueObj,
                                                "onUpdate:modelValue": ($event: any) => ((param.ParamValues[0].ValueObj) = $event),
                                                class: _normalizeClass('param'),
                                                "data-items": param.List,
                                                "text-field": 'Value',
                                                "data-item-key": 'Key',
                                                required: param.Required,
                                                "default-item": param.DefaultValue
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "data-items", "required", "default-item"])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (param.ParamType == 8)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                              _createElementVNode("div", _hoisted_8, [
                                                _createVNode(_component_kendo_daterangepicker, {
                                                  modelValue: 
                            _ctx.getParamValue(param.ParamValues, dataView).ValueObj
                          ,
                                                  "onUpdate:modelValue": ($event: any) => ((
                            _ctx.getParamValue(param.ParamValues, dataView).ValueObj
                          ) = $event),
                                                  format: 'MM/dd/yyyy'
                                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["items"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 2
            }, 1032, ["title"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["selected", "onSelect"])
    ])
  ], 64))
}