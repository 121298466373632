
import {defineComponent} from "vue";

  export default defineComponent({
    name: 'OrderSubHeader',
    props: {
      order: Object
    },
    computed: {
      getAsfFramingSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.Items.some((item: any) => item.Finishing === "Framing" && !item.CustomFinishingDetails)
          && this.order.OrderTypeId === 11
          ? 'ASF Framing'
          : '';
      },
      getBrandingSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.Items.some((item: any) => item.SpecialOrderTypeId === 9)
          ? 'Branding'
          : '';
      },
      getInsertSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.Items.some((item: any) => item.SpecialOrderTypeId === 8)
          ? 'Insert'
          : '';
      },
      getCoaSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.HasAuthenticityCertificates
          ? 'COA'
          : '';
      },
      getBespokeMountingSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.OrderTypeId !== 11 && this.order.Items.some((item: any) => item.Finishing === "Mounting")
          ? 'Bespoke Mounting'
          : '';
      },
      getBespokeFramingSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.OrderTypeId !== 11 && this.order.Items.some((item: any) => item.Finishing === "Framing")
          ? 'Bespoke Framing'
          : '';
      },
      getAsfCustomFinishingSubHeader() {
        if (!this.order) {
          return '';
        }

        return this.order.Items.some((item: any) => item.CustomFinishingDetails)
          ? 'ASF Custom Finishing'
          : '';
      }
    }
  })
