
import { defineComponent, ref, Ref } from "vue";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import { orderStatuses } from "@/core/orderStates";
declare const $: any;
export default defineComponent({
  setup() {
    const PopupNotification: Ref<Notification | null> = ref(null);
    return {
      PopupNotification,
      orderStatuses: orderStatuses,
    };
  },
  data() {
    const wdgt: any = {};
    const model: any = ref({});
    return {
      PopupNotificationWidget: wdgt,
      model: model,
    };
  },
  computed: {
    id() {
      return Number(this.$route.params.id);
    },
  },
  async mounted() {
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (this.$route.params.id) {
      await this.load(Number(this.$route.params.id));
    }
  },
  methods: {
    filterStatus(id: number) {
      const values = this.model[id] ? [...this.model[id], id] : [id];
      if (values) {
        return this.orderStatuses.filter(
          (x: any) => values.indexOf(x.Id) == -1,
        );
      }
      return this.orderStatuses;
    },
    async load(id: number) {
      const result: any = await $.get(`/form/grid/OrderStatusVersion?id=${id}`);
      var data = JSON.parse(result.JSONData);
      Object.assign(this.model, data);
    },
    async save() {
      const data = {
        Id: this.id,
        JSONData: JSON.stringify(this.model),
      };

      try {
        const { Id } = await $.ajax({
          type: "POST",
          url: "/form/grid/UpdateOrderStatues",
          data: JSON.stringify(data),
          dataType: "json",
          contentType: "application/json",
        });

        await this.load(Id);

        if (this.$route.params.id) {
          this.PopupNotificationWidget?.show(
            "Order status successfully updated",
            "success",
          );
        } else {
          this.PopupNotificationWidget?.show(
            "Order status successfully saved",
            "success",
          );
        }

        this.$router.push(`/order-state-manager/${Id}`);
      } catch (e: any) {
        this.PopupNotificationWidget?.show(
          `Something went wrong with saving the Order status`,
          "error",
        );
      }
    },
    GetName(statusId: number) {
      return this.orderStatuses.find((x: any) => x.Id == statusId).Name;
    },
    addVal(id: number, target: string) {
      const targetSelect = document.getElementById(target) as HTMLSelectElement;
      const val = Number(targetSelect.value);
      if (!this.model[id]) this.model[id] = [];
      if (!isNaN(val) && this.model[id].indexOf(val) == -1)
        this.model[id].push(val);
    },
    RemoveStatus(id: number, statusId: number) {
      const index = this.model[id].indexOf(statusId);
      if (index > -1) this.model[id].splice(index, 1);
    },
  },
});
