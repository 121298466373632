
import { defineComponent, PropType, Ref, ref } from "vue";
import { Notification as KNotification } from "@progress/kendo-popups-vue-wrapper";
import axios from "axios";

export default defineComponent({
  name: "OrdersBulkEditModal",
  components: {
    "k-notification": KNotification,
  },
  props: {
    orderIds: {
      required: true,
      type: Array as PropType<number[]>,
    },
    deliveryBranches: {
      required: true,
      type: Array as PropType<{ Id: number; Name: string }[]>,
    },
  },
  emits: ["close"],
  setup() {
    const popupNotification: Ref = ref(null);
    const saving: Ref<boolean> = ref(false);
    const selectedBranchId: Ref<number | null> = ref(null);

    return {
      popupNotification,
      selectedBranchId,
      saving,
    };
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
      show: false, // used solely for the entrance animation purposes
    };
  },
  mounted() {
    this.popupNotificationWidget = this.popupNotification?.kendoWidget();
    this.show = true;
  },
  methods: {
    close(isSaved = false): void {
      if (this.saving && !isSaved) return; // prevent cancelling during closing

      this.show = false;
      this.$emit("close", isSaved);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close();
    },
    async saveChanges(): Promise<void> {
      try {
        this.saving = true;
        const orderIds = this.orderIds ?? [];
        const batchSize = 10;
        for (let i = 0; i < orderIds.length; i += batchSize) {
          const batch = orderIds.slice(i, i + batchSize);
          await axios.post("/form/pms/SetOrderProductionBranchId", {
            branchId: this.selectedBranchId,
            orderIds: batch,
          });
        }

        this.popupNotificationWidget?.show("Orders were updated", "success");
        this.close(true);
      } catch {
        this.popupNotificationWidget?.show(
          "Something went wrong saving the production branch",
          "error",
        );
      } finally {
        this.saving = false;
      }
    },
    onStatusChange(e: any): void {
      this.selectedBranchId = Number(e.target.value);
    },
  },
});
