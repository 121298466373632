
import { defineComponent } from "vue";
import { formatDate } from "@telerik/kendo-intl";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import { MultiSelectVue3 as KMultiSelect } from "@progress/kendo-vue-dropdowns";
import { DatePicker as KDatePicker } from "@progress/kendo-vue-dateinputs";
import LocalStorageServiceFactory from "@/services/localStorageService";

declare var $: any;

const $localStorage = LocalStorageServiceFactory.create();

const emptyBand = Object.freeze({
  name: "",
  from: 0,
  to: 0,
});
const bandsKey = Object.freeze("new-sales-cohort-bands");

export default defineComponent({
  name: "DeliveryZones",
  components: {
    KDropDownList,
    KMultiSelect,
    KDatePicker,
  },
  data() {
    const allPeriods = Object.freeze(["Month", "Quarter", "Year"]);
    const allMetrics = Object.freeze([
      "Revenue",
      "Revenue Percent",
      "People",
      "People Percent",
    ]);
    const allBranches = Object.freeze([
      { id: "UK", name: "UK" },
      { id: "DE", name: "Germany" },
    ]);
    const allRevenueTypes = Object.freeze([
      "C-type",
      "Giclee",
      "Postage",
      "Courier",
      "Mounting",
      "Framing",
      "Ecommerce",
      "Other",
    ]);
    const jsStartDate = new Date(2018, 0, 1);
    const jsEndDate = new Date();
    return {
      allPeriods,
      allRevenueTypes,
      allBranches,
      allMetrics,
      jsStartDate,
      jsEndDate,
      model: {
        startDate: formatDate(jsStartDate, "yyyy-MM-dd"),
        endDate: formatDate(jsEndDate, "yyyy-MM-dd"),
        branches: new Array<any>(),
        revenueTypes: new Array<any>(),
        resultPeriod: allPeriods[2],
        cohortPeriod: allPeriods[2],
        revenueBands: new Array<any>(),
      },
      metric: allMetrics[0],
      bandKey: 0,
      band: Object.assign({}, emptyBand, { key: 0 }),
      busy: false,
      error: null as any,
      data: null as any,
    };
  },
  computed: {
    modelStartDate: {
      get(): Date {
        return this.jsStartDate;
      },
      set(v: undefined | Date) {
        this.jsStartDate = v || new Date();
        this.model.startDate = formatDate(this.jsStartDate, "yyyy-MM-dd");
      },
    },
    modelEndDate: {
      get(): Date {
        return this.jsEndDate;
      },
      set(v: undefined | Date) {
        this.jsEndDate = v || new Date();
        this.model.endDate = formatDate(this.jsEndDate, "yyyy-MM-dd");
      },
    },
    modelError(): string {
      if (
        this.allPeriods.indexOf(this.model.cohortPeriod) <
        this.allPeriods.indexOf(this.model.resultPeriod)
      ) {
        return "the cohort period cannot be shorter than the results period";
      }
      return "";
    },
    dataRowStyle(): any {
      if (!this.data) return {};
      const n = this.data.cohorts[0]?.Data.length || 0;
      if (!n) return {};
      return { width: `${32 + (n + 2) * 100}px` };
    },
  },
  mounted() {
    const bands = $localStorage.get(bandsKey);
    if (bands) this.model.revenueBands = bands;
  },
  methods: {
    addBand() {
      this.model.revenueBands.push(this.band);
      this.model.revenueBands.sort((a, b) => a.from - b.from);
      this.band = Object.assign({}, emptyBand, { key: --this.bandKey });
    },
    removeBand(b: any) {
      const i = this.model.revenueBands.indexOf(b);
      this.model.revenueBands.splice(i, 1);
    },
    async process() {
      this.busy = true;
      this.error = null;
      this.data = null;
      $localStorage.set(bandsKey, this.model.revenueBands);
      try {
        await $.post("/form/Analytics/GetNewSalesCohortReport", this.model)
          .success((data: any) => (this.data = data))
          .error((error: any) => (this.error = error));
      } catch (error) {
        this.error = error;
      } finally {
        this.busy = false;
      }
    },
    downloadOnClient() {
      if (!this.data) return;
      const parameters = [
        ["start", "end", "branches", "cohort", "result", "metric"].join(","),
        [
          this.data.start,
          this.data.end,
          this.data.branches,
          this.data.cohortPeriod,
          this.data.resultPeriod,
          this.metric,
        ].join(","),
      ];
      const headers = [
        "Start",
        "Band",
        `First ${this.data.cohortPeriod}`,
        ...this.data.cohorts[0].Data.map((o: any) => `"${o.ColumnName}"`),
      ].join(",");
      const rows = this.data.cohorts.map((o: any) =>
        [
          o.StartDateString,
          o.RevenueBand.Name,
          o.RevenueBand.From,
          ...o.Data.map((o: any, i: number) =>
            this.metric === "Revenue"
              ? o.GbpAmounts.toFixed(0)
              : this.metric === "Revenue Percent"
              ? o.GbpAmountsPercent.toFixed(i ? 1 : 0)
              : this.metric === "People"
              ? o.ActiveUsers.toFixed(0)
              : this.metric === "People Percent"
              ? o.ActiveUsersPercent.toFixed(i ? 1 : 0)
              : -1
          ),
        ].join(",")
      );
      const csv = [...parameters, "", headers, ...rows].join("\r\n");
      const date = formatDate(new Date(), "yyyy-MM-dd_HH-mm-ss");
      const link = document.createElement("a");
      link.href = "data:text/csv," + encodeURIComponent(csv);
      link.download = `cohorts_${date}.csv`;
      link.click();
    },
  },
});
