
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { defineComponent, PropType, Ref, ref } from "vue";
import { TextArea } from "@progress/kendo-vue-inputs";
export default defineComponent({
  name: "PreventUnpaidStatusSelectModalVue",
  components: {
    radiobutton: RadioButton,
    datepicker: DatePicker,
    dropdownlist: DropDownList,
    formtextarea: TextArea,
  },
  props: {
    allowsInvoicing: Boolean,
    input: {
      default: () => ({
        paidByInvoice: false,
      }),
      type: Object as PropType<PreventUnpaidStatusSelectModalResponse>,
    },
    isDynamic: {
      type: Boolean,
      default: false, // Default for static components
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    users: {
      required: true,
      type: Array as PropType<any[]>,
    },
  },
  emits: ["close"],
  data() {
    const manualPaymentMethods: any = ref([
      { Id: 1, Name: "Bank transfer" },
      { Id: 2, Name: "In house card payment terminal" },
      { Id: 3, Name: "Paypal" },
      { Id: 4, Name: "Manual Stripe charge" },
    ]);

    return {
      showPopup: false,
      model: { ...this.input },
      paymentDefaultDate: !this.input.paidByInvoice
        ? this.input.paymentDate ?? new Date()
        : null,
      paymentMethods: manualPaymentMethods,
    };
  },
  computed: {
    isFormValid() {
      return (
        this.model.user?.Id &&
        (this.model.paidByInvoice || this.model.manualPaymentMethod?.Id)
      );
    },
  },
  watch: {
    async show(val) {
      this.showPopup = val; // in case of static embedding of this component
    },
 },
  mounted() {
    if (this.isDynamic) this.showPopup = true; // solely to trigger appearing transition
  },
  methods: {
    close(save = false) {
      if (this.isSaving) return;
      if (this.isDynamic) this.showPopup = false;

      this.$emit("close", save ? this.model : null);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close();
    },
    handlePaidTypeChange(e: any): void {
      this.model.paidByInvoice = e.value === 2;
    },
    handlePaymentMethodChange(e: any): void {
      this.model.manualPaymentMethod = e.value;
    },
    handleUserChange(e: any): void {
      this.model.user = e.value;
    },
    handlePaymentDateChange(e: any): void {
      this.model.paymentDate = e.target.value;
    },
    handleNoteChange(e: any) {
      this.model.note = e.value;
    },
    onYesButtonClick(): void {
      this.close(true);
    },
  },
});
