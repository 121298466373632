
import { defineComponent, ref } from "vue";

declare var $: any;
export default defineComponent({
  name: "DeliveryZones",
  setup() {
    const grid: any = ref(null);
    return { grid };
  },
  mounted() {
    const grid = this.grid;
    $.get("/form/grid/deliveryzonepartial", {}, (html: string) => {
      html = html.replace(/\/grid\//gi, "/form$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
});
