import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "modal-footer" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-mask",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.oBackDropClick && _ctx.oBackDropClick(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["modal-header", _ctx.getTitleClass()])
                }, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.headerText), 1)
                ], 2),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "body", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.bodyText), 1)
                  ]),
                  (_ctx.isQuestion && _ctx.selectList)
                    ? _withDirectives((_openBlock(), _createElementBlock("select", {
                        key: 0,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectValue) = $event)),
                        class: "form-select"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectList, (item) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: (item as any).Id,
                            value: (item as any).Id
                          }, _toDisplayString((item as any).Name), 9, _hoisted_4))
                        }), 128))
                      ], 512)), [
                        [_vModelSelect, _ctx.selectValue]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    (_ctx.isQuestion)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("button", {
                            class: "btn btn-success margin-right",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onYesButtonClick && _ctx.onYesButtonClick(...args)))
                          }, " Yes "),
                          _createElementVNode("button", {
                            class: "btn btn-danger",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onYesCancelClick && _ctx.onYesCancelClick(...args)))
                          }, " Cancel ")
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.isQuestion)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createElementVNode("button", {
                            class: "btn btn-primary",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
                          }, " Ok ")
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}