
import { computed, defineComponent } from "vue";
import { postJson } from "tps.shared.ui/core/requestHelpers";
import axios from "axios";
export default defineComponent({
  data() {
    return {
      superAdmin: false,
      userRoles: {} as any,
    };
  },
  computed: {
    hasCommissionsMenu(): boolean {
      return this.userRoles && this.userRoles?.roles?.length > 0;
    },
  },

  async mounted() {
    const _window: any = window;
    await this.$account.getUserInRole();

    this.userRoles = await this.$account.getUserInRoles(
      this.$account.rolesForCommissions,
    );
    if (this.$account.userInRole)
      this.superAdmin = this.$account.userInRole.authorized;

    if (_window.safari) {
      console.log(_window.safari);
      history.pushState(null, "", location.href);
      window.onpopstate = () => {
        history.go(1);
      };
    }

    if (!_window.getNotesFlag)
      _window.getNotesFlag = (
        NotesCount: number | null,
        AdvisoryNotesCount: number | null,
      ) => {
        if (AdvisoryNotesCount && AdvisoryNotesCount > 0) {
          return "<span class='k-icon k-i-exclamation-circle'></span>";
        }

        return "<span></span>";
      };
  },
});
