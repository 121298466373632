
import { defineComponent, PropType, Ref, ref } from "vue";
import { Notification as KNotification } from "@progress/kendo-popups-vue-wrapper";
import axios from "axios";

export default defineComponent({
  name: "OrderNftsModal",
  components: {
    "k-notification": KNotification,
  },
  props: {
    show: Object as PropType<boolean>,
    orderId: Object as PropType<number>,
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
    };
  },
  setup() {
    const popupNotification: Ref = ref(null);
    const isLoading: Ref<boolean> = ref(false);
    const nfts: Ref<any[]> = ref<any[]>([]);

    return {
      popupNotification,
      isLoading,
      nfts,
    };
  },
  watch: {
    async show(val) {
      if (val === false || !this.orderId) {
        return;
      }

      this.popupNotificationWidget = this.popupNotification?.kendoWidget();

      this.isLoading = true;

      try {
        const response = await axios.get(
          `/form/authenticity-certificate/nfts/${this.orderId}`,
        );

        if (response.data !== "No editions found for this order") {
          this.nfts = response.data;
        }
      } catch (error: any) {
        console.error(error);
        this.popupNotificationWidget?.show(
          "Something went wrong during loading the NFTs.",
          "error",
        );
      }

      this.isLoading = false;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") {
        this.close();
      }
    },
    copyUrlToClipboard(text: string): void {
      navigator.clipboard.writeText(text).then(
        () => console.log("Async: Copying to clipboard was successful!"),
        err => console.error("Async: Could not copy text: ", err),
      );
    },
  },
});
