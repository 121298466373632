import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-mask",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.oBackDropClick && _ctx.oBackDropClick(...args)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["modal-header", _ctx.getTitleClass()])
                }, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.headerText), 1)
                ], 2),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "body", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.bodyText), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        class: "btn btn-primary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                      }, " Ok ")
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}