import axios from "axios";

export default class ExportCsvService {
  static exportCsv(exportUrl: string, exportFileName: string): void {
    axios
      .post(
        exportUrl,
        {
          responseType: "blob",
        },
      )
      .then(response => {
        const data = response.data;
        const blob = new Blob([data], {
          type: "text/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${exportFileName}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}