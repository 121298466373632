
import { defineComponent, ref } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { get } from "tps.shared.ui/core/requestHelpers";
import { filterBy } from "@progress/kendo-data-query";
import axios from "axios";
declare var $: any;
export default defineComponent({
  name: "DeliveryZones",
  components: {
    KendoDdl: DropDownList,
  },
  setup() {
    const grid: any = ref(null);
    return { grid };
  },
  data() {
    return {
      innerItems: [],
      items: [],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      const { data } = await get(axios, "/grid/zones", {
        prefix: "form",
      });
      this.innerItems = data;
      this.items = data;
    },
    selectZone(evtData: any) {
      const id = evtData.value?.Id;
      const grid = this.grid;
      $.get(
        `/form/grid/deliverydimensionzonespartial/${id}`,
        {},
        (html: string) => {
          html = html.replace(/\/grid\//gi, "/form$&");
          $(grid).html(html);
        }
      );
    },
    filterChange(event: any) {
      this.items = this.filterData(event.filter);
    },
    filterData(filter: any) {
      const data = this.innerItems.slice();
      return filterBy(data, filter);
    },
  },
});
