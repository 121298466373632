<template>
  <li class="k-item" @click="handleClick">
    <checkbox :checked="selected"  /> <span class="k-ml-1"> {{dataItem.Value}} </span>
  </li>
</template>

<script>
import { Checkbox } from '@progress/kendo-vue-inputs';
export default {
  name: "ItemRender",
  components: {
    checkbox: Checkbox,
  },
  props: {
    dataItem: {
      type: [Object, String, Number],
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    click: null,
  },
  methods: {
    handleClick: function (e) {
      this.$emit('click', e);
    },
  },
}
</script>

<style scoped>

</style>
