
import { defineComponent, ref } from "vue";

declare var $: any;

export default defineComponent({
  name: "GridFilterCategories",
  components: {},
  setup() {
    const grid: any = ref(null);

    return { grid };
  },
  data() {
    return {
      gridHandle: "#gridFilterCategories",
    };
  },
  mounted() {
    const grid = this.grid;

    $.get("/form/grid/GridFilterCategoriesPartial", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug

      $(grid).html(html);
    });
  },
});
