/**
 * Modules that wraps the different local storages:
 * - localStorage
 * - sessionStorage
 * - cookies [TODO]
 */

import { ApplicationError, ErrorCode } from "@/core/ApplicationError";

export const StorageType = Object.freeze({
  LOCAL_STORAGE: Symbol("localStorage"),
  SESSION_STORAGE: Symbol("sessionStorage"),
});

export default class LocalStorageServiceFactory {
  static create(storageType = StorageType.LOCAL_STORAGE) {
    switch (storageType) {
      case StorageType.LOCAL_STORAGE:
        return new LocalStorageService();
      case StorageType.SESSION_STORAGE:
        return new SessionStorageService();
      default:
        throw new ApplicationError(
          ErrorCode.NOT_SUPPORTED,
          `${String(storageType)} is not supported.`
        );
    }
  }
}

/**
 * Abstract base class. Not to be used.
 */
export interface ILocalStorageService {
  get(key: string): any;
  set(key: string, value: any): void;
  delete(key: string): void;
  clear(): void;
}

// End-user can disable local storage access on their browser - wrap all methods with a try..catch
export class LocalStorageService implements ILocalStorageService {
  get(key: string) {
    try {
      const json = localStorage.getItem(key);
      if (json) return JSON.parse(json).value;
    } catch (error) {
      console.error(`LocalStorageService get("${key}") failed`, { error });
    }
    return null;
  }

  set(key: string, value: any) {
    try {
      const json = JSON.stringify({ value });
      localStorage.setItem(key, json);
    } catch (error) {
      console.error(`LocalStorageService set("${key}") failed`, { error });
    }
  }

  delete(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`LocalStorageService delete("${key}") failed`, { error });
    }
  }

  clear() {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("LocalStorageService clear() failed", { error });
    }
  }
}

export class SessionStorageService implements ILocalStorageService {
  get(key: string) {
    return sessionStorage.getItem(key) || null;
  }

  set(key: string, obj: any) {
    sessionStorage.setItem(key, obj);
  }

  delete(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }
}
