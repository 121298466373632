export enum BranchCode {
  Unset = -1,
  GB = 1,
  DE = 2,
  US = 3,
}

export type Branch = {
  Id: BranchCode;
  Name: string;
};

export const branches: Branch[] = [
  { Id: BranchCode.Unset, Name: "None" },
  { Id: BranchCode.GB, Name: "GB" },
  { Id: BranchCode.DE, Name: "DE" },
  { Id: BranchCode.US, Name: "US" },
];

export const noneBranch = (): Branch => {
  return { Id: BranchCode.Unset, Name: "None" };
}

export type AdjustmentAction = "add-new-row" | "save" | "delete";

export type PostCalculationAdjustment = {
  Id: number;
  DeductBranch: Branch;
  AddBranch: Branch;
  Date: Date;
  Note: string;
  SummaryOrders: number;
  GeneralPrintingPrints: number;
  GeneralDispatchAsfPrintOnlyOrders: number;
  GeneralDispatchAsfFramedOrders: number;
  MagnumPrintingPrints: number;
  MagnumDispatchAsfPrintOnlyOrders: number;
  MagnumDispatchAsfFramedOrders: number;
  IsLoading: boolean;
  ActionTriggered?: AdjustmentAction | null;
};

export interface PostCalculationAdjustmentOld {
  deductBranchId: number;
  addBranchId: number;
  printsNumber: number;
  printedOrdersNumber: number;
  framedOrdersDispatchedNumber: number;
  printOnlyOrdersDispatchedNumber: number;
  date: string;
  note: string;
}

export function emptyPostCalculationAdjustment(): PostCalculationAdjustment {
  return {
    Id: 0,
    DeductBranch: { Id: BranchCode.Unset, Name: "None" },
    AddBranch: { Id: BranchCode.Unset, Name: "None" },
    Date: new Date(),
    Note: "",
    SummaryOrders: 0,
    GeneralPrintingPrints: 0,
    GeneralDispatchAsfPrintOnlyOrders: 0,
    GeneralDispatchAsfFramedOrders: 0,
    MagnumPrintingPrints: 0,
    MagnumDispatchAsfPrintOnlyOrders: 0,
    MagnumDispatchAsfFramedOrders: 0,
    IsLoading: false,
    ActionTriggered: null,
  };
}
