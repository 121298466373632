export enum RelayStatus {
  SuccessOrderAcknowledged = 0,
  SuccessOrderDownloaded = 1,
  SuccessOrderMarkedAsDownloaded = 2,
  SuccessUpdatingOrderStatus = 3,
  ErrorOrderAcknowledged = 4,
  ErrorOrderDownloaded = 5,
  ErrorOrderMarkingAsDownloaded = 6,
  ErrorUpdatingOrderStatus = 7
}
