
import { defineComponent, PropType, ref, Ref } from "vue";
import axios from "axios";
import { getCurrencySymbol } from "@/core/utils";
import { CalculateOrderItemPricing } from "@/core/util/pricings.helpers";

enum OrderItemType {
  Prints = 1,
  Frame,
  Mounting,
  Stationery,
  Retouch,
  Delivery,
  FreeText,
}

export default defineComponent({
  name: "VariantConfigurationModal",
  props: {
    widthInches: {
      type: Object as PropType<number>,
      required: true,
    },
    heightInches: {
      type: Object as PropType<number>,
      required: true,
    },
    variant: {
      type: Object as PropType<any>,
      required: true,
    },
    show: {
      type: Object as PropType<boolean>,
      required: true,
    },
    image: {
      type: Object as PropType<any>,
      required: true,
    },
    paperId: {
      type: Object as PropType<number>,
      required: true,
    },
  },
  setup() {
    const isLoading: Ref<boolean> = ref<boolean>(false);
    const currencySymbol: Ref<string | null> = ref<string | null>(null);
    const userBranchId: Ref<number | null> = ref<number | null>(null);
    const orderItemType: Ref<number | null> = ref<number | null>(null);

    const substrateId: Ref = ref<number | null>(null);
    const substrate: Ref = ref<any | null>(null);
    const fixingId: Ref = ref<number | null>(null);
    const substrateColourId: Ref = ref<number | null>(null);
    const backingId: Ref<number | null> = ref<number | null>(null);
    const laminationId: Ref = ref<number | null>(null);

    const frameTypeId: Ref = ref<number | null>(null);
    const mouldingId: Ref = ref<number | null>(null);
    const glassId: Ref = ref<number | null>(null);
    const hangingOptionId: Ref = ref<number | null>(null);
    const windowMountId: Ref = ref<number | null>(null);
    const borderTopMm: Ref<number> = ref<number>(0);
    const borderRightMm: Ref<number> = ref<number>(0);
    const borderBottomMm: Ref<number> = ref<number>(0);
    const borderLeftMm: Ref<number> = ref<number>(0);
    const deliveryOptionId: Ref = ref<number | null>(null);
    const deliveryPriceJson: Ref = ref<object | null>(null);

    const priceExcludingVat: Ref<number | string | null> = ref<
      number | string | null
    >(null);

    const orderItemTypes: Ref<any[]> = ref<any[]>([]);
    const substrates: Ref<any[]> = ref<any[]>([]);
    const backings: Ref<any[]> = ref<any[]>([]);
    const frameTypes: Ref<any[]> = ref<any[]>([]);
    const mouldings: Ref<any[]> = ref<any[]>([]);
    const printSizes: Ref<any[]> = ref<any[]>([]);
    const glasses: Ref<any[]> = ref<any[]>([]);
    const windowMounts: Ref<any[]> = ref<any[]>([]);
    const laminations: Ref<any[]> = ref<any[]>([]);
    const fixings: Ref<any[]> = ref<any[]>([]);
    const hangingOptions: Ref<any[]> = ref<any[]>([]);
    const substrateColours: Ref<any[]> = ref<any[]>([]);

    const options: Ref = ref<any | null>(null);

    return {
      isLoading,
      currencySymbol,
      userBranchId,
      orderItemType,
      substrateId,
      substrate,
      fixingId,
      substrateColourId,
      backingId,
      laminationId,
      frameTypeId,
      mouldingId,
      glassId,
      hangingOptionId,
      windowMountId,
      borderTopMm,
      borderRightMm,
      borderBottomMm,
      borderLeftMm,
      priceExcludingVat,
      orderItemTypes,
      substrates,
      backings,
      frameTypes,
      mouldings,
      printSizes,
      glasses,
      windowMounts,
      laminations,
      fixings,
      hangingOptions,
      substrateColours,
      options,
      deliveryOptionId,
      deliveryPriceJson,
    };
  },
  data() {
    const currencies: any = {
      "1": "GBP",
      "2": "EUR",
      "3": "USD",
    };
    return {
      currencies,
    };
  },
  computed: {
    deliveryOptions(): Array<any> {
      return (window as any).cvDeliveryOptions.deliveries;
    },
    zones(): Array<any> {
      return (window as any).cvDeliveryOptions.zones;
    },
  },
  watch: {
    async show(val) {
      if (val === false) {
        return;
      }

      this.isLoading = true;

      try {
        const customerDetailsRequest = axios.post(
          "/form/pms/GetCustomerNameAndShippingAddresses",
          { customerId: this.$route.params.customerId },
        );
        const orderItemTypesRequest = axios.get("/form/pms/SpecialOrderTypes");
        const substratesRequest = axios.get("/form/pms/Substrates");
        const backingsRequest = axios.get("/form/pms/Backings");
        const frameTypesRequest = axios.get("/form/pms/Frames");
        const laminationsRequest = axios.get("/form/pms/Laminations");
        const hangingOptionsRequest = axios.get("/form/pms/HangingOptions");

        const response = await Promise.all([
          customerDetailsRequest,
          orderItemTypesRequest,
          substratesRequest,
          backingsRequest,
          frameTypesRequest,
          laminationsRequest,
          hangingOptionsRequest,
        ]);

        this.userBranchId = response[0].data.userBranchId;
        this.orderItemTypes = response[1].data.filter(
          (oit: any) =>
            oit.value == OrderItemType.Prints ||
            oit.value == OrderItemType.Frame ||
            oit.value == OrderItemType.Mounting,
        );
        this.substrates = response[2].data;
        this.backings = response[3].data;
        this.frameTypes = response[4].data.filter((ft: any) =>
          ft.Name.endsWith(" Frame"),
        );
        this.laminations = response[5].data;
        this.hangingOptions = response[6].data;

        const optionsResponse = await axios.get(
          `/tps/OrderOptions/OptionsVue?countryId=${response[0].data.countryId}`,
        );

        this.options = optionsResponse.data;

        this.windowMounts = this.options.colours.filter(
          (c: any) => c.ProductionType === 0,
        );
        this.glasses = this.options.glasses;

        const isGiclee =
          this.options.printTypes.find((pt: any) =>
            pt.papers.some((p: any) => p.Id === this.paperId),
          )?.Name === "Giclée";

        if (isGiclee) {
          this.substrates = this.substrates.filter(
            s => s.Name !== "Acrylic Reverse",
          );
        }

        this.isLoading = false;
      } catch (error: any) {
        console.error(error);

        this.isLoading = false;
        return;
      }

      if (!this.userBranchId) {
        return;
      }

      this.currencySymbol = getCurrencySymbol(this.userBranchId);
      const finishing = this.variant.MouldingFrameId ? "Frame" : null;
      this.orderItemType = this.orderItemTypes.find(
        orderItemType => orderItemType.name === finishing,
      )?.value;

      if (!this.variant) {
        return;
      }

      const variant = {
        ...this.variant,
        orderItemType: this.variant.ImagePricingSizeConfiguration.OrderItemType,
        substrateId: this.variant.ImagePricingSizeConfiguration.SubstrateId,
        fixingId: this.variant.ImagePricingSizeConfiguration.FixingId,
        substrateSizeId:
          this.variant.ImagePricingSizeConfiguration.SubstrateSizeId,
        backingId: this.variant.ImagePricingSizeConfiguration.BackingId,
        substrateColourId:
          this.variant.ImagePricingSizeConfiguration.SubstrateColourId,
        laminationId: this.variant.ImagePricingSizeConfiguration.LaminationId,
        frameTypeId: this.variant.ImagePricingSizeConfiguration.FrameTypeId,
        mouldingId: this.variant.ImagePricingSizeConfiguration.MouldingId,
        printSizeId: this.variant.ImagePricingSizeConfiguration.PrintSizeId,
        glassId: this.variant.ImagePricingSizeConfiguration.GlassId,
        hangingOptionId:
          this.variant.ImagePricingSizeConfiguration.HangingOptionId,
        windowMountId: this.variant.ImagePricingSizeConfiguration.WindowMountId,
        borderTopMm: this.variant.BorderTopMM,
        borderRightMm: this.variant.BorderRightMM,
        borderBottomMm: this.variant.BorderBottomMM,
        borderLeftMm: this.variant.BorderLeftMM,
        priceExcludingVat:
          this.variant.ImagePricingSizeConfiguration.PriceExcludingVat,
        DeliveryId: this.variant.ImagePricingSizeConfiguration.DeliveryId,
        DeliveryPriceJson:
          this.variant.ImagePricingSizeConfiguration.DeliveryPriceJson,
      };

      this.orderItemType = variant.orderItemType;
      this.substrateId = variant.substrateId;
      if (this.substrateId) {
        const printSizeResponse = await axios.get(
          `/form/pms/Sizes?entityId=${this.substrateId}&branchId=${this.userBranchId}&applySpecialOrderMarkDown=true
        `,
        );

        this.printSizes = printSizeResponse.data;

        this.substrate = this.substrates.find(s => s.Id === this.substrateId);

        const fixingsResponse = await axios.post(`/form/pms/Fixings`, {
          substrateId: this.substrateId,
          widthInches: this.widthInches,
          heightInches: this.heightInches,
        });

        const substrateColourResponse = await axios.post(
          `/form/pms/SubstrateColours`,
          { substrateId: this.substrateId },
        );

        this.substrateColours = substrateColourResponse.data;

        this.fixings = fixingsResponse.data;
      }
      this.substrateColourId = variant.substrateColourId;
      this.fixingId = variant.fixingId;
      this.backingId = variant.backingId;
      this.laminationId = variant.laminationId;
      this.frameTypeId = variant.frameTypeId;
      if (this.frameTypeId) {
        const mouldingResponse = await axios.get(
          `/form/pms/Mouldings?frameId=${this.frameTypeId}`,
        );

        this.mouldings = mouldingResponse.data;
      }
      this.mouldingId = variant.mouldingId;
      if (this.mouldingId) {
        const printSizeResponse = await axios.get(
          `/form/pms/Sizes?entityId=${this.mouldingId}&branchId=${this.userBranchId}&applySpecialOrderMarkDown=true
        `,
        );

        this.printSizes = printSizeResponse.data;
      }
      this.glassId = variant.glassId;
      this.hangingOptionId = variant.hangingOptionId;
      this.windowMountId = variant.windowMountId;
      this.borderTopMm = variant.borderTopMm;
      this.borderRightMm = variant.borderRightMm;
      this.borderBottomMm = variant.borderBottomMm;
      this.borderLeftMm = variant.borderLeftMm;
      this.priceExcludingVat = variant.priceExcludingVat;
      this.deliveryOptionId = variant.DeliveryId ?? null;
      this.deliveryPriceJson = variant.DeliveryPriceJson
        ? JSON.parse(variant.DeliveryPriceJson)
        : null;
    },
  },
  methods: {
    close(): void {
      this.reset();

      this.$emit("close");
    },
    save(): void {
      const response = {
        orderItemType: this.orderItemType,
        substrateId: this.substrateId,
        fixingId: this.fixingId,
        backingId: this.backingId,
        substrateColourId: this.substrateColourId,
        laminationId: this.laminationId,
        frameTypeId: this.frameTypeId,
        mouldingId: this.mouldingId,
        glassId: this.glassId,
        hangingOptionId: this.hangingOptionId,
        windowMountId: this.windowMountId,
        borderTopMm: this.borderTopMm,
        borderRightMm: this.borderRightMm,
        borderBottomMm: this.borderBottomMm,
        borderLeftMm: this.borderLeftMm,
        priceExcludingVat: this.priceExcludingVat,
        variant: this.variant,
        customFinishingDetails: this.getFinishingDetails(),
        deliveryId: this.deliveryOptionId,
        deliveryPriceJson: this.deliveryPriceJson
          ? JSON.stringify(this.deliveryPriceJson)
          : null,
      };

      if (this.orderItemType === 3) {
        response.borderTopMm = this.variant.BorderTopMM;
        response.borderRightMm = this.variant.BorderRightMM;
        response.borderBottomMm = this.variant.BorderBottomMM;
        response.borderLeftMm = this.variant.BorderLeftMM;
      }

      this.reset();

      this.$emit("close", response);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") {
        this.close();
      }
    },
    getFinishingDetails(): string | null {
      if (this.orderItemType === OrderItemType.Prints) {
        const printOnly = "Print Only";
        const freeTag = +(this.priceExcludingVat ?? 0) == 0 ? ", Free" : "";

        return `${printOnly}${freeTag}`;
      }

      if (this.orderItemType === OrderItemType.Frame) {
        const frameType = this.frameTypes.find(f => f.Id === this.frameTypeId);
        const moulding = this.mouldings.find(
          m => m.EntityId === this.mouldingId,
        );
        const glass = this.glasses.find(g => g.Id === this.glassId);
        const hangingOption = this.hangingOptions.find(
          ho => ho.Id === this.hangingOptionId,
        );

        const opts = [
          frameType?.Name,
          moulding?.Description,
          glass?.Name,
          hangingOption?.Name,
        ];

        if (this.windowMountId) {
          opts.push(
            `${this.borderTopMm || 0}mm x ${this.borderRightMm || 0}mm x ${
              this.borderBottomMm || 0
            }mm x ${this.borderLeftMm || 0}mm`,
          );
        }

        return opts.filter(i => !!i).join(", ");
      }

      if (this.orderItemType === OrderItemType.Mounting) {
        const substrate = this.substrates.find(s => s.Id === this.substrateId);
        const lamination = this.laminations.find(
          l => l.Id === this.laminationId,
        );
        const fixing = this.fixings.find(f => f.Id === this.fixingId);
        const backing = this.backings.find(b => b.Id === this.backingId);
        const substrateColour = this.substrateColours.find(
          (sc: any) => sc.Id === this.substrateColourId,
        );

        return [
          substrate?.Name,
          lamination?.Name,
          fixing?.Name,
          backing?.Name,
          substrateColour?.Name,
        ]
          .filter(i => !!i)
          .join(", ");
      }

      return null;
    },
    onOrderItemTypeChanged(): void {
      this.fieldReset();
    },
    async onSubstrateChanged(): Promise<void> {
      if (!this.substrate?.SupportsBacking) {
        this.backingId = null;
      }

      if (this.substrateId >= 56 && this.substrateId <= 59) {
        this.fixingId = null;
      }

      this.printSizes = [];

      if (!this.substrateId) {
        return;
      }

      this.substrate = this.substrates.find(s => s.Id === this.substrateId);

      try {
        const printSizeResponse = await axios.get(
          `/form/pms/Sizes?entityId=${this.substrateId}&branchId=${this.userBranchId}&applySpecialOrderMarkDown=true
        `,
        );

        this.printSizes = printSizeResponse.data;

        const fixingsResponse = await axios.post(`/form/pms/Fixings`, {
          substrateId: this.substrateId,
          widthInches: this.widthInches,
          heightInches: this.heightInches,
        });

        this.fixings = fixingsResponse.data;

        const substrateColourResponse = await axios.post(
          `/form/pms/SubstrateColours`,
          { substrateId: this.substrateId },
        );

        this.substrateColours = substrateColourResponse.data;

        this.setPriceExcludingVat();
      } catch (error: any) {
        console.error(error);
      }
    },
    onFixingChanged(): void {
      this.setPriceExcludingVat();
    },
    onLaminationChanged(): void {
      if (!this.substrate?.SupportsLamination) {
        this.laminationId = null;
      }

      this.setPriceExcludingVat();
    },
    onBackingChanged(): void {
      this.setPriceExcludingVat();
    },
    async onFrameTypeChange(): Promise<void> {
      if (!this.frameTypeId) {
        return;
      }

      this.mouldings = [];
      this.mouldingId = null;

      try {
        const mouldingResponse = await axios.get(
          `/form/pms/Mouldings?frameId=${this.frameTypeId}`,
        );

        this.mouldings = mouldingResponse.data;
      } catch (error: any) {
        console.error(error);
      }

      this.setPriceExcludingVat();
    },
    async onMouldingChanged(): Promise<void> {
      if (!this.mouldingId) {
        return;
      }

      this.printSizes = [];

      try {
        const printSizeResponse = await axios.get(
          `/form/pms/Sizes?entityId=${this.mouldingId}&branchId=${this.userBranchId}&applySpecialOrderMarkDown=true
        `,
        );

        this.printSizes = printSizeResponse.data;

        this.setPriceExcludingVat();
      } catch (error: any) {
        console.error(error);
      }
    },
    onGlassChanged(): void {
      this.setPriceExcludingVat();
    },
    onHangingOptionChanged(): void {
      this.setPriceExcludingVat();
    },
    onWindowMountChanged(): void {
      this.setPriceExcludingVat();
    },
    reset(): void {
      this.orderItemType = null;

      this.fieldReset();
    },
    fieldReset(): void {
      this.substrateId = null;
      this.substrate = null;
      this.fixingId = null;
      this.backingId = null;
      this.laminationId = null;
      this.frameTypeId = null;
      this.mouldingId = null;
      this.glassId = null;
      this.hangingOptionId = null;
      this.windowMountId = null;
      this.borderTopMm = this.variant.BorderTopMM;
      this.borderRightMm = this.variant.BorderRightMM;
      this.borderBottomMm = this.variant.BorderBottomMM;
      this.borderLeftMm = this.variant.BorderLeftMM;
      this.priceExcludingVat = null;
      this.substrateColourId = null;
      this.deliveryOptionId = null;
      this.deliveryPriceJson = null;
    },
    calculatePrice(): number {
      const variant = {
        BorderRightMM: this.borderRightMm,
        BorderLeftMM: this.borderLeftMm,
        BorderTopMM: this.borderTopMm,
        BorderBottomMM: this.borderBottomMm,
        LongSideInches:
          this.widthInches > this.heightInches
            ? this.widthInches
            : this.heightInches,
        ShortSideInches:
          this.widthInches > this.heightInches
            ? this.heightInches
            : this.widthInches,
        MouldingId: this.mouldingId,
        FrameTypeId: this.frameTypeId,
        ColourId: this.windowMountId,
        SubstrateId: this.substrateId,
        FixingId: this.fixingId,
        GlassId: this.glassId,
      };

      return CalculateOrderItemPricing(
        variant,
        this.image,
        this.paperId,
        this.options,
        null,
        this.options.ecommerceHandlingFee,
      );
    },
    setPriceExcludingVat(): void {
      const price = this.calculatePrice();
      this.priceExcludingVat = +price.toFixed(2);
    },
    isValid(): boolean {
      if (this.orderItemType === OrderItemType.Prints) {
        return true;
      }

      if (this.orderItemType === OrderItemType.Mounting) {
        return !!this.substrateId && this.substrate?.SupportsBacking
          ? !!this.backingId
          : true;
      }

      if (this.orderItemType === OrderItemType.Frame) {
        return (
          !!this.frameTypeId && !!this.mouldingId && !!this.priceExcludingVat
        );
      }

      return false;
    },
    onDeliveryOptionChange(event: any) {
      this.deliveryOptionId = event.target.value;
      this.deliveryPriceJson = this.zones.map((z: any) =>
        Object.assign(
          {
            Prices: [
              { CurrencyId: 1, Value: 0 },
              { CurrencyId: 2, Value: 0 },
              { CurrencyId: 3, Value: 0 },
            ],
          },
          z,
        ),
      );
    },
  },
});
