
import { defineComponent, ref, Ref } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import {
  FormDataView,
  FormDataViewGridFilterParamValue,
  FormDataViewGroup,
  FormDataViewParam,
} from "@/core/types/formDataViewsModel";
import { Notification, Tooltip } from "@progress/kendo-popups-vue-wrapper";
import { Loader } from "@progress/kendo-vue-indicators";

import {
  initDataViewGroups,
  validateDataView,
  downloadDataView,
} from "@/core/dataViewsHelpers";

import {
  AutoComplete,
  ComboBox,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-vue-dropdowns";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardActions,
  TabStrip,
  TabStripTab,
  PanelBar,
} from "@progress/kendo-vue-layout";
import { DateRangePicker } from "@progress/kendo-vue-dateinputs";
import { postJson } from "tps.shared.ui/core/requestHelpers";
import axios from "axios";
declare var $: any;

export default defineComponent({
  name: "SpecialReportsList",
  components: {
    KCard: Card,
    KCardHeader: CardHeader,
    KCardBody: CardBody,
    KCardTitle: CardTitle,
    KCardSubtitle: CardSubtitle,
    KCardActions: CardActions,
    KTabStrip: TabStrip,
    KTabStripTab: TabStripTab,
    KPanelBar: PanelBar,
    KNotification: Notification,
    KTooltip: Tooltip,
    KButton: Button,
    KInput: Input,
    KAutoComplete: AutoComplete,
    KComboBox: ComboBox,
    KDropDownList: DropDownList,
    KMultiSelect: MultiSelect,
    "kendo-daterangepicker": DateRangePicker,
    loader: Loader,
  },
  setup() {
    const DataViewGroups: Ref<FormDataViewGroup[]> = ref([]);
    const PopupNotification: Ref<any> = ref(null);
    const UserIsInRole: Ref<any> = ref(null);
    return { DataViewGroups, PopupNotification, UserIsInRole };
  },
  data() {
    const wdgt: any = {};
    const isLoaderShow = ref(false);
    return {
      PopupNotificationWidget: wdgt,
      selected: 0,
      isLoaderShow,
    };
  },
  async mounted() {
    try {
      this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
      const data = await this.$dataViews.LoadDataViews();
      this.DataViewGroups = initDataViewGroups(data);
      this.setSelectedDataViewGroup();
      if (!this.$account.userInRole) {
        await this.$account.getUserInRole();
      }
      this.UserIsInRole = this.$account.userInRole;
    } catch (e) {
      this.PopupNotificationWidget?.show(
        "an error occurred while loading the component",
        "error",
      );
    }
  },
  methods: {
    getDataView(groupIndex: number, dataViewIndex: number): FormDataView {
      const group = this.DataViewGroups[groupIndex];
      return group.Views[dataViewIndex];
    },
    async dispatchAction(groupIndex: number, dataViewIndex: number) {
      const dataView = this.getDataView(groupIndex, dataViewIndex);
      console.log("dataView", dataView);
      if (!validateDataView(dataView)) {
        this.PopupNotificationWidget?.show(
          "Please fill out all the required parameters",
          "error",
        );
        return;
      }
      const isBase64Encoded: boolean =
        dataView.DataSource.Url.toLocaleLowerCase().includes("pickinglabelspdf")
          ? true
          : false;
      this.isLoaderShow = true;
      //const [success, showModal, msg] = [true, false, "Test"];

      const [success, showModal, msg] = await downloadDataView(
        $.ajax,
        this.$dataViews,
        dataView,
        isBase64Encoded,
        true,
      );
      this.isLoaderShow = showModal;
      const [tpl, type] = success
        ? ["Report successfully executed", "success"]
        : [msg, "error"];

      this.PopupNotificationWidget?.show(tpl, type);
    },
    getPanelBarItems(
      groupIndex: number,
      dataViewIndex: number,
    ): {
      title: string;
      content: string;
      expanded: boolean;
      parms: FormDataViewParam[];
    }[] {
      const dataView = this.getDataView(groupIndex, dataViewIndex);
      dataView.Parameters.filter(param => param.ParamType == 8).forEach(param =>
        param.ParamValues.forEach(value => {
          if (Array.isArray(value.ValueObj) || !value.ValueObj) {
            const start = new Date();
            const end = new Date();

            start.setDate(end.getDate() - 5);
            value.ValueObj = {
              start: start,
              end: end,
            };
            return;
          }
          if (typeof value.ValueObj === "object") {
            return;
          }
          value.ValueObj = value.ValueObj.toString().replace("//", "");
          const object = JSON.parse(value.ValueObj);
          value.ValueObj = {
            start: new Date(object.start),
            end: new Date(object.end),
          };
        }),
      );
      var items = [
        {
          title: "Parameters",
          content: "first",
          expanded: true,
          parms: dataView.Parameters,
        },
      ];

      return items;
    },
    setSelectedDataViewGroup(): void {
      const selectedDataViewGroup = localStorage.getItem(
        "selectedDataViewGroup",
      );
      if (selectedDataViewGroup)
        this.selected = parseInt(selectedDataViewGroup);
    },
    onSelect(e: any): void {
      this.selected = e.selected;
      localStorage.setItem("selectedDataViewGroup", this.selected.toString());
    },
    getParamValue(
      filters: FormDataViewGridFilterParamValue[],
      dataView: FormDataView,
    ): FormDataViewGridFilterParamValue {
      if (!this.UserIsInRole) {
        return filters[0];
      }
      const gridFilterIndex = dataView.GridFilters.findIndex(
        filter => filter?.UserId == this.UserIsInRole.userId,
      );
      if (gridFilterIndex == -1) {
        return filters[0];
      }
      return filters[gridFilterIndex];
    },
  },
});
