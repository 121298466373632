
import { defineComponent, ref, Ref } from "vue";
import * as utils from "@/core/utils";
import * as ku from "@/core/kendoUtils";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import {
  FormDataViewGridFilter,
  FormDataViewGridFilterColor,
} from "@/core/types/formDataViewsModel";
import { GridFilterType } from "@/core/enums/grid-filter.type";
import { TabStrip, TabStripTabProperties } from "@progress/kendo-vue-layout";

declare var $: any;

const getFileNameFromUrl = (url: string) => url.split("/").pop();

export default defineComponent({
  name: "OrdersList",
  components: {
    KButton: Button,
    KButtonGroup: ButtonGroup,
    KTabStrip: TabStrip,
  },
  setup() {
    const grid: any = ref(null);
    const GridFilters: Ref<FormDataViewGridFilter[]> = ref([]);
    const GridFilterCategories: Ref<TabStripTabProperties[]> = ref([]);
    const isExcelButtonClicked: Ref<boolean> = ref(false);
    const isSaveFilterLoading: Ref<boolean> = ref(false);
    const orderNumberValue: Ref<number | null> = ref(null);
    const customerValue: Ref<string | null> = ref(null);
    const ownerValue: Ref<string | null> = ref(null);
    const trackingNumberValue: Ref<string | null> = ref(null);
    const isShowingSearch: Ref<boolean> = ref(false);
    return {
      GridFilters,
      GridFilterCategories,
      grid,
      isExcelButtonClicked,
      isSaveFilterLoading,
      orderNumberValue,
      customerValue,
      ownerValue,
      isShowingSearch,
      trackingNumberValue,
    };
  },
  data() {
    return {
      storageKey: `${this.$options.name}GridOptions`,
      gridHandle: "#gridOrders",
      selected: 0,
      selectedTab: 0,
    };
  },
  async mounted() {
    const _window: any = window;
    // eslint-disable-next-line
    const _this = this;
    const grid = this.grid;
    _window.router = this.$router;
    _window.excelButtonClick = () => {
      const grid = $(this.gridHandle).data("kendoGrid");
      const dsr = ku.gridParametersToDataSourceRequest(grid);
      this.isExcelButtonClicked = true;
      const filters = _window.ordersGridFilterGetter();
      this.isExcelButtonClicked = false;
      const csvUrl = "/form/grid/csv";
      $.post(csvUrl, { ...dsr, ...filters }).then((resp: any) => {
        var request = new XMLHttpRequest();

        request.addEventListener("readystatechange", e => {
          if (request.readyState == 2 && request.status == 200) {
            // Download is being started
            $("#export-downloading").removeClass("hidden");
          } else if (request.readyState == 3) {
            // Download is under progress
          } else if (request.readyState == 4) {
            // Downloaing has finished
            _window._OBJECT_URL = URL.createObjectURL(request.response);
            const fileName = getFileNameFromUrl(resp.Location);

            const a = _window.document.createElement("a");
            a.href = _window._OBJECT_URL;
            a.download = fileName;
            _window.document.body.appendChild(a);
            a.click();
            _window.document.body.removeChild(a);
            $("#export-downloading").addClass("hidden");
            $.ajax(`${csvUrl}?fileName=${fileName}`, { type: "DELETE" });

            setTimeout(() => {
              _window.URL.revokeObjectURL(_window._OBJECT_URL);
            }, 60 * 1000);
          }
        });

        request.addEventListener("progress", e => {
          //const percent_complete = (e.loaded / e.total) * 100;
        });

        request.responseType = "blob";
        request.open("get", resp.Location);
        request.send();
      });
    };
    _window.ordersGridFilterGetter = () => {
      const result = {
        gridFilterId: _this.selected,
        monthOffset: this.isExcelButtonClicked
          ? (document.getElementById("toolBarInput") as HTMLInputElement)
              ?.value ?? 1
          : null,
        customerSearch: this.customerValue,
        orderNumberSearch: this.orderNumberValue,
        ownerSearch: this.ownerValue,
        trackingNumberSearch: this.trackingNumberValue,
      };

      this.isExcelButtonClicked = false;
      return result;
    };
    _window.openPms = function (e: any) {
      e.preventDefault();
      const grid = $("#gridOrders").getKendoGrid();

      const item = grid.dataItem($(e.currentTarget).closest("tr"));
      const pmsBaseUrl = utils.getPmsBaseUrl(_window);

      const pmsUrl = `${pmsBaseUrl}/PMS#showOrder/${item.Id}`;
      _window.open(pmsUrl);
    };
    _window.openNewTab = function (e: any) {
      e.preventDefault();
      const grid = $("#gridOrders").getKendoGrid();

      const item = grid.dataItem($(e.currentTarget).closest("tr"));

      _window.open(`/orders/${item.Id}`, "_blank");

      return false;
    };
    _window.ordersApplyGridOptions = () => {
      setTimeout(() => {
        const grid = $(this.gridHandle).data("kendoGrid");
        ku.setGridStateToStorage($, grid, GridFilterType.Orders, _window);
        $(".k-grid-delete").on("click", function (e: any) {
          _window.openNewTab(e);
        });
      }, 500);
    };
    _window.getOrderWithQuoteType = (OrderType: any, IsFinalised: boolean) => {
      if (!IsFinalised) {
        return "<span style='color: #005580; text-decoration: underline;'>Quote</span>";
      }
      return "<span>" + OrderType + "</span>";
    };

    if (!_window.getNotesFlag)
      _window.getNotesFlag = (
        NotesCount: number | null,
        AdvisoryNotesCount: number | null,
      ) => {
        if (AdvisoryNotesCount && AdvisoryNotesCount > 0) {
          return "<span class='k-icon k-i-exclamation-circle'></span>";
        }

        return "<span></span>";
      };

    this.$dataViews.LoadGridFilterCategories().then(categories => {
      this.GridFilterCategories = categories.map(category => ({
        id: category.Id.toString(),
        title: category.Name,
        content: category.Id.toString(),
      }));
      this.GridFilterCategories.unshift({
        id: "0",
        title: "None",
        content: "0",
      });
      this.$dataViews.LoadGridFiltersBySourceName("OrderList").then(data => {
        _this.GridFilters = data;
        const selectedFilterId = localStorage.getItem(
          `${GridFilterType[GridFilterType.Orders]}SelectedDataFilter`,
        );
        if (selectedFilterId) {
          const id = parseInt(selectedFilterId);
          const selected = _this.GridFilters.find(o => o.Id == id);
          const gf = selected ?? _this.GridFilters[0];
          this.selected = gf.Id ?? 0;
          this.selectedTab = this.GridFilterCategories.findIndex(
            category =>
              parseInt(category.id) === (gf.FormDataGridFilterCategoryId ?? 0),
          );
        } else {
          const defaultFilter = _this.GridFilters[0];
          localStorage.setItem(
            `${GridFilterType[GridFilterType.Orders]}SelectedDataFilter`,
            `${defaultFilter.Id}`,
          );
          this.selected = defaultFilter.Id ?? 0;
        }

        $.get("/form/grid/orderPartial", {}, (html: string) => {
          html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
          html = html.replace(/\/grid\//gi, "/form$&"); // fix up server url slug
          $(grid).html(html);
          $(grid).on("click", "td", async (e: any) => {
            const el = e.target;
            if ($(el).hasClass("k-grid-delete") || $(el).hasClass("k-icon"))
              return;

            const row = $(el).parents("tr");
            let id = row.find(".row-id").text();
            let gotoPage = `/orders/${id}`;

            if ($(el).hasClass("email-field")) {
              if ($(el).hasClass("is-user")) {
                id = row.find(".user-id").text();
              } else if ($(el).hasClass("is-owner")) {
                id = row.find(".owner-id").text();
              }
              gotoPage = `/customers/${id}`;
            }
            const data = await this.$specialOrder.getOrderQuoteStatus(id);
            if (data && !data.IsFinalised) {
              gotoPage = `/edit-order/${id}`;
            }
            sessionStorage.setItem("scrollToLastOpenedOrderId", id);
            //_window.router.push(gotoPage);
            _window.open(gotoPage, "_blank");
          });

          const gf = _this.GridFilters.find(o => o.Id == this.selected);
          _this.initGridFilter(gf);
        });
      });
    });
  },
  methods: {
    initGridFilter(gridFilter: FormDataViewGridFilter | undefined) {
      const grid = $(this.gridHandle).data("kendoGrid");
      if (gridFilter && !gridFilter?.Unfiltered) {
        for (let i = 0; i < grid.columns.length; i++) {
          grid.hideColumn(i);
        }
        grid.showColumn(0);
        for (const column of gridFilter.IncludedColumns) {
          grid.showColumn(column.FieldPath);
        }
      } else {
        //Reset columns
        for (let i = 0; i < grid.columns.length; i++) {
          grid.showColumn(i);
        }
      }
      const modifiedDate = new Date(gridFilter?.DateModified ?? "");
      const updatedTimeByFilter: string | null = localStorage.getItem(
        `${ku.getOrderStateKey(GridFilterType.Orders)}_Updated_Time`,
      );
      const updatedDate = new Date(updatedTimeByFilter ?? "");
      if (
        (gridFilter?.DateModified && updatedDate < modifiedDate) ||
        updatedTimeByFilter == null
      ) {
        const nameFilter: string | null = ku.getOrderStateKey(
          GridFilterType.Orders,
        );
        ku.removeState(GridFilterType.Orders);
        $.post(`/form/grid/DeleteFiltersByName`, {
          name: nameFilter,
          clearAll: false,
        }).done(() => {
          ku.setToGridState(
            $,
            grid,
            GridFilterType.Orders,
            `${this.gridHandle} .k-grid-toolbar`,
          );
          const utcDate = new Date(new Date().toUTCString().slice(0, -4));
          localStorage.setItem(
            `${ku.getOrderStateKey(GridFilterType.Orders)}_Updated_Time`,
            utcDate.toString(),
          );
        });
        return;
      }

      ku.setToGridState(
        $,
        grid,
        GridFilterType.Orders,
        `${this.gridHandle} .k-grid-toolbar`,
      );
    },
    getButtonClass(gridFilter: FormDataViewGridFilter): string {
      return FormDataViewGridFilterColor[gridFilter.Color] + "-color-filter";
    },
    isSelected(id: number) {
      return id === this.selected;
    },
    onSelectTab: function (e: any) {
      this.selectedTab = e.selected;
    },
    getGridFiltersByCategoryId(categoryId: string): FormDataViewGridFilter[] {
      return this.GridFilters.filter(
        filter =>
          (filter?.FormDataGridFilterCategoryId ?? 0) == parseInt(categoryId),
      );
    },
    onSelectEvent(id: number, e: any): void {
      this.clearSearch();
      setTimeout(() => {
        this.onSelect(id, e);
      }, 10);
    },
    onSelect(id: number, e: any) {
      const _window: any = window;
      _window.router = this.$router;
      e.preventDefault();
      this.selected = id;
      localStorage.setItem(
        `${GridFilterType[GridFilterType.Orders]}SelectedDataFilter`,
        id.toString() || "-1",
      );
      const grid = $(this.gridHandle).data("kendoGrid");

      grid.dataSource.filter({});
      grid.dataSource.sort({});
      grid.dataSource.read();

      this.grid = null;
      this.$dataViews.LoadGridFiltersBySourceName("OrderList").then(data => {
        this.GridFilters = data;

        $.get("/form/grid/orderPartial", {}, (html: string) => {
          html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
          html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug

          $(this.grid).html(html);
          $(this.grid).on("click", "td", function (e: any) {
            const el = e.target;
            if ($(el).hasClass("k-grid-delete") || $(el).hasClass("k-icon"))
              return;
            const row = $(el).parents("tr");
            let id = row.find(".row-id").text();
            let gotoPage = `/orders/${id}`;

            if ($(el).hasClass("email-field")) {
              if ($(el).hasClass("is-user")) {
                id = row.find(".user-id").text();
              } else if ($(el).hasClass("is-owner")) {
                id = row.find(".owner-id").text();
              }
              gotoPage = `/customers/${id}`;
            }
            _window.router.push(gotoPage);
          });

          const gf = this.GridFilters.find(o => o.Id == this.selected);
          this.initGridFilter(gf);
        });
      });
    },
    refreshGrid(): void {
      const grid = $(this.gridHandle).data("kendoGrid");
      if (grid) {
        grid.dataSource.read();
      }
    },
    clearSearchClick(): void {
      this.clearSearch();
      this.refreshGrid();
    },
    clearSearch(): void {
      this.orderNumberValue = null;
      this.ownerValue = null;
      this.customerValue = null;
      this.trackingNumberValue = null;
    },
    isClearSearch(): boolean {
      return (
        !!this.orderNumberValue ||
        !!this.customerValue ||
        !!this.ownerValue ||
        !!this.trackingNumberValue
      );
    },
    openSearchMenu(): void {
      const sidePanel = document.getElementById("searchOrderSidePanel");
      if (sidePanel) {
        sidePanel.style.width = "250px";
        sidePanel.style.border = "2px solid #00000047";
        sidePanel.style.padding = "10px";
      }
    },

    closeSearchMenu() {
      const sidePanel = document.getElementById("searchOrderSidePanel");
      if (sidePanel) {
        sidePanel.style.width = "0";
        sidePanel.style.border = "none";
        sidePanel.style.padding = "0";
      }
    },
  },
});
