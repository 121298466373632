
import {defineComponent, PropType, ref, Ref} from "vue";
import axios from "axios";
import {Grid, GridToolbar} from '@progress/kendo-vue-grid';
import {getCurrencySymbol} from "@/core/utils";

export default defineComponent({
  name: "CustomerInvoices",
  components: {
    Grid: Grid,
    GridToolbar: GridToolbar
  },
  props: {
    userId: {
      type: Object as PropType<number>,
      required: false
    },
  },
  data() {
    return {
      currencySymbol: ''
    }
  },
  setup() {
    const invoices: Ref<any[]> = ref([]);
    const isLoading: Ref<boolean> = ref<boolean>(false);

    return {
      invoices,
      isLoading
    }
  },
  async mounted() {
    this.isLoading = true;

    const customerDetailsRequest = axios.post(
      "/form/pms/GetCustomerNameAndShippingAddresses",
      {customerId: this.userId}
    );

    const response = await Promise.all([customerDetailsRequest]);

    const customerDetailsResponse = response[0].data;

    this.currencySymbol = getCurrencySymbol(customerDetailsResponse.userBranchId);

    this.invoices.push({})

    // TODO: load invoices.

    this.isLoading = false;
  },
  computed: {
    columns() {
      return [
        {
          title: 'Invoice №',
          cell: 'invoiceNumber',
        },
        {
          title: 'Date from',
          cell: 'dateFrom',
        },
        {
          title: 'Date to',
          cell: 'dateTo',
        },
        {
          title: 'Net total',
          cell: 'netTotal',
        },
        {
          title: 'VAT',
          cell: 'vat',
        },
        {
          title: 'Total',
          cell: 'total',
        },
        {
          title: 'Invoice',
          cell: 'invoice',
          width: '180px',
        },
        {
          title: 'Transactions',
          cell: 'transactions',
          width: '180px',
        },
      ]
    }
  }
});
