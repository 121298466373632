import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5edb3f7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_productivity_tab = _resolveComponent("productivity-tab")!
  const _component_k_tab_strip_tab = _resolveComponent("k-tab-strip-tab")!
  const _component_k_tab_strip = _resolveComponent("k-tab-strip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_tab_strip, {
      selected: _ctx.selectedTab,
      onSelect: _ctx.onSelectedTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_k_tab_strip_tab, {
            key: tab.id,
            title: tab.title
          }, {
            default: _withCtx(() => [
              _createVNode(_component_productivity_tab, _mergeProps(tab.props, {
                onAggregateChanged: _ctx.onAggregateChanged,
                onDateRangeChanged: _ctx.onDateRangeChanged
              }), null, 16, ["onAggregateChanged", "onDateRangeChanged"])
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["selected", "onSelect"])
  ]))
}