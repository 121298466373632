import { Filters, ShopifyOrder, ShopifyShop } from "../types/shopify";
import axios from "axios";
import Papa from "papaparse";

export function parseCsv(content: string) {
  const result = Papa.parse(content, {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  });
  if (result.errors.length > 0) {
    console.error("parseCsv::errors", result.errors);
  }
  return result.data as any[];
}

// ----------------------------------------------------------------------------

export async function findShop(url: string): Promise<ShopifyShop> {
  const response = await axios.post("/form/shopify/ShopDetails", {
    shopUrl: url,
  });

  return response.data;
}

// ----------------------------------------------------------------------------

export async function retrieveOrders(
  filters: Filters,
): Promise<ShopifyOrder[]> {
  const response = await axios.post("/form/shopify/RetrieveOrders", filters);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function importOrder(shopUrl: string, order: ShopifyOrder) {
  const response = await axios.post("/form/shopify/ImportOrder", {
    shopUrl,
    order,
  });

  return response.data;
}

// ----------------------------------------------------------------------------

export async function importAll(shopUrl: string, orders: ShopifyOrder[]) {
  const response = await axios.post("/form/shopify/ImportOrder", {
    shopUrl,
    orders,
  });

  return response.data;
}

// ----------------------------------------------------------------------------

export async function save(shopUrl: string, orders: ShopifyOrder[]) {
  const response = await axios.post("/form/shopify/SaveOrders", {
    shopUrl,
    orders,
  });

  return response.data;
}

export async function forceProcessing(): Promise<void> {
  await axios.get(`/form/shopify/ForceProcessing`);
}
// ----------------------------------------------------------------------------
