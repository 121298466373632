
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "GenericModalVue",
  props: {
    show: Boolean,
    bodyText: String,
    headerText: String,
    isSuccess: Boolean,
    isError: Boolean,
    isWarning: Boolean,
    isInfo: Boolean,
  },
  methods: {
    getTitleClass(): string {
      if (this.isSuccess) {
        return "success";
      }
      if (this.isError) {
        return "error";
      }
      if (this.isWarning) {
        return "warning";
      }

      return "info";
    },
  },
});
