// THIS FILE IS A FULL COPY OF THE SAME FILE IN THE CH APPLICATION,
// IF YOU ARE MAKING ANY CHANGES HERE PLEASE ALSO UPDATE THE CORRESPONDENT FILE IN CH.

export enum CoaId {
  Classic = 1,
  Minimalist = 2,
  Clean = 3,
  Typewriter = 4,
  Traditional = 5,
  Modern = 6,
  Nft = 7,
}

export interface Pricing {
  Price: number;
  Id: number | null;
}

const toleranceInches = 0.08;

const makeEmptyPricing = (price = 0): Pricing => ({ Id: null, Price: price });

function pricingForSize(
  widthInches: number,
  heightInches: number,
  option: any,
) {
  const unusedArea = (candidate: any): number =>
    candidate.height * candidate.width - widthInches * heightInches;
  const withTolerance = (
    dimension: number,
    tolerance = toleranceInches,
  ): number => dimension + tolerance;

  const makeCandidatePricing = (pricing: any) => ({
    width: withTolerance(pricing.WidthInches),
    height: withTolerance(pricing.HeightInches),
    pricing,
  });
  const printFits = (candidate: any): boolean =>
    (widthInches <= candidate.width && heightInches <= candidate.height) ||
    (widthInches <= candidate.height && heightInches <= candidate.width);

  if (!option?.pricing) {
    return makeEmptyPricing();
  }
  const candidates = option.pricing
    .map(makeCandidatePricing)
    .filter(printFits)
    .sort((a: any, b: any) => unusedArea(a) - unusedArea(b));

  return candidates?.length ? candidates[0].pricing : makeEmptyPricing(0);
}

function getPrintPrice(
  widthInches: number,
  heightInches: number,
  paperId: number,
  options: any,
) {
  const paper = options.papers.find((paper: any) => paper.Id == paperId);
  const paperPriceObj = pricingForSize(widthInches, heightInches, paper);
  return Number(paperPriceObj.Price);
}

function getFramingPrice(
  widthInches: number,
  heightInches: number,
  oi: any,
  options: any,
) {
  if (!oi.MouldingId || !oi.FrameTypeId) return 0;

  const frameTypeId = oi.FrameTypeId;
  const mouldingId = oi.MouldingId;

  const frame = options?.frameTypes.find((f: any) => f.Id == frameTypeId);

  const mouldings = frame.mouldings.find(
    (moulding: any) => moulding.Id == mouldingId,
  );
  const fmp = pricingForSize(widthInches, heightInches, mouldings);

  const mountingPrice = getMountingPrice(
    fmp.WidthInches,
    fmp.HeightInches,
    oi,
    options,
  );

  const glass = options.glasses.find((g: any) => g.Id === oi.GlassId);
  const glassPricing = pricingForSize(widthInches, heightInches, glass);

  return fmp.Price + mountingPrice + glassPricing?.Price;
}

function getCustomMountingPrice(
  widthInches: number,
  heightInches: number,
  oi: any,
  options: any,
) {
  if (!oi.SubstrateId) return 0;

  const substrate = options.substrates.find((o: any) => o.Id == oi.SubstrateId);
  const substratePricing = pricingForSize(widthInches, heightInches, substrate);
  const fixing = substrate.fixings.find((o: any) => o.Id == oi.FixingId);
  const fixingPricing = pricingForSize(widthInches, heightInches, fixing);

  return substratePricing?.Price + fixingPricing.Price;
}

function getMountingPrice(
  widthInches: number,
  heightInches: number,
  oi: any,
  options: any,
) {
  const colours = options.colours?.find(
    (colour: any) => colour.Id === oi.ColourId,
  );
  const colour = colours?.pricing.find(
    (mp: any) =>
      mp.WidthInches == widthInches && mp.HeightInches == heightInches,
  );
  return colour?.Price ?? 0;
}

export function getValueAddonsPricing(oi: any, file: any, user: any) {
  let result = 0;
  if (file.coaId != null && file.coaId > 1) {
    result +=
      file.coaId == CoaId.Nft ? user?.nftCosts.Cost : user?.coaCosts.Cost;
  }

  if (file.printInsertId != null) result += user?.insertCosts.Cost;
  if (user?.brandedDeliveryEnabled) result += user?.packagingCosts.Cost;
  return result;
}

export function CalculateOrderItemPricing(
  oi: any,
  file: any,
  paperId: number,
  options: any,
  user: any,
  handlingFee: number,
): number {
  const isPortrait = file.Width > file.Height;

  const borderWidthInches = (oi.BorderRightMM + oi.BorderLeftMM) / 25.4;
  const borderHeightInches = (oi.BorderTopMM + oi.BorderBottomMM) / 25.4;

  const printWidthInches =
    (isPortrait ? oi.LongSideInches : oi.ShortSideInches) + borderWidthInches;

  const printHeightInches =
    (isPortrait ? oi.ShortSideInches : oi.LongSideInches) + borderHeightInches;

  const printPrice = getPrintPrice(
    printWidthInches,
    printHeightInches,
    paperId,
    options,
  );

  const framingPrice = getFramingPrice(
    printWidthInches,
    printHeightInches,
    oi,
    options,
  );

  const mountingPrice = getCustomMountingPrice(
    printWidthInches,
    printHeightInches,
    oi,
    options,
  );

  const valueAddonsPrice = getValueAddonsPricing(oi, file, user);
  const additionalPricing = oi.AdditionalPricing || 0;

  return (
    printPrice * (1 + handlingFee) +
    framingPrice +
    valueAddonsPrice +
    additionalPricing +
    mountingPrice
  );
}
