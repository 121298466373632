import axios, { AxiosInstance } from "axios";
import { get, post, postJson } from "tps.shared.ui/core/requestHelpers";
import LogService from "@/core/services/logService";
import { GetQCUserListViewModel } from "../types/user/getQCUserListViewModel";

export default class AccountService {
  public get adminRoles() {
    return ["superAdmin", "salesadmin"];
  }
  public get rolesForCommissionsSummaryDataSource() {
    return ["superAdmin", "salesadmin"];
  }

  public get rolesForCommissionsSummary() {
    return this.rolesForCommissions;
  }

  public get rolesForCommissions() {
    return ["superAdmin", "salesadmin", "salesRep", "accountHandler"];
  }

  public userInRole: any = null;
  constructor(
    private readonly $log: LogService,
    private readonly axios: AxiosInstance,
    private readonly eventBus: any,
    private readonly sessionStorage: any,
  ) { }

  async getThemeFromCookie() {
    const r = await post(
      axios,
      "/Public/GetThemeFromCookie",
      {},
      { raw: true },
    );
    return r.data;
  }

  async setSelectedThemeCookie(theme: string) {
    const r = await post(
      axios,
      "/Public/SetSelectedThemeCookie",
      { theme },
      { raw: true },
    );
    return r.data;
  }

  async getGuestCookie() {
    // this.$log.time("services/account.getGuestCookie start");
    const r = await post(axios, "/GuestCookie", {}, { tpsDotNet: true });
    // this.$log.time("services/account.getGuestCookie fetched");
    return r.data;
  }

  async getConfigs({ isCreativesDotNew = false }) {
    // this.$log.time("services/account.getConfigs start");
    const query = `isCreativesDotNew=${isCreativesDotNew}`;
    const r = await get(axios, `/User/Configs?${query}`, {
      tpsDotNet: true,
    });
    // this.$log.time("services/account.getConfigs fetched");
    return r.data;
  }

  async getQCUsers(
    deliveryBranchId: number,
    currentStatus: number,
    orderId: number
  ): Promise<GetQCUserListViewModel> {
    const response = await get(
      axios,
      `/rolesAdmin/GetQCUsers?deliveryBranchId=${deliveryBranchId}&orderState=${currentStatus}&orderId=${orderId}`,
      {
        prefix: "form",
      },
    );
    return response.data;
  }

  async getAdminUsers(): Promise<GetQCUserListViewModel> {
    const response = await get(axios, `/rolesAdmin/GetAdminUsers`, {
      prefix: "form",
    });
    return response.data;
  }

  async getUserInRole(): Promise<any> {
    const response = await postJson(
      axios,
      "/user/UserIsInRole",
      {
        role: "superAdmin",
      },
      {
        prefix: "form",
      },
    );
    this.userInRole = response.data;
    return response.data;
  }

  async getUserInRoles(roles: string[]): Promise<any>  {
    const response = await postJson(
      axios,
      "/user/UserIsInRole",
      {
        roles
      },
      {
        prefix: "form",
      },
    );

    return response.data; 
  }
}
