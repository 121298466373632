
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import moment from "moment";
import { ProductionTeamWorker } from '@/core/types/productionteams/ProductionTeamWorker';
import ExportCsvService from '@/core/services/exportCsvService';

declare var $: any;

export interface FormEvent extends Event {
  model: any;
}

export default defineComponent({
  name: 'ProductionTeamAdmin',
  setup() {
    const _window: any = window;

    const form = ref(null);

    const teamGrid = ref(null);

    _window.formatDate = (date: Date): string => 
      !date ? "" : moment(String(date)).format("yyyy-MM-DD").toString();

    _window.dataForEmailInput = () => {
      return {
        text: $("#UserEmail").val()
      };
    }

    _window.onGridEdit = (e: any) => {
      console.log(e);
      e.model.StartDate = moment(String(e.model.StartDate)).format("yyyy-MM-DD").toString();
      if (e.model.EndDate) {
        e.model.EndDate = moment(String(e.model.EndDate)).format("yyyy-MM-DD").toString();
      }
    }

    const formSubmit = (e: FormEvent) => {
      e.preventDefault();
      
      if (!e.model.UserEmail) {
        alert("Please enter an email address.");
        return;
      }

      const employee: ProductionTeamWorker = {
        BranchId: e.model.BranchId,
        HubUserId: e.model.UserEmail.Id,
        ContractType: e.model.ContractTypeForm,
        StartDate: new Date(moment(e.model.StartDateForm).toString()),
        EndDate: e.model.EndDateForm ? new Date(moment(e.model.EndDateForm).toString()) : null,
      }

      axios.post("/form/grid/AddProductionTeamEmployee", {
        employee
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((responce: any) => {
        $("#UserEmail").val("");
        $("#BranchId").data("kendoDropDownList").value("1");
        $("#StartDateForm").data("kendoDatePicker").value(new Date());
        $("#ContractTypeForm").data("kendoDropDownList").value("0");
        $("#EndDateForm").data("kendoDatePicker").value(null);
        $("#productionTeamGrid").data("kendoGrid").dataSource.read();
      })
      .catch((error: any) => {
        alert("Error saving employee" + error);
      });
    }

    return {
      form,
      teamGrid,
      formSubmit
    }
  },
  mounted() {
    this.initializeProductionTeamForm();
    this.initializeProductionTeamGrid();
  },
  methods: {
    initializeProductionTeamForm(): void {
      const _window: any = window;

      const form = this.form;

      $.get("/form/grid/ProductionTeamAdminPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(form).html(html);

        $("#StartDateForm").data("kendoDatePicker").value(new Date());
        $("#ContractTypeForm").data("kendoDropDownList").value("0");
      });
      _window.formSubmit = this.formSubmit;
    },
    initializeProductionTeamGrid(): void {
      const _window: any = window;

      const grid = $(this.teamGrid);

      $.get("/form/grid/ProductionTeamAdminGridPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(grid).html(html);
      });

      _window.exportProductionTeam = () => ExportCsvService.exportCsv('form/grid/ExportProductionTeamCsv', 'ProductionTeam');
    }
  }
});
