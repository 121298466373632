export enum SpecialOrderType {
  Prints = 1,
  Frame = 2,
  Mounting = 3,
  Stationery = 4,
  BoothAndRetouch = 5,
  Delivery = 6,
  Freetext = 7,
  Insert = 8,
  CustomPackaging = 9,
  CustomCOA = 10,
  ArtistSignature = 13,
  FreeTextPrints = 101,
  FreeTextFrame = 102,
  FreeTextMounting = 103,
  FreeTextRetouch = 105,
  FreeTextDelivery = 106,
  FreeTextPackaging = 109,
  DdpPrepaidGst = 200,
  DdpPrepaidImportDuty = 201,
  DdpCourierHandlingFee = 202,
}

export type FinishingType = 
  | "None" 
  | "Framing" 
  | "Mounting"
  | "Rectangle"
  | "Circular";

export type CoaType = 
  | "None" 
  | "Classic"
  | "Minimalist"
  | "Clean"
  | "Typewriter"
  | "Traditional"
  | "Modern";

export type PrintType = "Giclée" | "C-Type" | "CMYK" | "Canvas";
export type OrderItem = {
  Id: number;
  PrintType: PrintType;
  Quantity: number;
  PrintHeightMM: number;
  PrintWidthMM: number;
  SpecialOrderTypeId?: SpecialOrderType;
  Finishing: FinishingType;
  OrderTypeSource: OrderTypeSource;
  CoaType: CoaType;
};

export type OrderTypeSource =
  | "ecommerce"
  | "gallery"
  | "hub"
  | "IllustrationSamplePack"
  | "inHouse"
  | "MerchantApi"
  | "order"
  | "PayPerSubbmission"
  | "PhotographySamplePack"
  | "samplePack"
  | "siteConnect";

export type Order = {
  Id: number;
  OrderTypeSource: OrderTypeSource;
  Items: OrderItem[];
};

export function makeOrderOf(order: any) {
  const sot = [
    SpecialOrderType.Prints,
    SpecialOrderType.Frame,
    SpecialOrderType.Mounting,
    SpecialOrderType.FreeTextPrints,
    SpecialOrderType.FreeTextFrame,
    SpecialOrderType.FreeTextMounting,
    SpecialOrderType.Insert,
    SpecialOrderType.CustomCOA,
    SpecialOrderType.CustomPackaging,
  ];
  const items = order.Items?.filter(
    (oi: any) =>
      oi.SpecialOrderTypeId == null ||
      (oi.SpecialOrderTypeId != null && sot.includes(oi.SpecialOrderTypeId)),
  ).map((oi: any) => makeOrderItemOf(oi, order.OrderTypeSource));

  return makeOrder(order.Id, order.OrderTypeSource, items);
}

function makeCoaTypeOf(coaTypeId?: number | null | undefined) {
  if (coaTypeId == 1) return "Classic";
  if (coaTypeId == 2) return "Minimalist";
  if (coaTypeId == 3) return "Clean";
  if (coaTypeId == 4) return "Typewriter";
  if (coaTypeId == 5) return "Traditional";
  if (coaTypeId == 6) return "Modern";

  return "None";
}

function makeOrderItemOf(
  orderItem: any,
  orderTypeSource: OrderTypeSource,
): OrderItem {
  return {
    Id: orderItem.Id,
    PrintType: orderItem.PrintType,
    Quantity: orderItem.Quantity,
    PrintHeightMM: orderItem.PrintHeightMM,
    PrintWidthMM: orderItem.PrintWidthMM,
    SpecialOrderTypeId: orderItem.SpecialOrderTypeId,
    Finishing: orderItem.Finishing || "None",
    OrderTypeSource: orderTypeSource,
    CoaType: makeCoaTypeOf(orderItem.COATypeId),
  };
}

function makeOrder(
  id = 0,
  ordertypeSource: OrderTypeSource = "order",
  orderItems = [],
): Order {
  return {
    Id: id,
    OrderTypeSource: ordertypeSource,
    Items: orderItems,
  };
}