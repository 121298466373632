export type equalityComparer<T> = (item1: T, item2: T) => boolean;


export const roundToNearestN = (x: number, n = 5, d = 2) =>
  (Math.round((x * 10 ** d) / n) * n) / 10 ** d;


export function sumIf<T>(items: T[], areEqual: equalityComparer<T>, sumField: string) {
  const isObject = (o: unknown): boolean =>
    typeof o == "object" && !Array.isArray(o) && o !== undefined && o !== null;

  return items.reduce((result, current) => {
    const itemIdx = result.findIndex(el => areEqual(el, current));
    if (itemIdx >= 0 && isObject(current)) {
      const dst: any = result[itemIdx];
      const src: any = current;
      dst[sumField] += src[sumField];
    } else {
      result.push({ ...current });
    }

    return result;
  }, [] as T[]);
}