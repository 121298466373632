
import { defineComponent, ref } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import axios from "axios";
import { get } from "tps.shared.ui/core/requestHelpers";
import { filterBy } from "@progress/kendo-data-query";

declare var $: any;
export default defineComponent({
  name: "DeliveryZones",
  components: {
    KendoDdl: DropDownList,
  },
  setup() {
    const grid: any = ref(null);
    return { grid };
  },
  data() {
    return {
      innerZones: [],
      zones: [],
    };
  },
  async mounted() {
    await this.getZones();
  },
  methods: {
    async getZones() {
      const { data } = await get(axios, "/grid/zones", {
        prefix: "form",
      });
      this.zones = data;
      this.innerZones = data;
    },
    select(evtData: any) {
      const id = evtData.value?.Id;
      const grid = this.grid;
      $.get(
        `/form/grid/deliveryzonecountriespartial/${id}`,
        {},
        (html: string) => {
          html = html.replace(/\/grid\//gi, "/form$&");
          $(grid).html(html);
        }
      );
    },
    filterChange(event: any) {
      this.zones = this.filterData(event.filter);
    },
    filterData(filter: any) {
      const data = this.innerZones.slice();
      return filterBy(data, filter);
    },
  },
});
