
import { defineComponent, ref, Ref } from "vue";
import * as ku from "@/core/kendoUtils";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import {
  FormDataViewGridFilter,
  FormDataViewGridFilterKind,
} from "@/core/types/formDataViewsModel";
import { GridFilterType } from "@/core/enums/grid-filter.type";
import { TabStrip, TabStripTabProperties } from "@progress/kendo-vue-layout";

declare var $: any;

export default defineComponent({
  name: "HubUsers",
  components: {
    KButton: Button,
    KButtonGroup: ButtonGroup,
    KTabStrip: TabStrip,
  },
  setup() {
    const grid: any = ref(null);

    const GridFilters: Ref<FormDataViewGridFilter[]> = ref([]);
    const GridFilterCategories: Ref<TabStripTabProperties[]> = ref([]);

    return { grid, GridFilters, GridFilterCategories };
  },
  data() {
    return {
      storageKey: `${this.$options.name}GridOptions`,
      gridHandle: "#gridHubUser",
      selected: 0,
      selectedTab: 0,
    };
  },
  mounted() {
    const _window: any = window;
    // eslint-disable-next-line
    const _this = this;
    const grid = this.grid;
    _window.router = this.$router;

    _window.usersGridFilterGetter = () => ({
      gridFilterId: _this.selected,
    });
    _window.usersApplyGridOptions = () => {
      const grid = $(this.gridHandle).data("kendoGrid");
      ku.setGridStateToStorage($, grid, GridFilterType.Customers, _window);
    };
    this.$dataViews.LoadGridFilterCategories().then(categories => {
      this.GridFilterCategories = categories.map(category => ({
        id: category.Id.toString(),
        title: category.Name,
        content: category.Id.toString(),
      }));
      this.GridFilterCategories.unshift({
        id: "0",
        title: "None",
        content: "0",
      });
      this.$dataViews.LoadGridFiltersBySourceName("CustomerList").then(data => {
        _this.GridFilters = data;
        const selectedFilterId = localStorage.getItem(
          `${GridFilterType[GridFilterType.Customers]}SelectedDataFilter`,
        );
        if (selectedFilterId) {
          const id = parseInt(selectedFilterId);
          const selected = _this.GridFilters.find(o => o.Id == id);
          const gf = selected ?? _this.GridFilters[0];
          this.selected = gf.Id ?? 0;
          this.selectedTab = this.GridFilterCategories.findIndex(
            category =>
              parseInt(category.id) === (gf.FormDataGridFilterCategoryId ?? 0),
          );
        } else {
          const defaultFilter = _this.GridFilters[0];
          localStorage.setItem(
            `${GridFilterType[GridFilterType.Customers]}SelectedDataFilter`,
            `${defaultFilter.Id}`,
          );
          this.selected = defaultFilter.Id ?? 0;
        }
        $.get("/form/grid/hubUserPartial", {}, (html: string) => {
          html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
          html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug

          $(grid).html(html);

          $(grid).on("click", "#new-customer-btn", (evt: any) => {
            evt.preventDefault();
            _window.open("/customers/0", "_blank");
            return false;
          });

          $(grid).on("click", "td", function (e: any) {
            const el = e.target;
            const id = $(el).parents("tr").find("td:first").text();

            _window.router.push(`/customers/${id}`);
          });

          const gf = _this.GridFilters.find(o => o.Id == this.selected);
          _this.initGridFilter(gf);
        });
      });
    });
  },
  methods: {
    initGridFilter(gridFilter: FormDataViewGridFilter | undefined) {
      const grid = $(this.gridHandle).data("kendoGrid");
      if (gridFilter && !gridFilter?.Unfiltered) {
        for (let i = 0; i < grid.columns.length; i++) {
          grid.hideColumn(i);
        }
        for (const column of gridFilter.IncludedColumns) {
          grid.showColumn(column.FieldPath);
        }
      } else {
        //Reset columns
        for (let i = 0; i < grid.columns.length; i++) {
          grid.showColumn(i);
        }
      }
      ku.setToGridState(
        $,
        grid,
        GridFilterType.Customers,
        `${this.gridHandle} .k-grid-toolbar`,
      );
    },
    isPublic(gridFilter: FormDataViewGridFilter) {
      return gridFilter.Kind == FormDataViewGridFilterKind.Public;
    },
    isSelected(id: number) {
      return id === this.selected;
    },
    onSelect(id: number, e: any) {
      e.preventDefault();
      this.selected = id;
      localStorage.setItem(
        `${GridFilterType[GridFilterType.Customers]}SelectedDataFilter`,
        id.toString() || "-1",
      );
      const grid = $(this.gridHandle).data("kendoGrid");

      grid.dataSource.filter({});
      grid.dataSource.sort({});
      grid.dataSource.read();

      const gridFilter = this.GridFilters.find(o => o.Id == id);
      this.initGridFilter(gridFilter);
    },
    getGridFiltersByCategoryId(categoryId: string): FormDataViewGridFilter[] {
      return this.GridFilters.filter(
        filter =>
          (filter?.FormDataGridFilterCategoryId ?? 0) == parseInt(categoryId),
      );
    },
    onSelectTab: function (e: any) {
      this.selectedTab = e.selected;
    },
  },
});
