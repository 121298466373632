import axios from "axios";
import GoogleAuth from "@/services/GoogleAuth";
/**
 * Represents of GoogleDoc.
 * @constructor
 */
export default class GoogleDoc extends GoogleAuth {
  /**
   * @param {string} gClientID - Google Client ID should be from https://console.developers.google.com/apis/credentials.
   * @param {string} gClientSecret - Google Client Secret should be from https://console.developers.google.com/apis/credentials.
   * @param {string} gRedirectURL - URL where google will redirect after successfully auth.
   * @param {string} gAuthScope - scope of Google API check - https://developers.google.com/docs/api/reference/rest/v1/documents/create.
   */
  constructor(
    gClientID: string,
    gClientSecret: string,
    gRedirectURL: string,
    gAuthScope?: string,
  ) {
    super(gClientID, gClientSecret, gRedirectURL, gAuthScope);
  }
  /**
   * Create Google Doc
   * @method
   * @param {string} documentTitle - the text which will represent the Google Doc name
   * @return {string | null} - string is the new documentId
   * */
  public async createGoogleDoc(documentTitle: string): Promise<string | null> {
    try {
      const { data } = await axios.post(
        "https://docs.googleapis.com/v1/documents",
        { title: documentTitle },
        {
          headers: {
            Authorization: `Bearer ${this.googleAccessToken}`,
          },
        },
      );
      if (data) {
        return data.documentId;
      }
      return null;
    } catch (error) {
      let errorMessage = "createGoogleDoc - Failed to create Google Doc";
      if (error instanceof Error) {
        errorMessage = `createGoogleDoc - ${error.message}`;
      }
      console.log(errorMessage);
      return null;
    }
  }

  /**
   * Update Google Doc
   * @method
   * @param {string} documentId - the id of the updated document
   * @param {any} requests - the body of the updated document
   * @return {string | null} - string is the new documentId
   * */
  public async updateGoogleDocument(
    documentId: string,
    requests: any,
  ): Promise<string | null> {
    try {
      const { data } = await axios.post(
        `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
        {
          documentId,
          requests,
        },
        {
          headers: {
            Authorization: `Bearer ${this.googleAccessToken}`,
          },
        },
      );

      if (data) {
        return data.documentId;
      }
      return null;
    } catch (error) {
      let errorMessage = "createGoogleDoc - Failed to create Google Doc";
      if (error instanceof Error) {
        errorMessage = `createGoogleDoc - ${error.message}`;
      }
      console.log(errorMessage);
      return null;
    }
  }
}
