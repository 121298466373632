import { AxiosInstance, AxiosRequestHeaders } from "axios";
import LogService from "@/core/services/logService";

import {
  FormDataView,
  FormDataViewGroup,
  FormDataViewGridFilter,
  FormDataViewSchedule,
  FormDataViewSource,
  FormDataGridFilterCategory,
} from "@/core/types/formDataViewsModel";

const url = (url = "") => `form/data-views${url}`;

export default class DataViewsService {
  constructor(
    private readonly $log: LogService,
    private readonly axios: AxiosInstance,
    private readonly eventBus: any,
    private readonly sessionStorage: any,
  ) { }

  async GetDataViewById(id: number): Promise<FormDataView> {
    const r = await this.axios.get(url(`/${id}`));

    return r.data;
  }

  async GetDataViewByIdAndGridFilterId(
    id: number,
    gridFilterId: number,
  ): Promise<FormDataView> {
    const r = await this.axios.get(url(`/${id}/grid-filters/${gridFilterId}`));
    return r.data;
  }

  async LoadDataViews(): Promise<FormDataViewGroup[]> {
    const r = await this.axios.get(url("/ls"));

    return r.data;
  }

  async GetGridFilterById(id: number): Promise<FormDataViewGridFilter> {
    const r = await this.axios.get(url(`/grid-filters/${id}`));

    return r.data;
  }

  async LoadGridFilters(): Promise<FormDataViewGridFilter[]> {
    const r = await this.axios.get(url(`/grid-filters`));

    return r.data;
  }

  async LoadGridFiltersBySourceName(
    sourceName: string,
  ): Promise<FormDataViewGridFilter[]> {
    const r = await this.axios.get(url(`/grid-filters/${sourceName}`));

    return r.data;
  }

  async LoadGridFilterCategories(): Promise<FormDataGridFilterCategory[]> {
    const r = await this.axios.get(url(`/grid-filters-categories`));

    return r.data;
  }

  async DeleteGridFilters(id: number): Promise<number> {
    const r = await this.axios.delete(url(`/grid-filters/${id}`));

    return r.data;
  }

  async UpsertGridFilters(
    gridFilter: FormDataViewGridFilter,
  ): Promise<FormDataViewGridFilter> {
    const r = await this.axios.put(url("/grid-filters"), gridFilter);

    return r.data;
  }

  async UpsertSchedule(
    schedule: FormDataViewSchedule,
  ): Promise<FormDataViewSchedule> {
    const r = await this.axios.put(url("/schedules"), { schedule });

    return r.data;
  }

  async DeleteSchedule(scheduleId: number): Promise<number> {
    const r = await this.axios.delete(url(`/schedules/${scheduleId}`));

    return r.data;
  }

  async GetSources(): Promise<FormDataViewSource[]> {
    const r = await this.axios.get(url(`/sources`));

    return r.data;
  }

  async GetCountries(): Promise<any> {
    const { data } = await this.axios.get("form/grid/countries");
    return data;
  }
}
