
import { computed, defineComponent, ref, Ref } from "vue";
import moment from "moment";
import { NumericTextBox as KNumericTextBox } from "@progress/kendo-vue-inputs";
import { Label as KLabel } from "@progress/kendo-vue-labels";
import { DateFilter } from "@/core/types/productionteams/DateFilter";
import {
  Dialog as KDialog,
  DialogActionsBar as KDialogActionBar,
} from "@progress/kendo-vue-dialogs";
import { Button as KButton } from "@progress/kendo-vue-buttons";
import {
  TabStrip as KTabStrip,
  TabStripTab as KTabStripTab,
} from "@progress/kendo-vue-layout";
import { TimePicker as KTimePicker } from "@progress/kendo-vue-dateinputs";
import ProductionTeamGrid from "@/components/ProductionTeamGrid.vue";
import {
  ClientReference,
  ProductionTeamAttendance,
  ProductionTeamAttendanceBreakdown,
  TaskType,
} from "@/core/types/productionteams/productionTeamAttendance";
import ProductionTeamService from "@/core/services/productionTeamService";
import { DatePicker as KDatePicker } from "@progress/kendo-vue-dateinputs";
import axios from "axios";
import { ChangeEvent } from "@progress/kendo-vue-dropdowns/dist/npm/common/events";
import { Window as KWindow } from "@progress/kendo-vue-dialogs";

declare var $: any;
const emptyBreakown = (): ProductionTeamAttendanceBreakdown[] => {
  return [
    {
      Position: 3,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "admin",
      PrintingHours: 0,
      DispatchHours: 0,
    },
    {
      Position: 4,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "stock",
      PrintingHours: 0,
      DispatchHours: 0,
    },
    {
      Position: 5,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "no-work",
      PrintingHours: 0,
      DispatchHours: 0,
    },
    {
      Position: 6,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "other",
      PrintingHours: 0,
      DispatchHours: 0,
    },
    {
      Position: 1,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "printspace",
      PrintingHours: 0,
      DispatchHours: 0,
    },
    {
      Position: 2,
      Id: 0,
      ProductionTeamAttendanceId: 0,
      ClientReference: "magnum",
      PrintingHours: 0,
      DispatchHours: 0,
    },
  ];
};

const emptyAttendance = (): ProductionTeamAttendance => {
  return {
    Id: 0,
    ProductionTeamEmployeeId: 0,
    FullName: "Default Employee",
    Date: new Date(),
    StartTimeBeforeLunch: moment("08:00", "HH:mm").toDate(),
    EndTimeBeforeLunch: moment("12:00", "HH:mm").toDate(),
    StartTimeAfterLunch: moment("13:00", "HH:mm").toDate(),
    EndTimeAfterLunch: moment("17:00", "HH:mm").toDate(),
    TotalMinutes: 0,
    TotalMinutesSpentOnDispatch: 0,
    TotalMinutesSpentOnPrinting: 0,
    TotalMinutesSpentOnPrintingPercentage: 0,
    Breakdown: emptyBreakown(),
  };
};

const proxy = (o: ProductionTeamAttendanceBreakdown) => {
  return new Proxy<ProductionTeamAttendanceBreakdown>(o, {
    get(target: any, property) {
      return target[property];
    },
    set(target: any, property, value: any) {
      const round = (n: number) => Math.round(n / 0.25) * 0.25;
      if (property == "PrintingHours" || property == "DispatchHours") {
        value = round(value);
      } else if (property == "Position") {
        switch (target.ClientReference) {
          case "general":
            target[property] = 1;
            break;
          case "magnum":
            target[property] = 2;
            break;
          case "admin":
            target[property] = 3;
            break;
          case "stock":
            target[property] = 4;
            break;
          case "no-work":
            target[property] = 5;
            break;
          case "other":
            target[property] = 6;
            break;
        }
      }
      target[property] = value;
      return true;
    },
  });
};

const proxify = (o: ProductionTeamAttendance) => {
  o.Breakdown = o.Breakdown.map(i => proxy(i));
  o.Breakdown.sort(
    (
      a: ProductionTeamAttendanceBreakdown,
      b: ProductionTeamAttendanceBreakdown,
    ): number => {
      return a.Position - b.Position;
    },
  );

  return o;
};

export default defineComponent({
  name: "ProductionTeams",
  components: {
    KDialog,
    KDatePicker,
    KDialogActionBar,
    KTabStrip,
    ProductionTeamGrid,
    KTabStripTab,
    KLabel,
    KTimePicker,
    KButton,
    KNumericTextBox,
    KWindow,
  },
  setup() {
    const dialogSlider = ref(null);
    const toolbar = ref(null);
    const productionTeamsTabStrip = ref(null);
    const isVisibleDialog = ref(false);
    const isVisibleInfo = ref(false);
    const selectedTab = ref(0);

    const tomorrow = () => {
      const dt = new Date();
      dt.setDate(dt.getDate() + 1);

      return dt;
    };

    const endDate = ref(tomorrow());
    const startDate = ref(
      new Date(
        endDate.value.getFullYear(),
        endDate.value.getMonth(),
        endDate.value.getDate() - 14,
      ),
    );

    const defaultDateFilter: Ref<DateFilter> = ref({
      startDate: new Date(
        Date.UTC(
          startDate.value.getFullYear(),
          startDate.value.getMonth(),
          startDate.value.getDate(),
          0,
          0,
          0,
          0,
        ),
      ),
      endDate: new Date(
        Date.UTC(
          endDate.value.getFullYear(),
          endDate.value.getMonth(),
          endDate.value.getDate(),
          0,
          0,
          0,
          0,
        ),
      ),
    });

    const startDateRef = computed({
      get() {
        return startDate.value;
      },
      set(value: Date) {
        startDate.value = value;
      },
    });

    const endDateRef = computed({
      get() {
        return endDate.value;
      },
      set(value: Date) {
        endDate.value = value;
      },
    });

    const defaultAttendance = ref(emptyAttendance());
    const currentAttendance = ref(emptyAttendance());

    const dateFilters = (date: DateFilter): any => {
      return {
        startDate: date.startDate.toISOString(),
        endDate: date.endDate.toISOString(),
      };
    };

    const additionalData = (date: DateFilter, branchId: number): any => {
      return {
        dateFilters: dateFilters(date),
        branchId: branchId,
      };
    };

    const additionalDataGB = () => {
      return additionalData(defaultDateFilter.value, 1);
    };

    const additionalDataDE = () => {
      return additionalData(defaultDateFilter.value, 2);
    };

    const additionalDataUSA = () => {
      return additionalData(defaultDateFilter.value, 3);
    };

    return {
      dialogSlider,
      toolbar,
      productionTeamsTabStrip,
      isVisibleDialog,
      isVisibleInfo,
      currentAttendance,
      defaultAttendance,
      dateFilters,
      additionalData,
      additionalDataGB,
      additionalDataDE,
      additionalDataUSA,
      selectedTab,
      startDateRef,
      endDateRef,
      tomorrow: tomorrow(),
    };
  },
  data() {
    return {
      steps: {
        hour: 1,
        minute: 15,
      },
      tabs: [
        {
          id: 0,
          title: "GB",
          content: "GB",
          url: "/form/grid/ProductionTeamGBPartial",
          gridId: "#productionTeamsGridGB",
          branchName: "GB",
          additionalDataFunctionName: "additionalDataGB",
          additionalData: this.additionalDataGB(),
          branchId: 1,
          isGridInitialized: false,
        },
        {
          id: 1,
          title: "DE",
          content: "DE",
          url: "/form/grid/ProductionTeamDEPartial",
          gridId: "#productionTeamsGridDE",
          branchName: "DE",
          additionalDataFunctionName: "additionalDataDE",
          additionalData: this.additionalDataDE(),
          branchId: 2,
          isGridInitialized: false,
        },
        {
          id: 2,
          title: "USA",
          content: "USA",
          url: "/form/grid/ProductionTeamUSAPartial",
          gridId: "#productionTeamsGridUSA",
          branchName: "USA",
          additionalDataFunctionName: "additionalDataUSA",
          additionalData: this.additionalDataUSA(),
          branchId: 3,
          isGridInitialized: false,
        },
      ],
      productionTeamService: null as ProductionTeamService | null,
    };
  },
  computed: {
    totalHoursSpentOnPrinting(): string {
      return this.formatMinutesToHours(this.totalMinutesSpentOnPrinting);
    },
    totalHoursSpentOnDispatch(): string {
      return this.formatMinutesToHours(this.totalMinutesSpentOnDispatch);
    },
    totalMinutesSpentOnPrinting(): number {
      return this.totalMinutesSpentOn("printing");
    },
    totalMinutesSpentOnDispatch(): number {
      return this.totalMinutesSpentOn("dispatch");
    },
    totalMinutes(): number {
      return (
        this.totalMinutesSpentOnPrinting + this.totalMinutesSpentOnDispatch
      );
    },
  },
  watch: {
    totalMinutes(n) {
      this.currentAttendance.TotalMinutes = n;
    },
  },
  created() {
    this.productionTeamService = new ProductionTeamService();
  },
  mounted() {
    this.setDefaultDateFilter();
    this.initializeOnClickForCells();
  },
  unmounted() {
    $(document).off("click", 'td[data-field="date"]');
  },
  methods: {
    productionActivities(
      attendance: ProductionTeamAttendanceBreakdown[],
    ): ProductionTeamAttendanceBreakdown[] {
      return (
        attendance.filter(
          b =>
            b.ClientReference == "printspace" || b.ClientReference == "magnum",
        ) ?? []
      );
    },
    otherActivities(
      attendance: ProductionTeamAttendanceBreakdown[],
    ): ProductionTeamAttendanceBreakdown[] {
      return (
        attendance.filter(
          b =>
            !(
              b.ClientReference == "printspace" || b.ClientReference == "magnum"
            ),
        ) ?? []
      );
    },
    isOther(reference: ClientReference) {
      return reference == "other";
    },
    hasPrintingAndDispatch(reference: ClientReference) {
      return reference == "printspace" || reference == "magnum";
    },
    workbreakdownTitle(item: ProductionTeamAttendanceBreakdown) {
      const breakdownTitles = {
        printspace: "Reg. orders",
        magnum: "Magnum",
        admin: "Admin",
        stock: "Stock",
        "no-work": "No Work",
        other: "Other",
      };

      return breakdownTitles[item.ClientReference];
    },
    totalMinutesSpentOn(taskType: TaskType): number {
      const printingMinutes = (o: ProductionTeamAttendanceBreakdown) =>
        o.PrintingHours * 60;
      const dispatchMinutes = (o: ProductionTeamAttendanceBreakdown) =>
        o.DispatchHours * 60;
      const minutes =
        taskType == "dispatch" ? dispatchMinutes : printingMinutes;
      const attendanceInMinutes = this.currentAttendance.Breakdown.map(minutes);
      const totalMinutes = attendanceInMinutes.reduce(
        (acc, hrs) => acc + hrs,
        0,
      );

      return totalMinutes;
    },
    onExportClick(): void {
      axios
        .post(
          "/form/grid/ExportProductionTeamAttendanceCsv",
          {
            dateFilters: this.dateFilters({
              startDate: this.startDateRef,
              endDate: this.endDateRef,
            }),
            branchId: this.tabs[this.selectedTab].branchId,
          },
          {
            responseType: "blob",
          },
        )
        .then(response => {
          const data = response.data;
          const blob = new Blob([data], { type: "text/csv" });

          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "ProductionTeamAttendance.csv";
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
        });
    },
    formatDateForDialog(model: ProductionTeamAttendance): string {
      if (model.Id === 0) {
        return moment(model.Date).format("yyyy-MM-DD");
      } else {
        return new Date(
          Date.UTC(
            model.Date.getFullYear(),
            model.Date.getMonth(),
            model.Date.getDate(),
            0,
            0,
            0,
            0,
          ),
        )
          .toISOString()
          .split("T")[0];
      }
    },
    formatMinutesToHours(minutes: number): string {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.round(minutes % 60);

      return `${hours}h ${remainingMinutes}m`;
    },
    setDefaultDateFilter(): void {
      const _window: any = window;

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 1);
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() - 16,
      );
      const defaultDateFilter: DateFilter = {
        startDate,
        endDate,
      };
      /*
      const defaultDateFilter: DateFilter = {
        startDate: new Date(
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            0,
            0,
            0,
            0,
          ),
        ),
        endDate: new Date(
          Date.UTC(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            0,
            0,
            0,
            0,
          ),
        ),
      };*/

      _window.additionalDataGB = this.additionalData(defaultDateFilter, 1);
    },
    initializeOnClickForCells(): void {
      $(document).on("click", 'td[data-field="date"]', (e: Event) => {
        e.preventDefault();
        const link = $(e.currentTarget).find(".hour-link");

        if (link.text() === "N/A") {
          alert(`${link.data("employee")} is not available at this date.`);
          return;
        }

        this.defaultAttendance.ProductionTeamEmployeeId = link.data("id");
        this.defaultAttendance.FullName = link.data("employee");
        this.defaultAttendance.Date = link.data("date");
        this.defaultAttendance.Breakdown = emptyBreakown();
        this.getInfoForDialog(
          this.defaultAttendance.Date,
          this.defaultAttendance.ProductionTeamEmployeeId,
        );

        this.toggleDialog();
      });
    },
    async getInfoForDialog(date: Date, id: number): Promise<void> {
      const employeeAttendance =
        await this.productionTeamService?.GetEmployeeAttendanceByDate(date, id);

      this.currentAttendance = proxify(
        employeeAttendance ?? this.defaultAttendance,
      );
    },
    toggleDialog(): void {
      this.isVisibleDialog = !this.isVisibleDialog;
    },
    toggleInfo(): void {
      this.isVisibleInfo = !this.isVisibleInfo;
      this.isVisibleDialog = !this.isVisibleInfo;
    },
    onSelectedTab(e: any) {
      this.tabs[this.selectedTab].isGridInitialized = false;
      this.selectedTab = e.selected;
    },
    formatDateToUTC(date: Date): string {
      return (
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0") +
        " " +
        String(date.getHours()).padStart(2, "0") +
        ":" +
        String(date.getMinutes()).padStart(2, "0") +
        ":" +
        String(date.getSeconds()).padStart(2, "0")
      );
    },
    async saveWorkingTime(): Promise<void> {
      if (this.currentAttendance.TotalMinutes === 0) {
        alert("Please set printing/dipatch hours.");
        return;
      }

      if (
        this.currentAttendance.StartTimeBeforeLunch! >=
          this.currentAttendance.EndTimeBeforeLunch! ||
        this.currentAttendance.StartTimeAfterLunch! >=
          this.currentAttendance.EndTimeAfterLunch!
      ) {
        alert("Start time must be before end time.");
        return;
      }

      if (
        this.currentAttendance.EndTimeBeforeLunch! >
        this.currentAttendance.StartTimeAfterLunch!
      ) {
        alert("End time before lunch must be before start time after lunch.");
        return;
      }
      if (this.totalMinutes < 8 * 60) {
        if (
          !confirm(
            "Total hours are less than 8.\nAre you sure you want the timesheet is complete?",
          )
        )
          return;
      }

      this.currentAttendance.TotalMinutesSpentOnPrinting =
        this.totalMinutesSpentOnPrinting;
      this.currentAttendance.TotalMinutesSpentOnDispatch =
        this.totalMinutesSpentOnDispatch;
      this.currentAttendance.TotalMinutes = this.totalMinutes;
      const data = {
        ...this.currentAttendance,
        StartTimeBeforeLunch: this.formatDateToUTC(
          this.currentAttendance.StartTimeBeforeLunch!,
        ),
        EndTimeBeforeLunch: this.formatDateToUTC(
          this.currentAttendance.EndTimeBeforeLunch!,
        ),
        StartTimeAfterLunch: this.formatDateToUTC(
          this.currentAttendance.StartTimeAfterLunch!,
        ),
        EndTimeAfterLunch: this.formatDateToUTC(
          this.currentAttendance.EndTimeAfterLunch!,
        ),
      };

      const attendanceId = await this.productionTeamService?.UpsertAttendance(
        data,
      );

      if (attendanceId != 0) {
        this.defaultAttendance.TotalMinutes = 0;
        this.defaultAttendance.TotalMinutesSpentOnDispatch = 0;
        this.defaultAttendance.TotalMinutesSpentOnPrinting = 0;
        this.defaultAttendance.TotalMinutesSpentOnPrintingPercentage = 0;
        $(`${this.tabs[this.selectedTab].gridId}`)
          .data("kendoGrid")
          .dataSource.read();
        this.toggleDialog();
      }
    },
    async removeWorkingTime(): Promise<void> {
      if (this.currentAttendance.Id === 0) {
        alert("This attendance is not saved yet.");
        return;
      }

      const result = confirm(
        "Are you sure you want to delete this attendance?",
      );

      if (result) {
        await this.productionTeamService?.ResetAttendance(
          this.currentAttendance.Id,
        );

        $(`${this.tabs[this.selectedTab].gridId}`)
          .data("kendoGrid")
          .dataSource.read();
        this.toggleDialog();
      }
    },
    onDateChange(event: ChangeEvent, field: string): void {
      const currentAttendance: any = this.currentAttendance;
      currentAttendance[field] = event.value;
    },
    onApplyDateRangeButton(): void {
      const startDate = new Date(this.startDateRef);
      const endDate = new Date(this.endDateRef);

      const utcStartDate = new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0,
          0,
          0,
        ),
      );

      const utcEndDate = new Date(
        Date.UTC(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          0,
          0,
          0,
          0,
        ),
      );

      const dateFilter: DateFilter = {
        startDate: utcStartDate,
        endDate: utcEndDate,
      };

      this.tabs[this.selectedTab].isGridInitialized = false;
      $(`${this.tabs[this.selectedTab].gridId}`).data(
        "kendoGrid",
      ).dataSource.transport.options.read.data = this.additionalData(
        dateFilter,
        this.tabs[this.selectedTab].branchId,
      );
      $(`${this.tabs[this.selectedTab].gridId}`)
        .data("kendoGrid")
        .dataSource.read();
    },
  },
});
