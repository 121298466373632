
import { defineComponent } from "vue";
import { get, postJson } from "tps.shared.ui/core/requestHelpers";
import axios from "axios";
export default defineComponent({
  name: "DeliveryTest",
  data() {
    return {
      branches: [],
      countries: [],
      pricelists: [],
      currencies: [],
      productionTypes: [],
      results: null,
      error: null as any,
      model: {
        forAppModel: true,
        forApiModel: false,
        beforeCutoffModel: true,
        orderDateCreated: "2021-01-01T06:00:00Z",
        forApp: true as boolean | null,
        forApi: null as boolean | null,
        sizes: [
          {
            heightInches: 0,
            widthInches: 0,
          },
        ],
        heightMM: 0,
        widthMM: 0,
        currencyId: 1,
        shippingCountryId: 74,
        shippingPostcode: "",
        priceListId: 1,
        userId: null as number | null,
        userBranchId: null as number | null,
        promoCode: "",
        orderTotalExcludingVAT: 0,
        productionType: 0,
      },
    };
  },
  watch: {
    "model.forAppModel"(v: boolean) {
      this.model.forApp = v ? true : null;
    },
    "model.forApiModel"(v: boolean) {
      this.model.forApi = v ? true : null;
    },
    "model.beforeCutoffModel"(v: boolean) {
      this.model.orderDateCreated = v
        ? "2021-01-01T06:00:00Z"
        : "2021-01-01T18:00:00Z";
    },
  },
  async mounted() {
    await Promise.all([
      this.getBranches(),
      this.getCountries(),
      this.getPriceLists(),
      this.getCurrencies(),
      this.getProductionTypes(),
    ]);
  },
  methods: {
    async getBranches() {
      const { data } = await get(axios, "/grid/branches", {
        prefix: "form",
      });
      this.branches = data;
      return data;
    },
    async getCountries() {
      const { data } = await get(axios, "/grid/countries", {
        prefix: "form",
      });
      this.countries = data;
      return data;
    },
    async getPriceLists() {
      const { data } = await get(axios, "/grid/pricelists", {
        prefix: "form",
      });
      this.pricelists = data;
      return data;
    },
    async getCurrencies() {
      const { data } = await get(axios, "/grid/currencies", {
        prefix: "form",
      });
      this.currencies = data;
      return data;
    },
    async getProductionTypes() {
      const { data } = await get(axios, "/grid/productiontypes", {
        prefix: "form",
      });
      this.productionTypes = data;
      return data;
    },
    async submit() {
      this.error = null;
      this.results = null;
      try {
        const { data } = await postJson(
          axios,
          "/grid/testdelivery",
          this.model,
          { prefix: "form" },
        );
        this.results = data;
      } catch (exception) {
        this.error = exception;
      }
    },
  },
});
