
import {defineComponent, PropType, Ref, ref} from "vue";
import {Note, OrderNoteType} from "@/core/types/note";
import axios from "axios"
import {UpdateNoteModel} from "@/core/types/updateNoteModel";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import GenericModalResponse from "@/core/types/genericModalResponse";
import {Notification} from "@progress/kendo-popups-vue-wrapper";

export default defineComponent({
  name: "NotesList",
  components: {
    KNotification: Notification,
    GenericModalVue
  },
  computed: {
    OrderNoteType() {
      return OrderNoteType;
    }
  },
  props: {
    notes: Object as PropType<Note[]>,
    type: Object as PropType<OrderNoteType>,
    parentId: Object as PropType<number>,
    customerId: Object as PropType<number>
  },
  emits: {
    noteAdded: null,
    noteDeleted: null,
    noteUpdated: null
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
    };
  },
  setup() {
    const noteMessage: Ref<string> = ref<string>("");
    const permanent: Ref<boolean> = ref<boolean>(false);
    const advisory: Ref<boolean> = ref<boolean>(false);
    const isEditMode: Ref<boolean> = ref<boolean>(false);
    const updateNoteId: Ref<number | null> = ref<number | null>(null);
    const deleteNoteId: Ref<number | null> = ref<number | null>(null);
    const showModal: Ref<boolean> = ref<boolean>(false);
    const popupNotification: Ref<Notification | null> = ref(null);

    return {
      noteMessage,
      permanent,
      advisory,
      isEditMode,
      updateNoteId,
      deleteNoteId,
      showModal,
      popupNotification
    };
  },
  async mounted() {
    this.popupNotificationWidget = this.popupNotification?.kendoWidget();
  },
  methods: {
    addNewNote(): void {
      let url = "";
      let fieldName = "";

      if (this.type === OrderNoteType.Order) {
        url = "/form/pms/addNewOrderNote";
        fieldName = "orderId";
      }

      if (this.type === OrderNoteType.User || this.permanent) {
        url = "/form/pms/AddNewHubUserNote";
        fieldName = "HubUserId"
      }

      const requestPayload = {
        [fieldName]: this.type === OrderNoteType.User || this.permanent ? this.customerId : this.parentId,
        text: this.noteMessage,
        isAdvisory: this.advisory
      };

      axios.post(url, requestPayload)
        .then((responsePayload: { data: Note }) => {
          const {data} = responsePayload;
          data.Text = this.noteMessage;
          this.$emit("noteAdded", data);
          this.noteMessage = "";
          this.permanent = false;
          this.advisory = false;

          this.popupNotificationWidget?.show('Note successfully added', "success");
        })
        .catch(() => {
          this.popupNotificationWidget?.show('Something went wrong', "error");
        });
    },
    updateNote(noteId: number): void {
      if (!this.notes) {
        return;
      }

      const note = this.notes.find(note => note.Id === noteId);
      if (!note) {
        return;
      }

      this.noteMessage = note.Text;
      this.updateNoteId = noteId;
      this.advisory = note.IsAdvisory;
      this.permanent = note.Type === OrderNoteType.User;
      this.isEditMode = true;
    },
    saveNote(): void {
      const requestPayload: UpdateNoteModel = {
        Text: this.noteMessage,
        IsAdvisory: this.advisory,
        OrderId: this.type === OrderNoteType.Order && !this.permanent
          ? this.parentId
          : undefined,
        CustomerId: (this.type === OrderNoteType.User || (this.type === OrderNoteType.Order && this.permanent))
          ? this.type === OrderNoteType.Order && this.permanent ? this.customerId : this.parentId
          : undefined
      };

      axios.put(`/form/notes/${this.updateNoteId}`, requestPayload)
        .then((responsePayload: { data: Note }) => {
          const {data} = responsePayload;
          data.Text = this.noteMessage;
          this.$emit("noteUpdated", data);
          this.noteMessage = "";
          this.updateNoteId = null;
          this.permanent = this.type === OrderNoteType.User;
          this.advisory = false;
          this.isEditMode = false;

          this.popupNotificationWidget?.show('Note successfully updated', "success");
        })
        .catch(() => {
          this.popupNotificationWidget?.show('Something went wrong', "error");
        });
    },
    deleteNote(noteId: number): void {
      this.showModal = true;
      this.deleteNoteId = noteId;
    },
    cancel(): void {
      this.noteMessage = "";
      this.updateNoteId = null;
      this.isEditMode = false;
      this.permanent = false;
      this.advisory = false;
    },
    onCloseModal(response: GenericModalResponse): void {
      this.showModal = false;
      if (response.isQuestionAnswer) {
        axios.delete(`/form/notes/${this.deleteNoteId}`)
          .then(() => {
            this.$emit("noteDeleted", this.deleteNoteId);

            this.deleteNoteId = null;

            this.popupNotificationWidget?.show('Note successfully deleted', "success");
          })
          .catch(() => {
            this.popupNotificationWidget?.show('Something went wrong', "error");
          });
      }
    }
  },
});
