import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56a9b9fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  ref: "form",
  class: "col-2"
}
const _hoisted_4 = {
  ref: "teamGrid",
  class: "col-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512),
      _createElementVNode("div", _hoisted_4, null, 512)
    ])
  ]))
}