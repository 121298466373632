/* eslint-disable no-console */
export default class LogService {
  private last?: Date;
  time(message: string, ...args: any[]) {
    const now = new Date();
    const ms = `000${now.getMilliseconds()}`.slice(-3);
    const s = `00${now.getSeconds()}`.slice(-2);
    const m = `00${now.getMinutes()}`.slice(-2);
    const h = `00${now.getHours()}`.slice(-2);
    const diff = this.last ? now.getTime() - this.last.getTime() : 0;
    console.log(
      `${h}:${m}:${s}.${ms} +${(diff / 1000).toFixed(3)} ${message}`,
      ...args,
    );
    this.last = now;
  }
}
