
import { defineComponent, ref, Ref } from "vue";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
declare const $: any;
export default defineComponent({
  components: {
    KNotification: Notification,
  },
  setup() {
    const PopupNotification: Ref<Notification | null> = ref(null);
    return {
      PopupNotification,
      LimitOperators: [
        { Name: "Less than", Id: 0 },
        { Name: "Less than or equal", Id: 1 },
        { Name: "Equal", Id: 2 },
        { Name: "Greater than or equal", Id: 3 },
        { Name: "Greater than", Id: 4 },
      ],
      States: [
        { Id: 23, Name: "In Dispatch" },
        { Id: 30, Name: "Bulk Dispatch" },
      ],
    };
  },
  data() {
    const wdgt: any = {};
    const setup = false;

    const model = ref({
      Id: null,
      PaymentBranchId: null,
      ProductionBranchId: null,
      DeliveryBranchId: null,
      Operator: null,
      Limit: null,
      OutputState: 23,
    });

    return {
      PopupNotificationWidget: wdgt,
      model,
      setup,
    };
  },
  computed: {
    invalidInput(): any {
      return (
        this.model.PaymentBranchId == null ||
        this.model.ProductionBranchId == null ||
        this.model.DeliveryBranchId == null
      );
    },
    Branches(): any[] {
      return this.$state.global.branchDetails;
    },
  },
  async mounted() {
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (this.$state.global.branchDetails.length == 0)
      await this.$dispatch.global.loadBranchDetails(null);
    if (this.$route.params.id) {
      await this.load();
    } else {
      Object.assign(this.model, this.newModel());
    }
  },
  methods: {
    newModel() {
      return {
        Id: null,
        PaymentBranchId: null,
        ProductionBranchId: null,
        DeliveryBranchId: null,
        Operator: null,
        Limit: null,
        OutputState: 23,
      };
    },
    async load() {
      await this.loadById(Number(this.$route.params.id));
    },
    async loadById(id: number) {
      const result: any = await $.get(`/form/grid/PrintBranchRule?id=${id}`);
      Object.assign(this.model, this.newModel());
      Object.assign(this.model, result);
    },
    async save() {
      if (this.model.Operator == null) {
        this.model.Limit = null;
      }
      try {
        const { Id } = await $.ajax({
          type: "POST",
          url: "/form/grid/SavePrintBranchRule",
          data: JSON.stringify(this.model),
          dataType: "json",
          contentType: "application/json",
        });
        await this.loadById(Id);
        if (this.$route.params.id) {
          this.PopupNotificationWidget?.show(
            "Rule successfully updated",
            "success",
          );
        } else {
          this.PopupNotificationWidget?.show(
            "Rule successfully saved",
            "success",
          );
        }

        this.$router.push(`/printing-rules/print-route/${Id}`);
      } catch (e: any) {
        this.PopupNotificationWidget?.show(
          `Something went wrong with saving the Rule`,
          "error",
        );
      }
    },
  },
});
