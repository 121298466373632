
import { defineComponent, ref, PropType } from 'vue';
import { OrderPrintType } from '../core/types/dashboard/orderPrintType';
import { RedoType } from '../core/types/dashboard/redoType';
import ExportCsvService from '@/core/services/exportCsvService';
import { OrderRedoReasonType } from '@/core/types/dashboard/orderRedoReasonType';

declare const $: any;

export default defineComponent({
  name: 'DashboardGrid',
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    additionalData: {
      type: Object as PropType<OrderPrintType | RedoType | OrderRedoReasonType>,
      required: false,
    },
    dataFunctionName: {
      type: String as PropType<string>,
      required: false,
    }, 
    exportUrl: {
      type: String as PropType<string>,
      required: false,
    },
    exportName: {
      type: String as PropType<string>,
      required: false,
    }
  },
  setup() {
    const grid = ref(null);

    const windowFunction = (data: OrderPrintType | RedoType | OrderRedoReasonType): OrderPrintType | RedoType | OrderRedoReasonType => {
      return data;
    }

    return {
      grid,
      windowFunction,
    };
  },
  mounted() { 
    this.loadGrid();
  },
  methods: {
    loadGrid(){
      const _window:any = window;

      if (this.dataFunctionName && this.additionalData) {
        _window[this.dataFunctionName] = this.windowFunction(this.additionalData);
      }

      $.get(this.url, {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(this.grid).html(html);

        $(this.grid).on("click", "#addNewCmd", (e: any) => {
          e.preventDefault();
          _window.router.push("/grid-filters/filter");
        });
      });


      if (this.exportUrl && this.exportName) {
          _window['export' + this.exportName] = () => ExportCsvService.exportCsv(this.exportUrl!, this.exportName!);
      }
    }
  }
});
