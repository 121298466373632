import { createApp, App } from "vue";
import AxiosStatic from "axios";
import AppVue from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import createStore, { key } from "./store";
import nvuex from "@/plugins/nvuex";
import Log from "@/plugins/log";
import Account from "@/plugins/account";
import DataViews from "@/plugins/dataViews";
import SpecialOrder from "@/plugins/specialOrder";
import FileProcessor from "tps.shared.ui/plugins/fileProcessor";
import UserActivityService from "tps.shared.ui/plugins/userActivity";
// can't import @datadog/browser-logs in tps.shared.ui as it pulls in an old version of tslib
import { setDatadogLogger } from "tps.shared.ui/core/requestHelpers";
import { datadogLogs } from "@datadog/browser-logs";

setDatadogLogger(datadogLogs as any);

const axios = AxiosStatic.create({
  baseURL: "/",
});

const store = createStore();

router.beforeEach(async () => {
  //await store.dispatch("global/ensureConfigs");
  //await store.dispatch("global/ensureGuestCookie");
});

export function createPlugin(plugin: any) {
  return function (app: App<Element>) {
    const ctx = {
      app: <any>null,
      router: app.config.globalProperties.$router,
      store: app.config.globalProperties.$store,
      $log: app.config.globalProperties.$log,
      $axios: app.config.globalProperties.$axios,
    };
    ctx.app = ctx;
    return plugin(ctx, function (name: string, implementation: any) {
      app.config.globalProperties[`$${name}`] = implementation;
      app.config.globalProperties.$store[`$${name}`] = implementation;
    });
  };
}

const app = createApp(AppVue);

app.use(router);
app.use(store, key);

app.config.globalProperties[`$axios`] = axios;

app
  .use(createPlugin(nvuex))
  .use(createPlugin(Log))
  .use(createPlugin(Account))
  .use(createPlugin(DataViews))
  .use(createPlugin(SpecialOrder))
  .use(createPlugin(FileProcessor))
  .use(createPlugin(UserActivityService))
  .mount("#app");
