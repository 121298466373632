const toleranceInches = 0.08;
export const minDpi = 200;

export function dpi(orderItem: any, image: any): number {
  return orderItem ? (image ? image.Width / mmToInches(orderItem.printWidthMM) : 0) : 0;
}

export function toPrecision(val: number, precision?: number): number {
  return parseFloat(val.toFixed(precision));
}

export function mmToInches(mm: number, precision?: number): number {
  const val = ((mm * 10) / 254);
  return precision !== undefined ? toPrecision(val, precision) : val;
}

export function inchesToMm(inches: number, precision?: number): number {
  const val = ((inches * 254) / 10);
  return precision !== undefined ? toPrecision(val, precision) : val;
}


