export enum FormDataViewType {
  DataView,
  GridFilter,
}

export enum FormDataViewGridFilterKind {
  Public,
  Private,
}

export enum FormDataViewGridFilterColor {
  Yellow,
  Green,
  Blue,
  LightBlue,
  Mint,
  LightGreen,
  Peach,
  Orange,
  Pink,
  Red,
  Purple,
  Violet,
}

export enum FormDataViewParamType {
  Text,
  Email,
  Integer,
  Real,
  DateTime,
  TimeInterval,
  KeyValueObject,
  Autocomplete,
  DateTimeRange,
  Boolean
}

export enum FormDataViewScheduleKind {
  Never,
  Daily,
  Weekday,
  Weekly,
  Monthly,
}

export enum Weekday {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum FormDataViewSortDirection {
  Ascending = 0,
  Descending = 1,
  None = 2,
}

export enum FormDataViewFilterOperator {
  NoFilter = -1,
  IsLessThan,
  IsLessThanOrEqualTo,
  IsEqualTo,
  IsNotEqualTo,
  IsGreaterThanOrEqualTo,
  IsGreaterThan,
  StartsWith,
  EndsWith,
  Contains,
  IsContainedIn,
  DoesNotContain,
  IsNull,
  IsNotNull,
  IsEmpty,
  IsNotEmpty,
  IsNullOrEmpty,
  IsNotNullOrEmpty,
  IsBetween,
  IsNotBetween,
}

export enum ParamValueType {
  Empty,
  Single,
  Range,
  Multiple,
  SingleOffset,
  RangeOffset,
}

export interface KeyValuePair<K, V> {
  Key: K;
  Value: V;
}

export interface FormDataViewParam {
  Id: number;
  Position: number;
  DataViewId: number;
  DataView: FormDataView;
  ParamType: FormDataViewParamType;
  Name: string;
  FieldPath: string;
  Label: string;
  Description: string;
  Required: boolean;
  DefaultValue: object | null;
  AllowMultiple: boolean;
  DataSourceName: string;
  DataSourceUrl: string;
  List: KeyValuePair<string, string>[];
  ParamValues: FormDataViewGridFilterParamValue[];
}

export interface FormDataViewSchedule {
  Id: number;
  Schedule: FormDataViewScheduleKind;
  Time: string;
  WeekDay?: Weekday | null;
  MonthDay?: number | null;
  Recipients: string;
  IsUiView: boolean;
  IsEmailReport: boolean;
}

export interface FormDataViewGridFilterParamValue {
  Id?: number;
  GridFilterId: number;
  GridFilter?: FormDataViewGridFilter;
  ParamId: number;
  Param?: FormDataViewParam;
  Value: string | null;
  ValueObj: any | null;
  ValueInt: any | null;
  ValueBoolean: boolean | null;
  Operator: FormDataViewFilterOperator;
  OperatorSelected?: any | null;
  ValueType: ParamValueType;
  ValueTypeSelected?: any | null;
  Format?: string;
  DateTimeSingleOptionSelected?: any | null;
  DateTimeRangeOptionSelected?: any | null;
}

export interface FormDataViewSortDescriptor {
  Id?: number;
  GridFilterId: number;
  GridFilter?: FormDataViewGridFilter;
  FormDataViewId: number;
  DataView?: FormDataView | null;
  FieldPath: string;
  Direction: FormDataViewSortDirection;
}

export interface FormDataViewIncludedColumns {
  Id?: number;
  GridFilterId?: number;
  GridFilter?: FormDataViewGridFilter;
  DataViewId?: number;
  DataView?: FormDataView | null;
  Position: number;
  FieldPath: string;
}

export interface FormDataViewGridFilter {
  Id?: number;
  DataViewId: number;
  DataView?: FormDataView | null;
  Kind: FormDataViewGridFilterKind;
  Color: FormDataViewGridFilterColor;
  UserId?: number;
  Name?: string;
  Description?: string;
  ScheduleId?: number;
  Schedule?: FormDataViewSchedule;
  ParamValues: FormDataViewGridFilterParamValue[];
  Sorts: FormDataViewSortDescriptor[];
  IncludedColumns: FormDataViewIncludedColumns[];
  DateCreated?: string | null;
  DateModified?: string | null;
  DateDeleted?: string | null;
  IsDeleted?: boolean;
  IsOwnedByLoggedUser?: boolean;
  Unfiltered?: boolean;
  IsFinalised?: boolean;
  FormDataGridFilterCategoryId?: number;
  FormDataGridFilterCategory?: FormDataGridFilterCategory;
}

export interface FormDataViewSource {
  Id: number;
  DataView: FormDataView;
  Name: string;
  Url: string;
  Method: string;
  SchemaUrl: string;
}

export interface FormDataView {
  Id: number;
  GroupId: number;
  Group: FormDataViewGroup;
  DataViewType: FormDataViewType;
  Name: string;
  Title: string;
  Description: string;
  ActionName: string;
  ActionLabel: string;
  DataSourceId: number;
  DataSource: FormDataViewSource;
  CanBeScheduled: boolean;
  ScheduleId: number | null;
  Schedule: FormDataViewSchedule;
  Parameters: FormDataViewParam[];
  Sorts: FormDataViewSortDescriptor[];
  GridFilters: FormDataViewGridFilter[];
  IncludedColumns: FormDataViewIncludedColumns[];
  DateCreated: string;
  DateModified: string | null;
  DateDeleted: string | null;
  IsDeleted: boolean;
}

export interface FormDataViewGroup {
  Id: number;
  Name: string;
  Description: string;
  Views: FormDataView[];
}

export interface FormDataGridFilterCategory {
  Id: number;
  Name: string;
  GridFilters: FormDataViewGridFilter[];
}

export const schedulingOptions = [
  { id: FormDataViewScheduleKind.Never, text: "Never" },
  { id: FormDataViewScheduleKind.Daily, text: "Daily" },
  { id: FormDataViewScheduleKind.Weekday, text: "Every weekday (Mon to Fri)" },
  { id: FormDataViewScheduleKind.Weekly, text: "Weekly" },
  { id: FormDataViewScheduleKind.Monthly, text: "Monthly" },
];

export const visibilityOptions = [
  { id: FormDataViewGridFilterKind.Public, text: "Public" },
  { id: FormDataViewGridFilterKind.Private, text: "Private" },
];

export const visibilityColorOptions = [
  { id: FormDataViewGridFilterColor.Yellow, text: "Yellow" },
  { id: FormDataViewGridFilterColor.Green, text: "Green" },
  { id: FormDataViewGridFilterColor.Blue, text: "Blue" },
  { id: FormDataViewGridFilterColor.LightBlue, text: "Light Blue" },
  { id: FormDataViewGridFilterColor.Mint, text: "Mint" },
  { id: FormDataViewGridFilterColor.LightGreen, text: "Light Green" },
  { id: FormDataViewGridFilterColor.Peach, text: "Peach" },
  { id: FormDataViewGridFilterColor.Orange, text: "Orange" },
  { id: FormDataViewGridFilterColor.Pink, text: "Pink" },
  { id: FormDataViewGridFilterColor.Red, text: "Red" },
  { id: FormDataViewGridFilterColor.Purple, text: "Purple" },
  { id: FormDataViewGridFilterColor.Violet, text: "Violet" },
];

export const weekdayOptions = [
  { id: Weekday.Monday, text: "Monday" },
  { id: Weekday.Tuesday, text: "Tuesday" },
  { id: Weekday.Wednesday, text: "Wednesday" },
  { id: Weekday.Thursday, text: "Thursday" },
  { id: Weekday.Friday, text: "Friday" },
  { id: Weekday.Saturday, text: "Saturday" },
  { id: Weekday.Sunday, text: "Sunday" },
];

export const dayOfMonthOptions = [...Array(31).keys()].map(i => i + 1);

const noFilterText = "no filter";
const isLessThanText = "is less than";
const isLessThanOrEqualToText = "is less than or equal to";
const isEqualToText = "is equal to";
const isNotEqualToText = "is not equal to";
const isGreaterThanOrEqualToText = "is greater than or equal to";
const isGreaterThanText = "is greater than";
const startsWithText = "starts with";
const endsWithText = "ends with";
const containsText = "contains";
const isContainedInText = "is contained in";
const doesNotContainText = "does not contain";
const isNullText = "is null";
const isNotNullText = "is not null";
const isEmptyText = "is empty";
const isNotEmptyText = "is not empty";
const isNullOrEmptyText = "is null or empty";
const isNotNullOrEmptyText = "is not null or empty";
const isBetweenText = "is between";
const isNotBetweenText = "is not between";

export const numberFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.IsLessThan, text: isLessThanText },
  {
    id: FormDataViewFilterOperator.IsLessThanOrEqualTo,
    text: isLessThanOrEqualToText,
  },
  { id: FormDataViewFilterOperator.IsEqualTo, text: isEqualToText },
  { id: FormDataViewFilterOperator.IsNotEqualTo, text: isNotEqualToText },
  {
    id: FormDataViewFilterOperator.IsGreaterThanOrEqualTo,
    text: isGreaterThanOrEqualToText,
  },
  { id: FormDataViewFilterOperator.IsGreaterThan, text: isGreaterThanText },
  { id: FormDataViewFilterOperator.IsBetween, text: isBetweenText },
  { id: FormDataViewFilterOperator.IsNotBetween, text: isNotBetweenText },
  { id: FormDataViewFilterOperator.IsNull, text: isNullText },
  { id: FormDataViewFilterOperator.IsNotNull, text: isNotNullText },
  { id: FormDataViewFilterOperator.IsEmpty, text: isEmptyText },
  { id: FormDataViewFilterOperator.IsNotEmpty, text: isNotEmptyText },
  { id: FormDataViewFilterOperator.IsNullOrEmpty, text: isNullOrEmptyText },
  {
    id: FormDataViewFilterOperator.IsNotNullOrEmpty,
    text: isNotNullOrEmptyText,
  },
];

export const textFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.StartsWith, text: startsWithText },
  { id: FormDataViewFilterOperator.EndsWith, text: endsWithText },
  { id: FormDataViewFilterOperator.Contains, text: containsText },
  { id: FormDataViewFilterOperator.DoesNotContain, text: doesNotContainText },
  { id: FormDataViewFilterOperator.IsNull, text: isNullText },
  { id: FormDataViewFilterOperator.IsNotNull, text: isNotNullText },
  { id: FormDataViewFilterOperator.IsEmpty, text: isEmptyText },
  { id: FormDataViewFilterOperator.IsNotEmpty, text: isNotEmptyText },
  { id: FormDataViewFilterOperator.IsNullOrEmpty, text: isNullOrEmptyText },
  {
    id: FormDataViewFilterOperator.IsNotNullOrEmpty,
    text: isNotNullOrEmptyText,
  },
];

export const indexFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.IsEqualTo, text: isEqualToText },
  { id: FormDataViewFilterOperator.IsNotEqualTo, text: isNotEqualToText },
  { id: FormDataViewFilterOperator.Contains, text: containsText },
  { id: FormDataViewFilterOperator.DoesNotContain, text: doesNotContainText },
  { id: FormDataViewFilterOperator.IsNull, text: isNullText },
  { id: FormDataViewFilterOperator.IsNotNull, text: isNotNullText },
];

export const dateTimeFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.IsLessThan, text: isLessThanText },
  {
    id: FormDataViewFilterOperator.IsLessThanOrEqualTo,
    text: isLessThanOrEqualToText,
  },
  { id: FormDataViewFilterOperator.IsEqualTo, text: isEqualToText },
  { id: FormDataViewFilterOperator.IsNotEqualTo, text: isNotEqualToText },
  {
    id: FormDataViewFilterOperator.IsGreaterThanOrEqualTo,
    text: isGreaterThanOrEqualToText,
  },
  { id: FormDataViewFilterOperator.IsGreaterThan, text: isGreaterThanText },
  { id: FormDataViewFilterOperator.IsBetween, text: isBetweenText },
  { id: FormDataViewFilterOperator.IsNotBetween, text: isNotBetweenText },
  { id: FormDataViewFilterOperator.IsNull, text: isNullText },
  { id: FormDataViewFilterOperator.IsNotNull, text: isNotNullText },
  { id: FormDataViewFilterOperator.IsEmpty, text: isEmptyText },
  { id: FormDataViewFilterOperator.IsNotEmpty, text: isNotEmptyText },
  { id: FormDataViewFilterOperator.IsNullOrEmpty, text: isNullOrEmptyText },
  {
    id: FormDataViewFilterOperator.IsNotNullOrEmpty,
    text: isNotNullOrEmptyText,
  },
];

export const listFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.IsEqualTo, text: isEqualToText },
  { id: FormDataViewFilterOperator.IsNotEqualTo, text: isNotEqualToText },
  { id: FormDataViewFilterOperator.Contains, text: containsText },
  { id: FormDataViewFilterOperator.DoesNotContain, text: doesNotContainText },
  { id: FormDataViewFilterOperator.IsNull, text: isNullText },
  { id: FormDataViewFilterOperator.IsNotNull, text: isNotNullText },
  { id: FormDataViewFilterOperator.IsEmpty, text: isEmptyText },
  { id: FormDataViewFilterOperator.IsNotEmpty, text: isNotEmptyText },
  { id: FormDataViewFilterOperator.IsNullOrEmpty, text: isNullOrEmptyText },
  {
    id: FormDataViewFilterOperator.IsNotNullOrEmpty,
    text: isNotNullOrEmptyText,
  },
];
export const booleanFilterOptions = [
  { id: FormDataViewFilterOperator.NoFilter, text: noFilterText },
  { id: FormDataViewFilterOperator.IsEqualTo, text: isEqualToText },
];
export const emptyValueOps = [
  FormDataViewFilterOperator.IsEmpty,
  FormDataViewFilterOperator.IsNotEmpty,
  FormDataViewFilterOperator.IsNull,
  FormDataViewFilterOperator.IsNotNull,
  FormDataViewFilterOperator.IsNullOrEmpty,
  FormDataViewFilterOperator.IsNotNullOrEmpty,
];

export const singleValueOps = [
  FormDataViewFilterOperator.IsLessThan,
  FormDataViewFilterOperator.IsLessThanOrEqualTo,
  FormDataViewFilterOperator.IsEqualTo,
  FormDataViewFilterOperator.IsNotEqualTo,
  FormDataViewFilterOperator.IsGreaterThanOrEqualTo,
  FormDataViewFilterOperator.IsGreaterThan,
  FormDataViewFilterOperator.StartsWith,
  FormDataViewFilterOperator.EndsWith,
];

export const rangeValueOps = [
  FormDataViewFilterOperator.IsBetween,
  FormDataViewFilterOperator.IsNotBetween,
];

export const multipleValueOps = [
  FormDataViewFilterOperator.Contains,
  FormDataViewFilterOperator.DoesNotContain,
];

export const dateTimeSingleOptions = [
  { id: 0, text: "today" },
  { id: 1, text: "yesterday" },
  { id: 2, text: "past 2 days" },
  { id: 3, text: "past 3 days" },
  { id: 4, text: "past 7 days" },
  { id: 5, text: "past 15 days" },
  { id: 6, text: "past 1 month" },
  { id: 7, text: "past manual input days" },
  { id: 8, text: "select from calendar" },
];

export const dateTimeRangeOptions = [
  { id: 0, text: "datetime" },
  { id: 1, text: "offset" },
];
