
import { defineComponent, ref } from "vue";
import * as ku from "@/core/kendoUtils";

declare var $: any;
// var magnumUserId = 6274938;
// var pwfUserId = 6212013;

export default defineComponent({
  name: "ReportGenerator",
  // components: { GridHubUsers },
  beforeRouteLeave(to, from, next) {
    const kGrid = $(this.gridHandle).data("kendoGrid");
    if (kGrid) ku.stashGridOptions(kGrid.getOptions(), this.storageKey);
    next();
  },
  setup() {
    const grid: any = ref(null);
    const selectedFilterId: any = ref("-1");
    const filters: any = ref([]);
    const filterName: any = ref("");

    return { grid, selectedFilterId, filters, filterName };
  },
  data() {
    return {
      storageKey: `${this.$options.name}GridOptions`,
      gridHandle: "#reportGenerator",
    };
  },
  mounted() {
    ku.applyGridOptionDeferred(
      $,
      this.gridHandle,
      ku.unstashGridOptions(this.storageKey)
    );
    const _window: any = window;
    _window.router = this.$router;
    // eslint-disable-next-line
    const _this = this;
    const grid = this.grid;
    $.get("/form/grid/ReportGeneratorPartial", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
  methods: {
    resetFilter() {
      $(this.gridHandle).data("kendoGrid").dataSource.filter({});
    },
    saveFilter() {
      const _window: any = window;
      const opts: any = $(this.gridHandle).data("kendoGrid").getOptions();

      $.post(
        "/form/grid/saveFilter/",
        {
          Name: this.filterName,
          Type: 1,
          FilterJson: _window.kendo.stringify(opts),
        },
        () => {
          $.get("/form/grid/getFilters/1", {}, (data: any) => {
            this.filters = data;
            this.filterName = "";
          });
        }
      );
    },
    loadFilter() {
      const filter: any = this.filters.filter(
        (f: any) => f.Id == this.selectedFilterId
      )[0];

      if (filter) {
        $(this.gridHandle)
          .data("kendoGrid")
          .setOptions(JSON.parse(filter.FilterJson));
      }
    },
    removeFilter() {
      $.post("/form/grid/deleteFilter/" + this.selectedFilterId, {}, () => {
        $.get("/form/grid/getFilters/1", {}, (data: any) => {
          this.filters = data;
          this.selectedFilterId = -1;
        });
      });
    },
  },
});
