
import { ProductionTeamGridData } from '@/core/types/productionteams/productionTeamGridData';
import { defineComponent, ref, PropType } from 'vue';
import moment from 'moment';

declare const $: any;

export default defineComponent({
  name: 'ProductionTeamGrid',
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    gridId: {
      type: String as PropType<string>,
      required: true,
    },
    branchName: {
      type: String as PropType<string>,
      required: true,
    },
    additionalDataFunctionName: {
      type: String as PropType<string>,
      required: true,
    },
    additionalData: {
      type: Object as PropType<ProductionTeamGridData>,
      required: true,
    },
    isGridInitialized: {
      type: Boolean,
      required: true,
      readonly: false,
    }
  },
  setup(props) {
    const grid = ref(null);

    const windowFunction = (data: ProductionTeamGridData): ProductionTeamGridData => {
      return data;
    }
    
    return {
      grid,
      windowFunction
    };
  },
  mounted() {
    this.loadGrid();
  },
  methods: {
    loadGrid() {
      const _window: any = window;

      _window[this.additionalDataFunctionName] = this.windowFunction(this.additionalData);

      $.get(this.url, {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(this.grid).html(html);

        $(this.grid).on("click", "#addNewCmd", (e: any) => {
          e.preventDefault();
          _window.router.push("/grid-filters/filter");
        });
      });
      
      _window['onRequestEnd' + this.branchName] = this.onRequestEnd;
    },
    onRequestEnd(e: any): void {
      const _this = this;
      if (e.type == 'read' && !this.isGridInitialized) {
        const grid = $(this.gridId).data("kendoGrid");
        
        if (e.response.Data.length == 0) {
          $(this.gridId).hide();
          $("#noData").attr("hidden", false);
        }

        let columns = grid.columns;

        const startDate = new Date(e.response.StartDate);
        const endDate = new Date(e.response.EndDate);

        columns = columns.filter((c: any) => c.field === "Name" || c.field === "TotalHours" || c.field === "ContractType");

        for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          const dateString: string = moment(String(d)).format("YYYY-MM-DD");

          const key: string = moment(String(d)).format("YYYY-MM-DD");

          let style = (d.getDay() == 0 || d.getDay() == 6) ? "background-color: whitesmoke; text-align: right;" : "text-align: right;"

          columns.push({
            title: dateString,
            template: function(dataItem: any): string {
              const hours = dataItem.DateHours[key] || 0;

              if (hours == 0) {
                return `<a href="#" class="hour-link" data-id="${dataItem.Id}" data-employee="${dataItem.Name}" data-date="${dateString}"></a>`
              } else if (hours === -1) {
                return `<a href="#" class="hour-link" data-id="${dataItem.Id}" data-employee="${dataItem.Name}" data-date="${dateString}">N/A</a>`
              }
              else {
                return `<a href="#" class="hour-link" data-id="${dataItem.Id}" data-employee="${dataItem.Name}" data-date="${dateString}">${hours}</a>`
              }
            },
            field: "date",
            attributes: {
              style: style
            },
            width: 150
          })
        }

        this.$emit('update:isGridInitialized', true);
        
        grid.setOptions({ columns: columns, scrollable: { virtual: true } });

        grid.bind("dataBound", function() {
          $("td").has("a:contains('N/A')").css("background-color", "lightyellow");
        });
      }
    }
  }
})
