import { GridFilterType } from "./enums/grid-filter.type";

export function serializeSorts(sorts: any[]): string {
  if (!sorts) return "";
  let res = "";
  const elems = [];
  for (let i = 0; i < sorts.length; i++) {
    elems.push(`${sorts[i].field}-${sorts[i].dir}`);
  }
  res = elems.join("~");

  return res;
}

export function gridParametersToDataSourceRequest(grid: any) {
  const parameterMap = grid.dataSource.transport.parameterMap;
  const data = parameterMap({
    sort: grid.dataSource.sort(),
    filter: grid.dataSource.filter(),
    group: grid.dataSource.group(),
  });

  return data;
}

export function serializeFilters(filters: any[]): string {
  const isDateTimeField = (colName: string) => colName.indexOf("Date") > -1;
  const serializeFilter = (filter: any) => {
    let value = `'${filter.value}'`;
    if (isDateTimeField(filter.field)) {
      let val = filter.value.toISOString().replaceAll(":", "-");
      const msPos = val.lastIndexOf(".");
      val = msPos > -1 ? val.substr(0, msPos) : val;
      value = `datetime'${val}'`;
    }

    return `${filter.field}~${filter.operator}~${value}`;
  };

  if (!filters) return "";
  let res = "";
  const elems = [];
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].filters) {
      const innerElems = [];
      const innerFilters = filters[i].filters;
      for (let j = 0; j < innerFilters.length; j++) {
        innerElems.push(serializeFilter(innerFilters[j]));
      }
      const innerRes = innerElems.join(`~${filters[i].logic}~`);
      elems.push(`(${innerRes})`);
    } else {
      elems.push(serializeFilter(filters[i]));
    }
  }
  res = elems.join("~and~");

  return res;
}

export function stashGridOptions(
  opts: any,
  storageKey: string,
  wnd: any = undefined,
): void {
  wnd = wnd || window;

  // Do not remove - this fixes a problem for setOptions
  if (opts.toolbar.command) {
    opts.toolbar = opts.toolbar.command;
  }
  const json = wnd.kendo.stringify(opts);
  sessionStorage.setItem(storageKey, json);
}

export function unstashGridOptions(storageKey: string): any {
  if (sessionStorage.getItem(storageKey)) {
    const opts = sessionStorage.getItem(storageKey);

    if (opts) {
      return JSON.parse(opts);
    }
  }
  return null;
}

export function applyGridOptionDeferred(
  jQuery: any,
  gridHandle: string,
  opts: any,
  delay = 250,
) {
  if (opts) {
    setTimeout(
      () => jQuery(gridHandle).data("kendoGrid").setOptions(opts),
      delay,
    );
  }
}

export function getOrderTableState($: any, type: GridFilterType): Promise<any> {
  return $.get(
    `/form/grid/GetFilter?type=${type}&key=${getOrderStateKey(type)}`,
  );
}
export function saveOrderTableState(
  $: any,
  filter: string,
  type: number,
): Promise<any> {
  return $.post("/form/grid/saveFilter/", {
    Name: getOrderStateKey(type),
    Type: type,
    FilterJson: filter,
  });
}
export function getOrderStateKey(type: GridFilterType): string | null {
  const selectedFilterId = localStorage.getItem(
    `${GridFilterType[type]}SelectedDataFilter`,
  );
  if (!selectedFilterId) {
    return null;
  }
  return `${GridFilterType[type]}TableState_${selectedFilterId}`;
}
export function getOrderStateIdKey(type: GridFilterType): string | null {
  const selectedFilterId = localStorage.getItem(
    `${GridFilterType[type]}SelectedDataFilter`,
  );
  if (!selectedFilterId) {
    return null;
  }
  return `${GridFilterType[type]}TableStateId_${selectedFilterId}`;
}
export function setToGridState(
  $: any,
  grid: any,
  type: GridFilterType,
  gridToolbarHandle: string,
): void {
  const key = getOrderStateKey(type);
  if (!key) {
    return;
  }
  const state: string | null = sessionStorage.getItem(key);
  if (state == null) {
    getOrderTableState($, type).then(response => {
      if (response) {
        const key = getOrderStateKey(type);
        const idKey = getOrderStateIdKey(type);
        if (!key || !idKey) {
          return;
        }
        sessionStorage.setItem(key, response.FilterJson);
        sessionStorage.setItem(idKey, JSON.stringify(response.Id));
        grid.setOptions(JSON.parse(response.FilterJson));
        scrollToElement();
      }
    });
  }
  if (state != null) {
    const toolBar = $(gridToolbarHandle).detach();
    grid.setOptions(JSON.parse(state));
    $(gridToolbarHandle).replaceWith(toolBar);
    scrollToElement();
  }
}
export function setGridStateToStorage(
  $: any,
  grid: any,
  type: GridFilterType,
  wnd: any = undefined,
): void {
  const key = getOrderStateKey(type);
  if (!key) {
    return;
  }
  if (grid) {
    wnd = wnd || window;
    const options = grid.getOptions();
    const stringOptions = wnd.kendo.stringify(options);
    $.post("/form/grid/saveFilter/", {
      Name: getOrderStateKey(type),
      Type: type,
      FilterJson: stringOptions,
    }).done((response: any) => {
      const idKey = getOrderStateIdKey(type);
      if (!idKey) {
        return;
      }
      sessionStorage.setItem(key, stringOptions);
      sessionStorage.setItem(idKey, JSON.stringify(response.Id));
    });
  }
  return;
}

export function removeState(type: GridFilterType) {
  const key = getOrderStateKey(type);
  const idKey = getOrderStateIdKey(type);
  if (key && idKey) {
    sessionStorage.removeItem(key);
    sessionStorage.removeItem(idKey);
  }
}

export function scrollToElement(): void {
  setTimeout(() => {
    const scrollOrderId: string | null = sessionStorage.getItem(
      "scrollToLastOpenedOrderId",
    );
    if (scrollOrderId) {
      const tbody = document.getElementsByTagName("tbody");
      if (tbody.length > 0) {
        const body = tbody[0];
        body.childNodes.forEach(value => {
          if (
            (value as HTMLTableRowElement).innerText.includes(scrollOrderId)
          ) {
            (value as HTMLTableRowElement).scrollIntoView();
            sessionStorage.removeItem("scrollToLastOpenedOrderId");
          }
        });
      }
    }
  }, 2000);
}
