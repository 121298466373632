
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    show: Boolean,
    selectList: Array,
  },
  computed: {
    list(): any[] {
      return this.selectList as any[];
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close();
    },
  },
});
