
import { defineComponent, Ref, ref, toRef } from "vue";

import { Summary, makeSummaryOf } from "./summary";

export default defineComponent({
  name: "DispatchQCModalVue",
  props: {
    show: Boolean,
    qcUsers: Array,
    order: Object,
  },
  emits: ["confirm", "cancel"],
  setup(props) {
    const selectedQcUserId: Ref<number> = ref(0);

    return {
      orderRef: toRef(props, "order"),
      selectedQcUserId,
    };
  },
  computed: {
    summary(): Summary {
      return makeSummaryOf(this.orderRef);
    },
    isQcUserSelected(): boolean {
      return this.selectedQcUserId > 0;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      console.log("selectedValue", this.selectedQcUserId);
      this.$emit("confirm", { selectedQcUserId: this.selectedQcUserId });
    },
  },
});
