
import { defineComponent, ref, Ref } from "vue";
import TileLayout from "../Components/TileLayout.vue";
import FiltersBar from "../Components/FiltersBar.vue";
import DataTable from "../Components/DataTable.vue";
import {
  productivity,
  productivityData,
} from "@/core/services/productivityDashboard";
import {
  dataTableTitles,
  DateRange,
  makeRangeDefault,
  ProductivityActivity,
  ProductivityDataAggregate,
  ProductivityLevel,
  ProductivityResponse,
  ProductivityType,
} from "@/core/types/dashboard/ProductivityData";

import * as utils from "@/core/utils";

type TileBody = "Items" | "PeopleHours" | "ItemsPerPeopleHours";
type Tile = {
  position: number;
  header: string;
  body: TileBody;
};

type Filters = {
  dateRange: DateRange;
  selectedAggregate: ProductivityDataAggregate;
};

export default defineComponent({
  name: "ProductivityTab",
  components: {
    FiltersBar,
    TileLayout,
    DataTable,
  },
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    level: {
      type: Number,
      required: false,
      default: ProductivityLevel.Summary,
    },
    activity: {
      type: Number,
      required: false,
      default: ProductivityActivity.All,
    },
    dateRange: {
      type: Object,
      required: false,
      default: makeRangeDefault,
    },
    aggregate: {
      type: Number,
      required: false,
      default: ProductivityDataAggregate.Daily,
    },
  },
  emits: ["aggregateChanged", "dateRangeChanged"],
  setup() {
    const tiles: Ref<Tile[]> = ref([]);
    const data = ref({} as ProductivityResponse);
    const isParentLoading = ref(false);
    return {
      cols: 2,
      tiles,
      data,
      isParentLoading,
    };
  },
  computed: {
    filters(): Filters {
      return {
        dateRange: this.dateRange as DateRange,
        selectedAggregate: this.aggregate,
      };
    },
  },
  watch: {
    isSelected: {
      async handler(newValue) {
        if (newValue) {
          await this.loadData();
        }
      },
    },
  },
  async mounted() {
    this.setupTiles();
    if (this.isSelected) {
      await this.loadData();
    }
  },
  methods: {
    tileMaker() {
      let position = -1;
      return (header: string, body: TileBody) => {
        position++;
        return {
          position,
          header,
          body,
        };
      };
    },
    setupTiles() {
      const makeTile = this.tileMaker();
      const [
        itemsDataTableTitle,
        peopleHoursDataTableTitle,
        itemsPerPeopleHoursDataTableTitle,
      ] = dataTableTitles(this.level, this.activity);

      this.tiles = [
        makeTile(itemsDataTableTitle, "Items"),
        makeTile(peopleHoursDataTableTitle, "PeopleHours"),
        makeTile(itemsPerPeopleHoursDataTableTitle, "ItemsPerPeopleHours"),
      ];
    },
    async loadData() {
      try {
        this.isParentLoading = true;
        if (this.isSelected) {
          this.data = await this.getProductivity();
        }
      } finally {
        this.isParentLoading = false;
      }
    },
    async getProductivity(): Promise<ProductivityResponse> {
      return await productivity({
        level: this.level,
        activity: this.activity,
        startDate: this.dateRange.start,
        endDate: this.dateRange.end,
        aggregate: this.aggregate,
      });
    },

    async getProductivityData(type: ProductivityType): Promise<void> {
      const strOfProductivityLevel = (level: ProductivityLevel) => {
        switch (level) {
          case ProductivityLevel.Summary:
            return "Summary";
          case ProductivityLevel.General:
            return "General";
          case ProductivityLevel.Magnum:
            return "Magnum";
          case ProductivityLevel.Consolidated:
            return "Consolidated";
          case ProductivityLevel.NonProduction:
            return "NonProduction";
          default:
            return "Summary";
        }
      };

      const strOfProductivityActivity = (activity: ProductivityActivity) => {
        switch (activity) {
          case ProductivityActivity.All:
            return "";
          case ProductivityActivity.Dispatch:
            return "Dispatch";
          case ProductivityActivity.Printing:
            return "Printing";
          default:
            return "";
        }
      };

      const strOfProductivityType = (
        type: ProductivityType,
        activity: ProductivityActivity,
      ) => {
        if (
          type === ProductivityType.Items &&
          activity === ProductivityActivity.Printing
        ) {
          return "Printed Items";
        } else if (
          type === ProductivityType.Items &&
          activity === ProductivityActivity.Dispatch
        ) {
          return "Orders";
        } else {
          if (type === ProductivityType.Items) return "Printed Items";
          else return "People Hours";
        }
      };

      const filename = () => {
        return (
          `${strOfProductivityLevel(this.level)}` +
          `_${strOfProductivityActivity(this.activity)}` +
          `_${strOfProductivityType(type, this.activity)}.csv`
        );
      };

      const result = await productivityData({
        level: this.level,
        activity: this.activity,
        startDate: this.dateRange.start,
        endDate: this.dateRange.end,
        type,
      });
      //const csv = utils.toCsv(result);
      utils.saveCsv(filename(), result);
    },
    onAggregateChanged(event: any) {
      this.$emit("aggregateChanged", event);
    },
    onDateRangeChanged(event: any) {
      this.$emit("dateRangeChanged", event);
    },
    async onApplyFilters(): Promise<void> {
      if (this.isSelected) {
        await this.loadData();
      }
    },
  },
});
