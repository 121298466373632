
import { defineComponent, ref } from "vue";
import { Editor, EditorTool } from "@progress/kendo-editor-vue-wrapper";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import moment from "moment";
import axios from "axios";
import { FILE_UPLOAD_EVENTS } from "tps.shared.ui/core/fileUpload/uploadStateEnum";
import { FileProcessorRegistration } from "tps.shared.ui/core/fileUpload/fileProcessor";
import FileModel from "tps.shared.ui/core/fileUpload/fileModel";
import CustomMultiselect from "@/components/CustomMultiselect/CustomMultiselect.vue";
import { Loader } from "@progress/kendo-vue-indicators";

import { useStore } from "@/store";
declare var $: any;

enum PostStatus {
  Unchanged = -1,
  Deleted = 0,
  Draft,
  Published,
  Preview = 10,
}

export default defineComponent({
  name: "CnShowcasePost",
  components: {
    editor: Editor,
    "editor-tool": EditorTool,
    CustomMultiselect,
    "kendo-notification": Notification,
    Loader,
  },
  setup() {
    const store = useStore();
    const post: any = ref({});
    const mode: any = ref("");
    const notify: any = ref({});
    const statuses: any = ref([]);
    const errors: any = ref({});
    const currentSlug: any = ref(null);
    const mainRadio: any = ref(0);
    const new_category_customs: any = ref(null);
    const new_category_cities: any = ref(null);
    const isSaved: any = ref(false);
    const talentEmailForCreate: any = ref(null);
    const textCount: any = ref(0);
    const popupNotificationWidget: any = ref(null);
    const imageProcessing: any = ref(false);
    const form: any = ref({
      Id: 0,
      Title: null,
      SeoTitle: null,
      ShortText: null,
      Text: ``,
      Talent: {
        Id: null,
      },
      Slug: null,
      Tags: [],
      Images: [],
      MetaDescription: null,
      MetaTags: null,
      ReadingTime: 3,
      Status: PostStatus.Draft,
    });
    const ACTIVITY_CODE = {
      0: "Creation",
      1: "DataChange",
      2: "Unpublished",
      3: "Published",
      4: "Deleted",
      5: "Restored",
    };
    const TAG_CODES: any = {
      0: "cities",
      1: "customs",
      2: "skills",
      3: "countries",
    };

    const categories_result: any = ref({
      skills: [],
      countries: [],
      cities: [],
      customs: [],
    });
    const categories_list: any = ref({
      countries: [],
      skills: [],
      cities: [],
      customs: [],
      // customs: [ { Id: 1, Tag: "Custom 1" }, { Id: 2, Tag: "Custom 2" },]
    });
    return {
      store,
      post,
      mode,
      isSaved,
      statuses,
      form,
      errors,
      notify,
      ACTIVITY_CODE,
      TAG_CODES,
      currentSlug,
      mainRadio,
      categories_result,
      categories_list,
      new_category_customs,
      new_category_cities,
      talentEmailForCreate,
      textCount,
      popupNotificationWidget,
      imageUploading: false,
      imageProcessing,
      uploadProgress: 0,
      onProcessedImageRegistration: null as FileProcessorRegistration,
      onFileCanceledRegistration: null as FileProcessorRegistration,
      onFileUploadingRegistration: null as FileProcessorRegistration,
      onFileUploadedRegistration: null as FileProcessorRegistration,
    };
  },
  computed: {
    actionBtnLabel(): string {
      return this.isPublished ? "Unpublish" : "Publish";
    },
    isPublished(): boolean {
      const form: any = this.form;
      return form.Status === PostStatus.Published;
    },
    getStatus(): string {
      const currentStatus = this.statuses.find(
        (item: any) => item.Key === this.form.Status,
      );
      if (currentStatus) {
        return currentStatus.Value;
      }
      return "";
    },
    hasSkills(): boolean {
      const form: any = this.form;
      return (
        form.Talent &&
        form.Talent.Skills &&
        Array.isArray(form.Talent.Skills) &&
        form.Talent.Skills.length > 0
      );
    },
    hasTalent(): boolean {
      const form: any = this.form;
      return (
        form.Talent &&
        form.Talent.Avatar &&
        form.Talent.Avatar.userPath &&
        form.Talent.Avatar.guid
      );
    },
    skillsList(): string {
      return this.getSkillsList();
    },
  },
  watch: {
    "form.Text"(newVal) {
      this.textCount = newVal ? newVal.length : 0;
      if (newVal && newVal.length > 4000) {
        this.errors.Text = {
          msg: "Text length shouldn't be more than 4000 characters",
        };
      } else {
        this.errors.Text = null;
      }
    },
  },
  mounted() {
    console.log("CnShowcasePost mounted");
    if (this.$route.params.postId) {
      this.mode = "edit";
      console.log("this.getPostById");
      this.getPostById(null);
    } else {
      this.mode = "new";
      this.getTagOptions();
    }
    this.getStatuses();
    this.popupNotificationWidget = (
      this.$refs.popupNotification as any
    ).kendoWidget();

    console.log("CnShowcasePost mounted: " + this.mode);

    //-------------------------------------------
    // file upload event bus

    this.onProcessedImageRegistration = this.$fileProcessor.eventBus.$on(
      FILE_UPLOAD_EVENTS.PROCESSED_IMAGE,
      this.onProcessedImage,
    );
    this.onFileCanceledRegistration = this.$fileProcessor.eventBus.$on(
      FILE_UPLOAD_EVENTS.FILE_CANCELLED,
      this.onFileCanceled,
    );
    this.onFileUploadingRegistration = this.$fileProcessor.eventBus.$on(
      FILE_UPLOAD_EVENTS.FILE_UPLOADING,
      this.onFileUploading,
    );
    this.onFileUploadedRegistration = this.$fileProcessor.eventBus.$on(
      FILE_UPLOAD_EVENTS.FILES_UPLOADED,
      this.onFilesUploaded,
    );
  },
  beforeUnmount() {
    this.$fileProcessor.eventBus.$off(this.onProcessedImageRegistration);
    this.$fileProcessor.eventBus.$off(this.onFileCanceledRegistration);
    this.$fileProcessor.eventBus.$off(this.onFileUploadingRegistration);
    this.$fileProcessor.eventBus.$off(this.onFileUploadedRegistration);
  },
  methods: {
    async changeVisibility() {
      if (!this.isPublished) {
        if (!confirm("You are about to publish this post, are you sure?")) {
          return false;
        }
        await this.sendPosts(PostStatus.Published);
      } else {
        await this.sendPosts(PostStatus.Draft);
      }
    },
    showPopupNotification(message: string, type: string) {
      this.popupNotificationWidget.show(message, type);
    },
    async getTalentByEmail() {
      if (
        this.talentEmailForCreate !== null &&
        this.talentEmailForCreate !== ""
      ) {
        await $.post(
          "/form/showcase/talents",
          {
            email: this.talentEmailForCreate,
          },
          (data: any) => (this.form.Talent = data),
        ).fail((e: any) => {
          this.showPopupNotification(
            `Can't get the talent by e-mail ${this.talentEmailForCreate}. ${e.responseText}`,
            "error",
          );
        });
      }
    },
    async createHeroImage(): Promise<void> {
      if (this.mainRadio && this.mainRadio !== 0) {
        this.form.Images.some(
          (item: any, idx: number) =>
            item.id === this.mainRadio &&
            this.form.Images.unshift(
              // remove the found item, in-place (by index with splice),
              // returns an array of a single item removed
              this.form.Images.splice(idx, 1)[0],
            ),
        );
        this.mainRadio = this.form.Images[0].id;
      }
    },
    getSkillsList(): string {
      if (this.hasSkills) {
        return this.form.Talent.Skills.map((elem: any) => elem.Text).join(",");
      }
      return "- No skills added -";
    },
    combineCategories(val: any, type: any) {
      if (!Array.isArray(val)) {
        return false;
      }
      this.categories_result[type] = val;
    },
    async addCategory(TagType: number, catTag: string) {
      if (!catTag || catTag === "") {
        this.showPopupNotification(`Can't create empty tag`, "error");
        return false;
      }
      await axios
        .post("/form/showcase/tags", {
          TagType: TagType,
          Value: catTag,
        })
        .then(() => {
          this.getCategoryOptions(this.TAG_CODES[TagType], TagType);
          this.new_category_customs = null;
          this.new_category_cities = null;
          this.showPopupNotification(
            `New tag ${catTag} created successfully`,
            "success",
          );
        })
        .catch((e: any) => {
          this.showPopupNotification(
            `Can't get the talent by e-mail ${this.talentEmailForCreate}. ${e.response.data}`,
            "error",
          );
        });
    },
    removeCategory(delted_item: any) {
      Object.keys(this.categories_result).forEach((item: any) => {
        const index = this.categories_result[item].findIndex(
          (itm: any) =>
            itm.Tag === delted_item.Tag && itm.Id === delted_item.Id,
        );
        if (index > -1) {
          this.categories_result[item].splice(index, 1);
        }
        return item;
      });
    },
    removeImage(imageId: number) {
      const index = this.form.Images.findIndex(
        (item: any) => item.id === imageId,
      );
      this.form.Images.splice(index, 1);
    },
    getTagOptions() {
      this.getCategoryOptions("cities", 0);
      this.getCategoryOptions("customs", 1);
      this.getCategoryOptions("skills", 2);
      this.getCategoryOptions("countries", 3);
    },
    setPost(data: any) {
      this.form = data;
      this.mainRadio =
        this.form && this.form.Images && this.form.Images.length > 0
          ? this.form.Images[0].id
          : null;
      this.currentSlug = data.Slug;
      this.getTagOptions();
    },
    async getPostById(postId: string | null) {
      const postID = postId ? postId : this.$route.params.postId;
      await $.get(`/form/showcase/posts/${postID}`, {}, (data: any) => {
        this.setPost(data);
      }).fail((e: any) => {
        this.showPopupNotification(
          `Can't get post by id ${postID}. ${e.responseText}`,
          "error",
        );
      });
    },
    getCategoryOptions(type: string, tagType: number) {
      $.get(`/form/showcase/${type}`, (data: any) => {
        this.categories_list[type] = data;
        this.form.Tags = this.form.Tags.map((item: any) => {
          if (item.TagType === tagType) {
            const currentItem = data.find((elem: any) => {
              return elem.TagType === item.TagType && elem.Id === item.Id;
            });
            if (currentItem && currentItem.Value) {
              item.Value = currentItem.Value;
              this.categories_result[type].push(item);
            }
          }
          return item;
        });
      }).fail((e: any) => {
        this.showPopupNotification(
          `Can't get categoty ${type}. ${e.responseText}`,
          "error",
        );
      });
    },
    getStatuses() {
      $.get(
        "/form/showcase/statuses",
        (data: any) => (this.statuses = data),
      ).fail((e: any) => {
        this.showPopupNotification(
          `Can't get statuses. ${e.responseText}`,
          "error",
        );
      });
    },
    getDate(value: any) {
      return moment(value).format("DD.MM.YYYY");
    },
    getTime(value: any) {
      return moment(value).format("hh:mm:ss");
    },
    async createSlug() {
      const { Slug } = await $.post(
        "/form/showcase/slugs",
        {
          title: this.form.Title,
        },
        () => {
          this.showPopupNotification(`Slug created successfully`, "success");
        },
      ).fail((e: any) => {
        this.showPopupNotification(
          `Can't create slug by title - ${this.form.Title}. ${e.responseText}`,
          "error",
        );
      });
      this.form.Slug = Slug;
    },
    previewFiles(event: any) {
      this.addImage(event.target.files);
    },
    async checkSlug(status: number) {
      this.form.Slug = await $.get(
        `/form/showcase/slugs/${this.form.Slug}`,
      ).fail(() => {
        this.errors.Slug = null;
        this.sendPosts(status);
      });
      if (this.form.Slug) {
        this.errors.Slug = {
          msg: "This slug exist, change to another",
        };
      }
    },
    preview() {
      const urls: any = {
        "https://form.creativehub.local:30443":
          "https://creatives.new.local:31443",
        "https://form.vnext.tps-production.io":
          "https://creatives.vnext.tps-production.io",
        "https://forms.creativehub.io": "https://creatives.new",
      };
      const redirectedUrl = `${urls[window.location.origin]}/preview/${
        this.form.Slug
      }`;
      window.open(redirectedUrl, "_blank");
      this.isSaved = false;
    },
    async createOrUpdate(status: number) {
      if (status === PostStatus.Deleted) {
        if (!confirm("You are going to delete the post, are you sure?")) {
          return false;
        }
      }
      if (status === PostStatus.Published) {
        if (!confirm("You are going to publish post, are you sure?")) {
          return false;
        }
      }
      if (!this.validateForm()) {
        this.form.Tags = Object.values(this.categories_result).flat();
        await this.createHeroImage();
        if (this.mode === "new") {
          await this.createSlug();
          this.sendPosts(status);
        }
        if (this.mode === "edit") {
          console.log(
            "this.currentSlug === this.form.Slug",
            this.currentSlug === this.form.Slug,
          );
          console.log(
            "this.currentSlug === this.form.Slug => this.currentSlug",
            this.currentSlug,
          );
          console.log(
            "this.currentSlug === this.form.Slug => this.form.Slug",
            this.form.Slug,
          );
          if (this.currentSlug === this.form.Slug) {
            if (this.form.Slug) {
              this.errors.Slug = null;
            }
            await this.sendPosts(status);
          } else {
            await this.checkSlug(status);
          }
        }
      } else {
        this.showPopupNotification(
          "Please fill in all required fields",
          "error",
        );
      }
    },
    async publishPost() {
      await axios
        .put(`/form/showcase/posts/published/${this.form.Id}`)
        .then(({ data }: any) => {
          this.showPopupNotification(`Post pulished successfully`, "success");
          this.isSaved = true;
          this.categories_result = {
            skills: [],
            countries: [],
            cities: [],
            customs: [],
          };
          this.setPost(data);
        })
        .catch((e: any) => {
          this.showPopupNotification(
            `Error - ${this.form.Title}. ${e.response.message}`,
            "error",
          );
        });
    },
    async sendPosts(status: PostStatus) {
      const form = { ...this.form };
      form.Status =
        status === PostStatus.Preview ? PostStatus.Unchanged : status;
      await axios
        .post("/form/showcase/posts", form)
        .then(({ data }: any) => {
          if (status === PostStatus.Draft) {
            this.showPopupNotification(`Draft saved successfully`, "success");
          }

          if (status === PostStatus.Preview) {
            this.showPopupNotification(
              `Draft before preview saved successfully`,
              "success",
            );
          }
          if (status === PostStatus.Published) {
            this.showPopupNotification(
              `Post saved as published successfully`,
              "success",
            );
          }
          if (status === PostStatus.Deleted) {
            this.showPopupNotification(
              `Post saved as deleted successfully`,
              "success",
            );
          }

          if (this.mode === "edit") {
            if (status === PostStatus.Preview) {
              this.preview();
            }
          } else {
            this.$router.push(`/cn-showcase/posts/${data.Id}`);
            this.mode = "edit";
          }
          this.isSaved = true;
          this.categories_result = {
            skills: [],
            countries: [],
            cities: [],
            customs: [],
          };
          this.setPost(data);
        })
        .catch((e: any) => {
          this.showPopupNotification(
            `Can't create slug by title - ${this.form.Title}. ${e.response.message}`,
            "error",
          );
        });
    },
    validateForm() {
      let error = false;
      if (this.form.Title === null || this.form.Title === "") {
        this.errors.Title = {
          msg: "Title is required",
        };
        error = true;
      } else {
        this.errors.Title = null;
      }
      if (this.form.Talent.Id === null) {
        this.errors.Talent = {
          msg: "Talent is required",
        };
        error = true;
      } else {
        this.errors.Talent = null;
      }
      if (this.form.ShortText === null || this.form.ShortText === "") {
        this.errors.ShortText = {
          msg: "Short text is required",
        };
        error = true;
      } else {
        this.errors.ShortText = null;
      }
      if (this.form.Text && this.form.Text.length > 4000) {
        this.errors.Text = {
          msg: "Text length shouldn't be more than 4000 characters",
        };
        error = true;
      } else {
        this.errors.Text = null;
      }
      return error;
    },
    dragover(event: any) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (
        !event.currentTarget.classList.contains(
          "upload-wrapper_label__dragover",
        )
      ) {
        event.currentTarget.classList.add("upload-wrapper_label__dragover");
      }
    },
    dragleave(event: any) {
      // Clean up
      event.currentTarget.classList.remove("upload-wrapper_label__dragover");
    },
    drop(event: any) {
      event.preventDefault();
      this.addImage(event.dataTransfer.files);
      // Clean up
      event.currentTarget.classList.remove("upload-wrapper_label__dragover");
    },
    addImage(images: any) {
      if (images.length > 6) {
        alert("You can upload only 6 files, now you try " + images.length);
        return false;
      }
      this.imageProcessing = true;
      this.$fileProcessor.addFiles(
        Object.values(images),
        undefined,
        undefined,
        "showcase",
      );
    },
    onFileCanceled() {
      const input = this.$refs.inputUpload as HTMLInputElement;
      if (input) input.value = "";
      this.uploadProgress = 0;
      this.imageUploading = false;
      this.imageProcessing = false;
    },
    onFileUploading() {
      this.imageUploading = true;
      const files = this.$state.fileUpload.files;
      this.uploadProgress =
        files.length == 0
          ? 0
          : Math.round(
              files.reduce((a, o) => a + o.uploadProgress, 0) / files.length,
            );
    },
    onFilesUploaded() {
      if (this.imageUploading) {
        this.imageUploading = true;
        this.uploadProgress = 100;
        this.imageProcessing = true;
      }
    },
    async onProcessedImage(event: FILE_UPLOAD_EVENTS, fileModel: FileModel) {
      console.log("CnShowcasePost.onImageProcessed", fileModel);
      await this.includeImageInPost(fileModel.file.name, fileModel.fileid);
      if (!this.imageProcessing) return;
      this.onFileCanceled();
    },
    async includeImageInPost(fileName: string, fileId: number | null) {
      if (fileId) {
        await this.getImagesByID(fileId).then(res => {
          this.form.Images.push(res.data);
        });
      }
    },
    async getImagesByID(imageID: number) {
      return await axios.get(`/form/showcase/images/${imageID}`);
    },
    copyUrlToClipboard(url: string): void {
      navigator.clipboard.writeText(window.location.origin + url).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        },
      );
    },
  },
});
