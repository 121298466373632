import axios from "axios";
import moment from "moment";

import { ProductionTeamAttendance } from "../types/productionteams/productionTeamAttendance";

const url = (path: string) => `form/grid${path}`;

export default class ProductionTeamService {

  async UpsertAttendance(data: any): Promise<number> {
    const response = await axios.put(url("/UpsertProductionTeamAttendance"), data);

    return response.data;
  }

  async ResetAttendance(id: number): Promise<number> {
    const response = await axios.delete(url(`/ResetTeamAttendance/${id}`));

    return response.data;
  }

  async GetEmployeeAttendanceByDate(date: Date, id: number): Promise<ProductionTeamAttendance | undefined> {
    try {
      const response = await axios.get(url(`/GetEmployeeAttendance`), {
        params: {
          id: id,
          date: date
        }
      });

      if (response.data) {
        const employee = {
          Id: response.data.Id,
          ProductionTeamEmployeeId: response.data.ProductionTeamEmployeeId,
          FullName: response.data.ProductionTeamEmployee.User.FullName,
          Date: moment(response.data.Date).toDate(),
          StartTimeBeforeLunch: moment(response.data.StartTimeBeforeLunch).toDate(),
          EndTimeBeforeLunch: moment(response.data.EndTimeBeforeLunch).toDate(),
          StartTimeAfterLunch: moment(response.data.StartTimeAfterLunch).toDate(),
          EndTimeAfterLunch: moment(response.data.EndTimeAfterLunch).toDate(),
          TotalMinutes: response.data.TotalMinutes,
          TotalMinutesSpentOnDispatch: response.data.TotalMinutesSpentOnDispatch,
          TotalMinutesSpentOnPrinting: response.data.TotalMinutesSpentOnPrinting,
          TotalMinutesSpentOnPrintingPercentage: response.data.TotalMinutesSpentOnPrintingPercentage,
          Breakdown: response.data.Breakdown,
        }

        return employee;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error("Error while fething employee attendance by date", error);
    }
  }
}
