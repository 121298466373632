import axios from "axios";
import { get, post } from "tps.shared.ui/core/requestHelpers";
import {
  BackingViewModel,
  DeliveryPricingViewModel,
  DeliveryTypeViewModel,
  DeliveryZoneViewModel,
  FixingViewModel,
  FrameViewModel,
  GlassViewModel,
  LaminationViewModel,
  MouldingViewModel,
  PrintMediumViewModel,
  RetouchingViewModel,
  SizeViewModel,
  SpecialOrderItemViewModel,
  SpecialOrderViewModel,
  StationeryViewModel,
  SubstrateViewModel,
  WindowMountViewModel,
  PaymentDataModel
} from "../types/specialOrderViewModelRequest";
import { doIntersect, getRedHotDeliveryPostcodePrefix } from "../utils";

export default class SpecialOrderService {
  deliveryBranches: { Id: number; Name: string }[] = [
    { Id: 1, Name: "UK" },
    { Id: 2, Name: "Germany" },
    { Id: 3, Name: "USA" },
  ];
  async getFrameTypes(): Promise<FrameViewModel[]> {
    const { data } = await get(axios, "/pms/Frames", {
      prefix: "form",
    });
    return data;
  }

  async getVatRates(): Promise<
    {
      BranchId: number;
      Id: number;
      Rate: number;
    }[]
  > {
    const { data } = await get(axios, "/pms/VATRates", {
      prefix: "form",
    });
    return data;
  }
  async getSpecialOrderTypes(): Promise<
    {
      name: string;
      value: string;
    }[]
  > {
    const { data } = await get(axios, "/pms/SpecialOrderTypes", {
      prefix: "form",
    });
    return data;
  }

  async getSpeciaOrderFreeTextTypes(): Promise<
    {
      name: string;
      value: string;
    }[]
  > {
    const { data } = await get(axios, "/pms/SpeciaOrderFreeTextTypes", {
      prefix: "form",
    });
    return data;
  }

  async getPrintMedias(): Promise<PrintMediumViewModel[]> {
    const { data } = await get(axios, "/pms/PrintMedia", {
      prefix: "form",
    });
    return data;
  }
  async getSizes(
    entityId: number,
    applySpecialOrderMarkDown: boolean,
    deliveryBranchId: number,
  ): Promise<any> {
    const url = applySpecialOrderMarkDown
      ? `/pms/Sizes?entityId=${entityId}&branchId=${deliveryBranchId}&applySpecialOrderMarkDown=${applySpecialOrderMarkDown}`
      : `/pms/Sizes?entityId=${entityId}&branchId=${deliveryBranchId}`;
    const { data } = await get(axios, url, {
      prefix: "form",
    });
    return data;
  }
  async getSubstrates(): Promise<SubstrateViewModel[]> {
    const { data } = await get(axios, "/pms/Substrates", {
      prefix: "form",
    });
    return data;
  }
  async getBackings(): Promise<BackingViewModel[]> {
    const { data } = await get(axios, "/pms/Backings", {
      prefix: "form",
    });
    return data;
  }
  async getLaminations(): Promise<LaminationViewModel[]> {
    const { data } = await get(axios, "/pms/Laminations", {
      prefix: "form",
    });
    return data;
  }
  async getStationeries(): Promise<StationeryViewModel[]> {
    const { data } = await get(axios, "/pms/Stationeries", {
      prefix: "form",
    });
    return data;
  }
  async getRetouchings(): Promise<RetouchingViewModel[]> {
    const { data } = await get(axios, "/pms/Retouchings", {
      prefix: "form",
    });
    return data;
  }
  async getDeliveryOptions(): Promise<DeliveryTypeViewModel[]> {
    const { data } = await get(axios, "/pms/DeliveryOptions", {
      prefix: "form",
    });
    return data;
  }
  async getDeliveryZones(): Promise<DeliveryZoneViewModel[]> {
    const { data } = await get(axios, "/pms/DeliveryZones", {
      prefix: "form",
    });
    return data;
  }
  async getCountries(): Promise<
    {
      value: number;
      name: string;
      IsUKForVAT: boolean;
      IsEU: boolean;
      PostageDeliveryBranchId: number;
      CourierDeliveryBranchId: number;
    }[]
  > {
    const { data } = await get(axios, "/pms/Countries", {
      prefix: "form",
    });
    return data;
  }
  async getOrderStati(): Promise<
    {
      name: string;
      value: number;
    }[]
  > {
    const { data } = await get(axios, "/pms/OrderStatiPms", {
      prefix: "form",
    });
    return data;
  }
  async getFixings(
    substrateId: number,
    widthInches: number,
    heightInches: number,
  ): Promise<FixingViewModel[]> {
    const { data } = await post(
      axios,
      "/pms/Fixings",
      {
        substrateId,
        widthInches,
        heightInches,
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async getSubstrateColours(
    substrateId: number,
  ): Promise<SubstrateViewModel[]> {
    const { data } = await post(
      axios,
      "/pms/SubstrateColours",
      {
        substrateId,
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async setEmailInvoice(orderId: number, email: string): Promise<boolean> {
    const { data } = await post(
      axios,
      "/pms/CreateOrderInvoiceFromOrderId",
      {
        orderId,
        email,
        emailUser: true,
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async addSpecialOrder(
    specialOrder: SpecialOrderViewModel,
    specialOrderItems: SpecialOrderItemViewModel[],
    customerReference: string,
    specialOrderInEdit: number,
    paymentData?: PaymentDataModel
  ): Promise<number> {
    const { data } = await post(
      axios,
      "/pms/AddSpecialOrder",
      {
        orderVM: {
          Id: specialOrderInEdit,
          DeliveryType: specialOrder.DeliveryType,
          VATRateId: specialOrder.VatRateId,
          UserId: specialOrder.UserId,
          SelectedShippingAddress: specialOrder.SelectedShippingAddress,
          CustomerReference: customerReference,
          NewStatus: paymentData ? specialOrder.NewStatus : 0,
          MessageToLab: paymentData ? specialOrder.MessageToLab : "",
          UserBranchId: specialOrder.UserBranchId,
          DeliveryBranchId: specialOrder.DeliveryBranchId,
          OrderItems: specialOrderItems,
          DueDate: specialOrder.DueDate
        },
        paymentData
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async getEntityPrice(
    entityId: number,
    branchId: number,
    widthInches: number,
    heightInches: number,
  ): Promise<number> {
    const { data } = await post(
      axios,
      "/pms/EntityPrice",
      {
        entityId,
        branchId,
        widthInches,
        heightInches,
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async getMouldings(
    currentSpecialOrderItem: SpecialOrderItemViewModel,
  ): Promise<MouldingViewModel[]> {
    if (currentSpecialOrderItem.Frame) {
      const { data } = await get(
        axios,
        `/pms/Mouldings?frameId=${currentSpecialOrderItem.Frame.Id}`,
        {
          prefix: "form",
        },
      );
      return data;
    }
    return [];
  }

  async getGlasses(): Promise<GlassViewModel[]> {
    const { data } = await get(axios, "/pms/Glasses", {
      prefix: "form",
    });
    return data;
  }

  async getWindowMounts(): Promise<WindowMountViewModel[]> {
    const { data } = await get(axios, "/pms/WindowMounts", {
      prefix: "form",
    });
    return data;
  }

  initSpecialOrderItem(): SpecialOrderItemViewModel {
    return {
      Id: 0,
      OrderTypeId: 0,
      PrintMedia: null,
      OrderFreeTextTypeId: null,
      BorderTopMm: 0,
      BorderRightMm: 0,
      BorderBottomMm: 0,
      BorderLeftMm: 0,
      Substrate: null,
      Frame: null,
      Lamination: null,
      Colour: null,
      SubstrateColour: null,
      Backing: null,
      Size: null,
      Fixing: null,
      Moulding: null,
      Stationery: null,
      Retouching: null,
      Glass: null,
      HangingOption: null,
      WindowMount: null,
      WmBorderTopMm: 0,
      WmBorderRightMm: 0,
      WmBorderBottomMm: 0,
      WmBorderLeftMm: 0,
      OrderItemId: 0,
      OrderTypeName: "",
      Description: "",
      PriceExcludingVat: "0.00",
      DiscountPercentage: "0",
      Vat: 0,
      Quantity: 1,
    };
  }

  calculateSpecialOrderPrice(
    currentSpecialOrderItem: SpecialOrderItemViewModel,
  ): string {
    let unitPrice = 0;
    switch (currentSpecialOrderItem.OrderTypeId) {
      case 1:
        unitPrice += currentSpecialOrderItem.Size
          ? currentSpecialOrderItem.Size.Price
          : 0;
        break;
      case 2:
        unitPrice += currentSpecialOrderItem.Moulding
          ? currentSpecialOrderItem.Moulding.Price
          : 0;
        unitPrice += currentSpecialOrderItem.Glass
          ? currentSpecialOrderItem.Glass.Price
          : 0;
        unitPrice += currentSpecialOrderItem.WindowMount
          ? currentSpecialOrderItem.WindowMount.Price
          : 0;
        unitPrice += currentSpecialOrderItem.Size
          ? currentSpecialOrderItem.Size.Price
          : 0;
        break;
      case 3:
        unitPrice += currentSpecialOrderItem.Fixing
          ? currentSpecialOrderItem.Fixing.Price
          : 0;
        unitPrice += currentSpecialOrderItem.Lamination
          ? currentSpecialOrderItem.Lamination.Price
          : 0;
        unitPrice += currentSpecialOrderItem.Size
          ? currentSpecialOrderItem.Size.Price
          : 0;
        break;
      case 4:
        unitPrice += currentSpecialOrderItem.Stationery
          ? currentSpecialOrderItem.Stationery.Price
          : 0;
        break;
      case 5:
        unitPrice += currentSpecialOrderItem.Retouching
          ? currentSpecialOrderItem.Retouching.Price
          : 0;
        break;
      case 6:
        break;
      case 7:
        unitPrice = parseFloat(currentSpecialOrderItem.PriceExcludingVat);
        break;
      default:
        break;
    }

    return (+unitPrice).toFixed(2);
  }

  async removeAllOrderItems(
    orderId: number,
    removeOrder: boolean,
  ): Promise<boolean> {
    const { data } = await post(
      axios,
      "/pms/RemoveAllOrderItems",
      {
        orderId,
        removeOrder,
      },
      {
        prefix: "form",
      },
    );
    return data;
  }

  async getOrderQuoteStatus(id: number): Promise<{ IsFinalised: boolean }> {
    const { data } = await get(
      axios,
      `/pms/GetOrderQuoteStatus?orderId=${id}`,
      {
        prefix: "form",
      },
    );
    return data;
  }

  canAddSpecialOrderItem(
    currentSpecialOrderItem: SpecialOrderItemViewModel,
    specialOrder: SpecialOrderViewModel,
  ): boolean | SizeViewModel | StationeryViewModel | DeliveryTypeViewModel {
    return (
      currentSpecialOrderItem &&
      !isNaN(parseFloat(currentSpecialOrderItem.DiscountPercentage)) &&
      isFinite(parseFloat(currentSpecialOrderItem?.DiscountPercentage)) &&
      ((currentSpecialOrderItem?.OrderTypeId == 1 &&
        currentSpecialOrderItem?.PrintMedia &&
        currentSpecialOrderItem?.Size) ||
        (currentSpecialOrderItem?.OrderTypeId == 2 &&
          currentSpecialOrderItem?.Frame &&
          currentSpecialOrderItem?.Moulding &&
          currentSpecialOrderItem?.Size) ||
        (currentSpecialOrderItem.OrderTypeId == 3 &&
          currentSpecialOrderItem.Substrate &&
          currentSpecialOrderItem.Size) ||
        (currentSpecialOrderItem.OrderTypeId == 4 &&
          currentSpecialOrderItem.Stationery) ||
        (currentSpecialOrderItem.OrderTypeId == 5 &&
          currentSpecialOrderItem.Retouching) ||
        (currentSpecialOrderItem.OrderTypeId == 6 &&
          currentSpecialOrderItem.OrderTypeId &&
          ((specialOrder.SelectedShippingAddress &&
            specialOrder.DeliveryTypeTentative) ||
            (!specialOrder.SelectedShippingAddress &&
              !specialOrder.DeliveryTypeTentative))) ||
        (currentSpecialOrderItem.OrderTypeId == 7 &&
          currentSpecialOrderItem.OrderFreeTextTypeId !== null &&
          currentSpecialOrderItem.Description.length > 0))
    );
  }

  availableDeliveryMethodsForAddress(
    deliveryAddress: any,
    deliveryBranchIdValue: number,
    deliveryZones: DeliveryZoneViewModel[],
    deliveryOptions: DeliveryTypeViewModel[],
  ): any {
    if (!deliveryAddress) {
      return [];
    }

    const countryId = deliveryAddress.CountryId;
    const postcode = deliveryAddress.PostCode;
    const deliveryBranchId = deliveryBranchIdValue ?? 1;

    const deliveryZonesFiltered = deliveryZones
      .filter(function (deliveryZone) {
        if (deliveryZone.Name === "Anywhere") {
          return true;
        } else if (deliveryZone.CountryIds) {
          return deliveryZone.CountryIds.indexOf(countryId) >= 0;
        } else if (deliveryZone.Code) {
          return (
            getRedHotDeliveryPostcodePrefix(postcode) === deliveryZone.Code &&
            countryId === deliveryZone.CountryId
          );
        }

        return false;
      })
      .map(function (deliveryZone) {
        return deliveryZone.Id;
      });

    const deliveryMethodsFiltered = deliveryOptions
      .filter(
        (type: DeliveryTypeViewModel) => type.BranchId === deliveryBranchId,
      )
      .map((type: DeliveryTypeViewModel) => {
        return {
          Id: type.Id,
          Name: type.Name,
          Abbreviation: type.Abbreviation,
          InHouseOnly: type.InHouseOnly,
          DeliveryTime: type.DeliveryTime,
          Pricings: type.Pricings.filter(function (pricing) {
            return doIntersect(deliveryZonesFiltered, pricing.DeliveryZoneIds);
          }).map((price: DeliveryPricingViewModel) => {
            return {
              Id: price.Id,
              IsPrintOnly: price.IsPrintOnly,
              WidthInches: price.WidthInches,
              HeightInches: price.HeightInches,
              Price: price.Price,
            };
          }),
        };
      })
      .filter(function (deliveryMethod) {
        return deliveryMethod.Pricings.length > 0;
      });

    return deliveryMethodsFiltered;
  }
}
