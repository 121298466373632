
import { defineComponent, Ref, ref } from "vue";

declare var $: any;

export default defineComponent({
  setup() {
    const grid: Ref<any> = ref(null);
    return { grid };
  },
  mounted() {
    const grid = this.grid;
    $.get("/form/grid/ChAdminManagementPartial", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
});
