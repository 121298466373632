import DataViewsService from "@/core/services/dataViewsService";
import * as fd from "@/core/fileDownloader";
import { formatDate } from "@telerik/kendo-intl";
import {
  CompositeFilterDescriptor,
  DataSourceRequestState,
  toDataSourceRequest,
} from "@progress/kendo-data-query";

import {
  FormDataView,
  FormDataViewGroup,
  FormDataViewParam,
  FormDataViewParamType,
  FormDataViewGridFilter,
  FormDataViewGridFilterKind,
  FormDataViewGridFilterParamValue,
  FormDataViewFilterOperator,
  ParamValueType,
  emptyValueOps,
  singleValueOps,
  rangeValueOps,
  multipleValueOps,
  textFilterOptions,
  numberFilterOptions,
  dateTimeFilterOptions,
  listFilterOptions,
  FormDataViewGridFilterColor,
  indexFilterOptions,
  booleanFilterOptions,
} from "@/core/types/formDataViewsModel";
import { postJson } from "tps.shared.ui/core/requestHelpers";
import { GridFilterType } from "@/core/enums/grid-filter.type";
import axios from "axios";


const Titles :  {[key: string]: string} = {
  OrderList: "Orders",
  CustomerList: "Customers",
  OrderItemList: "Order Items"
};

const Type :  {[key: string]: GridFilterType} = {
  OrderList: GridFilterType.Orders,
  CustomerList: GridFilterType.Customers,
  OrderItemList: GridFilterType.OrderItems
};

export const getListTitle = (name: string | null | undefined) => name ? Titles[name] ?? "Unkown" : "Unkown";
export const getGridFilterType = (name: string | null | undefined) => name ? Type[name] ?? GridFilterType.None : GridFilterType.None;

export const paramIndexCompareValues = [
  "RefundRedoReason",
  "RefundRedoReasonType",

];
export function makeFormDataViewGridFilter(
  dataView: FormDataView | null = null,
): FormDataViewGridFilter {
  const gridFilter = {
    Id: 0,
    DataViewId: dataView?.Id ?? 0,
    DataView: dataView,
    Kind: FormDataViewGridFilterKind.Private,
    Color: FormDataViewGridFilterColor.Green,
    Sorts: [],
    ParamValues: [],
    IncludedColumns: dataView?.IncludedColumns ?? [],
  };

  if (dataView && dataView.Parameters)
    initFormDataViewGridFilterParamValue(gridFilter, dataView.Parameters);

  return gridFilter;
}

function makeFormDataViewGridFilterParamValue(
  gridFilter: FormDataViewGridFilter,
  param: FormDataViewParam,
  value: string,
  valueObj: any,
): FormDataViewGridFilterParamValue {
  return {
    Id: 0,
    GridFilterId: gridFilter?.Id ?? 0,
    ParamId: param.Id,
    Value: value,
    ValueObj: [valueObj],
    ValueInt: parseInt(valueObj),
    ValueBoolean: valueObj === null,
    Operator: FormDataViewFilterOperator.NoFilter,
    OperatorSelected: getParamDataItems(param)[0],
    ValueType: ParamValueType.Empty,
  };
}

export function getDefaultValue(param: FormDataViewParam): [string, any] {
  const defaultValue: any = param.DefaultValue;
  let defaultValueObj: any = defaultValue;
  if (
    param.DefaultValue &&
    param.ParamType == FormDataViewParamType.KeyValueObject
  ) {
    const dv = param.List.find(o => o.Key == defaultValue);
    if (dv) {
      defaultValueObj = dv;
      param.DefaultValue = defaultValue;
    }
  }

  return [defaultValue, defaultValueObj];
}

export function initFormDataViewGridFilterParamValue(
  gridFilter: FormDataViewGridFilter,
  params: FormDataViewParam[],
) {
  for (const p of params) {
    const [defaultValue, defaultValueObj] = getDefaultValue(p);
    let pv = gridFilter.ParamValues.find(o => o.ParamId == p.Id);
    if (!pv) {
      pv = makeFormDataViewGridFilterParamValue(
        gridFilter,
        p,
        defaultValue,
        defaultValueObj,
      );
      gridFilter.ParamValues.push(pv);
    } else {
      let value: any = pv?.Value;
      if (p.ParamType == FormDataViewParamType.KeyValueObject) {
        value = p.List.find(o => o.Key == pv?.Value);
      }
      pv.ValueObj = value;
    }
    p.ParamValues.push(pv);
  }
}

function dataViewParamsToDataSourceRequest(
  params: FormDataViewParam[],
  gridFilterId: number,
): DataSourceRequestState {
  const filters = params.map((p: FormDataViewParam) => {
    const paramValue =
      p.ParamValues.find(param => param.GridFilterId === gridFilterId) ??
      p.ParamValues[0];
    const value: any =
      p.ParamType === FormDataViewParamType.Email
        ? paramValue.Value
        : paramValue.ValueObj;
    return {
      field: p.FieldPath,
      ignoreCase: true,
      operator: "eq",
      value:
        p.ParamType == FormDataViewParamType.KeyValueObject ? value.Key : value,
    };
  });
  const filter: CompositeFilterDescriptor = {
    filters: filters,
    logic: "and",
  };

  const result = {
    filter,
  };

  return toDataSourceRequest(result);
}

export function initDataViewGroups(
  groups: FormDataViewGroup[],
): FormDataViewGroup[] {
  for (const group of groups) {
    initDataViewGroup(group);
  }

  return groups;
}

export function initDataViewGroup(group: FormDataViewGroup): FormDataViewGroup {
  const dataViewsWithParams = group.Views.filter(r => r.Parameters.length > 0);
  for (const dataView of dataViewsWithParams) {
    // Add a grid filter if not present
    dataView.Parameters.sort(
      (p1: FormDataViewParam, p2: FormDataViewParam) =>
        p1.Position - p2.Position,
    );
    if ((dataView.GridFilters?.length ?? 0) == 0) {
      dataView.GridFilters.push(makeFormDataViewGridFilter(dataView));
    } else {
      for (const gridFilter of dataView.GridFilters) {
        initFormDataViewGridFilterParamValue(gridFilter, dataView.Parameters);
      }
    }
  }
  return group;
}

export function validateDataView(
  dataView: FormDataView | undefined | null,
): boolean {
  if (!dataView) return false;

  return dataView.Parameters.every(
    o => o.Required && o.ParamValues.find(x => x.ValueObj),
  );
}

export function validateGridFilter(
  gridFilter: FormDataViewGridFilter | undefined | null,
): boolean {
  if (!gridFilter || !gridFilter.DataView) return false;
  if (!gridFilter.Name || gridFilter?.Name.length == 0) return false;

  for (const p of gridFilter.DataView.Parameters) {
    for (const pv of p.ParamValues) {
      if (pv.ValueType != ParamValueType.Empty) {
        switch (p.ParamType) {
          case FormDataViewParamType.DateTime:
            if (
              !pv.DateTimeSingleOptionSelected &&
              !pv.DateTimeRangeOptionSelected
            )
              return false;
            break;
          case FormDataViewParamType.TimeInterval:
            break;
          case FormDataViewParamType.Boolean:
            break;
          default:
            if (!(pv.ValueObj && pv.ValueObj.length > 0 && pv.ValueObj[0])) {
              return false;
            }
        }
      }
    }
  }

  return true;
}

export function noFilterSpecified(
  dataView: FormDataView | undefined | null,
): boolean {
  if (!dataView) return false;

  return dataView.Parameters.map(o => o.ParamValues).every(
    o => !o || o.length == 0 || !o[0].ValueObj || !o[0].ValueObj[0],
  );
}

export async function downloadDataView(
  jqXHR: any,
  dataViewSvc: DataViewsService,
  dataView: FormDataView,
  isBase64Encoded: boolean,
  shouldSetDefaultValueTypeAndParams: boolean,
): Promise<[boolean, boolean, string | null]> {
  let result: [boolean, boolean, string | null] = [false, true, null];
  try {
    const { data } = await postJson(
      axios,
      "/user/UserIsInRole",
      {
        role: "superAdmin",
      },
      {
        prefix: "form",
      },
    );
    const userGridFilterIndex: number = dataView?.GridFilters.findIndex(
      filter => filter.UserId == data.userId,
    );
    //Save the gridFilters
    const userGridFilter =
      userGridFilterIndex !== -1
        ? dataView?.GridFilters[userGridFilterIndex]
        : dataView?.GridFilters[0];
    if (userGridFilter) {
      userGridFilter.DataView = undefined;
      userGridFilter.ParamValues = userGridFilter.ParamValues.map(p => {
        const value: any = p.ValueObj;
        p.Param = undefined;

        if (value && typeof value == "object" && "Key" in value) {
          p.Value = value.Key;
        }

        if (value && typeof value == "object" && "start" in value) {
          p.Value = JSON.stringify(value);
        }
        if (shouldSetDefaultValueTypeAndParams) {
          p.Operator =
            p.Operator == FormDataViewFilterOperator.NoFilter
              ? FormDataViewFilterOperator.IsEqualTo
              : p.Operator;
          p.ValueType =
            p.ValueType == ParamValueType.Empty
              ? ParamValueType.Single
              : p.ValueType;
        }

        return p;
      });

      if (userGridFilterIndex === -1) {
        userGridFilter.Id = 0;
        userGridFilter.UserId = data.userId;
      }
      const gf = await dataViewSvc.UpsertGridFilters(userGridFilter);
      userGridFilter.Id = gf.Id;
      if (userGridFilterIndex === -1) {
        dataView?.GridFilters.push(gf);
      }
    }
    const gidFilterIndex: number = dataView?.GridFilters.findIndex(
      filter => filter.UserId == data.userId,
    );
    const gridFilter =
      gidFilterIndex !== -1
        ? dataView?.GridFilters[gidFilterIndex]
        : dataView?.GridFilters[0];

    const gridFilterId: number = gridFilter?.Id ?? 0;
    const dt =
      dataView.DataSource.Method == "POST"
        ? {
          request: dataViewParamsToDataSourceRequest(
            dataView.Parameters,
            gridFilterId,
          ),
          gridFilterId: gridFilterId,
        }
        : null;
    console.log('downloadDataView :: dataView.DataSource.Method', dataView.DataSource.Method);
    console.log('downloadDataView :: dataView.Parameters', dataView.Parameters);
    console.log('downloadDataView :: gridFilterId', gridFilterId);
    console.log('downloadDataView :: dt', dt);
    let url = dataView.DataSource.Url;
    if (dataView.Parameters.some(param => param.ParamType == FormDataViewParamType.DateTimeRange)) {
      dataView.Parameters.forEach(param => {
        const paramValue = gridFilter.ParamValues.find(
          value => value.ParamId === param.Id,
        );
        if (!paramValue) {
          return;
        }
        const seperateSymbol = url === dataView.DataSource.Url ? "?" : "&";
        if (param.ParamType == FormDataViewParamType.DateTimeRange) {
          url += `${seperateSymbol}DateFrom=${formatDate(
            paramValue.ValueObj.start,
            "MM-dd-yyyy",
          )}&DateTo=${formatDate(paramValue.ValueObj.end, "MM-dd-yyyy")}`;
          return;
        }
        url += `${seperateSymbol}${param.Name}=${paramValue.Value}`;
      });
    }

    await jqXHR({
      url: url,
      type: dataView.DataSource.Method,
      data: dt,
    })
      .done((data: any, _: string, jqXHR: any) => {
        if (data?.isError) {
          result = [false, false, data?.responseText];
          return;
        }
        fd.downloadFile(jqXHR, data, isBase64Encoded);
        result = [true, false, data?.responseText];
      })
      .fail((jqXHR: any, statusText: string, _: any) => {
        result = [false, false, "Error"];
      });
  } catch (e: any) {
    console.log(e);
    let msg = e?.statusText ?? "Error";
    if (e?.response?.status) {
      if (e.response.status == 404) msg = "Filters not found";
      else if (e.response.status == 403) msg = "Permission denied";
    }
    result = [false, false, msg];
  }
  
  return result;
}

export function getParamValueType(
  paramValue: FormDataViewGridFilterParamValue,
  param: FormDataViewParam,
): ParamValueType {
  if (emptyValueOps.includes(paramValue.Operator)) return ParamValueType.Empty;
  if (singleValueOps.includes(paramValue.Operator))
    return ParamValueType.Single;
  if (rangeValueOps.includes(paramValue.Operator)) return ParamValueType.Range;
  if (multipleValueOps.includes(paramValue.Operator))
    return param.ParamType == FormDataViewParamType.Text ||
      param.ParamType == FormDataViewParamType.Email
      ? ParamValueType.Single
      : ParamValueType.Multiple;

  return ParamValueType.Empty;
}

export function canUseOffset(param: FormDataViewParam) {
  return param.ParamType == FormDataViewParamType.DateTime;
}

export function getParamDataItems(param: FormDataViewParam) {
  if (
    param.ParamType == FormDataViewParamType.Text ||
    param.ParamType == FormDataViewParamType.Email
  )
    return textFilterOptions;
  if (paramIndexCompareValues.some(paramName => paramName === param.Name)) {
    return indexFilterOptions;
  }
  if (
    param.ParamType == FormDataViewParamType.Integer ||
    param.ParamType == FormDataViewParamType.Real
  )
    return numberFilterOptions;

  if (param.ParamType == FormDataViewParamType.DateTime)
    return dateTimeFilterOptions;

  if (param.ParamType == FormDataViewParamType.KeyValueObject)
    return listFilterOptions;

  if (param.ParamType == FormDataViewParamType.Boolean)
    return booleanFilterOptions;
  return [];
}
