
import { defineComponent, ref, Ref } from "vue";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import {
  Button as KButton,
  SplitButton as KSplitButton,
} from "@progress/kendo-vue-buttons";
import {
  ClientManager,
  ClientManagerItem,
  ClientManagerType,
  ActionCode,
} from "../typesAndConstants";

import AddNewClientModal from "../ActionModals/AddNewClientModal.vue";
import ChangeTransferredStatusModal from "../ActionModals/ChangeTransferredStatusModal.vue";
import RecalculateCommissionsModal from "../ActionModals/RecalculateCommissionsModal.vue";
import ChangeClientTypeModal from "../ActionModals/ChangeClientTypeModal.vue";
import ChangeClientManagerModal from "../ActionModals/ChangeClientManagerModal.vue";
import ViewAuditModal from "../ActionModals/ViewAuditModal.vue";

import * as helpers from "../helpers";
import * as api from "../api";


export default defineComponent({
  name: "ClientManagerClientsTab",
  components: {
    KDropDownList,
    KButton,
    KSplitButton,
    AddNewClientModal,
    ChangeTransferredStatusModal,
    RecalculateCommissionsModal,
    ChangeClientTypeModal,
    ChangeClientManagerModal,
    ViewAuditModal,
  },
  props: {
    exportOptions: { type: Array, required: true },
    selectedExportOption: { type: Object, required: true },
    clientManagers: { type: Array, required: true },
    selectedClientManager: { type: Object, required: true },
    clientManagerType: { type: Number, required: true },
    dateRangeFrom: { type: Object, required: true },
    dateRangeTo: { type: Object, required: true },
  },
  emits: ["sales-rep-clients-sales-rep-changed"],
  setup(props: any) {
    const isAccountHandler = props.clientManagerType == ClientManagerType.AccountHandler;
    const changeClientManagerLabel = isAccountHandler
      ? "Account Handler"
      : "Sales Rep";
    const clientActionsByManagerType = [
      { label: "Change Client Type", code: "changeClientType" },
      {
        label: `Change ${changeClientManagerLabel}`,
        code: "changeClientManager",
      },
      { label: "Change Transferred Status", code: "changeTransferredStatus" },
      { label: "Recalculate Commissions", code: "recalculateCommissions" },
      { label: "View Audit", code: "viewAudit" },
    ];
    if (isAccountHandler) {
      clientActionsByManagerType.splice(0, 1);
      clientActionsByManagerType.splice(1, 1);
    }

    const data: Ref<ClientManagerItem[]> = ref([] as ClientManagerItem[]);
    const userRoles: Ref<any> = ref({});
    const clientActions = ref(clientActionsByManagerType);
    const showActionModal: Ref<ActionCode> = ref("none");
    const actionModel: Ref<ClientManagerItem | null> = ref(null);
    return {
      data,
      userRoles,
      clientActions,
      showActionModal,
      actionModel,
    };
  },
  computed: {
    clientManagerList(): ClientManager[] {
      return this.clientManagers as ClientManager[];
    },
    currentClientManager(): ClientManager {
      return this.selectedClientManager as ClientManager;
    },
    inAdminRole(): boolean {
      return (
        this.userRoles &&
        this.userRoles?.roles?.some((role: string) =>
          this.$account.adminRoles.includes(role),
        )
      );
    },
  },
  async mounted() {
    this.data = await this.load();
    this.userRoles = await this.$account.getUserInRoles(
      this.$account.rolesForCommissions,
    );
  },
  methods: {
    async load(): Promise<ClientManagerItem[]> {
      return await api.getClientManagerClients(
        this.selectedClientManager?.Id ?? 0,
      );
    },
    // #region Event handlers
    async onSalesRepChanged(event: any): Promise<void> {
      this.$emit("sales-rep-clients-sales-rep-changed", {
        value: event.value,
      });
      this.data = await this.load();
    },
    async onExportClick(): Promise<void> {
      const salesRepUserId = this.selectedClientManager?.Id ?? 0;
      const { fileName, mimeType, content } = helpers.getCsvInfo(
        await api.getClientManagerClientsCsv(salesRepUserId),
      );

      helpers.saveAsFile(content, mimeType, fileName);
    },
    onActionClicked(event: any, client: ClientManagerItem): void {
      this.actionModel = client;
      this.showActionModal = event.item.code;
    },
    onAddNewClient(): void {
      this.showActionModal = "addNewClient";
    },
    onCloseModal(): void {
      this.showActionModal = "none";
    },
    async onCommandExecuted(): Promise<void> {
      this.data = await this.load();
    },
    // #endregion
  },
});
