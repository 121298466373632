import {
  NGetter,
  NMutation,
  NCommit,
  NAction,
  NDispatch,
  makeGetters,
  makeCommits,
  makeDispatches,
  RootState,
} from "@/plugins/nvuex";
import perf from "tps.shared.ui/core/store/dev.performance";
import { Store } from "vuex";
const MODULE = "global";
declare const $: any;
//-------------------------------------------------------------------------------------------------
// state

const _state = () => ({
  branchDetails: new Array<any>(),
  papers: new Array<any>()
});
export type State = ReturnType<typeof _state>;
export const state = _state as () => State;

//-------------------------------------------------------------------------------------------------
// getters

type StateGetterTree<O> = {
  [K in keyof O]: O[K] extends NGetter<State, infer T>
    ? NGetter<State, T>
    : never;
};
function typeGetters<O extends StateGetterTree<O>>(o: O) {
  return o;
}
export const _getters = typeGetters({
  
});
type Getters = typeof _getters;
type StateGetTree<O extends StateGetterTree<O>> = {
  [K in keyof O]: O[K] extends NGetter<State, infer T> ? T : never;
};
export function fnMakeGetters(store: Store<RootState>): StateGetTree<Getters> {
  return makeGetters(store, MODULE, _getters);
}
export type Gets = StateGetTree<Getters>;

//-------------------------------------------------------------------------------------------------
// mutations

type StateMutationTree<O> = {
  [K in keyof O]: O[K] extends NMutation<State, infer T>
    ? NMutation<State, T>
    : never;
};
function typeMutations<O extends StateMutationTree<O>>(o: O) {
  return o;
}
const _mutations = typeMutations({
  setBranchDetails(s,data:Array<any>){
    s.branchDetails = data;
  },
  setPapers(s,data:Array<any>){
    s.papers = data;
  }
});
type Mutations = typeof _mutations;
type StateCommitTree<O extends StateMutationTree<O>> = {
  [K in keyof O]: O[K] extends NMutation<State, infer T> ? NCommit<T> : never;
};
export function fnMakeCommits(
  store: Store<RootState>,
): StateCommitTree<Mutations> {
  return makeCommits(store, MODULE, _mutations);
}
export type Commits = StateCommitTree<Mutations>;

//-------------------------------------------------------------------------------------------------
// actions


type StateActionTree<O> = {
  [K in keyof O]: O[K] extends NAction<State, infer P, infer T>
    ? NAction<State, P, T>
    : never;
};
function typeActions<O extends StateActionTree<O>>(o: O) {
  return o;
}
const _actions = typeActions({
  async loadBranchDetails({ commit }) {
    const response = (await $.get(`/form/grid/BranchDetails`)) as any[];
    commit("setBranchDetails", response);
  },
  async loadPapers({ commit }) {
    const response = (await $.get(`/form/grid/Papers`)) as any[];
    commit("setPapers", response);
  }
});
export type Actions = typeof _actions;
type StateDispatchTree<O extends StateActionTree<O>> = {
  [K in keyof O]: O[K] extends NAction<State, infer P, infer T>
    ? NDispatch<P, T>
    : never;
};
export function fnMakeDispatches(
  store: Store<RootState>,
): StateDispatchTree<Actions> {
  return makeDispatches(store, MODULE, _actions);
}
export type Dispatches = StateDispatchTree<Actions>;

//-------------------------------------------------------------------------------------------------
// exports

export const getters = perf.nWrapGetterTree(MODULE, _getters);
export const mutations = perf.nWrapMutationTree(MODULE, _mutations);
export const actions = perf.nWrapActionTree(MODULE, _actions);

//-------------------------------------------------------------------------------------------------
