import { DataFrame } from "./DataFrame";

export type DataTable = {
  colTitles: Array<string>;
  rows: Array<Array<string>>;
};

// ----------------------------------------------------------------------------
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
type Format = {
  locale: string;
  options: {
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  };
};

// ----------------------------------------------------------------------------

function parseFormat(fmt: string): Format {
  if (!fmt) {
    return {
      locale: "en-GB",
      options: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    };
  }

  const [locale, maxDigits] = fmt.split(",");

  return {
    locale: locale,
    options: {
      minimumFractionDigits: parseInt(maxDigits) || 2,
      maximumFractionDigits: parseInt(maxDigits) || 2,
    },
  };
}
// ----------------------------------------------------------------------------

export function dataTableOf(df: DataFrame): DataTable {
  const format = (fmt: string): Intl.NumberFormat => {
    const frmt = parseFormat(fmt);

    return new Intl.NumberFormat(frmt.locale, frmt.options);
  };

  const value = (val: number, fmt: string) => {
    if (val == 0) return "-";
    const intl = format(fmt);

    return intl.format(val);
  };

  const hasRowLabelCol = df.RowLabels.length > 0;
  const rowLabelCol = hasRowLabelCol ? 1 : 0;
  const numOfCols = df.Columns.length + rowLabelCol;
  const numOfRows = df.Columns[0] ? df.Columns[0].Values.length : 0;
  const rows: Array<Array<string>> = [];

  for (let ri = 0; ri < numOfRows; ri++) {
    const row = Array(numOfCols).fill("");
    if (hasRowLabelCol) {
      row[0] = df.RowLabels[ri];
    }

    for (let ci = 0; ci < numOfCols - rowLabelCol; ci++) {
      if (df.Columns[ci].Values) {
        row[ci + rowLabelCol] = value(
          df.Columns[ci].Values[ri],
          df.Columns[ci].Format,
        );
      }
    }
    rows.push(row);
  }
  const colTitles = hasRowLabelCol
    ? ["", ...df.Columns.map(c => c.Label)]
    : df.Columns.map(c => c.Label);

  return {
    colTitles,
    rows,
  };
}

// ----------------------------------------------------------------------------
