
import GenericModalResponse from "@/core/types/genericModalResponse";
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "GenericModalVue",
  props: {
    show: Boolean,
    bodyText: String,
    headerText: String,
    isQuestion: Boolean,
    isSuccess: Boolean,
    isError: Boolean,
    isWarning: Boolean,
    isInfo: Boolean,
    selectList: Array,
  },
  setup() {
    const selectValue: Ref<any> = ref("");
    return {
      selectValue,
    };
  },
  methods: {
    close(answer: boolean) {
      const response: GenericModalResponse = {
        isQuestionAnswer: answer,
        model: this.selectValue,
      };
      this.selectValue = null;
      this.$emit("close", response);
    },
    getTitleClass(): string {
      if (this.isSuccess) {
        return "success";
      }
      if (this.isError) {
        return "error";
      }
      if (this.isWarning) {
        return "warning";
      }

      return "info";
    },
    onYesButtonClick(): void {
      this.close(true);
    },
    onYesCancelClick(): void {
      this.close(false);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close(false);
    },
  },
});
