
import { defineComponent, ref, onMounted } from "vue";
import GoogleDoc from "@/services/GoogleDoc";
interface gTokens {
  googleAccessToken: string;
  googleRefreshToken: string;
}
export default defineComponent({
  name: "GoogleDoc",

  setup() {
    const newDocument: any = [
      {
        insertText: {
          text: "New test text inserted in the document",
          location: {
            index: 1,
          },
        },
      },
    ];
    const directDocURL: any = ref({ url: "" });

    const gClientID =
      "220534925367-ctraesev6til78tn08oolmiuhfg4u5uq.apps.googleusercontent.com";
    const gClientSecret = "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";
    const gAuthScope = "https://www.googleapis.com/auth/documents";
    const gRedirectURL = `${window.location.origin}${window.location.pathname}`;

    const gDocs = new GoogleDoc(
      gClientID,
      gClientSecret,
      gRedirectURL,
      gAuthScope,
    );
    const createDocument = async () => {
      const isGInit = await gDocs.init();
      if (isGInit) {
        const documentID = await gDocs.createGoogleDoc("New Google Doc Title");
        if (documentID) {
          const result = await gDocs.updateGoogleDocument(
            documentID,
            newDocument,
          );
          if (result) {
            directDocURL.value.url = gDocs.createDirectLink(documentID);
          }
        }
      }
    };
    onMounted(async () => {
      if (gDocs.isCodeInUrlSearchParams()) {
        await createDocument();
      }
    });
    return { newDocument, createDocument, directDocURL };
  },
});
