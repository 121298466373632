// #region ENUMS

export enum ClientManagerType {
  SalesRep = 1,
  AccountHandler,
}

// ----------------------------------------------------------------------------

export enum ClientManagerGroup {
  InHouse = 1,
  AgencyMember,
  ExAgencyMember,
  AccountHandler,
}

// ----------------------------------------------------------------------------

export enum SummaryRevenueItemKind {
  AgencyRevenueType1 = 1,
  AgencyRevenueType2 = 2,
  AgencyRevenueType3 = 3,
  HouseClients = 4,
}

// ----------------------------------------------------------------------------

export enum ExportValueOption {
  Revenue,
  Commission,
}

// ----------------------------------------------------------------------------

export enum CommissionRateKind {
  SpecialRate = 1,
  HighRate,
  LowRate,
  NoRate,
  TransferredRate,
  AccountHandlerRate,
}

// ----------------------------------------------------------------------------

export enum SummaryRevenueItemValueType {
  TypeOfRevenue,
  SpecialAccount,
  Total,
}

// ----------------------------------------------------------------------------

export enum CommissionUserRole {
  Client = 1,
  ClientManager,
}

// ----------------------------------------------------------------------------

export type ActionCode =
  | "none"
  | "addNewClient"
  | "changeClientType"
  | "changeClientManager"
  | "changeClientManagerInfo"
  | "changeTransferredStatus"
  | "recalculateCommissions"
  | "viewAudit";

// ----------------------------------------------------------------------------

// #endregion

// #region TYPES

export type ClientManager = {
  Id: number;
  Group: string;
  FullName: string;
  Email: string;
};

// ----------------------------------------------------------------------------
export type Tab = {
  title: string;
  content: string;
};

// ----------------------------------------------------------------------------

export type ExportOption = {
  actionName: string;
  value: ExportValueOption;
};

// ----------------------------------------------------------------------------

export type FiscalYear = {
  label: string;
  value: number;
};

export const fiscalYearOf = (date: Date): FiscalYear => {
  const thisYear = date.getFullYear();
  const fiscalYear = date.getMonth() < 6 ? thisYear - 1 : thisYear;

  return { label: `FY${fiscalYear}`, value: fiscalYear };
};

export const currentFiscalYear = (): FiscalYear => fiscalYearOf(new Date());

// ----------------------------------------------------------------------------

export type Filters = {
  //years: FiscalYear[];
  //selectedYear: FiscalYear;
  exportOptions: ExportOption[];
  selectedExportOption: ExportOption;
  clientManagers: ClientManager[];
  selectedClientManager: ClientManager;
  clientManagerType: ClientManagerType;
  dateRangeFrom: Date;
  dateRangeTo: Date;
};

// ----------------------------------------------------------------------------

export type DataTable = { headers: string[]; body: Array<string[]> };

// ----------------------------------------------------------------------------

export type RevenueAndCommissionConfig = {
  salesReps: ClientManager[];
  lastUpdate: string;
};

// ----------------------------------------------------------------------------

export type RevenueAndCommissionsInfo = {
  CommissionableRevenue: number;
  Commissions: number;
  Month: number;
  Year: number;
};

// ----------------------------------------------------------------------------

export type RevenueAndCommissionSummaryItem = {
  Description?: string;
  Kind: SummaryRevenueItemKind;
  RevenueAndCommissionsInfo: RevenueAndCommissionsInfo[];
  SummaryRevenueItemValueType: SummaryRevenueItemValueType;
};

// ----------------------------------------------------------------------------

export type RevenueAndCommissionsGroup = {
  CommissionRateKind: CommissionRateKind;
  CommissionRate: number;
  RevenueAndCommissionsInfo: RevenueAndCommissionsInfo[];
};

// ----------------------------------------------------------------------------

export type RevenueAndCommissionsItem = {
  RevenueAndCommissionsGroups: RevenueAndCommissionsGroup[];
  SalesRepEmail: string;
  SalesRepFullName: string;
  SalesRepGroup: ClientManagerGroup;
}

// ----------------------------------------------------------------------------

export type BreakdownItem = {
  Year: number;
  Month: number;
  ClientFullName: string;
  ClientEmail: string;
  CommissionRate: number;
  CommissionRateKind: CommissionRateKind;
  PositiveRevenues: number;
  NegativeRevenues: number;
  CommissionableRevenues: number;
  Commissions: number;
};

// ----------------------------------------------------------------------------

export type BreakdownLineInit = {
  Client: string;
  Email: string;
  CommissionRateKind: CommissionRateKind;
  Revenues: number;
  Deductions: number;
  Commissionable: number;
  Commissions: number;
};

// ----------------------------------------------------------------------------

export type BreakdownLine = {
  Client: string;
  Email: string;
  //-
  StdCommRevLe12MoRevenues: number;
  StdCommRevLe12MoDeductions: number;
  StdCommRevLe12MoCommissionable: number;
  StdCommRevLe12MoCommission: number;
  //-
  StdCommRevGt12MoRevenues: number;
  StdCommRevGt12MoDeductions: number;
  StdCommRevGt12MoCommissionable: number;
  StdCommRevGt12MoCommission: number;
  //-
  TransferredRevenues: number;
  TransferredDeductions: number;
  TransferredCommissionable: number;
  TransferredCommission: number;
  //-
  ZeroCommRevenues: number;
  ZeroCommCommission: number;
  //-
  TotalPayableCommissions: number;
};

// ----------------------------------------------------------------------------
export type BreakdownLineFmt = {
  Client: string;
  Email: string;
  //-
  StdCommRevLe12MoRevenues: string;
  StdCommRevLe12MoDeductions: string;
  StdCommRevLe12MoCommissionable: string;
  StdCommRevLe12MoCommission: string;
  //-
  StdCommRevGt12MoRevenues: string;
  StdCommRevGt12MoDeductions: string;
  StdCommRevGt12MoCommissionable: string;
  StdCommRevGt12MoCommission: string;
  //-
  TransferredDeductions: string;
  TransferredCommissionable: string;
  TransferredRevenues: string;
  TransferredCommission: string;
  //-
  ZeroCommRevenues: string;
  ZeroCommCommission: string;
  //-
  TotalPayableCommissions: string;
};

// ----------------------------------------------------------------------------

export type ClientManagerItem = {
  ClientId: number;
  AssignedOn: string;
  Client: string;
  ClientEmail: string;
  ClientType: string;
  CommissionType: string;
  Transferred: boolean;
};

// ----------------------------------------------------------------------------

export enum ClientType {
  InHouse = 1,
  AgencyClient = 2,
  NonAgencyClient = 3,
}

// ----------------------------------------------------------------------------

export type ClientTypeHistoryEntry = {
  Id: number;
  ClientType: ClientType;
  From: Date;
  To: Date | null;
};

// ----------------------------------------------------------------------------

export type ClientManagerHistoryEntry = {
  Id: number;
  ClientManager: ClientManager;
  From: Date;
  To: Date | null;
  Transferred: boolean;
};

// ----------------------------------------------------------------------------

export type Client = {
  Id: number;
  Email: string;
  FullName: string;
};

// ----------------------------------------------------------------------------

export type ClientCommissionAudit = {
  Id: number;
  ClientManagerId: number;
  ClientId?: number;
  Timestamp: string;
  Operator: string;
  Action: ActionCode;
  Note?: string;
  OldValue?: unknown;
  NewValue?: unknown;
};

// ----------------------------------------------------------------------------

export type AuditedCommandParams<T> = {
  UserRole: CommissionUserRole;
  UserId: number;
  OldValue: T | null;
  NewValue: T;
  Note?: string;
};

// ----------------------------------------------------------------------------

export type ClientTransferredStatusParams = {
  Transferred: boolean;
};

// ----------------------------------------------------------------------------

export type ClientManagerInfoParams = {
  ClientManagerGroup: ClientManagerGroup;
};

// ----------------------------------------------------------------------------

export type ClientToClientManagerParams = {
  ClientId: number;
  ClientType: ClientType;
  AssignedOn: string;
};

// ----------------------------------------------------------------------------
export type RecalculateCommissionsParams = {
  StartDate: string;
  EndDate: string;
};

// ----------------------------------------------------------------------------

// #endregion

// #region CONSTANTS

export const SALES_TEAM_NAME = "Sell Out";

// ----------------------------------------------------------------------------

export const MONTH_LABELS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// ----------------------------------------------------------------------------

export const COMMON_TABS: Tab[] = [
  {
    title: "Revenue and Commission",
    content: "revenueAndCommission",
  },
  {
    title: "Breakdown",
    content: "breakdown",
  },
  {
    title: "clients",
    content: "clientManagerClients",
  },
];

// ----------------------------------------------------------------------------

export const SUMMARY_TAB: Tab = {
  title: "Summary",
  content: "summary",
};

// ----------------------------------------------------------------------------
export const EXPORT_OPTIONS: ExportOption[] = [
  { actionName: "Revenue", value: ExportValueOption.Revenue },
  { actionName: "Commission", value: ExportValueOption.Commission },
];

// ----------------------------------------------------------------------------

export const CLIENT_TYPES: { key: ClientType; value: string }[] = [
  { key: ClientType.AgencyClient, value: "Sell Out client" },
  { key: ClientType.NonAgencyClient, value: "Ex Sell Out client" },
  { key: ClientType.InHouse, value: "In House client" },
];

// ----------------------------------------------------------------------------

export const SALES_REP_GROUPS: { key: ClientManagerGroup; value: string }[] = [
  { key: ClientManagerGroup.InHouse, value: "In House" },
  { key: ClientManagerGroup.AgencyMember, value: "Current Sell Out" },
  { key: ClientManagerGroup.ExAgencyMember, value: "Ex Sell Out" },
];

// ----------------------------------------------------------------------------

// #endregion
