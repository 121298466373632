export const orderStatuses = new Array<any>(
  { Id: 0, Name: "Processing Payment" },
  { Id: 1, Name: "New Order" },
  { Id: 2, Name: "Image File Received" },
  { Id: 3, Name: "Printed" },
  { Id: 4, Name: "In Framing" },
  { Id: 5, Name: "In Mounting" },
  { Id: 23, Name: "In Dispatch" },
  { Id: 6, Name: "To Be Redone" },
  { Id: 7, Name: "Mounting Started" },
  { Id: 8, Name: "Framing Complete" },
  { Id: 9, Name: "Framing Started" },
  { Id: 10, Name: "Mounting Complete" },
  { Id: 11, Name: "Dispatched" },
  { Id: 12, Name: "Ready For Collection" },
  { Id: 13, Name: "Collected" },
  { Id: 14, Name: "Cancelled" },
  { Id: 15, Name: "For Return" },
  { Id: 16, Name: "Return Received" },
  { Id: 17, Name: "Refund Initiated" },
  { Id: 18, Name: "Payment Failed" },
  { Id: 19, Name: "Packed" },
  { Id: 20, Name: "Delivered" },
  { Id: 21, Name: "Shipping Failed" },
  { Id: 22, Name: "On Hold" },
  { Id: 24, Name: "In ASF Framing" },
  { Id: 25, Name: "Dispatch QC" },
  { Id: 26, Name: "Mounting/Framing QC" },
  { Id: 27, Name: "Product shots pending" },
  { Id: 28, Name: "Product shots complete" },
  { Id: 30, Name: "Bulk Dispatch" },
  { Id: 100, Name: "Card Required" },
  { Id: 101, Name: "Order Failed" },
  { Id: 1000, Name: "Embryonic Order" },
  { Id: 9999, Name: "Suspended Order" },
);
