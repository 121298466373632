
import { defineComponent, ref, Ref } from "vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
declare const $: any;
export default defineComponent({
  components: {
    kdate: DatePicker,
    KNotification: Notification,
  },
  setup() {
    const PopupNotification: Ref<Notification | null> = ref(null);
    return {
      PopupNotification,
      types: [
        { Name: "Fixed Discount", Id: 0 },
        { Name: "Percentage Discount", Id: 1 },
        { Name: "Free Shipping", Id: 2 },
        { Name: "Referral", Id: 3 },
        { Name: "Subscription Discount", Id: 4 },
      ],
    };
  },
  data() {
    const wdgt: any = {};
    const model = ref({
      Name: null,
      Type: 0,
      Start: new Date() as any,
      End: new Date() as any,
      MaxNumberOfUsers: 1,
      NumberOfUsesPerUser: 1,
      NumberOfUsesDebitOrCreditCard: 1,
      FreeShipping: false,
      FreeShippingCourier: false,
      Branches: [] as any[],
    });
    const errors = ref([] as any[]);
    return {
      PopupNotificationWidget: wdgt,
      model,
      errors,
    };
  },
  computed: {
    branches(): Array<any> {
      return this.$state.global.branchDetails;
    },
    branchModel: function (id: number, model: any) {
      return model.Branches.find((x: any) => x.Id == id);
    },
  },
  async mounted() {
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (this.$state.global.branchDetails.length == 0)
      await this.$dispatch.global.loadBranchDetails(null);
    if (this.$route.params.id) {
      await this.load();
    } else {
      this.model = this.newModel();
    }
  },
  methods: {
    async load() {
      await this.loadById(this.$route.params.id);
    },
    async loadById(id: any) {
      const result: any = await $.get(`/form/grid/PromoCode?id=${id}`);

      this.model = this.fixModel(result);
    },
    fixModel(model: any) {
      model.Start = new Date(Number(model.Start.match(/\d+/)[0]));
      model.End = new Date(Number(model.End.match(/\d+/)[0]));

      const currentBranches = model.Branches.map((b: any) => b.UserBranchId);

      const missing = this.branches
        .filter((b: any) => currentBranches.indexOf(b.Id) == -1)
        .map(b => ({
          BranchCode: b.Code,
          UserBranchId: b.Id,
          Symbol: b.Symbol,
          Amount: 0,
          OrderValueLimit: 0,
        }));

      model.Branches = [...model.Branches, ...missing];
      return model;
    },
    newModel() {
      return {
        Name: null,
        Type: 0,
        Start: new Date(),
        End: new Date(),
        MaxNumberOfUsers: 1,
        NumberOfUsesPerUser: 1,
        NumberOfUsesDebitOrCreditCard: 1,
        FreeShipping: false,
        FreeShippingCourier: false,
        Branches: this.branches.map(b => ({
          BranchCode: b.Code,
          UserBranchId: b.Id,
          Symbol: b.Symbol,
          Amount: 0,
          OrderValueLimit: 0,
        })),
      };
    },
    updateStart(event: any) {
      this.model.Start = new Date(event.value);
    },
    updateEnd(event: any) {
      this.model.End = new Date(event.value);
    },
    async save() {
      this.errors = [];
      const model = JSON.parse(JSON.stringify(this.model));
      if (model.Type != 2) {
        model.Branches = model.Branches.filter((x: any) => x.Amount > 0);
      } else {
        for (let i = 0, c = model.Branches.length; i < c; i++) {
          model.Branches[i].Amount = 0;
          model.Branches[i].OrderValueLimit = 0;
        }
      }

      if (model.Branches.length == 0) {
        this.errors = ["At least one currency needs a value"];
        return;
      }
      try {
        const { Id } = await $.ajax({
          type: "POST",
          url: "/form/grid/SavePromoCode",
          data: JSON.stringify(model),
          dataType: "json",
          contentType: "application/json",
        });

        await this.loadById(Id);

        if (this.$route.params.id) {
          this.PopupNotificationWidget?.show(
            "Promo successfully updated",
            "success",
          );
        } else {
          this.PopupNotificationWidget?.show(
            "Promo successfully saved",
            "success",
          );
        }

        this.$router.push(`/promotional-code/${Id}`);
      } catch (e: any) {
        this.errors = e.responseJSON.Errors;
        this.PopupNotificationWidget?.show(
          `Something went wrong with saving the Promotional Code`,
          "error",
        );
      }
    },
  },
});
