
import { defineComponent, ref } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { get } from "tps.shared.ui/core/requestHelpers";
import axios from "axios";
import { filterBy } from "@progress/kendo-data-query";
declare var $: any;

export default defineComponent({
  name: "DeliveryPrices",
  components: {
    KendoDdl: DropDownList,
  },
  setup() {
    const grid: any = ref(null);
    return { grid };
  },
  data() {
    return {
      innerItems: [],
      items: [],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      const { data } = await get(axios, "/grid/Deliveries", {
        prefix: "form",
      });
      this.innerItems = data;
      this.items = data;
    },
    select(evtData: any) {
      const id = evtData.value?.Id;
      const grid = this.grid;
      $.get(`/form/grid/deliverypricespartial/${id}`, {}, (html: string) => {
        html = html.replace(/\/grid\//gi, "/form$&");
        $(grid).html(html);
      });
    },
    filterChange(event: any) {
      this.items = this.filterData(event.filter);
    },
    filterData(filter: any) {
      const data = this.innerItems.slice();
      return filterBy(data, filter);
    },
  },
});
