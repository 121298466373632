<template>
  <div class="d-flex d-row justify-content-start align-items-start">
    <div class="col side-bar">
      <div
        class="d-flex flex-column flex-shrink-0 p-3 pt-0 justify-content-start align-items-start"
      >
        <div class="accordion">
          <div class="accordion-item">
            <h2>
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                Analytics
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="panelsStayOpen-headingOne"
            >
              <div class="accordion-body">
                <ul class="nav nav-pills flex-column mb-auto">
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/analytics/new-sales-cohorts"
                    >
                      New Sales Cohorts
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <router-view />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.side-bar
  width 350px
  flex 0 0 350px
.col
  text-align left
</style>
