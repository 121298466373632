
import { defineComponent, ref, Ref } from "vue";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Notification } from "@progress/kendo-popups-vue-wrapper";

declare var $: any;

export default defineComponent({
  name: "GridFiltersList",
  components: {
    KDialog: Dialog,
    KDialogActionsBar: DialogActionsBar,
    KButton: Button,
    KNotification: Notification,
  },
  setup() {
    const grid: any = ref(null);
    const PopupNotification: Ref<any> = ref(null);

    return { grid, PopupNotification };
  },
  data() {
    const wdgt: any = {};
    const item: any = {};
    const gridDataSource: any = {};
    return {
      gridHandle: "#gridGridFilters",
      visibleDialog: false,
      itemToBeDeleted: item,
      PopupNotificationWidget: wdgt,
      GridDataSource: gridDataSource,
    };
  },
  mounted() {
    const _window: any = window;
    _window.router = this.$router;
    // eslint-disable-next-line
    const _this = this;
    const grid = this.grid;
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();

    _window.deleteItem = function (e: any) {
      e.preventDefault();

      _this.itemToBeDeleted = this.dataItem($(e.currentTarget).closest("tr"));
      _this.toggleDialog();
      _this.GridDataSource = this;
    };

    _window.canDelete = function (e: any) {
      return e?.IsOwnedByLoggedUser ?? false;
    };

    $.get("/form/grid/GridFilters", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug
      $(grid).html(html);

      $(grid).on("click", "td", function (e: any) {
        const el = e.target;
        if ($(el).hasClass("k-grid-delete") || $(el).hasClass("k-icon")) return;
        const row = $(el).parents("tr");

        let id = row.find("td:first").text();

        _window.router.push(`/grid-filters/filter/${id}`);
      });

      $(grid).on("click", "#addNewCmd", (e: any) => {
        e.preventDefault();
        _window.router.push("/grid-filters/filter");
      });
    });
  },
  methods: {
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    async deleteItem() {
      this.toggleDialog();
      try {
        await this.$dataViews.DeleteGridFilters(this.itemToBeDeleted.Id);
        this.GridDataSource.dataSource.read();
      } catch (e: any) {
        this.PopupNotificationWidget.show(
          "An error occurred while trying to delete the item",
          "error",
        );
      }
    },
  },
});
