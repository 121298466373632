
import { defineComponent, ref } from "vue";
import {
  postWithCsrfTokenFormData,
  makeFormData,
} from "tps.shared.ui/core/requestHelpers";
import axios from "axios";
declare var $: any;
export default defineComponent({
  name: "ShopifyTemplate",
  data() {
    let templatePages: any[] = [];
    let sessionId: any = null;
    return {
      templatePages,
      sessionId,
    };
  },
  setup() {
    const store: any = ref(null);
    const fomo: any = ref(null);
    const targetpage: any = ref(null);
    return { store, fomo, targetpage };
  },
  methods: {
    async uploadFomo() {
      if (this.fomo.value != "") {
        const form: any = { file: this.fomo.files[0] };
        const { data } = await postWithCsrfTokenFormData(
          axios,
          "/Shopify/LoadFomoTemplate",
          form,
          { prefix: "form" },
          {},
        );
        this.templatePages = data.files;
        this.sessionId = data.SessionId;
      }
    },
  },
});
