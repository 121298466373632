export const ErrorCode = Object.freeze({
  AUTHENTICATION: Symbol(10),
  AUTHORIZATION: Symbol(11),
  NOT_SUPPORTED: Symbol(20),
  UNEXPECTED: Symbol(21),
  SERVER_FAILED: Symbol(22),
  HTTP_NO_RESPONSE: Symbol(30),
  HTTP_ERROR_RESPONSE: Symbol(31),
  HTTP_ERROR_404_NOT_FOUND: Symbol(32),
  HTTP_BAD_REQUEST: Symbol(33),
  HTTP_FORBIDDEN: Symbol(34),
  CANCELLED: Symbol(9000),
  GENERIC: Symbol(9999),
});

export class ApplicationError {
  /**
   *
   * @param {Symbol} code
   * @param {string} message - english error message for logs
   * @param {any} payload - any extra information
   */
  constructor(
    public code: symbol,
    public message: string,
    public payload: any = null
  ) {}

  /**
   *
   * @param {any} payload - any extra information
   */
  static Unexpected(payload: any = null) {
    return new ApplicationError(
      ErrorCode.UNEXPECTED,
      "Unexpected error",
      payload
    );
  }
}
