import {OrderStatusModel} from "@/core/types/orderStatusModel";

export enum OrderStatus {
  ProcessingPayment = 0,
  NewOrder = 1,
  ImageFileReceived = 2,
  Printed = 3,
  InFraming = 4,
  InMounting = 5,
  InDispatch = 23,
  ToBeRedone = 6,
  MountingStarted = 7,
  FramingComplete = 8,
  FramingStarted = 9,
  MountingComplete = 10,
  Dispatched = 11,
  ReadyForCollection = 12,
  Collected = 13,
  Cancelled = 14,
  ForReturn = 15,
  ReturnReceived = 16,
  RefundInitiated = 17,
  PaymentFailed = 18,
  Packed = 19,
  Delivered = 20,
  ShippingFailed = 21,
  OnHold = 22,
  InASFFraming = 24,
  DispatchQC = 25,
  MountingFramingQC = 26,
  ProductShotsPending = 27,
  ProductShotsComplete = 28,
  CardRequired = 100,
  OrderFailed = 101,
  EmbryonicOrder = 1000,
  SuspendedOrder = 9999,
}

export const OrderStatuses: OrderStatusModel[] = [
  { Id: OrderStatus.ProcessingPayment, Name: "Processing Payment" },
  { Id: OrderStatus.NewOrder, Name: "New Order" },
  { Id: OrderStatus.ImageFileReceived, Name: "Image File Received" },
  { Id: OrderStatus.Printed, Name: "Printed" },
  { Id: OrderStatus.InFraming, Name: "In Framing" },
  { Id: OrderStatus.InMounting, Name: "In Mounting" },
  { Id: OrderStatus.InDispatch, Name: "In Dispatch" },
  { Id: OrderStatus.ToBeRedone, Name: "To Be Redone" },
  { Id: OrderStatus.MountingStarted, Name: "Mounting Started" },
  { Id: OrderStatus.FramingComplete, Name: "Framing Complete" },
  { Id: OrderStatus.FramingStarted, Name: "Framing Started" },
  { Id: OrderStatus.MountingComplete, Name: "Mounting Complete" },
  { Id: OrderStatus.Dispatched, Name: "Dispatched" },
  { Id: OrderStatus.ReadyForCollection, Name: "Ready For Collection" },
  { Id: OrderStatus.Collected, Name: "Collected" },
  { Id: OrderStatus.Cancelled, Name: "Cancelled" },
  { Id: OrderStatus.ForReturn, Name: "For Return" },
  { Id: OrderStatus.ReturnReceived, Name: "Return Received" },
  { Id: OrderStatus.RefundInitiated, Name: "Refund Initiated" },
  { Id: OrderStatus.PaymentFailed, Name: "Payment Failed" },
  { Id: OrderStatus.Packed, Name: "Packed" },
  { Id: OrderStatus.Delivered, Name: "Delivered" },
  { Id: OrderStatus.ShippingFailed, Name: "Shipping Failed" },
  { Id: OrderStatus.OnHold, Name: "On Hold" },
  { Id: OrderStatus.InASFFraming, Name: "In ASF Framing" },
  { Id: OrderStatus.DispatchQC, Name: "Dispatch QC" },
  { Id: OrderStatus.MountingFramingQC, Name: "Mounting/Framing QC" },
  { Id: OrderStatus.ProductShotsPending, Name: "Product shots pending" },
  { Id: OrderStatus.ProductShotsComplete, Name: "Product shots complete" },
  { Id: OrderStatus.CardRequired, Name: "Card Required" },
  { Id: OrderStatus.OrderFailed, Name: "Order Failed" },
  { Id: OrderStatus.EmbryonicOrder, Name: "Embryonic Order" },
  { Id: OrderStatus.SuspendedOrder, Name: "Suspended Order" },
];
