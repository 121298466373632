export enum OrderNoteType {
  User = 0,
  Order = 1,
  System = 2
}

export interface Note {
  CreatedById: number;
  CreatedByName: string;
  UpdatedById?: number;
  UpdatedByName?: string;
  CssClass: string;
  DateCreated: string;
  DateUpdated?: string;
  Id: number;
  OrderId?: number;
  HubUserId?: number;
  Text: string;
  Type: OrderNoteType;
  IsAdvisory: boolean;
}
