
import { defineComponent, ref, getCurrentInstance } from "vue";
import axios from "axios";
import { Button as KButton } from "@progress/kendo-vue-buttons";
import { MultiSelect as KMultiselect } from "@progress/kendo-vue-dropdowns";

declare var $: any;

const dashboardGroupTypesMap: Map<string, number> = new Map([
  ["Summary", 1],
  ["Orders", 2],
  ["RedoOrders", 3],
  ["RefundOrders", 4],
  ["OrdersOnHold", 5],
  ["OrdersOverSLA", 6],
]);

export default defineComponent({
  name: "DashboardSettings",
  components: {
    KMultiselect,
    KButton,
  },
  setup() {
    const excludeUserInput = ref(null);
    const excludedUsersGrid = ref(null);

    const instance = getCurrentInstance();

    const onMultiselectOpen = () => {
      $("#excludeUserInput").data("kendoMultiSelect").dataSource.read();
    };

    return {
      excludeUserInput,
      excludedUsersGrid,
      onMultiselectOpen,
    };
  },
  data() {
    return {
      dashboardGroupTypes: [ 'Summary', 'Orders', 'RedoOrders', 'RefundOrders', 'OrdersOnHold', 'OrdersOverSLA' ],
      multiselectValue: [],
      dashboardSettings: null,
      GridDataSource1: null,
    };
  },
  mounted() {
    this.InitializeDashboardSettings();
    this.initializeGrid();
  },
  methods: {
    onSaveClick(e: any): void {
      const convertedValuesFromMultiselect: number [] = [];

      this.multiselectValue.forEach(element => {
        const value = dashboardGroupTypesMap.get(element) as number;
        if (value !== undefined) {
          convertedValuesFromMultiselect.push(value);
        }
      });

      const data = $("#excludeUserInput").data("kendoMultiSelect").value();

      axios.post("/form/grid/SaveExcludedUsers", {
          ListOfUsersToExclude: data,
          GroupTypesToExclude: convertedValuesFromMultiselect,
        })
        .then(() => {
          $("#excludeUserInput").data("kendoMultiSelect").value([]);
          this.multiselectValue = [];
          $("#excludedUsersGrid").data("kendoGrid").dataSource.read();
        })
        .catch(() => {
          alert("Error saving filter");
        });
    },
    InitializeDashboardSettings() {
      const dashboardSettings = this.excludeUserInput;
      const _window: any = window;

      $.get("/form/grid/DashboardSettingsPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(dashboardSettings).html(html);

        this.dashboardSettings = $(dashboardSettings).data("kendoGrid");
      });
      _window.onMultiselectOpen = this.onMultiselectOpen;
    },
    initializeGrid() {
      const grid = this.excludedUsersGrid;
      const _window: any = window;

      $.get("/form/grid/DashboardSettingsGridPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(grid).html(html);


      });
    }
  },
});
