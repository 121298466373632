import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c686f644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "splash" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "tab-strip-menu" }
const _hoisted_4 = { ref: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_button_group = _resolveComponent("k-button-group")!
  const _component_KTabStrip = _resolveComponent("KTabStrip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_KTabStrip, {
          selected: _ctx.selectedTab,
          "tab-position": 'top',
          tabs: _ctx.GridFilterCategories,
          onSelect: _ctx.onSelectTab
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.GridFilterCategories, (category) => {
            return {
              name: category.id,
              fn: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_k_button_group, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getGridFiltersByCategoryId(
                    category.id,
                  ), (gridFilter, gfi) => {
                        return (_openBlock(), _createBlock(_component_k_button, {
                          key: gfi,
                          toggable: true,
                          "fill-mode": 'flat',
                          selected: gridFilter.Id === _ctx.selected,
                          class: _normalizeClass([_ctx.isPublic(gridFilter) ? 'public-filter' : 'private-filter']),
                          onClick: ($event: any) => (_ctx.onSelect(gridFilter.Id ?? 0, $event))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(gridFilter.Name), 1)
                          ]),
                          _: 2
                        }, 1032, ["selected", "class", "onClick"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ])
              ])
            }
          })
        ]), 1032, ["selected", "tabs", "onSelect"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, null, 512)
  ]))
}