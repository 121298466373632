import axios from "axios";
/**
 * Represents of GoogleAuth.
 * @constructor
 */
export default class GoogleAuth {
  gClientID: string;
  gClientSecret: string;
  gRedirectURL: string;
  gAuthScope?: string;
  googleAccessToken: string;

  /**
   * @param {string} gClientID - Google Client ID should be from https://console.developers.google.com/apis/credentials.
   * @param {string} gClientSecret - Google Client Secret should be from https://console.developers.google.com/apis/credentials.
   * @param {string} gRedirectURL - URL where google will redirect after successfully auth.
   * @param {string} gAuthScope - scope of Google API check - https://developers.google.com/docs/api/reference/rest/v1/documents/create.
   */
  constructor(
    gClientID: string,
    gClientSecret: string,
    gRedirectURL: string,
    gAuthScope?: string,
  ) {
    this.gClientID = gClientID;
    this.gClientSecret = gClientSecret;
    this.gRedirectURL = gRedirectURL;
    this.gAuthScope = gAuthScope || "";
    this.googleAccessToken = "";
  }

  /**
   * Initialize of google auth
   * @method
   * @return {boolean}
   * */
  public async init(): Promise<boolean> {
    if (this.hasAccessTokenInStorage()) {
      const googleAccessToken = localStorage.getItem("googleAccessToken");
      if (googleAccessToken) {
        const isLive = await this.isAccessTokenLive(googleAccessToken);
        if (isLive) {
          return true;
        }
        localStorage.removeItem("googleAccessToken");
      }
    }
    if (this.hasRefreshTokenInStorage()) {
      const googleRefreshToken = localStorage.getItem("googleRefreshToken");
      if (googleRefreshToken) {
        const refreshed = await this.refreshAccessToken(googleRefreshToken);
        if (refreshed) {
          return true;
        }
        localStorage.removeItem("googleRefreshToken");
      }
    }
    const code = this.isCodeInUrlSearchParams();
    if (code) {
      const tokensByCode = await this.getGoogleTokensByCode(code);
      if (tokensByCode) {
        this.clearSearch();
        return true;
      }
    }
    await this.googleAuth();
    return true;
  }
  /**
   * Redirect to google auth page
   * @method
   * */
  protected async googleAuth() {
    window.location.replace(
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=" +
        this.gClientID +
        "&response_type=code" +
        "&state=state_parameter_passthrough_value" +
        "&scope=" +
        this.gAuthScope +
        "&redirect_uri=" +
        this.gRedirectURL +
        "&prompt=consent" +
        "&include_granted_scopes=true" +
        "&access_type=offline",
    );
  }

  /**
   * Get google access_token and refresh_token by google auth code
   * @method
   * */
  public async getGoogleTokensByCode(code: string): Promise<boolean> {
    try {
      const { data } = await axios.post(
        "https://accounts.google.com/o/oauth2/token",
        `code=${code}&redirect_uri=${this.gRedirectURL}&client_id=${this.gClientID}&client_secret=${this.gClientSecret}&scope=&grant_type=authorization_code&access_type=offline`,
      );
      if (data && data.access_token && data.refresh_token) {
        localStorage.setItem("googleAccessToken", data.access_token);
        localStorage.setItem("googleRefreshToken", data.refresh_token);
        this.googleAccessToken = data.access_token;
        return true;
      }
      return false;
    } catch (error) {
      let errorMessage =
        "getGoogleTokensByCode - Failed to get access_token && refresh token";
      if (error instanceof Error) {
        errorMessage = `getGoogleTokensByCode - ${error.message}`;
      }
      console.log(errorMessage);
      return false;
    }
  }

  /**
   * Create Google Doc
   * @method
   * @param {string} documentTitle - the text which will represent the Google Doc name
   * @return {string | null} - string is the new documentId
   * */
  public async createGoogleDoc(documentTitle: string): Promise<string | null> {
    try {
      const { data } = await axios.post(
        "https://docs.googleapis.com/v1/documents",
        { title: documentTitle },
        {
          headers: {
            Authorization: `Bearer ${this.googleAccessToken}`,
          },
        },
      );
      if (data) {
        return data.documentId;
      }
      return null;
    } catch (error) {
      let errorMessage = "createGoogleDoc - Failed to create Google Doc";
      if (error instanceof Error) {
        errorMessage = `createGoogleDoc - ${error.message}`;
      }
      console.log(errorMessage);
      return null;
    }
  }

  /**
   * Update Google Doc
   * @method
   * @param {string} documentId - the id of the updated document
   * @param {any} requests - the body of the updated document
   * @return {string | null} - string is the new documentId
   * */
  public async updateGoogleDocument(
    documentId: string,
    requests: any,
  ): Promise<string | null> {
    try {
      const { data } = await axios.post(
        `https://docs.googleapis.com/v1/documents/${documentId}:batchUpdate`,
        {
          documentId,
          requests,
        },
        {
          headers: {
            Authorization: `Bearer ${this.googleAccessToken}`,
          },
        },
      );

      if (data) {
        return data.documentId;
      }
      return null;
    } catch (error) {
      let errorMessage = "createGoogleDoc - Failed to create Google Doc";
      if (error instanceof Error) {
        errorMessage = `createGoogleDoc - ${error.message}`;
      }
      console.log(errorMessage);
      return null;
    }
  }
  /**
   * Check do we have google auth code in window.location.search
   * @method
   * @param {string} documentId - the id of the google doc
   * @return {string}
   * */
  public createDirectLink(documentId: string): string {
    return `https://docs.google.com/document/d/${documentId}/edit`;
  }
  /**
   * Check do we have google auth code in window.location.search
   * @method
   * @return {string | null}
   * */
  public isCodeInUrlSearchParams(): string | null {
    if (window !== undefined && window.location.search !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code) {
        return code;
      }
    }
    return null;
  }
  /**
   * Check do we have a Google's  access_token in localStorage
   * @method
   * @return boolean
   */
  public hasAccessTokenInStorage(): boolean {
    const accessToken: string | null =
      localStorage.getItem("googleAccessToken");
    return accessToken !== null;
  }

  /**
   * Check do we have a Google's refresh_token in localStorage
   * @method
   * @return boolean
   */
  public hasRefreshTokenInStorage(): boolean {
    const refreshToken: string | null =
      localStorage.getItem("googleRefreshToken");
    return refreshToken !== null;
  }

  /**
   * Check is google access token is live
   * @method
   * @param {string} accessToken - google access token
   * @return boolean
   */
  public async isAccessTokenLive(accessToken: string): Promise<boolean> {
    try {
      const { data } = await axios.get(
        `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`,
      );
      if (data && data.expires_in > 0) {
        this.googleAccessToken = accessToken;
        return false;
      }
      return false;
    } catch (error) {
      let errorMessage = "isAccessTokenLive - Failed to check access_token";
      if (error instanceof Error) {
        errorMessage = `isAccessTokenLive - ${error.message}`;
      }
      console.log(errorMessage);
      return false;
    }
  }

  /**
   * Try to refresh google access_token via refresh_token
   * @method
   * @param {string} refreshToken - google refresh_token
   * @return boolean
   */
  public async refreshAccessToken(refreshToken: string): Promise<boolean> {
    try {
      const { data } = await axios.post(
        "https://accounts.google.com/o/oauth2/token",
        `client_secret=${this.gClientSecret}&grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${this.gClientID}`,
      );
      if (data && data.access_token) {
        localStorage.setItem("googleAccessToken", data.access_token);
        this.googleAccessToken = data.access_token;
        return true;
      }
      return false;
    } catch (error) {
      let errorMessage = "refreshAccessToken - Failed to refresh access_token";
      if (error instanceof Error) {
        errorMessage = `refreshAccessToken - ${error.message}`;
      }
      console.log(errorMessage);
      return false;
    }
  }
  /**
   * Clear search part from URL
   * @method
   */
  protected clearSearch(): void {
    if (window instanceof Window) {
      history.pushState("", document.title, window.location.pathname);
    }
  }
  /**
   * Clear gAccessToken && gRefresh token from localStorage
   * @method
   */
  protected clearStorage(): void {
    localStorage.removeItem("googleAccessToken");
    localStorage.removeItem("googleRefreshToken");
  }
}
