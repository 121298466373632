
import { defineComponent, ref } from "vue";
import {
  TabStrip as KTabStrip,
  TabStripTab as KTabStripTab,
} from "@progress/kendo-vue-layout";

import ProductivityTab from "./Tabs/ProductivityTab.vue";

import {
  DateRange,
  makeRangeDefault,
  ProductivityActivity,
  ProductivityDataAggregate,
  ProductivityLevel,
} from "@/core/types/dashboard/ProductivityData";

type Tab = {
  id: number;
  title: string;
  props: {
    isSelected: boolean;
    level: ProductivityLevel;
    activity: ProductivityActivity;
    dateRange: DateRange;
    aggregate: ProductivityDataAggregate;
  };
};

export default defineComponent({
  name: "ProductivityDashboard",
  components: {
    KTabStrip,
    KTabStripTab,
    ProductivityTab,
  },
  setup() {
    const tabMaker = (
      dateRange: DateRange,
      aggregate: ProductivityDataAggregate,
    ) => {
      let id = -1;
      return (
        title: string,
        level: ProductivityLevel,
        activity: ProductivityActivity,
      ) => {
        id++;
        const isSelected = id == 0;
        return {
          id,
          title,
          props: {
            isSelected,
            level,
            activity,
            dateRange,
            aggregate,
          },
        };
      };
    };

    const selectedTab = ref(0);
    const aggregate = ref(ProductivityDataAggregate.Daily);
    const makeTab = tabMaker(makeRangeDefault(), aggregate.value);
    const tabs = ref([
      makeTab("Summary", ProductivityLevel.Summary, ProductivityActivity.All),
      makeTab(
        "General Printing",
        ProductivityLevel.General,
        ProductivityActivity.Printing,
      ),
      makeTab(
        "General Dispatch",
        ProductivityLevel.General,
        ProductivityActivity.Dispatch,
      ),
      makeTab(
        "Magnum Printing",
        ProductivityLevel.Magnum,
        ProductivityActivity.Printing,
      ),
      makeTab(
        "Magnum Dispatch",
        ProductivityLevel.Magnum,
        ProductivityActivity.Dispatch,
      ),
      makeTab(
        "Consolidated Printing",
        ProductivityLevel.Consolidated,
        ProductivityActivity.Printing,
      ),
      makeTab(
        "Consolidated Dispatch",
        ProductivityLevel.Consolidated,
        ProductivityActivity.Dispatch,
      ),
      makeTab(
        "Non Production",
        ProductivityLevel.NonProduction,
        ProductivityActivity.All,
      ),
    ]);

    return {
      selectedTab,
      tabs,
    };
  },
  mounted() {
    this.onSelectedTab({ selected: 0 });
  },
  methods: {
    tabApply(action: (tab: Tab) => void) {
      for (const tab of this.tabs) action(tab);
    },
    onSelectedTab(e: any) {
      this.selectedTab = e.selected;
      this.tabApply(tab => (tab.props.isSelected = e.selected == tab.id));
    },
    onAggregateChanged(event: any) {
      this.tabApply(tab => (tab.props.aggregate = event.selectedAggregate));
    },
    onDateRangeChanged(event: any) {
      this.tabApply(tab => {
        if (tab.id != this.selectedTab) {
          const { start, end } = event.selectedDataRange;
          tab.props.dateRange.start = start;
          tab.props.dateRange.end = end;
        }
      });
    },
  },
});
