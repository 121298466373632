export enum OrderState {
  ProcessingPayment = 0,
  NewOrder = 1,
  ImageFileReceived = 2,
  Printed = 3,
  InFraming = 4,
  InMounting = 5,
  ToBeRedone = 6,
  MountingStarted = 7,
  FramingComplete = 8,
  FramingStarted = 9,
  MountingComplete = 10,
  Dispatched = 11,
  ReadyForCollection = 12,
  Collected = 13,
  Cancelled = 14,
  ForReturn = 15,
  ReturnReceived = 16,
  RefundInitiated = 17,
  PaymentFailed = 18,
  Packed = 19,
  Delivered = 20,
  ShippingFailed = 21,
  OnHold = 22,
  Dispatch = 23,
  ASFFraming = 24,
  DispatchQC = 25,
  BulkDispatch = 30,
  CardRequired = 100,
  OrderFailed = 101,
  DuplicateOrder = 102,
  EmbryonicOrder = 1000,
  SuspendedOrder = 9999,
  NotSet = -1,
};