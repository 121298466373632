
import { defineComponent, ref, Ref } from "vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import { formatDate } from "@telerik/kendo-intl";
declare const $: any;

export default defineComponent({
  components: {
    kdate: DatePicker,
    KNotification: Notification,
  },
  setup() {
    const PopupNotification: Ref<Notification | null> = ref(null);
    return {
      PopupNotification,
      types: [
        { Name: "Fixed Discount", Id: 0 },
        { Name: "Percentage Discount", Id: 1 },
        { Name: "Free Shipping", Id: 2 },
        { Name: "Referral", Id: 3 },
        { Name: "Subscription Discount", Id: 4 },
      ],
    };
  },
  data() {
    const wdgt: any = {};
    const model = ref({
      Count: 0,
      CharLimit: 8,
      Name: null,
      Type: 0,
      Start: new Date() as any,
      End: new Date() as any,
      MaxNumberOfUsers: 1,
      NumberOfUsesPerUser: 1,
      NumberOfUsesDebitOrCreditCard: 1,
      FreeShipping: false,
      Branches: [] as any[],
    });
    const errors = ref([] as any[]);
    return {
      PopupNotificationWidget: wdgt,
      model,
      errors,
    };
  },
  computed: {
    branches(): Array<any> {
      return this.$state.global.branchDetails;
    },
    branchModel: function (id: number, model: any) {
      return model.Branches.find((x: any) => x.Id == id);
    },
  },
  async mounted() {
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (this.$state.global.branchDetails.length == 0)
      await this.$dispatch.global.loadBranchDetails(null);
    this.model = this.newModel();
  },
  methods: {
    fixModel(model: any) {
      model.Start = new Date(Number(model.Start.match(/\d+/)[0]));
      model.End = new Date(Number(model.End.match(/\d+/)[0]));

      const currentBranches = model.Branches.map((b: any) => b.UserBranchId);

      const missing = this.branches
        .filter((b: any) => currentBranches.indexOf(b.Id) == -1)
        .map(b => ({
          BranchCode: b.Code,
          UserBranchId: b.Id,
          Symbol: b.Symbol,
          Amount: 0,
          OrderValueLimit: 0,
        }));

      model.Branches = [...model.Branches, ...missing];
      return model;
    },
    newModel() {
      return {
        Count: 0,
        CharLimit: 8,
        Name: null,
        Type: 0,
        Start: new Date(),
        End: new Date(),
        MaxNumberOfUsers: 1,
        NumberOfUsesPerUser: 1,
        NumberOfUsesDebitOrCreditCard: 1,
        FreeShipping: false,
        Branches: this.branches.map(b => ({
          BranchCode: b.Code,
          UserBranchId: b.Id,
          Symbol: b.Symbol,
          Amount: 0,
          OrderValueLimit: 0,
        })),
      };
    },
    updateStart(event: any) {
      this.model.Start = new Date(event.value);
    },
    updateEnd(event: any) {
      this.model.End = new Date(event.value);
    },
    validate(event: any) {
      const value = Number(event.target.value);
      const min = Number(event.target.min);
      const max = Number(event.target.max);

      if (value < min) event.target.value = min;
      else if (value > max) event.target.value = max;
    },
    async generate() {
      this.errors = [];
      const model = JSON.parse(JSON.stringify(this.model));
      if (model.Type != 2) {
        model.Branches = model.Branches.filter((x: any) => x.Amount > 0);
      } else {
        for (let i = 0, c = model.Branches.length; i < c; i++) {
          model.Branches[i].Amount = 0;
          model.Branches[i].OrderValueLimit = 0;
        }
      }

      if (model.Branches.length == 0) {
        this.errors = ["At least one currency needs a value"];
        return;
      }
      try {
        const { GeneratedPromoCodes } = await $.ajax({
          type: "POST",
          url: "/form/grid/GenerateBulkPromoCodes",
          data: JSON.stringify(model),
          dataType: "json",
          contentType: "application/json",
        });

        this.PopupNotificationWidget?.show(
          "Promos successfully created",
          "success",
        );

        const csv = GeneratedPromoCodes.join("\r\n");
        const date = formatDate(new Date(), "yyyy-MM-dd_HH-mm-ss");
        const link = document.createElement("a");
        link.href = "data:text/csv," + encodeURIComponent(csv);
        link.download = `Promocodes_${date}.csv`;
        link.click();
      } catch (e: any) {
        this.errors = e.responseJSON.Errors;
        this.PopupNotificationWidget?.show(
          `Something went wrong with saving the Promotional Code`,
          "error",
        );
      }
    },
  },
});
