
import { defineComponent, ref, Ref } from "vue";
import { dateDiffInDays, loadKendoGrid } from "@/core/utils";
import { ButtonGroup, Button } from "@progress/kendo-vue-buttons";
import {
  DateRangePicker,
  DateTimePicker,
  TimePicker,
} from "@progress/kendo-vue-dateinputs";
import {
  Input,
  TextArea,
  RadioGroup,
  Checkbox,
  NumericTextBox,
} from "@progress/kendo-vue-inputs";
import { Notification, Tooltip } from "@progress/kendo-popups-vue-wrapper";
import {
  AutoComplete,
  ComboBox,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-vue-dropdowns";

import {
  FormDataViewGridFilter,
  FormDataViewIncludedColumns,
  FormDataViewParam,
  FormDataViewParamType,
  FormDataViewGridFilterParamValue,
  FormDataViewFilterOperator,
  FormDataViewSource,
  FormDataViewScheduleKind,
  ParamValueType,
  FormDataViewSchedule,
  schedulingOptions,
  visibilityOptions,
  weekdayOptions,
  numberFilterOptions,
  textFilterOptions,
  dayOfMonthOptions,
  dateTimeFilterOptions,
  listFilterOptions,
  dateTimeSingleOptions,
  dateTimeRangeOptions,
  FormDataViewSortDescriptor,
  FormDataViewSortDirection,
  visibilityColorOptions,
  FormDataGridFilterCategory,
} from "@/core/types/formDataViewsModel";

import {
  makeFormDataViewGridFilter,
  getListTitle,
  getGridFilterType,
  getParamDataItems,
  getParamValueType,
  getDefaultValue,
  validateGridFilter,
} from "@/core/dataViewsHelpers";
import { GridFilterType } from "@/core/enums/grid-filter.type";
declare var $: any;
import * as ku from "@/core/kendoUtils";
import { formatDate } from "@telerik/kendo-intl";
enum PageMode {
  New,
  Edit,
}

export default defineComponent({
  name: "GridFilterDetails",
  components: {
    KNotification: Notification,
    KTooltip: Tooltip,
    KAutoComplete: AutoComplete,
    KComboBox: ComboBox,
    KDropDownList: DropDownList,
    KMultiSelect: MultiSelect,
    KButtonGroup: ButtonGroup,
    KButton: Button,
    KInput: Input,
    KTextArea: TextArea,
    KRadioGroup: RadioGroup,
    KCheckbox: Checkbox,
    KNumericTextBox: NumericTextBox,
    KTimePicker: TimePicker,
    KDateTimePicker: DateTimePicker,
    "kendo-daterangepicker": DateRangePicker,
  },
  setup() {
    const grid: any = ref(null);
    const PopupNotification: Ref<any> = ref(null);
    const DataSources: Ref<FormDataViewSource[] | null> = ref(null);
    const GridFilter: Ref<FormDataViewGridFilter> = ref(
      makeFormDataViewGridFilter(),
    );
    const GridFilterCategories: Ref<FormDataGridFilterCategory[]> = ref([]);
    const wdgt: any = {};
    const gridFields: Ref<any[]> = ref([]);
    const schOpts = schedulingOptions.map(
      o =>
        o as {
          id: number;
          text: string;
          Time: Date;
          Recipients: string[];
          RecipientsValue: string;
          WeekDay?: { id: number; text: string };
          MonthDay?: number | null;
          IsUiView: boolean;
          IsEmailReport: boolean;
        },
    );
    schOpts.forEach(x => (x.IsUiView = true));
    return {
      grid,
      PopupNotification,
      GridFilter,
      DataSources,
      PopupNotificationWidget: wdgt,
      visibilityOptions: visibilityOptions.map((o: any) => ({
        label: o.text,
        value: o.id,
      })),
      colorOptions: visibilityColorOptions.map((o: any) => ({
        label: o.text,
        value: o.id,
      })),
      weekdayOptions,
      dayOfMonthOptions,
      schedulingOptions: schOpts,
      schedule: ref(schOpts[0]),
      gridFields,
      numberFilterOptions,
      textFilterOptions,
      dateTimeFilterOptions,
      listFilterOptions,
      dateTimeSingleOptions,
      dateTimeRangeOptions,
      GridFilterCategories,
    };
  },
  computed: {
    pageMode(): PageMode {
      return this.$route.params.gridFilterId ? PageMode.Edit : PageMode.New;
    },
    parameters(): FormDataViewParam[] {
      return this.GridFilter.DataView?.Parameters ?? [];
    },
    dataSources(): FormDataViewSource[] {
      return this.DataSources ?? [];
    },
    listTitle(): string {
      return getListTitle(this.GridFilter?.DataView?.DataSource?.Name);
    },
  },
  async mounted() {
    const _window: any = window;
    _window.openPms = function (e: any) {};
    if (!_window.OrderItemSelect) {
      _window.OrderItemSelect = function () {
        const row = this.dataItem(this.select());
        const gotoPage = `/orders/${row.OrderId}`;
        _window.router.push(gotoPage);
      };
    }
    _window.openNewTab = function (e: any) {
      e.preventDefault();
      const grid = $("#gridOrders").getKendoGrid();
      const item = grid.dataItem($(e.currentTarget).closest("tr"));
      _window.open(`/orders/${item.Id}`, "_blank");
      return false;
    };
    _window.gridFilterGetter = () => {};
    _window.ordersGridFilterGetter = () => {};
    _window.usersGridFilterGetter = () => {};
    _window.applyGridOptions = () => {};
    _window.ordersApplyGridOptions = () => {};
    _window.usersApplyGridOptions = () => {};
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    _window.getOrderWithQuoteType = (OrderType: any, IsFinalised: boolean) => {
      if (!IsFinalised) {
        return "<span style='color: #005580; text-decoration: underline;'>Quote</span>";
      }
      return "<span>" + OrderType + "</span>";
    };
    this.DataSources = await this.$dataViews.GetSources();
    for (const ds of this.DataSources) ds.Name = getListTitle(ds.Name);

    this.GridFilterCategories =
      await this.$dataViews.LoadGridFilterCategories();

    // Change to an existing gridFilter
    if (this.pageMode == PageMode.Edit) {
      if (Array.isArray(this.$route.params.gridFilterId)) return;
      await this.loadGridFilter(parseInt(this.$route.params.gridFilterId));
    }
  },
  methods: {
    prepareSchedule() {
      const schedule = this.GridFilter.Schedule;
      const os = this.schedulingOptions.find(o => o.id == schedule?.Schedule);
      if (os) {
        this.schedule = os;
        // Time
        const els = schedule?.Time.split(":") ?? [];
        if (els && els.length == 2) {
          const h = parseInt(els[0]);
          const m = parseInt(els[1]);
          this.schedule.Time = new Date();
          this.schedule.Time.setUTCHours(h, m);
        }

        // Recipients
        this.schedule.Recipients = schedule?.Recipients.split(",") ?? [];

        //Weekday
        const weekDay = this.weekdayOptions.find(
          o => o.id == schedule?.WeekDay,
        );
        this.schedule.WeekDay = weekDay;
      }

      //MonthDay
      this.schedule.MonthDay = schedule?.MonthDay;
      this.schedule.IsEmailReport = schedule?.IsEmailReport ?? false;
      this.schedule.IsUiView = schedule?.IsUiView ?? true;
    },
    async loadGridFilter(id: number) {
      const gf = await this.$dataViews.GetGridFilterById(id);
      await this.loadGridFilterGraph(gf);
    },
    getOperatorSelected(
      param: FormDataViewParam,
      operator: FormDataViewFilterOperator,
    ) {
      const di = getParamDataItems(param);
      return di.find(o => o.id == operator) ?? di[0];
    },
    setMultiValue(e: any, pv: FormDataViewGridFilterParamValue) {
      const values = e.target.value;
      pv.ValueObj.length = 0;
      for (const value of values) pv.ValueObj.push(value);
    },
    async loadGridFilterGraph(gridFilter: FormDataViewGridFilter) {
      const dv = await this.$dataViews.GetDataViewByIdAndGridFilterId(
        gridFilter.DataViewId,
        gridFilter?.Id ?? 0,
      );

      for (const p of dv.Parameters) {
        const [defaultValue, defaultValueObj] = getDefaultValue(p);

        if (p.ParamValues.length > 0) {
          for (const pv of p.ParamValues) {
            pv.OperatorSelected = this.getOperatorSelected(p, pv.Operator);
            pv.ValueType = getParamValueType(pv, p);
            pv.ValueObj = [];
            let value: any = pv?.Value;
            const els = value?.split("|") ?? [];
            if (p.ParamType == FormDataViewParamType.KeyValueObject) {
              if (pv.ValueType == ParamValueType.Single) {
                const valueObj = this.getValueForValueObjectFromArray(
                  p.Name,
                  pv?.Value,
                  p.List,
                );
                pv.ValueObj.push(valueObj);
              } else if (pv.ValueType == ParamValueType.Multiple) {
                for (const el of els) {
                  const valueObj = this.getValueForValueObjectFromArray(
                    p.Name,
                    el,
                    p.List,
                  );
                  pv.ValueObj.push(valueObj);
                }
              }
            } else if (p.ParamType == FormDataViewParamType.DateTime) {
              const dtOpt = els[0];

              pv.DateTimeSingleOptionSelected = dateTimeSingleOptions.find(
                o => o.id == dtOpt,
              );
              if (pv.ValueType == ParamValueType.Single) {
                const now = new Date();
                now.setDate(now.getDate() - els[1]);
                value =
                  els[0] === "7"
                    ? now
                    : els[0] === "8"
                    ? new Date(els[1])
                    : new Date();
                pv.ValueObj.push(value);
                const diffTime = Math.abs(
                  new Date().valueOf() - new Date(els[1]).valueOf(),
                );
                pv.ValueInt =
                  els[0] === "7"
                    ? parseInt(els[1])
                    : els[0] === "8"
                    ? diffTime / (24 * 60 * 60 * 1000)
                    : null;
              } else if (pv.ValueType == ParamValueType.SingleOffset) {
                value = parseInt(els[1]);
                pv.ValueObj.push(value);
                const diffTime = Math.abs(
                  new Date().valueOf() - new Date(els[1]).valueOf(),
                );
                pv.ValueInt =
                  els[0] === "7"
                    ? parseInt(els[1])
                    : els[0] === "8"
                    ? diffTime / (24 * 60 * 60 * 1000)
                    : null;
              } else if (pv.ValueType == ParamValueType.Range) {
                pv.DateTimeRangeOptionSelected = dateTimeRangeOptions.find(
                  o => o.id == dtOpt,
                );
                const dates = els[1]?.split("_");
                if (pv.DateTimeRangeOptionSelected.id == 0) {
                  const [startMonth, startDay, startYear] = dates[0].split("-");
                  const [endMonth, endDay, endYear] = dates[1].split("-");
                  pv.ValueObj = {
                    start: new Date(+startYear, +startMonth - 1, +startDay),
                    end: new Date(+endYear, +endMonth - 1, +endDay),
                  };
                } else if (pv.DateTimeRangeOptionSelected.id == 1) {
                  const value = [parseInt(dates[0]), parseInt(dates[1])];
                  pv.ValueInt = value;
                }
              }
            } else if (p.ParamType == FormDataViewParamType.Integer) {
              if (pv.ValueType == ParamValueType.Single) {
                pv.ValueObj.push(parseInt(els[0]));
              } else if (pv.ValueType == ParamValueType.Range) {
                pv.ValueObj.push(parseInt(els[0]));
                pv.ValueObj.push(parseInt(els[1]));
              }
            } else if (p.ParamType == FormDataViewParamType.Real) {
              if (pv.ValueType == ParamValueType.Single) {
                pv.ValueObj.push(parseFloat(els[0]));
              } else if (pv.ValueType == ParamValueType.Range) {
                pv.ValueObj.push(parseFloat(els[0]));
                pv.ValueObj.push(parseFloat(els[1]));
              }
            } else if (p.ParamType == FormDataViewParamType.Boolean) {
              pv.ValueBoolean = els[0] === "True" || els[0] === "true";
            } else {
              pv.ValueObj.push(els[0]);
            }
          }
        }
      }

      await this.loadGrid(dv.DataSource?.SchemaUrl, gridFilter.IncludedColumns);
      gridFilter.DataView = dv;

      this.GridFilter = gridFilter;
      this.gridFields.forEach(f => this.getIconByState(f));
      this.prepareSchedule();
    },
    async setDataView(e: any) {
      const selected: any = e.target.value;
      const dv = await this.$dataViews.GetDataViewById(selected.DataViewId);

      this.GridFilter = makeFormDataViewGridFilter(dv);

      await this.loadGrid(selected.SchemaUrl, []);
    },
    async loadGrid(
      schemaUrl: string,
      includedColumns: FormDataViewIncludedColumns[],
    ): Promise<void> {
      await loadKendoGrid($, this.grid, schemaUrl);

      this.gridFields = $(".k-grid")
        .data("kendoGrid")
        .dataSource.options.fields.map((f: any) => {
          const selected =
            includedColumns.length == 0
              ? true
              : !!includedColumns.find(
                  o => o.FieldPath == f.headerAttributes["data-field"],
                );
          return {
            selected,
            fieldPath: f.headerAttributes["data-field"],
            title: f.headerAttributes["data-title"],
            state: 2,
            icon: "k-icon k-i-arrows-kpi",
          };
        })
        .filter((o: any) => o.fieldPath);
    },
    getParamDataItems(param: FormDataViewParam) {
      return getParamDataItems(param);
    },
    initParamValue(param: FormDataViewParam) {
      const pv: FormDataViewGridFilterParamValue = {
        Id: 0,
        GridFilterId: this.GridFilter?.Id ?? 0,
        ParamId: param.Id,
        Value: null,
        ValueObj: [],
        ValueInt: 0,
        ValueBoolean: false,
        Operator: FormDataViewFilterOperator.NoFilter,
        ValueType: ParamValueType.Empty,
        DateTimeSingleOptionSelected: {},
        DateTimeRangeOptionSelected: {},
      };
      if (!param.ParamValues) {
        param.ParamValues = [pv];
      } else {
        param.ParamValues.push(pv);
      }
    },
    initDateRangeValue(param: FormDataViewParam): void {
      if (
        (param.ParamType === 4 || param.ParamType === 5) &&
        param.ParamValues[0].DateTimeRangeOptionSelected == null
      ) {
        param.ParamValues[0].DateTimeRangeOptionSelected =
          dateTimeRangeOptions[0];
      }
      if (param.ParamType === 4 || param.ParamType === 5) {
        param.ParamValues.forEach(value => {
          if (value.ValueType === 2) {
            if (Array.isArray(value.ValueObj) || !value.ValueObj) {
              const start = new Date();
              const end = new Date();

              start.setDate(end.getDate() - 5);
              value.ValueObj = {
                start: start,
                end: end,
              };
              return;
            }
            if (typeof value.ValueObj === "object") {
              return;
            }
            value.ValueObj = value.ValueObj.toString().replace("//", "");
            const object = JSON.parse(value.ValueObj);
            value.ValueObj = {
              start: new Date(object.start),
              end: new Date(object.end),
            };
          }
        });
      }
    },
    getParamValue(param: FormDataViewParam) {
      if (!param.ParamValues[0]) {
        this.initParamValue(param);
      }
      this.initDateRangeValue(param);
      return param.ParamValues[0];
    },
    setParamValueType(e: any, param: FormDataViewParam) {
      const selected: any = e.target.value;
      const pv = this.getParamValue(param);
      pv.Operator = selected.id;
      pv.ValueType = getParamValueType(pv, param);
      if (param.ParamType == FormDataViewParamType.Integer) {
        pv.Format = "n0";
      } else if (param.ParamType == FormDataViewParamType.Real) {
        pv.Format = "n";
      }
      if (pv.ValueType == ParamValueType.Multiple) {
        pv.ValueObj = [];
      } else if (
        !pv.ValueObj &&
        param.ParamType !== FormDataViewParamType.Boolean
      ) {
        pv.ValueObj = [null, null];
      }
    },
    getListTitle(name: string | null | undefined) {
      return getListTitle(name);
    },
    setState(fieldIdx: number) {
      const newVal: any = (this.gridFields[fieldIdx].state + 1) % 3;
      this.gridFields[fieldIdx].state = newVal;
      const newIcon =
        newVal == 0
          ? "k-i-arrow-60-up"
          : newVal == 1
          ? "k-i-arrow-60-down"
          : "k-i-arrows-kpi";

      this.gridFields[fieldIdx].icon = `k-icon ${newIcon}`;
    },
    selectAll() {
      for (const item of this.gridFields) item.selected = true;
    },
    deselectAll() {
      for (const item of this.gridFields) item.selected = false;
    },
    invert() {
      for (const item of this.gridFields) item.selected = !item.selected;
    },
    validateRecipient(e: any) {
      const values = e.target.value;
      const lastItem = values[values.length - 1];
      if (lastItem) {
        if (lastItem.indexOf("@") < 0 && Array.isArray(values)) {
          this.PopupNotificationWidget?.show(
            `"${lastItem}" This is not  valid Email`,
            "error",
          );

          values.pop();
          e.target.value = values;
          return false;
        }
        const els = lastItem.split("@");
        if (els && els[1] != "theprintspace.co.uk") {
          this.PopupNotificationWidget?.show(
            `"${lastItem}" is outside the organisation`,
            "warning",
          );
        }
      }
    },
    setParamValues() {
      var pvs =
        this.GridFilter.DataView?.Parameters.map(o => ({
          type: o.ParamType,
          paramName: o.Name,
          paramValue: o.ParamValues[0],
        })) ?? [];

      for (const pv of pvs) {
        if (pv.paramValue.Operator == FormDataViewFilterOperator.NoFilter)
          continue;
        switch (pv.type) {
          case FormDataViewParamType.KeyValueObject:
            switch (pv.paramValue.ValueType) {
              case ParamValueType.Multiple:
                pv.paramValue.Value = pv.paramValue.ValueObj.map((value: any) =>
                  this.getValueForValueObject(pv.paramName, value),
                ).join("|");
                break;
              default:
                pv.paramValue.Value = this.getValueForValueObject(
                  pv.paramName,
                  pv.paramValue.ValueObj[0],
                );
            }

            break;
          case FormDataViewParamType.DateTime:
            switch (pv.paramValue.ValueType) {
              case ParamValueType.Single:
                if (pv.paramValue.DateTimeSingleOptionSelected.id == 8)
                  pv.paramValue.Value = `8|${pv.paramValue.ValueObj[0].toISOString()}`;
                else if (pv.paramValue.DateTimeSingleOptionSelected.id == 7)
                  pv.paramValue.Value = `7|${pv.paramValue.ValueInt}`;
                else
                  pv.paramValue.Value = `${pv.paramValue.DateTimeSingleOptionSelected.id}`;
                break;
              case ParamValueType.Range:
                if (pv.paramValue.DateTimeRangeOptionSelected.id == 0) {
                  pv.paramValue.Value = `0|${formatDate(
                    pv.paramValue.ValueObj.start,
                    "MM-dd-yyyy",
                  )}_${formatDate(pv.paramValue.ValueObj.end, "MM-dd-yyyy")}`;
                } else if (pv.paramValue.DateTimeRangeOptionSelected.id == 1) {
                  pv.paramValue.Value = `1|${pv.paramValue.ValueInt[0]}_${pv.paramValue.ValueInt[1]}`;
                }
                break;
            }
            break;
          case FormDataViewParamType.Integer:
          case FormDataViewParamType.Real:
            switch (pv.paramValue.ValueType) {
              case ParamValueType.Range:
                pv.paramValue.Value = pv.paramValue.ValueObj.map(
                  (o: any) => `${o}`,
                ).join("|");
                break;
              default:
                pv.paramValue.Value = `${pv.paramValue.ValueObj[0]}`;
            }
            break;
          case FormDataViewParamType.Boolean:
            pv.paramValue.Value = `${pv.paramValue.ValueBoolean}`;
            break;
          default:
            pv.paramValue.Value = pv.paramValue.ValueObj[0];
        }
      }
      this.GridFilter.ParamValues = pvs.map(o => o.paramValue) ?? [];
    },
    setColumns() {
      this.GridFilter.IncludedColumns =
        this.gridFields
          .filter((o: any) => o.selected)
          .map(
            (o: any, idx: number) =>
              ({
                GridFilterId: this.GridFilter.Id,
                Position: idx,
                FieldPath: o.fieldPath,
              } as FormDataViewIncludedColumns),
          ) ?? [];
    },
    setSorts(): void {
      this.GridFilter.Sorts =
        this.gridFields
          .filter((o: any) => o.selected && o.state < 2)
          .map(
            (o: any) =>
              ({
                GridFilterId: this.GridFilter.Id,
                FormDataViewId: this.GridFilter.DataViewId,
                FieldPath: o.fieldPath,
                Direction: o.state,
              } as FormDataViewSortDescriptor),
          ) ?? [];
    },
    async setSchedule() {
      const scheduleId = this.GridFilter.ScheduleId;
      if (this.schedule && this.schedule.id != FormDataViewScheduleKind.Never) {
        const time = `${this.schedule.Time.getUTCHours().toLocaleString(
          "en-GB",
          {
            minimumIntegerDigits: 2,
            useGrouping: false,
          },
        )}:${this.schedule.Time.getUTCMinutes().toLocaleString("en-GB", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`;
        const weekDay =
          this.schedule.id == FormDataViewScheduleKind.Weekly
            ? this.schedule.WeekDay?.id
            : null;
        const monthDay =
          this.schedule.id == FormDataViewScheduleKind.Monthly
            ? this.schedule.MonthDay
            : null;
        const recipients: any = this.schedule.Recipients.join(",");
        const gfSchedule: FormDataViewSchedule = {
          Id: scheduleId ?? 0,
          Schedule: this.schedule.id,
          Time: time,
          WeekDay: weekDay,
          MonthDay: monthDay,
          Recipients: recipients,
          IsUiView: this.schedule.IsUiView,
          IsEmailReport: this.schedule.IsEmailReport,
        };
        const response = await this.$dataViews.UpsertSchedule(gfSchedule);

        this.GridFilter.ScheduleId = response.Id;
        this.GridFilter.Schedule = response;
      } else {
        this.GridFilter.ScheduleId = undefined;
        this.GridFilter.Schedule = undefined;
      }
    },
    async saveGridFilter() {
      try {
        if (!this.GridFilter) throw "No grid filter defined";
        if (!this.GridFilter?.DataView) throw "No grid filter view defined";
        if (!validateGridFilter(this.GridFilter)) {
          this.PopupNotificationWidget?.show(
            "Please fill all the required fields",
            "error",
          );
          return;
        }
        this.GridFilter.FormDataGridFilterCategoryId =
          this.GridFilter.FormDataGridFilterCategory?.Id;
        // if (noFilterSpecified(this.GridFilter.DataView)) {
        //   this.PopupNotificationWidget?.show(
        //     "Please select at least one filter",
        //     "error",
        //   );
        //   return;
        // }

        this.setParamValues();
        this.setColumns();
        this.setSorts();
        await this.setSchedule();
        const type = getGridFilterType(
          this.GridFilter?.DataView?.DataSource?.Name,
        );
        const nameFilter: string | null = ku.getOrderStateKey(type);
        sessionStorage.clear();
        $.post(`/form/grid/DeleteFiltersByName`, {
          name: nameFilter,
          clearAll: true,
        });
        const gf = await this.$dataViews.UpsertGridFilters(this.GridFilter);
        if (this.pageMode == PageMode.New) {
          this.$router.push(`/grid-filters/filter/${gf.Id}`);
        }
        await this.loadGridFilterGraph(gf);

        this.PopupNotificationWidget?.show(
          "Filter saved successfully",
          "success",
        );
      } catch (e: any) {
        console.log(e);
        this.PopupNotificationWidget?.show(
          "Error while saving the filter",
          "error",
        );
        return;
      }
    },
    getIconByState(field: any): void {
      const sortItem = this.GridFilter.Sorts.find(
        sort => sort.FieldPath == field.fieldPath,
      );
      field.state =
        sortItem?.Direction === FormDataViewSortDirection.Descending
          ? 1
          : sortItem?.Direction === FormDataViewSortDirection.Ascending
          ? 0
          : 2;
      const newIcon =
        field.state === 1
          ? "k-i-arrow-60-down"
          : field.state === 0
          ? "k-i-arrow-60-up"
          : "k-i-arrows-kpi";
      field.icon = `k-icon ${newIcon}`;
    },
    getValueForValueObject(paramName: string, model: any): string | null {
      if (!model) {
        return null;
      }
      return this.isParametrWithMainValue(paramName) ? model.Value : model.Key;
    },
    getValueForValueObjectFromArray(
      paramName: string,
      value: string | null,
      list: any[],
    ): string {
      return this.isParametrWithMainValue(paramName)
        ? list.find(o => o.Value == value)
        : list.find(o => o.Key == value);
    },
    isParametrWithMainValue(paramName: string): boolean {
      return paramName == "OrderType" || paramName == "DeliveryOptionName";
    },
    onRangeOptionsChange(event: any, param: FormDataViewParam) {
      const value = event.target.value;
      this.setDateTimeRangeParams(value, param);
    },

    setDateTimeRangeParams(option: any, param: FormDataViewParam): void {
      const now = new Date();
      if (option.id == 0) {
        const start = new Date();
        const end = new Date();

        start.setDate(now.getDate() + param.ParamValues[0].ValueInt[0]);
        end.setDate(now.getDate() + param.ParamValues[0].ValueInt[1]);
        param.ParamValues[0].ValueObj = {
          start: start,
          end: end,
        };
      }
      if (option.id == 1) {
        const date = param.ParamValues[0].ValueObj;
        param.ParamValues[0].ValueInt = [
          dateDiffInDays(now, date.start),
          dateDiffInDays(now, date.end),
        ];
      }
    },
  },
});
