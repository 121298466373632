//-----------------------------------------------------------------------------
// typings

import * as Vuex from "vuex";
import { IVuex, IPlugins } from "@/core/types/vue.this";

export type NGetter<S, T> = (
  state: S,
  getters: any,
  rootState: RootState,
  rootGetters: any,
) => T;

export type NMutation<S, T> = (
  this: Vuex.Store<RootState>,
  state: S,
  payload: T,
  options?: Vuex.CommitOptions,
) => void;
export type NCommit<T> = (payload: T, options?: Vuex.CommitOptions) => void;

export interface NActionContext<S> {
  dispatch: Vuex.Dispatch;
  commit: Vuex.Commit;
  state: S;
  getters: any;
  rootState: RootState;
  rootGetters: any;
}
export type NActionHandler<S, P, T> = (
  this: Vuex.Store<RootState> & IVuex & IPlugins,
  injectee: NActionContext<S>,
  payload: P,
) => T;
export interface NActionObject<S, P, T> {
  root?: boolean;
  handler: NActionHandler<S, P, T>;
}

export type NAction<S, P, T> = NActionHandler<S, P, T> | NActionObject<S, P, T>;
export type NDispatchPromise<T> = T extends Promise<infer R>
  ? Promise<R>
  : Promise<T>;
export type NDispatch<P, T> = (payload: P) => NDispatchPromise<T>;

export function makeGetters<Getters>(
  store: Vuex.Store<RootState>,
  module: string,
  g: Getters,
) {
  const o: any = {};
  for (const p in g) {
    Object.defineProperty(o, p, { get: () => store.getters[`${module}/${p}`] });
  }
  return o;
}

export function makeCommits<Mutations>(
  store: Vuex.Store<RootState>,
  module: string,
  m: Mutations,
) {
  const o: any = {};
  for (const p in m) {
    o[p] = (payload?: any, options?: any) =>
      store.commit(`${module}/${p}`, payload, options);
  }
  return o;
}

export function makeDispatches<Actions>(
  store: Vuex.Store<RootState>,
  module: string,
  a: Actions,
) {
  const o: any = {};
  for (const p in a) {
    o[p] = (payload?: any) => store.dispatch(`${module}/${p}`, payload);
  }
  return o;
}

//-----------------------------------------------------------------------------
// this store

import * as Alert from "tps.shared.ui/store/alert";
import * as FileUpload from "tps.shared.ui/store/fileUpload";
import * as Global from "@/store/global";

export type RootState = {
  fileUpload: FileUpload.State;
  global: Global.State;
};

export default (ctx: any, inject: any) => {
  //-----------------------------------------------------------------------------
  // fix vue dev tools

  //Vue.prototype.$dispatch = () => { };

  //-----------------------------------------------------------------------------
  // plugins

  inject("state", ctx.store.state);
  inject("getters", {
    alert: Alert.fnMakeGetters(ctx.store),
    fileUpload: FileUpload.fnMakeGetters(ctx.store),
    global: Global.fnMakeGetters(ctx.store),
  });
  inject("commit", {
    alert: Alert.fnMakeCommits(ctx.store),
    fileUpload: FileUpload.fnMakeCommits(ctx.store),
    global: Global.fnMakeCommits(ctx.store),
  });
  inject("dispatch", {
    alert: Alert.fnMakeDispatches(ctx.store),
    fileUpload: FileUpload.fnMakeDispatches(ctx.store),
    global: Global.fnMakeDispatches(ctx.store),
  });
};

//-----------------------------------------------------------------------------
