import * as utils  from "./utils" 
import { Order, OrderItem, SpecialOrderType, makeOrderOf } from "./order";

export type SummaryItemType = "PRINT-ITEM" | "BRANDING-ITEM";
export type SummaryItemName =
  | "PRINT"
  | "FRAME"
  | "FRAME (ASF)"
  | "MOUNT"
  | "CANVAS (STRETCHED)"
  | "CANVAS (ROLLED)"
  | "INSERT"
  | "COA"
  | "STICKER"
  | "UNKNOWN";

export type SummaryItem = {
  type: SummaryItemType;
  style: string;
  name: SummaryItemName;
  quantity: number;
  heightCm: number;
  widthCm: number;
  heightIn: number;
  widthIn: number;
};

export type SummaryGroup = {
  items: SummaryItem[];
  total: number;
};

export type Summary = {
  printGroup: SummaryGroup;
  brandingGroup: SummaryGroup;
};

export function makeSummaryOf(order: any): Summary{
  const o: Order = makeOrderOf(order);

  return makeSummary(mapOrderItems(o.Items.map(oi => oi) || []));
}

function makeSummaryGroup(items: SummaryItem[]): SummaryGroup {
  const total = items.reduce((result, current) => result + current.quantity, 0);
  return {
    items,
    total,
  };
}

function makeSummary(items: SummaryItem[]): Summary {
  const result = utils.sumIf(items, itemsAreEqual, "quantity");

  return {
    printGroup: makeSummaryGroup(result.filter(o => o.type == "PRINT-ITEM")),
    brandingGroup: makeSummaryGroup(
      result.filter(o => o.type == "BRANDING-ITEM"),
    ),
  };
}

function makeSummaryItem(
  type: SummaryItemType,
  name: SummaryItemName,
  quantity = 0,
  heightCm = 0,
  widthCm = 0,
  heightIn = 0,
  widthIn = 0,
) {
  const style =
    name == "PRINT"
      ? "print"
      : name == "FRAME"
      ? "frame"
      : name == "FRAME (ASF)"
      ? "frame-asf"
      : name == "MOUNT"
      ? "mount"
      : name == "CANVAS (STRETCHED)"
      ? "canvas-stretched"
      : name == "CANVAS (ROLLED)"
      ? "canvas-rolled"
      : "";

  return {
    type,
    style,
    name,
    quantity,
    heightCm,
    widthCm,
    heightIn,
    widthIn,
  };
}



function isBrandingItem(orderItem: OrderItem): boolean {
  const bisot = [
    SpecialOrderType.Insert,
    SpecialOrderType.CustomCOA,
    SpecialOrderType.CustomPackaging,
  ];

  return (
    orderItem.SpecialOrderTypeId != undefined &&
    orderItem.SpecialOrderTypeId != null &&
    bisot.includes(orderItem.SpecialOrderTypeId)
  );
}

function summaryItemTypeOf(orderItem: OrderItem): SummaryItemType {
  return isBrandingItem(orderItem) ? "BRANDING-ITEM" : "PRINT-ITEM";
}

function summaryItemNameOf(orderItem: OrderItem): SummaryItemName {

  const brandingNameOf = (orderItem: OrderItem): SummaryItemName => {
    if (orderItem.SpecialOrderTypeId == SpecialOrderType.Insert)
      return "INSERT";
    if (orderItem.SpecialOrderTypeId == SpecialOrderType.CustomCOA)
      return "COA";

    return "STICKER";
  };

  const printNameOf = (orderItem: OrderItem): SummaryItemName => {
    
    if (orderItem.SpecialOrderTypeId == null) {
      if (orderItem.PrintType == "Canvas" && orderItem.Finishing == "None") return "CANVAS (ROLLED)";
      if (orderItem.PrintType == "Canvas" && orderItem.Finishing == "Framing") return "CANVAS (STRETCHED)";
      if (orderItem.Finishing == "None") return "PRINT";
      if (orderItem.Finishing == "Mounting") return "MOUNT";
      if (
        orderItem.Finishing == "Framing" &&
        orderItem.OrderTypeSource != "ecommerce"
      )
        return "FRAME";
      if (
        orderItem.Finishing == "Framing" &&
        orderItem.OrderTypeSource == "ecommerce"
      )
        return "FRAME (ASF)";
      
      return "UNKNOWN";
    } else {
      if (
        orderItem.SpecialOrderTypeId == SpecialOrderType.Prints ||
        orderItem.SpecialOrderTypeId == SpecialOrderType.FreeTextPrints
      )
        return "PRINT";

      if (
        orderItem.SpecialOrderTypeId == SpecialOrderType.Frame ||
        orderItem.SpecialOrderTypeId == SpecialOrderType.FreeTextFrame
      )
        return "FRAME";

      if (
        orderItem.SpecialOrderTypeId == SpecialOrderType.Mounting ||
        orderItem.SpecialOrderTypeId == SpecialOrderType.FreeTextMounting
      )
        return "MOUNT";
    }

    return "UNKNOWN";
  };

  return isBrandingItem(orderItem)
    ? brandingNameOf(orderItem)
    : printNameOf(orderItem);
}

function quantityOf(orderItem: OrderItem): number {
  return orderItem.Quantity;
}

function heightCmOf(orderItem: OrderItem): number {
  return utils.roundToNearestN(orderItem.PrintHeightMM / 10, 5, 0);
}

function widthCmOf(orderItem: OrderItem): number {
  return utils.roundToNearestN(orderItem.PrintWidthMM / 10, 5, 0);
}

function heightInOf(orderItem: OrderItem): number {
  return utils.roundToNearestN(heightCmOf(orderItem) / 2.54);
}

function widthInOf(orderItem: OrderItem): number {
  return utils.roundToNearestN(widthCmOf(orderItem) / 2.54);
}

function summaryItemOf(orderItem: OrderItem) {
  const type = summaryItemTypeOf(orderItem);
  const name = summaryItemNameOf(orderItem);
  const quantity = quantityOf(orderItem);
  const heightCm = heightCmOf(orderItem);
  const widthCm = widthCmOf(orderItem);
  const heightIn = heightInOf(orderItem);
  const widthIn = widthInOf(orderItem);

  return makeSummaryItem(
    type,
    name,
    quantity,
    heightCm,
    widthCm,
    heightIn,
    widthIn,
  );
}

function mapOrderItems(orderItems: OrderItem[]): SummaryItem[] {
  const baseSummaryItems = orderItems?.map(summaryItemOf);
  const freeCoas = orderItems
    .filter(oi => oi.CoaType == "Classic")
    .map(oi => makeSummaryItem("BRANDING-ITEM", "COA", oi.Quantity));

  return [...baseSummaryItems, ...freeCoas];
}

function itemsAreEqual(item1: SummaryItem, item2: SummaryItem) {
  return (
    item1.type == item2.type &&
    item1.name == item2.name &&
    item1.heightCm == item2.heightCm &&
    item1.widthCm == item2.widthCm &&
    item1.heightIn == item2.heightIn &&
    item1.widthIn == item2.widthIn
  );
}
