
import { defineComponent } from "vue";
import { ClientManagerType } from "./typesAndConstants";
import ClientManagerCommissions from "./ClientManagersCommission.vue";

export default defineComponent({
  name: "AcccountHandlerCommissions",
  components: {
    ClientManagerCommissions,
  },
  data() {
    return {
      ClientManagerType: ClientManagerType.AccountHandler,
      key: "ClientManagerType-AccountHandler",
    };
  },
});
