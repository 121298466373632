import { DataFrame } from "./DataFrame";

export enum ProductivityDataAggregate {
  Daily,
  Monthly,
  Total,
}

// ----------------------------------------------------------------------------

export enum ProductivityLevel {
  Summary,
  General,
  Magnum,
  Consolidated,
  NonProduction,
}

// ----------------------------------------------------------------------------

export enum ProductivityActivity {
  All,
  Printing,
  Dispatch,
}

// ----------------------------------------------------------------------------

export enum ProductivityType {
  Items,
  PeopleHours,
  ItemsPerPeopleHours,
}

// ----------------------------------------------------------------------------

export type ProductivityResponse = {
  Items: DataFrame;
  PeopleHours: DataFrame;
  ItemsPerPeopleHours: DataFrame;
};

// ----------------------------------------------------------------------------

export type ProductivityDataItem = {
  OrderId: number;
  PrintDate: Date;
  DispatchDate: Date;
  PrintBranch: string;
  DispatchBranch: string;
  OwnerEmail: string;
  IsPrintOnly: boolean;
  PrintedQuantity: number;
};

// ----------------------------------------------------------------------------

export type DateRange = {
  start: Date;
  end: Date;
};

// ----------------------------------------------------------------------------

export const makeRange = (start: Date, end: Date) => {
  return { start, end };
};

// ----------------------------------------------------------------------------

export const makeRangeDefault = () => {
  const end = new Date();
  end.setDate(end.getDate() - 1);
  const start = new Date(end.getFullYear(), end.getMonth(), end.getDate() - 7);

  return makeRange(start, end);
};

// ----------------------------------------------------------------------------

const itemsDataTableTitles = [
  "Print & Dispatched Totals",
  "Printed Items",
  "Labour Units per Order Type",
  "United Kingdom (GB)",
];

// ----------------------------------------------------------------------------

const peopleHoursDataTableTitles = [
  "People Hours",
  "People Hours In Printing",
  "People Hours In Dispatch",
  "Germany (DE)",
];

// ----------------------------------------------------------------------------

const itemsPerPeopleHoursDataTableTitles = [
  "Productivity Per Hour",
  "Printed Items Per People Per Hour",
  "Labour Units Per People Per Hour",
  "United States of America (US)",
];

// ----------------------------------------------------------------------------

export const dataTableTitles = (
  level: ProductivityLevel,
  activity: ProductivityActivity,
) => {
  let itemsDataTableTitle = "";
  let peopleHoursDataTableTitle = "";
  let itemsPerPeopleHoursDataTableTitle = "";

  if (level == ProductivityLevel.Summary) {
    itemsDataTableTitle = itemsDataTableTitles[0];
    peopleHoursDataTableTitle = peopleHoursDataTableTitles[0];
    itemsPerPeopleHoursDataTableTitle = itemsPerPeopleHoursDataTableTitles[0];
  } else if (level == ProductivityLevel.NonProduction) {
    itemsDataTableTitle = itemsDataTableTitles[3];
    peopleHoursDataTableTitle = peopleHoursDataTableTitles[3];
    itemsPerPeopleHoursDataTableTitle = itemsPerPeopleHoursDataTableTitles[3];
  } else if (activity == ProductivityActivity.Printing) {
    itemsDataTableTitle = itemsDataTableTitles[1];
    peopleHoursDataTableTitle = peopleHoursDataTableTitles[1];
    itemsPerPeopleHoursDataTableTitle = itemsPerPeopleHoursDataTableTitles[1];
  } else if (activity == ProductivityActivity.Dispatch) {
    itemsDataTableTitle = itemsDataTableTitles[2];
    peopleHoursDataTableTitle = peopleHoursDataTableTitles[2];
    itemsPerPeopleHoursDataTableTitle = itemsPerPeopleHoursDataTableTitles[2];
  }

  return [
    itemsDataTableTitle,
    peopleHoursDataTableTitle,
    itemsPerPeopleHoursDataTableTitle,
  ];
};
