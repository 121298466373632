import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a31eb48e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTabStripTab = _resolveComponent("KTabStripTab")!
  const _component_KTabStrip = _resolveComponent("KTabStrip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_KTabStrip, {
      selected: _ctx.selectedTab,
      onSelect: _ctx.onSelectedTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
          return (_openBlock(), _createBlock(_component_KTabStripTab, {
            title: tab.title,
            key: tab.id
          }, {
            default: _withCtx(() => [
              (_ctx.selectedTab === tab.id)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["selected", "onSelect"])
  ], 64))
}