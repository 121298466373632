
import { defineComponent, ref, Ref } from "vue";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
declare const $: any;

export default defineComponent({
  components: {
    KNotification: Notification,
  },
  setup() {
    const PopupNotification: Ref<Notification | null> = ref(null);
    return {
      PopupNotification,
    };
  },
  data() {
    const wdgt: any = {};
    const setupPaper = false;

    const model = ref({
      Id: null,
      Email: "",
      UserId: null,
      PaperId: null,
      Ecommerce: false,
      Print: false,
    });

    return {
      PopupNotificationWidget: wdgt,
      model,
      setupPaper,
      selectedPaper: null,
      users: [] as any[],
    };
  },
  computed: {
    papers(): Array<any> {
      return this.$state.global.papers;
    },
    invalidInput(): any {
      return (
        (this.model.PaperId == null || this.model.Email == null) &&
        (!this.model.Ecommerce || !this.model.Print)
      );
    },
  },
  async mounted() {
    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (this.$state.global.papers.length == 0)
      await this.$dispatch.global.loadPapers(null);
    if (this.$route.params.id) {
      await this.load();
    } else {
      Object.assign(this.model, this.newModel());
    }
  },
  methods: {
    newModel() {
      return {
        Id: null,
        Email: "",
        UserId: null,
        PaperId: null,
        Ecommerce: false,
        Print: false,
      };
    },
    async load() {
      await this.loadById(Number(this.$route.params.id));
    },
    async loadById(id: number) {
      const result: any = await $.get(`/form/grid/USPrintRule?id=${id}`);
      Object.assign(this.model, this.newModel());
      Object.assign(this.model, result);
    },
    async save() {
      try {
        const { Id } = await $.ajax({
          type: "POST",
          url: "/form/grid/SaveUsPrintRule",
          data: JSON.stringify(this.model),
          dataType: "json",
          contentType: "application/json",
        });
        await this.loadById(Id);
        if (this.$route.params.id) {
          this.PopupNotificationWidget?.show(
            "Rule successfully updated",
            "success",
          );
        } else {
          this.PopupNotificationWidget?.show(
            "Rule successfully saved",
            "success",
          );
        }

        this.$router.push(`/printing-rules/us-print-rule/${Id}`);
      } catch (e: any) {
        this.PopupNotificationWidget?.show(
          `Something went wrong with saving the Rule`,
          "error",
        );
      }
    },
    async search() {
      this.users = await $.ajax({
        type: "POST",
        url: "/form/grid/EmailSearch",
        data: JSON.stringify({ email: this.model.Email }),
        dataType: "json",
        contentType: "application/json",
      });
    },
    selectUser(user: any) {
      this.model.UserId = user.Id;
      this.model.Email = user.Email;
      this.users = [];
    },
  },
});
