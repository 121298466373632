
import { defineComponent, ref } from "vue";
declare var $: any;
export default defineComponent({
  name: "PromoCodesList",
  setup() {
    const grid: any = ref(null);
    return {
      grid,
    };
  },
  async mounted() {
    const _w = window as any;
    if (!_w.promocode_select) {
      _w.promocode_select = function (e: any) {
        const id = this.dataItem(this.select()).Id;
        _w.open(`/promotional-code/${id}`, "_blank");
      };
    }
    const grid = this.grid;
    $.get("/form/grid/promoCodesPartial", {}, (html: string) => {
      html = html.replace(/\/grid\//gi, "/form$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
});
