import { InjectionKey } from "vue";
import {
  Store,
  createStore as vuexCreateStore,
  useStore as vuexUseStore,
} from "vuex";
import { RootState } from "@/plugins/nvuex";
import * as Alert from "tps.shared.ui/store/alert";
import * as FileUpload from "tps.shared.ui/store/fileUpload";
import * as Global from "@/store/global";

function s(store: any) {
  return {
    namespaced: true,
    state: store.state,
    getters: store.getters,
    mutations: store.mutations,
    actions: store.actions,
  };
}

const state = () => ({});

export type State = ReturnType<typeof state>;

export const key: InjectionKey<Store<RootState>> = Symbol();

export function useStore(): Store<RootState> {
  return vuexUseStore(key);
}

export default function createStore(): Store<RootState> {
  return vuexCreateStore({
    modules: {
      test: s({
        state,
        getters: {},
        actions: {},
        mutations: {},
      }),
      alert: s(Alert),
      fileUpload: s(FileUpload),
      global: s(Global),
    },
  });
}
