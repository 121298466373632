
export type Column = {
  Name: string;
  Label: string;
  Format: string;
  Values: number[];
};

// ----------------------------------------------------------------------------

export type DataFrame = {
  Title: string;
  WithRowAggregate: boolean;
  WithColAggregate: boolean;
  Columns: Column[];
  RowLabels: string[];
};

// ----------------------------------------------------------------------------

export const emptyDataFrame = () => {
  return {
    Title: "",
    WithRowAggregate: false,
    WithColAggregate: false,
    Columns: [],
    RowLabels: [],
  };
};

// ----------------------------------------------------------------------------
