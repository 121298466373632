
import { OrderState } from "@/core/enums/orderState";
import { OrderActionType } from "@/core/enums/orderActionType";
import {
  GetReasonRefundRedoReasonTypesViewModel,
  GetRefundRedoReasonTypesViewModel,
} from "@/core/types/refund/getRefundRedoReasonTypesViewModel";
import { defineComponent, Ref, ref } from "vue";
import { Notification } from "@progress/kendo-popups-vue-wrapper";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import GenericMessageModal from "@/components/Modal/GenericMessageModal.vue";
import DispatchQCModalVue from "@/components/Modal/DispatchQC/Modal.vue";
import OrderHistoryModalVue from "@/components/Modal/OrderHistoryModal.vue";
import GenericModalResponse from "@/core/types/genericModalResponse";
import {
  GetQCUserListViewModel,
  UserGetQCUserListViewModel,
} from "@/core/types/user/getQCUserListViewModel";
import { DateRangePicker } from "@progress/kendo-vue-dateinputs";
import { formatDate } from "@telerik/kendo-intl";
import { Button } from "@progress/kendo-vue-buttons";
import { Checkbox, Input } from "@progress/kendo-vue-inputs";
import { Window } from "@progress/kendo-vue-dialogs";
import PreventUnpaidStatusSelectModalVue from "@/components/Modal/PreventUnpaidStatusSelectModal.vue";
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import { getUtcDate } from "@/core/utils";
import { useStore } from "@/store";
import { Router } from "vue-router";
import OrderSubHeader from "@/components/Order/OrderSubHeader.vue";
import { Note, OrderNoteType } from "@/core/types/note";
import NotesList from "@/components/NotesList.vue";
import { OrderStatus, OrderStatuses } from "@/core/static-data/orderStatuses";
import { RelayStatus } from "@/core/enums/relayStatus";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import OrderNftsModal from "@/components/Modal/OrderNftsModal.vue";

import { orderStatuses } from "@/core/orderStates";

declare var $: any;
const types: { [key: string]: string } = {
  "2": "Minimalist",
  "3": "Clean",
  "4": "Typewriter",
  "5": "Traditional",
  "6": "Modern",
};

const USATypes: { [key: number]: string } = {
  0: "None",
  1: "UK Printed",
  2: "US Printed",
};

type ContractInsuranceType = "CIF" | "FOB";

enum DeliveryOptionId {
  CollectInStudioUk = 1,
  RoyalMailRecorded = 2,
  RoyalMailEurope = 3,
  RoyalMailInternational = 4,
  ExpressCourierUk = 5,
  InternationalCourierUk = 6,
  SameDayCourierUk = 8,
  RoyalMailDropshipping = 9,
  OvernightCourierAMUk = 15,
  OvernightCourierSaturdayUk = 16,

  CollectInStudioDe = 13,
  StandardPostDeutschePost = 17,
  EuropeanCourier = 14,
  StandardPostInternational = 18,
  DomesticCourier = 19,
  InternationalCourierDe = 22,
  DomesticCourierAMDe = 20,
  GermanDropshipping = 11,
  DomesticCourierSaturdayDe = 21,
}

type DeliveryTypeViewModel = {
  Id: number;
  BranchId: number;
  Name: string;
  Keyword: string;
  Abbreviation: string;
  InHouseOnly: boolean;
  ShowInApp: boolean;
  ShowInApi: boolean;
};

function crossBranchDeliveryOptionMap(
  deliveryOptionId: number,
): DeliveryOptionId | undefined {
  const keys = [
    null, // 0
    "CollectInStudioUk", // 1
    "RoyalMailRecorded", // 2
    "RoyalMailEurope", // 3
    "RoyalMailInternational", // 4
    "ExpressCourierUk", // 5
    "InternationalCourierUk", // 6
    null, // 7
    "SameDayCourierUk", // 8
    "RoyalMailDropshipping", // 9
    null, // 10
    "GermanDropshipping", // 11
    null, // 12
    "CollectInStudioDe", // 13
    "EuropeanCourier", // 14
    "OvernightCourierAMUk", //15
    "OvernightCourierSaturdayUk", //16
    "StandardPostDeutschePost", // 17
    "StandardPostInternational", // 18
    "DomesticCourier", // 19
    "DomesticCourierAMDe", // 20
    "DomesticCourierSaturdayDe", // 21
    "InternationalCourierDe", // 22
  ];

  const map = new Map(
    Object.entries({
      // UK => DE
      CollectInStudioUk: DeliveryOptionId.CollectInStudioDe,
      RoyalMailRecorded: DeliveryOptionId.StandardPostDeutschePost,
      RoyalMailEurope: DeliveryOptionId.StandardPostInternational,
      RoyalMailInternational: DeliveryOptionId.StandardPostInternational,
      ExpressCourierUk: DeliveryOptionId.DomesticCourier,
      InternationalCourierUk: DeliveryOptionId.InternationalCourierDe,
      SameDayCourierUk: DeliveryOptionId.DomesticCourierAMDe,
      RoyalMailDropshipping: DeliveryOptionId.GermanDropshipping,
      OvernightCourierAMUk: DeliveryOptionId.DomesticCourierAMDe,
      OvernightCourierSaturdayUk: DeliveryOptionId.DomesticCourierSaturdayDe,

      // DE => UK
      CollectInStudioDe: DeliveryOptionId.CollectInStudioUk,
      StandardPostDeutschePost: DeliveryOptionId.RoyalMailRecorded,
      StandardPostInternational: DeliveryOptionId.RoyalMailInternational,
      DomesticCourier: DeliveryOptionId.ExpressCourierUk,
      InternationalCourierDe: DeliveryOptionId.InternationalCourierUk,
      DomesticCourierAMDe: DeliveryOptionId.SameDayCourierUk,
      GermanDropshipping: DeliveryOptionId.RoyalMailDropshipping,
      DomesticCourierSaturdayDe: DeliveryOptionId.OvernightCourierSaturdayUk,
    }),
  );

  if (deliveryOptionId < 0 && deliveryOptionId > keys.length - 1)
    return deliveryOptionId;
  const key = keys[deliveryOptionId];
  if (key == null) return deliveryOptionId;

  return map.get(key);
}

const formatDateTime = (value: Date) => formatDate(value, "dd/MM/yyyy, HH:mm");

export default defineComponent({
  name: "OrderDetails",
  components: {
    KNotification: Notification,
    GenericModalVue: GenericModalVue,
    DispatchQCModalVue: DispatchQCModalVue,
    OrderHistoryModalVue: OrderHistoryModalVue,
    PreventUnpaidStatusSelectModalVue: PreventUnpaidStatusSelectModalVue,
    "kendo-daterangepicker": DateRangePicker,
    kwindow: Window,
    kbutton: Button,
    kinput: Input,
    KCheckbox: Checkbox,
    OrderSubHeader: OrderSubHeader,
    "notes-list": NotesList,
    OrderNftsModal,
    KTooltip: Tooltip,
    GenericMessageModal,
  },
  setup() {
    const order: any = ref({});
    const refundRedoReasonTypes: Ref<GetRefundRedoReasonTypesViewModel[]> = ref<
      GetRefundRedoReasonTypesViewModel[]
    >([]);
    const os: any = ref(orderStatuses);
    const publishedOrderStatuses: any = {};
    const filteredOrderStatusOptions: any = ref([]);
    const orderActionTypes: any = ref([
      { Id: 0, Name: "Refund" },
      { Id: 1, Name: "Recharge" },
    ]);
    const orderActionTypeSelected = ref<number | null>(null);
    const selectAllOrderItems: Ref<boolean> = ref<boolean>(false);
    const isRefundRechargeMode: Ref<boolean> = ref<boolean>(false);
    const isRedoMode: Ref<boolean> = ref<boolean>(false);
    const shouldUpdateCourier: Ref<boolean> = ref<boolean>(false);
    const refundRedoReasonTypeSelected: Ref<number | null> = ref<number | null>(
      null,
    );
    const PopupNotification: Ref<any> = ref(null);
    const refundRedoReasonSelected: Ref<number | null> = ref<number | null>(
      null,
    );
    const deliveryBranches: Ref<{ Id: number; Name: string }[]> = ref([]);
    const refundRechrgeTotal: Ref<number> = ref<number>(0);
    const refundRechrgeTotalMax: Ref<number> = ref<number>(0);
    const showGenericModal: Ref<boolean> = ref<boolean>(false);
    const showDispatchQCModal: Ref<boolean> = ref<boolean>(false);
    const printShippingLabelFromModal: Ref<boolean> = ref<boolean>(false);
    const showOrderHistoryModal: Ref<boolean> = ref<boolean>(false);
    const showPreventUnpdaidStatusSelectModal: Ref<boolean> =
      ref<boolean>(false);
    const selectIncludeDelivery: Ref<boolean> = ref<boolean>(false);
    const isStatusChanged: Ref<boolean> = ref<boolean>(false);
    const modalBodyText: Ref<string> = ref("");
    const originalStatus: Ref<number> = ref(0);
    const modalSelectList: Ref<UserGetQCUserListViewModel[]> = ref([]);
    const refundRedoOtherReason: Ref<string> = ref("");
    const refundRedoReportType: Ref<number> = ref(0);
    const refundRedoSaving: Ref<boolean> = ref<boolean>(false);
    const addEditShippingAddressVisible: Ref<boolean> = ref<boolean>(false);
    const uspsertShippingAddressModel: Ref<any> = ref<any>({});
    const uspsertShippingAddressCountries: Ref<any[]> = ref<any[]>([]);
    const manualPaidOptions: any = ref([
      { Id: 1, Name: "Bank transfer" },
      { Id: 2, Name: "In house card payment terminal" },
      { Id: 3, Name: "Paypal" },
      { Id: 4, Name: "Manual Stripe charge" },
    ]);
    const deliveryOptions: Ref<DeliveryTypeViewModel[]> = ref([]);
    const showBulkModal: Ref<boolean> = ref(false);
    const showOrderNftsModal: Ref<boolean> = ref<boolean>(false);

    const bulkDispatchReceivedSuccess = ref<boolean>(false);
    //const isBulkDispatchStatus= ref<boolean>(false);
    const store = useStore();
    return {
      store,
      order,
      refundRedoReasonTypes,
      orderActionTypes,
      orderActionTypeSelected,
      refundRedoReportType,
      selectAllOrderItems,
      isRefundRechargeMode,
      isRedoMode,
      refundRedoReasonTypeSelected,
      refundRedoReasonSelected,
      refundRechrgeTotal,
      shouldUpdateCourier,
      PopupNotification,
      showGenericModal,
      showDispatchQCModal,
      printShippingLabelFromModal,
      showOrderHistoryModal,
      showPreventUnpdaidStatusSelectModal,
      isStatusChanged,
      modalBodyText,
      modalSelectList,
      refundRedoOtherReason,
      selectIncludeDelivery,
      refundRedoSaving,
      addEditShippingAddressVisible,
      uspsertShippingAddressModel,
      uspsertShippingAddressCountries,
      refundRechrgeTotalMax,
      manualPaidOptions,
      originalStatus,
      deliveryBranches,
      deliveryOptions,
      showOrderNftsModal,
      showBulkModal,
      publishedOrderStatuses,
      filteredOrderStatusOptions,
      orderStatuses: os,
      bulkDispatchReceivedSuccess,
      //isBulkDispatchStatus,
    };
  },
  data() {
    const wdgt: any = {};
    return {
      PopupNotificationWidget: wdgt,
    };
  },
  computed: {
    isBulkDispatchStatus(): boolean {
      return false; //this.order.CurrentStatus == OrderState.BulkDispatch;
    },
    CustomsCodeJSON(): any {
      if (!!this.order.CustomsCode && this.order.CustomsCode.length != 0)
        return JSON.parse(this.order.CustomsCode);
      return null;
    },
    CustomsCodeHeadings(): any {
      if (this.CustomsCodeJSON != null)
        return Object.getOwnPropertyNames(this.CustomsCodeJSON);
      return null;
    },
    hasBulkDispatchReceived(): boolean {
      return !!this.order?.BulkDispatchReceived;
    },
    USAType() {
      const order: any = this.order;
      return USATypes[order.USAType];
    },
    OrderNoteType() {
      return OrderNoteType;
    },
    isMagnumOrder() {
      const order: any = this.order;
      return (
        order.UserId == 6274938 ||
        order.OwnerEmail == "magnum@theprintspace.co.uk"
      );
    },
    notes() {
      const notes: Note[] = this.order.Notes;
      const customerNotes: Note[] = this.order.CustomerNotes;
      customerNotes.forEach(
        note => (note.CssClass = `${note.CssClass || "notes"} customer-note`),
      );

      return [...notes, ...customerNotes];
    },
    orderTotal() {
      const order: any = this.order;

      if (typeof order.Id === "undefined") return "";
      if (this.isRedoMode) {
        const total: number = (order.Items as any[]).reduce(
          (previous: any, current: any) =>
            (current.PrintType != "N/A" ? current.Quantity : 1) *
              current.PriceExcludingVat +
            previous,
          0,
        );
        return order.CurrencySymbol + total.toFixed(2);
      }

      if (!order.IsInHouseOrder) {
        if (order.PromoCodeAmount > 0 && order.PromoCodeIsPercentage) {
          const promoCodeAmount: number = order.PromoCodeAmount;
          return (
            (Math.round(promoCodeAmount * 100) / 100).toFixed(2) + "%  Discount"
          );
        }

        if (order.PromoCodeAmount > 0 && !order.PromoCodeIsPercentage) {
          return (
            order.CurrencySymbol +
            parseFloat(order.PromoCodeAmount).toFixed(2) +
            " Discount"
          );
        }

        return (
          order.CurrencySymbol +
          (Math.round(order.TotalExcludingVat * 100) / 100).toFixed(2)
        );
      } else {
        return (
          order.CurrencySymbol +
          (Math.round(order.TotalExcludingVat * 100) / 100).toFixed(2)
        );
      }
    },
    deliveryTotal() {
      const order: any = this.order;

      if (typeof order.Id === "undefined") return "";

      return (
        order.CurrencySymbol +
        (Math.round(order.DeliveryCharge * 100) / 100).toFixed(2)
      );
    },
    hasInserts() {
      const order: any = this.order;
      const items: Array<any> = order.Items;
      return items.some((item: any) => item.PrintInsertId != null);
    },
    IsOrderPremadeFraming() {
      const order: any = this.order;
      const items: Array<any> = order.Items;
      return items.some((i: any) => i.FrameProductionType == "Premade");
    },
    hasCutFile() {
      const order: any = this.order;
      const items: Array<any> = order.Items;

      return items.some(
        (i: any) => i.WindowMount != null && i.WindowMount != "N/A",
      );
    },
    orderDeliveryErrors() {
      const order: any = this.order;
      if (order.Status != 9999) return [];
      const errors: Array<any> = order?.Errors;
      return errors?.filter((e: any) => e.Code == 8);
    },
    orderDisplayType() {
      const order: any = this.order;
      if (order.OrderTypeId == 12) return "Product Photography";
      return order.IsInHouseOrder
        ? "In House Order"
        : order.IsGalleryOrder
        ? "Ecommerce Order"
        : "Web Order";
    },
    getReasons(): GetReasonRefundRedoReasonTypesViewModel[] {
      const reasonType = this.refundRedoReasonTypes.find(
        (type: GetRefundRedoReasonTypesViewModel) =>
          type.Id == this.refundRedoReasonTypeSelected,
      );
      return reasonType?.RefundRedoReasons ?? [];
    },
    showUpgradeToCourier(): boolean {
      if (!this.order.DeliveryOption) {
        return false;
      }
      return !(this.order.DeliveryOption.Keyword as string)
        .toLocaleLowerCase()
        .includes("courier");
    },
    orderVat(): string {
      const order: any = this.order;
      if (this.isRedoMode) {
        const total: number = (order.Items as any[]).reduce(
          (previous: any, current: any) =>
            (current.PrintType != "N/A" ? current.Quantity : 1) * current.Vat +
            previous,
          0,
        );

        const deliveryChargeVat =
          this.order.Vat === 0 ? 0 : this.order.DeliveryCharge * 0.2;
        const result: number = total + deliveryChargeVat;
        return order.CurrencySymbol + result.toFixed(2);
      }
      return order.CurrencySymbol + order.Vat.toFixed(2);
    },
    orderPriceIncludingVat(): string {
      const order: any = this.order;
      if (this.isRedoMode) {
        const total: number = (order.Items as any[]).reduce(
          (previous: any, current: any) =>
            (current.PrintType != "N/A" ? current.Quantity : 1) *
              current.PriceIncludingVat +
            previous,
          0,
        );

        const deliveryChargeVat =
          this.order.Vat === 0 ? 0 : this.order.DeliveryCharge * 0.2;
        const result: number =
          total + deliveryChargeVat + this.order.DeliveryCharge;
        return order.CurrencySymbol + result.toFixed(2);
      }
      return (
        order.CurrencySymbol +
        (Math.round(order.TotalIncludingVat * 100) / 100).toFixed(2)
      );
    },
    enableShippyProShipmentsCreation(): boolean {
      return true; //this.store.getters["global/enableShippyProShipmentsCreation"];
    },
    inDispatchOrDispatched(): boolean {
      return this.order.CurrentStatus === 11 || this.order.CurrentStatus === 23;
    },
    inDispatchQC(): boolean {
      return this.order.CurrentStatus === 25;
    },
    orderDeliveryBranchId(): number {
      return this.order.DeliveryBranchId;
    },
    orderProductionBranchId(): number {
      return this.order.ProductionBranchId;
    },
    orderDeliveryOptionId(): number {
      return this.order.DeliveryOptionId;
    },
    hasDeliveryOption(): boolean {
      return this.deliveryOptions.some(
        o => o.Id == this.order.DeliveryOptionId,
      );
    },
    ownerCategories(): string {
      const hasOwnerSpendCategory = !!this.order.OwnerSpendCategory;
      const isAgencyClient = this.order.OwnerIsAgencyClient;
      let result = "";
      if (hasOwnerSpendCategory && isAgencyClient) {
        result = `Agency/${this.order.OwnerSpendCategory}`;
      } else if (hasOwnerSpendCategory && !isAgencyClient) {
        result = this.order.OwnerSpendCategory;
      } else if (!hasOwnerSpendCategory && isAgencyClient) {
        result = "Agency client";
      }

      return result;
    },
    hasErrorMessages() : boolean {
      return this.order.ErrorMessages && this.order.ErrorMessages.length > 0;
    }
  },
  watch: {
    $route() {
      const _window: any = window;
      _window.router = this.$router;
      _window.router.go(this.$router.currentRoute);
    },
    orderDeliveryBranchId(
      newDeliveryBranchId: number,
      oldDeliveryBranchId: number,
    ) {
      if (!oldDeliveryBranchId || newDeliveryBranchId == oldDeliveryBranchId)
        return;
      this.loadBranchDeliveryOptions(
        newDeliveryBranchId,
        this.orderProductionBranchId,
        true,
      );
    },
    orderDeliveryOptionId(newOptId: number, _) {
      const opt = this.deliveryOptions.find(o => o.Id == newOptId);
      this.order.DeliveryOption = opt ? opt : null;
    },
  },
  async mounted() {
    this.deliveryBranches = this.$specialOrder.deliveryBranches;

    this.publishedOrderStatuses = JSON.parse(
      (await $.get(`/form/grid/GetPublishedStatus`)).data,
    );

    this.getOrder();

    $.get("/form/pms/GetRefundRedoReasonTypes").then(
      (response: GetRefundRedoReasonTypesViewModel[]) => {
        this.refundRedoReasonTypes = response;
      },
    );

    this.PopupNotificationWidget = this.PopupNotification?.kendoWidget();
    if (!this.$account.userInRole) {
      await this.$account.getUserInRole();
    }
  },
  methods: {
    branchDescription(branchId: number): string {
      if (branchId == 1) return "UK";
      if (branchId == 2) return "Germany";
      if (branchId == 3) return "USA";

      return "Unknown";
    },
    async printShippyProShippingLabel() {
      const response = await this.getQCUsers(false, OrderState.DispatchQC);
      if (response.Users.length > 0) {
        if (
          this.order.Status == OrderState.DispatchQC ||
          this.order.Status == OrderState.Dispatched
        ) {
          this.openShippyProShippingLabelTab();
        } else {
          this.showDispatchQCModal = true;
        }
      } else {
        this.order.CurrentStatus = this.order.Status;
      }
    },
    async printCustomsInvoice() {
      const response = await this.getQCUsers(false, OrderState.DispatchQC);
      if (response.Users.length > 0) {
        if (
          this.order.Status == OrderState.DispatchQC ||
          this.order.Status == OrderState.Dispatched
        ) {
          this.openCustomsInvoiceTab();
        } else {
          this.showDispatchQCModal = true;
        }
      } else {
        this.order.CurrentStatus = this.order.Status;
      }
    },
    initOrder(data: any) {
      this.order = data;
      this.order.Items = (data.Items as any[]).map(item => {
        item.OriginalQuantity = item.Quantity;
        item.ShowAssetLinks = false;
        return item;
      });
      this.order.DeliveryBranchId = data.DeliveryBranchId;
      this.order.CurrentStatus = data.Status;
      this.originalStatus = data.Status;
      this.isStatusChanged = false;
      this.selectAllOrderItems = false;
      this.isRefundRechargeMode = false;
      this.isStatusChanged = false;
      this.refundRedoReasonTypeSelected = null;
      this.refundRedoReasonSelected = null;
      this.refundRedoOtherReason = "";
      this.refundRechrgeTotal = 0;
      this.setRefundMaxTotal();
      this.refundRedoSaving = false;
    },
    getOrderUrl(): string {
      return `/form/pms/getorder/${this.$route.params.orderId}?orderId=${this.$route.params.orderId}`;
    },
    loadBranchDeliveryOptions(
      deliveryBranchId: number,
      productionBranchId: number,
      mapDeliveryOpt = false,
    ): void {
      $.when(
        $.get(`/form/pms/BranchDeliveryOptions?branchId=${deliveryBranchId}`),
        $.get(`/form/pms/BranchDeliveryOptions?branchId=${productionBranchId}`),
      ).done((deliveryBranchResponse: any, productionBranchResponse: any) => {
        const response: DeliveryTypeViewModel[] =
          deliveryBranchResponse[0].concat(productionBranchResponse[0]);
        this.deliveryOptions = response;
        if (!mapDeliveryOpt) return;
        const mappedOptId = crossBranchDeliveryOptionMap(
          this.order.DeliveryOptionId,
        );

        const opt = this.deliveryOptions.find(o => o.Id == mappedOptId);
        this.order.DeliveryOptionId = mappedOptId;
        this.order.DeliveryOption = opt ? opt : null;
      });
    },
    getOrder(): void {
      $.get(this.getOrderUrl(), {}, (data: any) => {
        this.initOrder(data);
        this.loadBranchDeliveryOptions(
          this.orderDeliveryBranchId,
          this.orderProductionBranchId,
        );
        this.setFilteredOrderStatusOptions(data);
      });
    },
    setFilteredOrderStatusOptions(orderData: any): void {
      //LPB
      var currentStatus = this.order.CurrentStatus;
      var options = this.publishedOrderStatuses[currentStatus];
      if (options) {
        const filterIds = [...options, this.order.CurrentStatus];
        this.filteredOrderStatusOptions = ref(
          orderStatuses.filter((x: any) => filterIds.indexOf(x.Id) > -1),
        );
      } else {
        this.filteredOrderStatusOptions = ref(orderStatuses);
      }
    },
    getDisplayName(item: any): string {
      switch (item.SpecialOrderTypeId) {
        case 1:
          return "Print";
        case 2:
          return "Frame";
        case 3:
          return "Mounting";
        case 4:
          return `Stationery - ${item.Stationery}`;
        case 5:
          return `Booth &amp Retouch - ${item.Retouching}`;
        case 6:
          return "Delivery";
        case 7:
          return `Freetext - ${item.Freetext}`;
        case 8:
          return `${item.PrintInsertId}_${item.PrintInsertName} - (Custom insert for: ${item.PrintInserts})`;
        case 9:
          return "Branded Packaging :";
        case 10:
          return `Alternative COA - ${types[item.COATypeId]}`;
        case 11:
          return "Classic COA";
        case 13:
          return `Artist Signature - ${item.Freetext}`;
        case 14:
          return `Open Edition Classic COA`;
        case 15:
          return `Open Edition COA - ${types[item.COATypeId]}`;
        case 16:
          return `Nft COA`;
        case 200:
          var contractInsuranceTypeDesc = this.getContractInsuranceTypeDesc(
            item.ContractInsuranceType,
          );
          var gstPerc = this.getGstPerc(item.GstPercentage);

          return `DDP prepaid GST (${gstPerc}% on ${contractInsuranceTypeDesc})`;
        case 201:
          return "DDP prepaid import duty";
        case 202:
          return "DDP courier admin fee";
        case 301:
          return "Product shot coa";
        case 302:
          return "Product shot insert";
        case 303:
          return "Product shot branding";
        case 101:
        case 102:
        case 103:
        case 105:
        case 106:
        case 109:
          return item.Freetext;
        default:
          return item.DisplayName;
      }
    },
    getFinishing(item: any) {
      if (item.SpecialOrderTypeId == 2) return "Framing";
      if (item.SpecialOrderTypeId == 3) return "Mounting";
      if (item.CustomFinishing) return item.CustomFinishing;
      return item.Finishing;
    },
    getGstPerc(perc: number | null | undefined) {
      perc ??= 0;

      return perc.toFixed(2);
    },
    getContractInsuranceTypeDesc(
      contractInsuranceType: ContractInsuranceType,
    ): string {
      return contractInsuranceType === "FOB"
        ? "products"
        : "products and delivery";
    },
    getRelayStatusText(relayStatus: RelayStatus): string {
      const relayStatuses = {
        [RelayStatus.SuccessOrderAcknowledged]:
          "Successfully marked order as acknowledged.",
        [RelayStatus.SuccessOrderDownloaded]:
          "Successfully order is downloaded.",
        [RelayStatus.SuccessOrderMarkedAsDownloaded]:
          "Successfully marked order as downloaded.",
        [RelayStatus.SuccessUpdatingOrderStatus]:
          "Successfully updated order status.",
        [RelayStatus.ErrorOrderAcknowledged]:
          "Error while marking order as acknowledged.",
        [RelayStatus.ErrorOrderDownloaded]: "Error while order downloading.",
        [RelayStatus.ErrorOrderMarkingAsDownloaded]:
          "Error while marking order as downloaded.",
        [RelayStatus.ErrorUpdatingOrderStatus]:
          "Error while updating order status.",
      };

      return relayStatuses[relayStatus] ?? "";
    },
    getPrintedArea(item: any): string {
      const horizontalWMBorderMM = item.BorderLeft + item.BorderRight;
      const verticalWMBorderMM = item.BorderTop + item.BorderBottom;

      const horizontalBorderMM =
        item.WindowMountBorderLeft + item.WindowMountBorderRight;
      const verticalBorderMM =
        item.WindowMountBorderTop + item.WindowMountBorderBottom;

      const totalWidth =
        item.PrintWidthMM +
        horizontalWMBorderMM +
        horizontalBorderMM +
        item.CanvasEdgeMM * 2;
      const totalHeight =
        item.PrintHeightMM +
        verticalWMBorderMM +
        verticalBorderMM +
        item.CanvasEdgeMM * 2;
      if (item.IsOrderPremadeFraming) {
        return `${(
          (totalWidth +
            item.WindowMountBorderLeft +
            item.WindowMountBorderRight) *
          (totalHeight +
            item.WindowMountBorderTop +
            item.WindowMountBorderBottom)
        ).toFixed(2)}mm`;
      }

      return `${(totalWidth * totalHeight).toFixed(2)}mm`;
    },
    getFinishingDetails(item: any): string[] {
      const isSpecialOrderItem = item.SpecialOrderTypeId != null;
      if (
        item.Finishing == "Framing" ||
        (item.IsOrderPremadeFraming != "" &&
          isSpecialOrderItem &&
          item.SpecialOrderTypeId < 7)
      ) {
        if (item.CustomFinishingDetails) {
          return [`Custom: ${item.CustomFinishingDetails}`];
        }

        const opts = [
          `Frame Type: ${item.FrameType} `,
          `Moulding: ${item.Moulding} `,
        ];
        if (item.WindowMount != "N/A") {
          opts.push(`Window Mount Colour: ${item.WindowMount}`);
          opts.push("Window Mount Size (WM):");
          opts.push(`WM - Top: ${item.WindowMountBorderTop}mm`);
          opts.push(`WM - Right: ${item.WindowMountBorderRight}mm`);
          opts.push(`WM - Bottom: ${item.WindowMountBorderBottom}mm`);
          opts.push(`WM - Left: ${item.WindowMountBorderLeft}mm`);
        }

        return opts;
      }

      if (
        item.Finishing == "None" ||
        (isSpecialOrderItem &&
          item.SpecialOrderTypeId != 2 &&
          item.SpecialOrderTypeId != 3)
      )
        return [`N/A`];

      if (
        item.Finishing == "Framing" ||
        (isSpecialOrderItem && item.SpecialOrderTypeId == 2)
      ) {
        if (item.CustomFinishingDetails) {
          return [`Custom: ${item.CustomFinishingDetails}`];
        }

        const opts = [
          `Frame Type: ${item.FrameType}`,
          `Moulding: ${item.Moulding}`,
          `Glass: ${item.Glass}`,
          `Hanging Option: ${item.HangingOption}`,
        ];

        if (item.WindowMount != "N/A") {
          opts.push("Window Mount Size:");
          opts.push(`Top: ${item.WindowMountBorderTop}mm`);
          opts.push(`Right: ${item.WindowMountBorderRight}mm`);
          opts.push(`Bottom: ${item.WindowMountBorderBottom}mm`);
          opts.push(`Left: ${item.WindowMountBorderLeft}mm`);
        }

        return opts;
      }

      if (
        item.Finishing == "Mounting" ||
        (isSpecialOrderItem && item.SpecialOrderTypeId == 3)
      ) {
        if (item.CustomFinishingDetails) {
          return [`Custom: ${item.CustomFinishingDetails}`];
        }

        const results = [
          `Substrate: ${item.Substrate}`,
          `Substrate Colour: ${item.SubstrateColour}`,
          `Lamination: ${item.Lamination}`,
          `Fixing: ${item.Fixing}`,
          `Backing: ${item.Backing}`,
        ];

        return results;
      }

      return [`N/A`];
    },
    orderItemToggleShowAssetLinks(item: any): void {
      item.ShowAssetLinks = !item.ShowAssetLinks;
    },
    orderItemHasSignature(item: any): boolean {
      return item.SignatureEnabled;
    },
    orderItemIsNonPrintableAsset(item: any): boolean {
      return item.SpecialOrderTypeId == 400;
    },
    orderItemHasDownloadableAssets(item: any): boolean {
      return (
        item.SpecialOrderTypeId != 1 &&
        item.SpecialOrderTypeId != 2 &&
        item.SpecialOrderTypeId != 3 &&
        item.SpecialOrderTypeId != 6 &&
        item.SpecialOrderTypeId != 7 &&
        item.SpecialOrderTypeId != 8 &&
        item.SpecialOrderTypeId != 10 &&
        item.SpecialOrderTypeId != 11 &&
        item.SpecialOrderTypeId != 13 &&
        item.SpecialOrderTypeId != 14 &&
        item.SpecialOrderTypeId != 15 &&
        item.SpecialOrderTypeId != 16 &&
        item.SpecialOrderTypeId != 101 &&
        item.SpecialOrderTypeId != 102 &&
        item.SpecialOrderTypeId != 103 &&
        item.SpecialOrderTypeId != 105 &&
        item.SpecialOrderTypeId != 106 &&
        item.SpecialOrderTypeId != 109 &&
        item.SpecialOrderTypeId != 200 &&
        item.SpecialOrderTypeId != 201 &&
        item.SpecialOrderTypeId != 202 &&
        item.SpecialOrderTypeId != 301 &&
        item.SpecialOrderTypeId != 302 &&
        item.SpecialOrderTypeId != 400
      );
    },
    orderItemHasImage(item: any): boolean {
      return (
        item.SpecialOrderTypeId != 1 &&
        item.SpecialOrderTypeId != 2 &&
        item.SpecialOrderTypeId != 3 &&
        item.SpecialOrderTypeId != 6 &&
        item.SpecialOrderTypeId != 7 &&
        item.SpecialOrderTypeId != 8 &&
        item.SpecialOrderTypeId != 10 &&
        item.SpecialOrderTypeId != 11 &&
        item.SpecialOrderTypeId != 13 &&
        item.SpecialOrderTypeId != 14 &&
        item.SpecialOrderTypeId != 15 &&
        item.SpecialOrderTypeId != 16 &&
        item.SpecialOrderTypeId != 101 &&
        item.SpecialOrderTypeId != 102 &&
        item.SpecialOrderTypeId != 103 &&
        item.SpecialOrderTypeId != 105 &&
        item.SpecialOrderTypeId != 106 &&
        item.SpecialOrderTypeId != 109 &&
        item.SpecialOrderTypeId != 200 &&
        item.SpecialOrderTypeId != 201 &&
        item.SpecialOrderTypeId != 202 &&
        item.SpecialOrderTypeId != 301 &&
        item.SpecialOrderTypeId != 302
      );
    },
    getItemPriceExVat(item: any) {
      return (
        this.order.CurrencySymbol +
        parseFloat(
          (
            Math.round(
              (item.PrintType != "N/A" ? item.Quantity : 1) *
                item.PriceExcludingVat *
                100,
            ) / 100
          ).toFixed(2),
        )
      );
    },
    getItemPriceInVat(item: any) {
      return (
        this.order.CurrencySymbol +
        parseFloat(
          (
            Math.round(
              (item.PrintType != "N/A" ? item.Quantity : 1) *
                item.PriceIncludingVat *
                100,
            ) / 100
          ).toFixed(2),
        )
      );
    },
    getItemVat(item: any) {
      return (
        this.order.CurrencySymbol +
        parseFloat(
          (
            Math.round(
              (item.PrintType != "N/A" ? item.Quantity : 1) * item.Vat * 100,
            ) / 100
          ).toFixed(2),
        )
      );
    },
    copySelection() {
      var address = $("#shipping-address option:selected").text();
      var textarea = $("<textarea />");
      textarea
        .val(address)
        .css({
          width: "0px",
          height: "0px",
          border: "none",
          visibility: "none",
        })
        .prependTo("body");

      textarea.focus().select();

      try {
        if (!document.execCommand || !document.execCommand("copy")) {
          alert("Copy failed, or is not supported on your browser");
        }
      } catch (err) {
        alert("Copy failed, or is not supported on your browser");
      }

      textarea.remove();
    },
    download() {
      $.get(`/form/pms/DownloadOrderToRelay?orderId=${this.order.Id}`)
        .done((result: any) => {
          this.PopupNotificationWidget?.show(result?.response, "success");
        })
        .fail(() => {
          this.PopupNotificationWidget?.show(
            `Something went wrong with download`,
            "error",
          );
        });
      return false;
    },
    viewJobSheet() {
      window.open(
        "/form/pms/viewJobSheet?orderId=" + this.order.Id,
        "_blank",
        "fullscreen=yes",
      );
      return false;
    },
    viewInvoice() {
      window.open(
        "/form/pms/CreateOrderInvoiceFromOrderId?orderId=" + this.order.Id,
        "_blank",
        "fullscreen=yes",
      );
      return false;
    },
    emailInvoiceToCustomer(event:any) {
      event.target.disabled = true;
      const oldText = event.target.innerText;
      event.target.innerText = "Generating and Sending Invoice Email"
      $.post(
        "/form/pms/CreateOrderInvoiceFromOrderId",
        {
          orderId: this.order.Id,
          email: null,
          emailUser: true,
        },
        () => {
          event.target.disabled = false;
          event.target.innerText = oldText;
          alert("Invoice emailed");
        },
      );
    },
    printShippingLabel() {
      window.open(
        "/form/pms/CreateShippingLabel?orderId=" + this.order.Id,
        "_blank",
        "fullscreen=yes",
      );
      return false;
    },
    printMagnumPickingLabel() {
      window.open(
        "/form/pms/CreateShippingLabel?pickingLabelsForMagnum=true&orderId=" +
          this.order.Id,
        "_blank",
        "fullscreen=yes",
      );
      return false;
    },
    updateOrder() {
      if (this.order.Status === 11 && !this.order.TrackingNumber) {
        this.PopupNotificationWidget?.show(
          "Please fill out TrackingNumber",
          "error",
        );
        return;
      }
      const utcDate = formatDateTime(getUtcDate());
      if (this.order.CurrentStatus === OrderStatus.Dispatched) {
        this.order.DispatchDate = utcDate;
      } else if (this.order.CurrentStatus === OrderStatus.ReadyForCollection) {
        this.order.ReadyForCollectionDate = utcDate;
      } else if (this.order.CurrentStatus === OrderStatus.Printed) {
        this.order.DatePrinted = utcDate;
      }
      $.post("/form/pms/setOrder", this.order)
        .done((result: any) => {
          this.PopupNotificationWidget?.show(
            `Order successfully updated`,
            "success",
          );
          if (result.OrderState == OrderState.BulkDispatch) {
            this.showBulkModal = true;
          }
          this.getOrder();
        })
        .fail((e: any) => {
          this.PopupNotificationWidget?.show(
            `Something went wrong with order update: ${e.responseJSON.error}`,
            "error",
          );
          this.order.CurrentStatus = this.order.Status;
        });
    },
    goToCustomer() {
      this.$router.push(`/customers/${this.order.UserId}`);
    },
    downloadInserts() {
      window.open(
        "/form/pms/downloadInserts?id=" + this.order.Id,
        "_blank",
        "fullscreen=yes",
      );
    },
    downloadBrandedDeliveryFile() {
      window.open(
        `/form/pms/downloadBrandedDeliveryFile?id=${this.order.BrandedDeliveryFileId}&orderId=${this.order.Id}`,
        "_blank",
        "fullscreen=yes",
      );
    },
    downloadCertificates() {
      window.open(
        `/classic/AuthenticityCertificate/Order/${this.order.Id}`,
        "_blank",
        "fullscreen=yes",
      );
    },
    listNftCertificates() {
      this.showOrderNftsModal = true;
      // Opens a modal and list the content returned by the url below
      // window.open(
      //   `/classic/AuthenticityCertificate/Nfts/${this.order.Id}`,
      //   "_blank",
      //   "fullscreen=yes",
      // );
    },
    onCloseOrderNftsModal(): void {
      this.showOrderNftsModal = false;
    },
    downloadCutfile() {
      window.open(
        `/form/pms/DownloadCutFiles?orderId=${this.order.Id}`,
        "_blank",
        "fullscreen=yes",
      );
    },
    onRefundButtonClick(): void {
      this.isRefundRechargeMode = !this.isRefundRechargeMode;
      if (this.isRedoMode) {
        this.isRedoMode = false;
      }
    },
    onRedoButtonClick(): void {
      this.isRedoMode = !this.isRedoMode;

      if (this.isRefundRechargeMode) {
        this.isRefundRechargeMode = false;
      }
      if (!this.isRedoMode) {
        (this.order.Items as any[]).map(item => {
          item.Quantity = item.OriginalQuantity;
          return item;
        });
      }
    },
    onSelectAllClick(): void {
      (this.order.Items as any[]).forEach((item: any) => {
        item.isSelected = !!this.selectAllOrderItems;
      });
      this.selectIncludeDelivery = !!this.selectAllOrderItems;
      this.calculateAndSetRefundTotal();
    },
    onResetRefundClick(): void {
      this.selectAllOrderItems = false;
      (this.order.Items as any[]).forEach((item: any) => {
        item.isSelected = !!this.selectAllOrderItems;
      });
      this.refundRechrgeTotal = 0;
      this.refundRedoReasonTypeSelected = null;
      this.refundRedoReasonSelected = null;
      this.refundRedoOtherReason = "";
      this.selectIncludeDelivery = false;
    },
    onSelectOrderClick(): void {
      this.calculateAndSetRefundTotal();
      this.selectAllOrderItems =
        (this.order.Items as any[]).filter(item => item.isSelected).length ==
        (this.order.Items as any[]).length;
    },
    calculateAndSetRefundTotal(): void {
      const total: number = this.getAllSelectedTotal();
      this.refundRechrgeTotal = isNaN(total) ? 0 : total;
    },
    getAllSelectedTotal(): number {
      const total: number = (this.order.Items as any[])
        .filter(item => item.isSelected)
        .reduce(
          (previous: any, current: any) =>
            (current.PrintType != "N/A" ? current.Quantity : 1) *
              current.PriceIncludingVat +
            previous,
          0,
        );
      const deliveryChargeVat =
        this.order.Vat === 0 ? 0 : this.order.DeliveryCharge * 0.2;
      const result: number = this.selectIncludeDelivery
        ? total +
          ((this.order.Items as any[]).some(item => item.isSelected)
            ? deliveryChargeVat + this.order.DeliveryCharge
            : this.order.DeliveryCharge)
        : total;

      return parseFloat(result.toFixed(2));
    },
    setRefundMaxTotal(): void {
      const total: number = (this.order.Items as any[]).reduce(
        (previous: any, current: any) =>
          (current.PrintType != "N/A" ? current.Quantity : 1) *
            current.PriceIncludingVat +
          previous,
        0,
      );
      const deliveryChargeVat =
        this.order.Vat === 0 ? 0 : this.order.DeliveryCharge * 0.2;
      const result: number =
        total + deliveryChargeVat + this.order.DeliveryCharge;

      this.refundRechrgeTotalMax = parseFloat(result.toFixed(2));
    },
    onRefundRechargeConfirmClick(): void {
      const selectedItems = (this.order.Items as any[]).filter(
        item => item.isSelected,
      );
      if (selectedItems.length <= 0 && !this.selectIncludeDelivery) {
        this.PopupNotificationWidget?.show(
          "Please select at least one item from list",
          "error",
        );
        return;
      }
      this.refundRedoSaving = true;
      if (this.isRefundRechargeMode) {
        if (this.orderActionTypeSelected == null) {
          this.PopupNotificationWidget?.show(
            "Please select an action from the Refund/Recharge dropdown list",
            "error",
          );
          return;
        }

        $.post("/form/pms/AddNewOrderAction", {
          ...this.order,
          note: {
            text: this.getRefundReasonNoteText(),
            type: 1,
          },
          refundRedoReasonId: this.refundRedoReasonSelected,
          refundRedoOtherReason: this.refundRedoOtherReason,
          shouldIncludeDelivery: this.selectIncludeDelivery,
          orderItemIds: selectedItems.map(item => item.Id),
          orderId: this.order.Id,
          actionType: this.orderActionTypeSelected,
          amount: this.refundRechrgeTotal.toFixed(2),
          quantity: selectedItems.reduce(
            (previous: any, current: any) => current.Quantity + previous,
            0,
          ),
        })
          .done(() => {
            this.getOrder();
          })
          .fail(() => {
            this.PopupNotificationWidget?.show(
              `Something went wrong with refund/recharge`,
              "error",
            );
            this.order.CurrentStatus = this.order.Status;
            this.refundRedoSaving = false;
          });
      }

      if (this.isRedoMode) {
        $.post(
          "/form/pms/AddRedoOrder",
          {
            orderId: this.order.Id,
            orderItems: selectedItems.map(item => ({
              quantity: item.Quantity,
              id: item.Id,
            })),
            shouldUpdateCourier: this.shouldUpdateCourier,
            shouldIncludeDelivery: this.selectIncludeDelivery,
            refundRedoReasonId: this.refundRedoReasonSelected,
            refundRedoOtherReason: this.refundRedoOtherReason,
          },
          async (data: any) => {
            if (!data.OrderId) {
              return;
            }
            await this.goToPage(`/orders/${data.OrderId}`);
            this.refundRedoSaving = false;
          },
        ).fail(() => {
          this.PopupNotificationWidget?.show(
            `Something went wrong with add redo order`,
            "error",
          );
          this.order.CurrentStatus = this.order.Status;
          this.refundRedoSaving = false;
        });
      }
    },
    getRefundReasonNoteText(): string {
      const reasonType = this.refundRedoReasonTypes.find(
        item => item.Id == this.refundRedoReasonTypeSelected,
      );
      const selectedItems = (this.order.Items as any[]).filter(
        item => item.isSelected,
      );
      const quantity = selectedItems.reduce(
        (previous: any, current: any) =>
          isNaN(previous.Quantity)
            ? 0 + current.Quantity
            : current.Quantity + previous.Quantity,
        0,
      );
      const refundRecharge = `${
        this.isRefundRechargeMode && this.orderActionTypeSelected == 0
          ? "Refund"
          : "Recharge"
      }`;
      return `${
        this.isRefundRechargeMode ? refundRecharge : "Redo"
      } attempt of ${this.order.CurrencySymbol} ${
        this.refundRechrgeTotal
      }, quantity: ${quantity}, for ${
        this.selectAllOrderItems
          ? this.order.RefundRechargeTargets[0].Name
          : selectedItems.reduce(
              (previous: any, current: any) =>
                `${previous.DisplayName ? `${previous.DisplayName} ,` : ""}` +
                `${current.DisplayName}`,
              "",
            )
      }, Reason type: ${reasonType?.Name}, reason: ${
        reasonType?.RefundRedoReasons.find(
          item => item.Id === this.refundRedoReasonSelected,
        )?.Name ?? this.refundRedoOtherReason
      }`;
    },
    getOrderActionType(action: any): string {
      return OrderActionType[action];
    },
    onDownloadPdfClick(refund: any): void {
      window.open(
        `/form/pms/CreateRefundRechargeInvoice?orderActionId=${refund.Id}`,
        "_blank",
        "fullscreen=yes",
      );
    },
    onEmailCustomerClick(refund: any): void {
      $.post(`/form/pms/CreateRefundRechargeInvoice`, {
        orderActionId: refund.Id,
        email: null,
        emailUser: true,
      });
    },
    onIncludeDeliveryClick(): void {
      const total: number = this.getAllSelectedTotal();
      this.refundRechrgeTotal = isNaN(total) ? 0 : total;
    },
    onCancelDispatchQCModal(): void {
      this.showDispatchQCModal = false;
    },
    async openShippyProShippingLabelTab() {
      //await checkShippingLabel() ->
      // -- if shipstation - retrieve pdf -> if error return error and show error/ otherwise open new tab
      const slc = await this.ShippingLabelCheck();
      if (slc) {
        this.printShippingLabelFromModal = true;
        window.open(
          `/form/pms/CreateShippyProShippingLabel?orderId=${this.order.Id}`,
          "_blank",
          "fullscreen=yes",
        );
        this.printShippingLabelFromModal = false;
        this.getOrder();
      }
    },
    async openCustomsInvoiceTab() {
      //await checkShippingLabel() ->
      // -- if shipstation - retrieve pdf -> if error return error and show error/ otherwise open new tab
      const slc = await this.ShippingLabelCheck();
      if (slc) {
        this.printShippingLabelFromModal = true;
        window.open(
          `/form/pms/CreateShippyProCommercialInvoice?orderId=${this.order.Id}`,
          "_blank",
          "fullscreen=yes",
        );
        this.printShippingLabelFromModal = false;
        this.getOrder();
      }
    },
    async ShippingLabelCheck() {
      const { Errors, Message } = await $.post(`/form/pms/CheckShippingLabel`, {
        orderId: this.order.Id,
      });
      if (Errors) {
        this.PopupNotificationWidget?.show(Message, "error");
      }
      return !Errors;
    },
    showError(message: string) {
      this.PopupNotificationWidget?.show(message, "error");
    },
    resetOrderStatus() {
      this.order.Status = this.order.CurrentStatus;
      this.isStatusChanged = false;
    },
    setOrderStatus(status: OrderState) {
      this.order.Status = status;
      this.isStatusChanged = true;
    },
    setDispatchQCUserId(qcUserId: number) {
      this.order.DispatchQCUserId = qcUserId;
      this.order.CheckedDispatchQCUserId = qcUserId;
    },
    async forceSaveAsync() {
      await $.post("/form/pms/setOrder", this.order);
      const data = await $.get(this.getOrderUrl());
      this.initOrder(data);
    },
    async onConfirmDispatchQCModal(evt: any): Promise<void> {
      this.showDispatchQCModal = false;

      const { selectedQcUserId } = evt;
      if (selectedQcUserId <= 0) {
        this.showError("You must select a QC user to proceed");
        return;
      }

      try {
        this.setOrderStatus(OrderState.DispatchQC);
        this.setDispatchQCUserId(selectedQcUserId);
        await this.forceSaveAsync();
        this.setOrderStatus(OrderState.Dispatched);
        window.setTimeout(this.openShippyProShippingLabelTab, 500);
      } catch (e: any) {
        this.resetOrderStatus();
        this.showError(
          `Something went wrong with order update: ${e.responseJSON.error}`,
        );
      }
    },
    onCloseModal(response: GenericModalResponse): void {
      this.showGenericModal = false;

      this.modalSelectList = [];
      if (!response.model) {
        this.PopupNotificationWidget?.show(
          `Sorry you can't change status to Printed or Packed without selected quality control user`,
          "error",
        );
        this.order.CurrentStatus = this.order.Status;
        this.printShippingLabelFromModal = false;
        return;
      }
      if (this.order.CurrentStatus === 23 && response.isQuestionAnswer) {
        this.order.PrintQCUserId = response.model;
        this.order.CheckedPrintQCUserId = response.model;
      }
      if (this.order.CurrentStatus === 25 && response.isQuestionAnswer) {
        if (this.printShippingLabelFromModal == true) {
          window.open(
            `/form/pms/CreateShippyProShippingLabel?orderId=${this.order.Id}`,
            "_blank",
            "fullscreen=yes",
          );
        }
        this.order.DispatchQCUserId = response.model;
        this.order.CheckedDispatchQCUserId = response.model;
        this.printShippingLabelFromModal = false;
      }
      if (
        [8, 10].includes(this.order.CurrentStatus) &&
        response.isQuestionAnswer
      ) {
        this.order.FramingMountingQCUserId = response.model;
        this.order.CheckedFramingMountingQCUserId = response.model;
      }
      this.order.Status = this.order.CurrentStatus;
      this.updateOrder();
    },
    onClosePreventUnpaidModal(
      response: PreventUnpaidStatusSelectModalResponse,
    ): void {
      if (response) {
        if (
          (!response.paidByInvoice && response.manualPaymentMethod == null) ||
          (!response.paidByInvoice && response.user == null)
        ) {
          this.PopupNotificationWidget?.show(
            `Sorry you can't change status without selected manual payment method or user`,
            "error",
          );
          this.order.CurrentStatus = this.order.Status;
          this.showPreventUnpdaidStatusSelectModal = false;
          return;
        }
        this.order.Status = this.order.CurrentStatus;
        if (!response.paidByInvoice) {
          this.order.IsManualPaid = true;
        }
        if (response.paidByInvoice && this.order.OrderTypeId === 11) {
          this.order.IsASFForInvoice = true;
        }
        if (response.paidByInvoice && !this.order.IsFinalised) {
          this.order.IsInHouseForInvoice = true;
        }
        if (response.paidByInvoice && !(this.order.OrderTypeId === 11)) {
          this.order.IsPrintForInvoice = true;
        }
        this.order.PaidDate = !response.paidByInvoice
            ? formatDate(response.paymentDate || getUtcDate(), "dd/MM/yyyy")
            : formatDate(getUtcDate(), "dd/MM/yyyy");
        this.order.IsAddedManualPayment = true;
        this.order.ManualPaymentMethod = response.manualPaymentMethod?.Id ?? 0;
        this.order.ManualPaymentUserId = response.user?.Id;
        this.order.ManualPaymentNote = response.note;
        this.updateOrder();
        this.showPreventUnpdaidStatusSelectModal = false;
        return;
      }
      this.order.CurrentStatus = this.order.Status;
      this.showPreventUnpdaidStatusSelectModal = false;
    },
    goToPage(link: string): void {
      window.open(link, "_blank");
    },
    async getQCUsers(
      isAdmin: boolean,
      forcedStatus: any = undefined,
    ): Promise<GetQCUserListViewModel> {
      forcedStatus ??= this.order.CurrentStatus;
      const response: GetQCUserListViewModel = isAdmin
        ? await this.$account.getAdminUsers()
        : await this.$account.getQCUsers(
            this.order.DeliveryBranchId,
            forcedStatus,
            this.order.Id,
          );

      if (!response) {
        this.PopupNotificationWidget?.show(
          `Sorry you don't have permission to change status to Printed or Packed it can make only super admin`,
          "error",
        );
        this.order.CurrentStatus = this.order.Status;
      }
      if (response.Users.length > 0) {
        this.modalSelectList = response.Users;
      }
      return response;
    },
    async onStatusChange(): Promise<void> {
      const qcStatus = [8, 10, 23, 25];

      if (qcStatus.indexOf(this.order.CurrentStatus) > -1) {
        this.setQCModalMessage();
        const response = await this.getQCUsers(false);
        if (response.Users.length > 0) {
          this.showGenericModal = true;
        }
      }

      if (this.isSelectedUnpaidStatus()) {
        const response = await this.getQCUsers(true);
        if (response.Users.length > 0) {
          this.showPreventUnpdaidStatusSelectModal = true;
        }

        return;
      }

      if (this.order.CurrentStatus == 11) {
        await this.updateTrackingNumber();
      }

      this.isStatusChanged = true;
      this.order.Status = this.order.CurrentStatus;
    },
    async updateTrackingNumber() {
      var { TrackingNumber } = await $.get(this.getOrderUrl());
      this.order.TrackingNumber = TrackingNumber;
    },
    isRefundRedoTypeOther(): boolean {
      return this.refundRedoReasonTypeSelected === 6;
    },
    isSelectedUnpaidStatus(): boolean {
      const unpaidStatuses = OrderStatuses.filter(
        status =>
          status.Id === OrderStatus.ProcessingPayment ||
          status.Id === OrderStatus.CardRequired ||
          status.Id === OrderStatus.PaymentFailed ||
          status.Id === OrderStatus.SuspendedOrder ||
          status.Id === OrderStatus.EmbryonicOrder,
      );
      const cancelledOrderStatus = OrderStatuses.find(
        status => status.Id === OrderStatus.Cancelled,
      );

      const wasUnpaid: boolean = unpaidStatuses.some(
        status => status.Id == this.originalStatus,
      );

      const wasUnpaidBeforeCancel = unpaidStatuses
        .map(status => status.Name)
        .some(
          name =>
            this.order.OrderStatusHistory?.length === 0 ||
            this.order.OrderStatusHistory.findLast(
              (history: any) =>
                history.NewStatus === cancelledOrderStatus?.Name,
            )?.OldStatus === name,
        );
      const isCancelledOrOnHold =
        this.order.CurrentStatus === OrderStatus.Cancelled ||
        this.order.CurrentStatus === OrderStatus.OnHold;
      if (wasUnpaid && isCancelledOrOnHold) {
        return false;
      }

      const wasCancelled = this.originalStatus == OrderStatus.Cancelled;

      return (
        (wasUnpaid && !isCancelledOrOnHold) ||
        (wasUnpaidBeforeCancel && wasCancelled)
      );
    },
    onRefundRedoReasonTypeChange(): void {
      if (!this.isRefundRedoTypeOther()) {
        this.refundRedoOtherReason = "";
      }
      if (this.isRefundRedoTypeOther()) {
        this.refundRedoReasonSelected = null;
      }
    },
    onViewOrderStatusHistoryClick(): void {
      this.showOrderHistoryModal = true;
    },
    onOrderHistoryCloseModal(): void {
      this.showOrderHistoryModal = false;
    },
    setQCModalMessage(): void {
      switch (this.order.CurrentStatus) {
        case 8:
          this.modalBodyText =
            "Has the framing passed the QC checks? Your name will be recorded as the quality checker";
          break;
        case 10:
          this.modalBodyText =
            "Has the mountin passed the QC checks? Your name will be recorded as the quality checker";
          break;
        case 23:
          this.modalBodyText =
            "Has the print passed the QC checks? Your name will be recorded as the quality checker";
          break;
        case 25:
          this.modalBodyText =
            "Have you checked the order to ensure: address, number of prints, COAS, Stickers, Inserts, size of prints, framed or unframed parameters are correct?";
          break;
        case 26:
          this.modalBodyText =
            "Have you checked the order to ensure: mounting and/or framing parameters are correct?";
          break;
        default:
          this.modalBodyText = "";
          break;
      }
    },
    setDisableToOrderStatuses(item: any): boolean {
      return (
        item.Id === 6 ||
        item.Id === 15 ||
        item.Id === 17 ||
        item.Id === 19 ||
        item.Id === 27 ||
        item.Id === 28 ||
        item.Id === 101 ||
        item.Id === 18 ||
        item.Id === 0 ||
        item.Id === 9999 ||
        item.Id === 1000 ||
        item.Id === 100 ||
        item.Id === 21 ||
        (item.Id == 30 && this.order.USAType != 1)
      );
    },
    onAddNewAddressClick(): void {
      this.uspsertShippingAddressModel = {};
      this.$dataViews
        .GetCountries()
        .then((response: any) => {
          if (!response) {
            this.PopupNotificationWidget?.show(
              `Sorry we have problem with country loading!`,
              "error",
            );
          }
          this.uspsertShippingAddressCountries = response;
          this.addEditShippingAddressVisible =
            !this.addEditShippingAddressVisible;
        })
        .catch(() => {
          this.PopupNotificationWidget?.show(
            `Sorry we have problem with country loading!`,
            "error",
          );
        });
    },
    onEditAddressClick(): void {
      const address = (this.order.ShippingAddresses as any[]).find(
        address => address.Id == this.order.ShippingAddressId,
      );
      if (!address) {
        this.PopupNotificationWidget?.show(
          `Please select address first!`,
          "error",
        );
        return;
      }
      this.$dataViews
        .GetCountries()
        .then((response: any) => {
          if (!response) {
            this.PopupNotificationWidget?.show(
              `Sorry we have problem with contry loading!`,
              "error",
            );
          }
          this.uspsertShippingAddressCountries = response;
          this.uspsertShippingAddressModel = { ...address };
          this.addEditShippingAddressVisible =
            !this.addEditShippingAddressVisible;
        })
        .catch(() => {
          this.PopupNotificationWidget?.show(
            `Sorry we have problem with country loading!`,
            "error",
          );
        });
    },
    closeUpsertShippingAddressWindow(): void {
      this.addEditShippingAddressVisible = false;
    },
    submitUpsertShippingAddress(): void {
      this.addEditShippingAddressVisible = false;
      const country = this.uspsertShippingAddressCountries.find(
        country => country.Id === this.uspsertShippingAddressModel.CountryId,
      );
      if (country) {
        this.uspsertShippingAddressModel.Country = country.Name;
        this.uspsertShippingAddressModel.CountryCode = country.Code;
      }

      $.post(
        `/form/pms/UpsertShippingAddress?userId=${this.order.UserId}&orderId=${this.order.Id}`,
        this.uspsertShippingAddressModel,
      )
        .done(() => {
          this.PopupNotificationWidget?.show(
            `Shipping address successfully updated`,
            "success",
          );
          this.getOrder();
        })
        .fail(() => {
          this.PopupNotificationWidget?.show(
            `Something went wrong with Shipping address update`,
            "error",
          );
          this.uspsertShippingAddressModel = {};
        });
    },
    onTotalInputBlur(): void {
      if (this.refundRechrgeTotal > this.refundRechrgeTotalMax) {
        this.refundRechrgeTotal = this.refundRechrgeTotalMax;
      }
    },
    getManualPaymentMethod(optionId: number) {
      return this.manualPaidOptions.find(
        (option: any) => option.Id === optionId,
      ).Name;
    },
    getPaidType() {
      if (this.order.IsManualPaid) {
        return "Manual Paid";
      }
      if (
        this.order.IsASFForInvoice ||
        this.order.IsInHouseForInvoice ||
        this.order.IsPrintForInvoice
      ) {
        return "For Invoice";
      }
      return null;
    },
    onChangeUpdateCourier(): void {
      if (!this.$account.userInRole.authorized) {
        this.PopupNotificationWidget?.show(
          "Sorry you must be a super admin to upgrade to courier",
          "error",
        );
        this.shouldUpdateCourier = !this.shouldUpdateCourier;
        return;
      }
    },
    onLastOrderClick(): void {
      const _window: any = window;
      _window.router = this.$router;
      _window.router
        .push({
          path: `/orders/${this.order.LastOrderId}`,
        })
        .then(() => _window.router.go(this.$router.currentRoute));
      window.location.assign(this.$route.fullPath);
    },
    onNextOrderClick(): void {
      const _window: any = window;
      _window.router = this.$router;
      (_window.router as Router).push(`/orders/${this.order.NextOrderId}`);
    },
    onBulkDispatchReceivedClick(): void {
      $.post("/form/pms/SetBulkDispatchReceived", { orderId: this.order.Id })
        .done((data: any) => {
          this.PopupNotificationWidget?.show(
            `Bulk dispatch received successfully updated`,
            "success",
          );
          this.bulkDispatchReceivedSuccess = data.bulkDispatchReceivedSuccess;
          this.order.BulkDispatchReceivedDate =
            data.order.BulkDispatchReceivedDate;
        })
        .fail(() => {
          this.PopupNotificationWidget?.show(
            `Something went wrong with Bulk dispatch received update`,
            "error",
          );
          this.bulkDispatchReceivedSuccess = false;
          this.order.BulkDispatchReceivedDate = null;
        });
    },
    noteAdded(note: Note): void {
      if (!this.order) {
        return;
      }

      switch (note.Type) {
        case OrderNoteType.Order:
          this.order.Notes.push(note);
          break;
        case OrderNoteType.User:
          this.order.CustomerNotes.push(note);
          break;
      }
    },
    noteUpdated(note: Note): void {
      if (!this.order) {
        return;
      }

      if (note.Type === OrderNoteType.User) {
        this.order.Notes = this.order.Notes.filter(
          (n: Note) => n.Id !== note.Id,
        );

        if (this.order.CustomerNotes.some((n: Note) => n.Id === note.Id)) {
          this.order.CustomerNotes = this.order.CustomerNotes.map((n: Note) =>
            n.Id !== note.Id ? n : note,
          );
        } else {
          this.order.CustomerNotes.push(note);
        }
      }

      if (note.Type === OrderNoteType.Order) {
        this.order.CustomerNotes = this.order.CustomerNotes.filter(
          (n: Note) => n.Id !== note.Id,
        );

        if (this.order.Notes.some((n: Note) => n.Id === note.Id)) {
          this.order.Notes = this.order.Notes.map((n: Note) =>
            n.Id !== note.Id ? n : note,
          );
        } else {
          this.order.Notes.push(note);
        }
      }
    },
    noteDeleted(noteId: number): void {
      if (!this.order) {
        return;
      }

      this.order.Notes = this.order.Notes.filter(
        (note: Note) => note.Id !== noteId,
      );
      this.order.CustomerNotes = this.order.CustomerNotes.filter(
        (note: Note) => note.Id !== noteId,
      );
    },
  },
});
