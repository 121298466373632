
import { defineComponent, PropType, ref, Ref } from "vue";
import axios from "axios";
import { Notification as KNotification } from "@progress/kendo-popups-vue-wrapper";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Switch as KSwitch } from "@progress/kendo-vue-inputs";

export interface SalesConfiguration {
  type: ClientType;
  commissionType: CommissionType | null;
  salesRepId: number | null;
  accountHandlerId: number | null;
  firstPo: string | null;
  firstAsf: string | null;
  manualFirstPo: string | null;
  manualFirstAsf: string | null;
  transferred: boolean;
}

export interface SalesInformation {
  Type: ClientType;
  CommissionType: CommissionType | null;
  SalesRepId: number | null;
  AccountHandlerId: number | null;
  FirstPo?: string | null;
  FirstAsf?: string | null;
  ManualFirstPo: string | null;
  ManualFirstAsf: string | null;
  CustomerId: number;
  Transferred: boolean;
}

export enum ClientType {
  InHouse = 1,
  FrenzyClient = 2,
  NonFrenzyClient = 3,
}

export enum CommissionType {
  Standard = 1,
}

export interface User {
  Id: number;
  Name: string;
}

export default defineComponent({
  name: "CustomerSales",
  components: {
    "k-notification": KNotification,
    DatePicker,
    KSwitch,
  },
  props: {
    userId: {
      type: Object as PropType<number>,
      required: true,
    },
  },
  setup() {
    const popupNotification: Ref = ref(null);
    const salesConfiguration: Ref<SalesConfiguration> = ref<SalesConfiguration>(
      {
        type: ClientType.InHouse,
        commissionType: null,
        salesRepId: null,
        accountHandlerId: null,
        firstPo: null,
        firstAsf: null,
        manualFirstPo: null,
        manualFirstAsf: null,
        transferred: false,
      },
    );
    const isLoading: Ref<boolean> = ref<boolean>(false);
    const isDisabled: Ref<boolean> = ref<boolean>(true);
    const transferred: Ref<boolean> = ref<boolean>(false);
    const salesRepUsers: Ref<User[]> = ref<User[]>([]);
    const accountHandlerUsers: Ref<User[]> = ref<User[]>([]);

    const firstPoDate: Ref<Date | null> = ref<Date | null>(null);
    const firstAsfDate: Ref<Date | null> = ref<Date | null>(null);
    const manualFirstPoDate: Ref<Date | null> = ref<Date | null>(null);
    const manualFirstAsfDate: Ref<Date | null> = ref<Date | null>(null);

    return {
      popupNotification,
      salesConfiguration,
      isLoading,
      isDisabled,
      salesRepUsers,
      accountHandlerUsers,
      firstPoDate,
      firstAsfDate,
      manualFirstPoDate,
      manualFirstAsfDate,
      transferred,
    };
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
    };
  },
  computed: {
    ClientType() {
      return ClientType;
    },
    CommissionType() {
      return CommissionType;
    },
    isValidForm(): boolean {
      return !!this.salesConfiguration.type;
    },
  },
  async mounted() {
    this.isLoading = true;

    this.popupNotificationWidget = this.popupNotification?.kendoWidget();

    const salesRepUsersRequest = axios.get<{ Users: User[] }>(
      "/form/rolesAdmin/GetUsersByRole?roleName=salesRep",
    );
    const accountHandlerUsersRequest = axios.get<{ Users: User[] }>(
      "/form/rolesAdmin/GetUsersByRole?roleName=accountHandler",
    );
    const salesInformationRequest = axios.get<SalesInformation>(
      `/form/pms/GetSalesInformation?customerId=${this.userId}`,
    );
    const userIsInRoleRequest = axios.post<{ authorized: boolean }>(
      "/form/user/UserIsInRole",
      {
        roles: ["superAdmin", "salesadmin"],
      },
    );

    const response = await Promise.all([
      salesRepUsersRequest,
      accountHandlerUsersRequest,
      salesInformationRequest,
      userIsInRoleRequest,
    ]);

    this.salesRepUsers = response[0].data.Users;
    this.accountHandlerUsers = response[1].data.Users;

    const salesInformation = response[2].data;
    if (salesInformation) {
      this.salesConfiguration.type = salesInformation.Type;
      this.salesConfiguration.commissionType = salesInformation.CommissionType;
      this.salesConfiguration.salesRepId = salesInformation.SalesRepId;
      this.salesConfiguration.accountHandlerId =
        salesInformation.AccountHandlerId;
      this.firstPoDate = salesInformation.FirstPo
        ? new Date(salesInformation.FirstPo)
        : null;
      this.firstAsfDate = salesInformation.FirstAsf
        ? new Date(salesInformation.FirstAsf)
        : null;
      this.manualFirstPoDate = salesInformation.ManualFirstPo
        ? new Date(salesInformation.ManualFirstPo)
        : null;
      this.manualFirstAsfDate = salesInformation.ManualFirstAsf
        ? new Date(salesInformation.ManualFirstAsf)
        : null;

      this.transferred = salesInformation.Transferred;
    }

    this.isDisabled = !response[3].data.authorized;

    this.isLoading = false;
  },

  methods: {
    async save(): Promise<void> {
      this.isLoading = true;

      const requestModel: SalesInformation = {
        Type: this.salesConfiguration.type,
        CommissionType: this.salesConfiguration.commissionType,
        SalesRepId: this.salesConfiguration.salesRepId,
        AccountHandlerId: this.salesConfiguration.accountHandlerId,
        ManualFirstPo:
          this.manualFirstPoDate?.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }) ?? null,
        ManualFirstAsf:
          this.manualFirstAsfDate?.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }) ?? null,
        CustomerId: this.userId,
        Transferred: this.transferred,
      };

      try {
        await axios.post("/form/pms/ModifySalesInformation", requestModel);

        this.popupNotificationWidget?.show(
          "Sales Information was updated",
          "success",
        );
      } catch {
        this.popupNotificationWidget?.show(
          "Sales Information was not updated",
          "error",
        );
      }

      this.isLoading = false;
    },
    onManualFirstPoDateChange(e: any): void {
      this.manualFirstPoDate = e.target.value;
    },
    onManualFirstAsfDateChange(e: any): void {
      this.manualFirstAsfDate = e.target.value;
    },
  },
});
