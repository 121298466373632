import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bb6721c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = {
  key: 0,
  class: "col-xs-2 mx-1 sub-header asf-framing"
}
const _hoisted_3 = {
  key: 1,
  class: "col-sm-2 mx-1 sub-header branding"
}
const _hoisted_4 = {
  key: 2,
  class: "col-sm-2 mx-1 sub-header insert"
}
const _hoisted_5 = {
  key: 3,
  class: "col-sm-2 mx-1 sub-header coa"
}
const _hoisted_6 = {
  key: 4,
  class: "col-sm-2 mx-1 sub-header bespoke-mounting"
}
const _hoisted_7 = {
  key: 5,
  class: "col-sm-2 mx-1 sub-header bespoke-framing"
}
const _hoisted_8 = {
  key: 6,
  class: "col-sm-2 mx-1 sub-header asf-custom-finishing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.getAsfFramingSubHeader || _ctx.getBrandingSubHeader || _ctx.getInsertSubHeader || _ctx.getCoaSubHeader
    || _ctx.getBespokeMountingSubHeader || _ctx.getBespokeFramingSubHeader || _ctx.getAsfCustomFinishingSubHeader)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.getAsfFramingSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getAsfFramingSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getBrandingSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getBrandingSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getInsertSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getInsertSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getCoaSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getCoaSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getBespokeMountingSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getBespokeMountingSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getBespokeFramingSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.getBespokeFramingSubHeader), 1))
          : _createCommentVNode("", true),
        (_ctx.getAsfCustomFinishingSubHeader)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.getAsfCustomFinishingSubHeader), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}