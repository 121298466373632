
import { defineComponent, ref } from "vue";

declare var $: any;

export default defineComponent({
  name: "RefundRedoTypes",
  components: {},
  setup() {
    const grid: any = ref(null);

    return { grid };
  },
  data() {
    return {
      gridHandle: "#refundRedoTypes",
    };
  },
  async mounted() {
    const _window: any = window;
    // eslint-disable-next-line
    const _this = this;
    const grid = this.grid;
    _window.router = this.$router;
    _window.ReasonTypeDataBound = () => {
      const grid = $(this.gridHandle).data("kendoGrid");
      grid.expandRow("tr.k-master-row:first");
    };

    $.get("/form/grid/RefundRedoTypesPartial", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug

      $(grid).html(html);
    });
  },
});
