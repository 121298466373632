import Papa from "papaparse";

export function getPmsBaseUrl(window: any) {
  if (window.location.host.includes("form.creativehub.io")) {
    return "https://app.creativehub.io";
  }

  if (window.location.host.includes("form.sc.tps-test.io")) {
    return "https://app-sc.creativehub.io";
  }

  return "https://app.creativehub.local:10443";
}

// ----------------------------------------------------------------------------

export async function loadKendoGrid(
  $: any,
  ref: any,
  url: string | null | undefined,
): Promise<void> {
  if (!url) return;
  let html = await $.get(url);
  html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
  html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug
  $(ref).html(html);
}

// ----------------------------------------------------------------------------

export function dateDiffInDays(a: Date, b: Date): number {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

// ----------------------------------------------------------------------------

export function getUtcDate(): Date {
  return new Date(new Date().toUTCString().slice(0, -4));
}

// ----------------------------------------------------------------------------

export function getCurrencySymbol(id: number) {
  switch (id) {
    case 1:
      return "£";
    case 2:
      return "€";
    case 3:
      return "$";
    default:
      return "NaN";
  }
}

// ----------------------------------------------------------------------------

export function getRedHotDeliveryPostcodePrefix(postcode: string) {
  if (!postcode || !postcode.trim) {
    return null;
  }
  postcode = postcode.trim();
  const regex =
    /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9]{1,2})\s*[0-9][A-Za-z]{2}$|^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9])[A-Za-z]\s*[0-9][A-Za-z]{2}$/;
  const match = regex.exec(postcode);
  if (!match) {
    return null;
  }
  if (!match[1]) {
    return match[2];
  }
  return match[1];
}

// ----------------------------------------------------------------------------

export function doIntersect(a: any, b: any) {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }

  return a.some(function (aItem) {
    return b.indexOf(aItem) >= 0;
  });
}

// ----------------------------------------------------------------------------

export function saveCsv<T>(filename: string, list: T[]): void;
export function saveCsv(filename: string, content: string): void;
export function saveCsv<T>(filename: string, contentOrList: unknown): void {
  const csvContent =
    typeof contentOrList === "string"
      ? contentOrList
      : toCsv(contentOrList as T[]);

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  URL.revokeObjectURL(link.href);
}

// ----------------------------------------------------------------------------

export function toCsv<T>(list: T[]) {
  return Papa.unparse(list);
}

// ----------------------------------------------------------------------------
