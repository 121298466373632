
import { defineComponent, ref, Ref } from "vue";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import { 
  Button as KButton,
  SplitButton as KSplitButton, 
} from "@progress/kendo-vue-buttons";
import {
  MONTH_LABELS,
  BreakdownLine,
  BreakdownLineFmt,
  ClientManager,
  ActionCode,
  ClientManagerItem,
  ClientManagerType,
} from "../typesAndConstants";
import ChangeClientManagerInfoModal from "../ActionModals/ChangeClientManagerInfoModal.vue";
import RecalculateCommissionsModal from "../ActionModals/RecalculateCommissionsModal.vue";
import ViewAuditModal from "../ActionModals/ViewAuditModal.vue";

import * as helpers from "../helpers";
import * as api from "../api";

type Month = { id: string; text: string };

type BreakdownValues = {
  months: Month[];
  selectedMonth: Month;
  data: Map<string, BreakdownLine[]>;
};

export default defineComponent({
  name: "ClientManagerBreakdownTab",
  components: {
    KDropDownList,
    KButton,
    KSplitButton,
    ChangeClientManagerInfo: ChangeClientManagerInfoModal,
    RecalculateCommissionsModal,
    ViewAuditModal,
  },
  props: {
    exportOptions: { type: Array, required: true },
    selectedExportOption: { type: Object, required: true },
    clientManagers: { type: Array, required: true },
    selectedClientManager: { type: Object, required: true },
    clientManagerType: { type: Number, required: true },
    dateRangeFrom: { type: Object, required: true },
    dateRangeTo: { type: Object, required: true },
  },
  emits: [
    "breakdown-year-changed",
    "breakdown-sales-rep-changed",
    "data-loading",
    "data-loaded",
  ],
  setup(props: any) {
    const isSalesRep = props.clientManagerType == ClientManagerType.SalesRep;

    const clientManagerTitle = isSalesRep ? "Sales Rep" : "Account Hanlder";
    const clientActionsByManagerType = isSalesRep
      ? [
          {
            label: `Change ${clientManagerTitle} Info`,
            code: "changeClientManagerInfo",
          },
          { label: "Recalculate Commissions", code: "recalculateCommissions" },
          { label: "View Audit", code: "viewAudit" },
        ]
      : [
          { label: "Recalculate Commissions", code: "recalculateCommissions" },
          { label: "View Audit", code: "viewAudit" },
        ];

    const userRoles: Ref<any> = ref({});
    const actions = ref(clientActionsByManagerType);
    const actionModel: Ref<ClientManagerItem | null> = ref(null);
    const months: Ref<Month[]> = ref([] as Array<Month>);
    const selectedMonth: Ref<Month> = ref({} as Month);
    const data: Ref<Map<string, BreakdownLine[]>> = ref(
      new Map<string, BreakdownLine[]>(),
    );
    const showActionModal: Ref<ActionCode> = ref("none");
    const expandLte12Mo = ref(false);
    const expandGt12Mo = ref(false);
    const expandTransferred = ref(false);
    
    return {
      userRoles,
      months,
      selectedMonth,
      data,
      showActionModal,
      actions,
      actionModel,
      expandLte12Mo,
      expandGt12Mo,
      expandTransferred,
    };
  },
  computed: {
    colspanLte12Mo(): number {
      return this.expandLte12Mo ? 4 : 2;
    },
    colspanGt12Mo(): number {
      return this.expandGt12Mo ? 4 : 2;
    },
    colspanTranferred(): number {
      return this.expandTransferred ? 4 : 2;
    },
    currentClientManager(): ClientManager {
      return this.selectedClientManager as ClientManager;
    },
    inAdminRole(): boolean {
      return (
        this.userRoles &&
        this.userRoles?.roles?.some((role: string) =>
          this.$account.adminRoles.includes(role),
        )
      );
    },
    lines(): BreakdownLine[] {
      //const key = this.selectedMonth?.text?.slice(0, 3);
      const key = this.selectedMonth?.id;

      return this.data ? this.data.get(key) ?? [] : [];
    },
    fmtLines(): BreakdownLineFmt[] {
      return this.lines.map(helpers.makeBreakdownLineFmt);
    },
    stdCommRevLe12MoRevenuesTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevLe12MoRevenues,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevLe12MoDeductionsTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevLe12MoDeductions,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevLe12MoCommissionableTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevLe12MoCommissionable,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevLe12MoCommissionTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevLe12MoCommission,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevGt12MoRevenuesTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevGt12MoRevenues,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevGt12MoDeductionsTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevGt12MoDeductions,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevGt12MoCommissionableTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevGt12MoCommissionable,
        0,
      );

      return helpers.moneyFmt(result);
    },
    stdCommRevGt12MoCommissionTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.StdCommRevGt12MoCommission,
        0,
      );

      return helpers.moneyFmt(result);
    },
    transferredRevenuesTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.TransferredRevenues,
        0,
      );

      return helpers.moneyFmt(result);
    },
    transferredDeductionsTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.TransferredDeductions,
        0,
      );

      return helpers.moneyFmt(result);
    },
    transferredCommissionableTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.TransferredCommissionable,
        0,
      );

      return helpers.moneyFmt(result);
    },
    transferredCommissionTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.TransferredCommission,
        0,
      );

      return helpers.moneyFmt(result);
    },
    zeroCommRevenuesTotal(): number {
      const result = this.lines.reduce(
        (acc, val) => acc + val.ZeroCommRevenues,
        0,
      );
      return result;
    },
    zeroCommRevenuesTotalFmt(): string {
      return helpers.moneyFmt(this.zeroCommRevenuesTotal);
    },
    zeroCommCommissionTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.ZeroCommCommission,
        0,
      );
      return helpers.moneyFmt(result);
    },
    totalPayableCommissionsTotalFmt(): string {
      const result = this.lines.reduce(
        (acc, val) => acc + val.TotalPayableCommissions,
        0,
      );

      return helpers.moneyFmt(result);
    },
    hasZeroCommRevenuesTotal(): boolean {
      return this.zeroCommRevenuesTotal > 0;
    },
  },
  watch: {
    dateRangeFrom: {
      async handler(_n, _o) {
        await this.update();
      },
    },
    dateRangeTo: {
      async handler(_n, _o) {
        await this.update();
      },
    },
  },  
  async mounted() {
    this.userRoles = await this.$account.getUserInRoles(
      this.$account.rolesForCommissions,
    );
    await this.update();
  },
  methods: {
    async update() {
      this.$emit("data-loading");
      const result = await this.load();
      this.months = result.months;
      this.selectedMonth = result.selectedMonth;
      this.data = result.data;
      this.$emit("data-loaded");
    },
    async load(): Promise<BreakdownValues> {
      const data = await api.getRevenueAndCommissionsClientManagerBreakdown(
        this.dateRangeFrom as Date,
        this.dateRangeTo as Date,
        this.selectedClientManager?.Id,
      );
      const monthYearKey = (o: any) => `${o.Year}|${o.Month}`;
      const monthYearDeconstruct = (o: string): [number, number] => {
        const els = o.split("|");
        if (els.length != 2) throw new Error("invalid monthYear format");
        if (
          !Number.isInteger(parseInt(els[0])) ||
          !Number.isInteger(parseInt(els[1]))
        )
          throw new Error("invalid monthYear format");

        return [parseInt(els[0]), parseInt(els[1])];
      };
      const dict = new Map<string, BreakdownLine[]>();
      const monthYears = new Set<string>(data.map(monthYearKey));
      for (const monthYear of monthYears) {
        dict.set(monthYear, []);
      }

      for (const item of data) {
        const lineItem = helpers.makeBreakdownLine({
          Client: item.ClientFullName,
          Email: item.ClientEmail,
          CommissionRateKind: item.CommissionRateKind,
          Commissionable: item.CommissionableRevenues,
          Deductions: item.NegativeRevenues,
          Revenues: item.PositiveRevenues,
          Commissions: item.Commissions,
        });

        dict.get(monthYearKey(item))?.push(lineItem);
      }
      const ms = [...monthYears].map(m => {
        const [year, month] = monthYearDeconstruct(m);
        const calendarYearLabel = `${year}`.slice(-2);
        const monthLabel = `${MONTH_LABELS[month - 1]} '${calendarYearLabel}`;

        return { id: m, text: monthLabel };
      });

      const selectedMonth = ms[0];
      const breakdown = {
        months: ms,
        selectedMonth,
        data: dict,
      };

      return breakdown;
    },
    // #region Event Handlers
    async onYearChanged(event: any): Promise<void> {
      this.$emit("breakdown-year-changed", {
        value: event.value,
      });
      await this.update();
    },

    onMonthChanged(event: any) {
      this.selectedMonth = event.value;
    },

    async onSalesRepChanged(event: any): Promise<void> {
      this.$emit("breakdown-sales-rep-changed", {
        value: event.value,
      });
      await this.update();
    },
    async onExportItemClicked(): Promise<void> {

      const salesRepUserId = this.selectedClientManager?.Id ?? 0;

      const { fileName, mimeType, content } = helpers.getCsvInfo(
        await api.getRevenueAndCommissionsClientManagerBreakdownCsv(
          this.dateRangeFrom as Date,
          this.dateRangeTo as Date,
          salesRepUserId,
        ),
      );

      helpers.saveAsFile(content, mimeType, fileName);
    },
    onCloseModal(): void {
      this.showActionModal = "none";
    },
    async onCommandExecuted(): Promise<void> {
      await this.update();
    },
    onActionClicked(event: any): void {
      this.showActionModal = event.item.code;
    },
    onToggleExpandLte12Mo(): void {
      this.expandLte12Mo = !this.expandLte12Mo;
    },
    onToggleExpandGt12Mo(): void {
      this.expandGt12Mo = !this.expandGt12Mo;
    },
    onToggleExpandTransferred(): void {
      this.expandTransferred = !this.expandTransferred;
    },
    // #endregion
  },
});
