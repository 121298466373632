
import { defineComponent, ref, Ref } from "vue";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import {
  SplitButton as KSplitButton,
  Button as KButton,
} from "@progress/kendo-vue-buttons";

import {
  ExportValueOption,
  SummaryRevenueItemValueType,
  SummaryRevenueItemKind,
  DataTable,
  MONTH_LABELS,
  RevenueAndCommissionSummaryItem,
  RevenueAndCommissionsInfo,
  ClientManagerType,
} from "../typesAndConstants";

import * as helpers from "../helpers";
import * as api from "../api";

export default defineComponent({
  name: "SummaryTab",
  components: {
    KDropDownList,
    KSplitButton,
    KButton,
  },
  props: {
    exportOptions: { type: Array, required: true },
    selectedExportOption: { type: Object, required: true },
    clientManagers: { type: Array, required: true },
    selectedClientManager: { type: Object, required: true },
    clientManagerType: { type: Number, required: true },
    dateRangeFrom: { type: Object, required: true },
    dateRangeTo: { type: Object, required: true },
  },
  emits: ["summary-export-option-changed", "data-loading", "data-loaded"],
  setup() {
    const data: Ref<DataTable> = ref({
      headers: [] as string[],
      body: [] as Array<string[]>,
    });
    const userRoles: Ref<any> = ref({});
    return {
      data,
      userRoles,
    };
  },
  computed:{
    hasSourceDataButton() {
      return (
        this.userRoles &&
        this.userRoles?.roles?.some((role: string) =>
          this.$account.rolesForCommissionsSummaryDataSource.includes(role),
        )
      );
    },
  },
  watch: {
    dateRangeFrom: {
      async handler(_n, _o) {
        this.data = await this.load();
      },
    },
    dateRangeTo: {
      async handler(_n, _o) {
        this.data = await this.load();
      },
    },
  },
  async mounted() {
    this.data = await this.load();
    this.userRoles = await this.$account.getUserInRoles(
      this.$account.rolesForCommissions,
    );
  },
  methods: {
    async load(): Promise<DataTable> {
      const isSalesRepCommissions =
        this.clientManagerType == ClientManagerType.SalesRep;

      const getMonthlyValues = (months: Date[], record: any): string[] => {
        const monthlyValues = [...Array(months.length)]
          .fill(0)
          .map(helpers.moneyFmt);
        let idx = 0; 
        for (const info of record.RevenueAndCommissionsInfo) {
          const value =
            this.selectedExportOption?.value == ExportValueOption.Revenue
              ? info.CommissionableRevenue
              : info.Commissions;
          monthlyValues[idx] = helpers.moneyFmt(value);
          idx++;
        }
        return monthlyValues;
      };

      const getRowDescription = (record: RevenueAndCommissionSummaryItem) => {
        return record.SummaryRevenueItemValueType ==
          SummaryRevenueItemValueType.TypeOfRevenue
          ? record.Kind == SummaryRevenueItemKind.HouseClients ||
            record.Kind == SummaryRevenueItemKind.AgencyRevenueType1
            ? `Other ${helpers.strOfSummaryRevenueItemKind(record.Kind)}`
            : helpers.strOfSummaryRevenueItemKind(record.Kind)
          : record.SummaryRevenueItemValueType ==
            SummaryRevenueItemValueType.SpecialAccount
          ? `${
              record.Description
            }<br><span class="summary-revenue-info">${helpers.strOfSummaryRevenueItemKind(
              record.Kind,
              true,
            )}</span>`
          : record.SummaryRevenueItemValueType ==
            SummaryRevenueItemValueType.Total
          ? `<strong>Total for ${helpers.strOfSummaryRevenueItemKind(
              record.Kind,
              true,
            )}</strong>`
          : "";
      };

      const getRow = (
        months: Date[],
        record: RevenueAndCommissionSummaryItem,
      ) => {
        return [getRowDescription(record), ...getMonthlyValues(months, record)];
      };

      const bodyBuilder = (months: Date[]) => {
        const body: any[] = [];
        const add = (recordOrList: any): void => {
          if (Array.isArray(recordOrList)) {
            for (const record of recordOrList) {
              body.push(getRow(months, record));
            }
          } else {
            body.push(getRow(months, recordOrList));
          }
        };
        const addAll = (...args: any[]): void => {
          for (const arg of args) {
            add(arg);
          }
        };
        const build = () => body;

        return {
          add,
          addAll,
          build,
        };
      };

      const sumBuilder = () => {
        const result = new Map<string, RevenueAndCommissionsInfo>();
        const pushRecord = (record: RevenueAndCommissionSummaryItem) => {
          for (const item of record.RevenueAndCommissionsInfo) {
            const key = `${item.Year}${item.Month.toString().padStart(2, "0")}`;
            if (!result.has(key)) {
              result.set(key, {
                Year: item.Year,
                Month: item.Month,
                CommissionableRevenue: 0,
                Commissions: 0,
              });
            }

            const value = result.get(key);
            if (!value) throw new Error("Key not found");
            value.CommissionableRevenue += item.CommissionableRevenue;
            value.Commissions += item.Commissions;
          }
        };
        const sum = () => [...result.values()];

        return {
          pushRecord,
          sum,
        };
      };

      const sumRecords = (
        recordA: RevenueAndCommissionSummaryItem,
        recordB: RevenueAndCommissionSummaryItem,
      ) => {
        if (recordA.Kind != recordB.Kind)
          throw new Error("You cannot sum records with different kind!");
        const sb = sumBuilder();
        [recordA, recordB].forEach(sb.pushRecord);
        const revenuAndCommissionInfo = sb.sum();
        const result = {
          SummaryRevenueItemValueType: SummaryRevenueItemValueType.Total,
          Kind: recordA.Kind,
          RevenueAndCommissionsInfo: revenuAndCommissionInfo,
        };

        return result;
      };

      // ---------------------------------------------------------------------
      this.$emit("data-loading");

      const [
        revenueType1Data,
        revenueType2Data,
        revenueType3Data,
        inHouseData,
      ] = await api.getRevenueAndCommissionsSummary(
        this.dateRangeFrom as Date,
        this.dateRangeTo as Date,
        this.clientManagerType,
      );

      const [allisonFriendData, magnumPhotographyData] =
        await api.getSpecialAccountRevenueAndCommissionsSummary(
          this.dateRangeFrom as Date,
          this.dateRangeTo as Date,
          this.clientManagerType,
        );

      const headers: string[] = ["Type of revenue"];
      const months = helpers.pushMonthlyHeadersDataRange(
        headers,
        this.dateRangeFrom as Date,
        this.dateRangeTo as Date,
        MONTH_LABELS,
      );
      const bb = bodyBuilder(months);
      if (isSalesRepCommissions && magnumPhotographyData)
        bb.add(magnumPhotographyData);
      if (inHouseData) bb.add(inHouseData);
      if (isSalesRepCommissions && inHouseData && magnumPhotographyData) {
        const totalInHouse = sumRecords(magnumPhotographyData, inHouseData);
        bb.add(totalInHouse);
      }
      const hasAllisonFriendData =
        allisonFriendData &&
        !!allisonFriendData.RevenueAndCommissionsInfo?.length;
      if (hasAllisonFriendData) bb.add(allisonFriendData);
      if (revenueType1Data) bb.add(revenueType1Data);
      if (revenueType1Data && hasAllisonFriendData) {
        const totalRevenueType1 = sumRecords(
          allisonFriendData,
          revenueType1Data,
        );
        bb.add(totalRevenueType1);
      }
      if (revenueType2Data) bb.add(revenueType2Data);
      if (revenueType3Data) bb.add(revenueType3Data);

      this.$emit("data-loaded");
      return {
        headers,
        body: bb.build(),
      };
    },
    // #region Event Handlers
    async onExportOptionChanged(event: any): Promise<void> {
      this.$emit("summary-export-option-changed", {
        value: event.value,
      });
      this.data = await this.load();
    },
    async onExportItemClicked(event: any): Promise<void> {
      const exportOption = event.item.value;

      const { fileName, mimeType, content } = helpers.getCsvInfo(
        await api.getRevenueAndCommissionsSummaryCsv(
          this.dateRangeFrom as Date,
          this.dateRangeTo as Date,
          exportOption,
          this.clientManagerType,
        ),
      );

      helpers.saveAsFile(content, mimeType, fileName);
    },
    async onSourceDataClicked(): Promise<void> {
      const { fileName, mimeType, content } = helpers.getCsvInfo(
        await api.getRevenueAndCommissionsSummarySourceDataCsv(
          this.dateRangeFrom as Date,
          this.dateRangeTo as Date,
          this.clientManagerType,
        ),
      );

      helpers.saveAsFile(content, mimeType, fileName);
    },
    // #endregion
  },
});
