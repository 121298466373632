
import { defineComponent, ref, Ref } from "vue";
import axios from "axios";
import { Grid } from "@progress/kendo-vue-grid";
import { PresetSizes } from "@/core/static-data/presetSizes";
import { PresetSizeModel } from "@/core/types/presetSizeModel";
import { Orientation } from "@/core/enums/orientation";
import { Notification as KNotification } from "@progress/kendo-popups-vue-wrapper";
import { getCurrencySymbol } from "@/core/utils";
import VariantConfigurationModal from "@/components/Modal/VariantConfigurationModal.vue";
import { mmToInches } from "@/core/util/dimensionUtils";

export default defineComponent({
  name: "ProductVariants",
  components: {
    Grid: Grid,
    "k-notification": KNotification,
    VariantConfigurationModal: VariantConfigurationModal,
  },
  data() {
    return {
      popupNotificationWidget: {} as any,
      currencySymbol: "",
    };
  },
  setup() {
    const variants: Ref<any[]> = ref<any[]>([]);
    const initialVariants: Ref<any[]> = ref<any[]>([]);
    const isLoading: Ref<boolean> = ref<boolean>(false);
    const image: Ref = ref<any>();
    const printTypeId: Ref<number | null> = ref<number | null>(null);
    const options: Ref = ref<any>();
    const paperId: Ref<number | null> = ref<number | null>(null);
    const popupNotification: Ref = ref(null);
    const showVariantConfigurationModal: Ref<boolean> = ref<boolean>(false);
    const variantConfigurationModalOptions: Ref = ref<any | null>(null);

    return {
      variants,
      initialVariants,
      isLoading,
      image,
      printTypeId,
      options,
      paperId,
      popupNotification,
      showVariantConfigurationModal,
      variantConfigurationModalOptions,
    };
  },
  async mounted() {
    this.isLoading = true;

    this.popupNotificationWidget = this.popupNotification?.kendoWidget();

    const variantsPromise = axios.post("/tps/Image/GetImagePropertiesForUser", {
      userId: this.$route.params.customerId,
      imageIds: [this.$route.params.productId],
    });

    const customerDetailsPromise = axios.post(
      "/form/pms/GetCustomerNameAndShippingAddresses",
      { customerId: this.$route.params.customerId },
    );

    const results = await Promise.all([
      variantsPromise,
      customerDetailsPromise,
    ]);

    const variantsResponse = results[0].data;
    const customerDetailsResponse = results[1].data;

    const optionsResponse = await axios.get(
      `/tps/OrderOptions/OptionsVue?countryId=${customerDetailsResponse.countryId}`,
    );

    this.variants = JSON.parse(JSON.stringify(variantsResponse[0].Pricings));
    this.initialVariants = JSON.parse(
      JSON.stringify(variantsResponse[0].Pricings),
    );
    this.image = variantsResponse[0].Image;
    this.printTypeId = variantsResponse[0].PrintTypeId;
    this.paperId = variantsResponse[0].PaperId;
    this.options = optionsResponse.data;
    this.currencySymbol = getCurrencySymbol(
      customerDetailsResponse.userBranchId,
    );
    const _window = window as any;
    if (!_window.cvDeliveryOptions) {
      const cvResponse = await axios.get(
        `/form/pms/CustomVariantDeliveryOptions`,
      );
      _window.cvDeliveryOptions = cvResponse.data;
    }

    this.isLoading = false;
  },
  computed: {
    columns() {
      return [
        {
          title: "Total print size (inc borders)",
          cell: "printSize",
        },
        {
          title: "Size and Framing",
          cell: "sizeAndFraming",
        },
        {
          title: "Custom Finishing",
          cell: "customFinishing",
        },
        {
          title: "Custom Finishing Details",
          cell: "customFinishingDetails",
        },
        {
          title: "Your cost",
          cell: "yourCost",
          width: "120px",
        },
        {
          title: "SKU",
          cell: "sku",
          width: "130px",
        },
        {
          title: "Actions",
          cell: "actions",
          width: "300px",
        },
      ];
    },
  },
  methods: {
    duplicateVariant(id: number): void {
      const variant = { ...this.variants.find(variant => variant.Id === id) };

      variant.isEdit = true;
      variant.IsCustomVariant = true;
      variant.Id = null;
      variant.parentId = id;
      variant.SKU = null;

      // If the original variant has framing than set it as a default value and disable it.
      if (variant.MouldingFrameId) {
        variant.CustomFinishing = "Framing";
      }

      this.variants.push(variant);
    },
    async deleteVariant(event: any): Promise<void> {
      try {
        await axios.delete(
          `/form/pms/DeleteCustomVariant?customVariantId=${event.dataItem.Id}`,
        );

        this.variants.splice(event.dataIndex - 1, 1);

        this.popupNotificationWidget?.show(
          "Custom Variant was deleted",
          "success",
        );
      } catch {
        this.popupNotificationWidget?.show(
          "Custom Variant was not deleted",
          "error",
        );
      }
    },
    async saveVariant(variant: any): Promise<void> {
      const model = {
        parentId: variant.parentId,
        variantId: variant.Id,
        customFinishing: variant.CustomFinishing,
        customFinishingDetails: variant.CustomFinishingDetails,
        additionalPricing: variant.AdditionalPricing,
        orderItemType:
          variant.orderItemType ??
          variant.ImagePricingSizeConfiguration.OrderItemType,
        substrateId:
          variant.substrateId ??
          variant.ImagePricingSizeConfiguration.SubstrateId,
        fixingId:
          variant.fixingId ?? variant.ImagePricingSizeConfiguration.FixingId,
        substrateSizeId:
          variant.substrateSizeId ??
          variant.ImagePricingSizeConfiguration.SubstrateSizeId,
        backingId:
          variant.backingId ?? variant.ImagePricingSizeConfiguration.BackingId,
        laminationId:
          variant.laminationId ??
          variant.ImagePricingSizeConfiguration.LaminationId,
        frameTypeId:
          variant.frameTypeId ??
          variant.ImagePricingSizeConfiguration.FrameTypeId,
        mouldingId:
          variant.mouldingId ??
          variant.ImagePricingSizeConfiguration.MouldingId,
        printSizeId:
          variant.printSizeId ??
          variant.ImagePricingSizeConfiguration.PrintSizeId,
        glassId:
          variant.glassId ?? variant.ImagePricingSizeConfiguration.GlassId,
        hangingOptionId:
          variant.hangingOptionId ??
          variant.ImagePricingSizeConfiguration.HangingOptionId,
        windowMountId:
          variant.windowMountId ??
          variant.ImagePricingSizeConfiguration.WindowMountId,
        borderTopMm: variant.borderTopMm ?? variant.BorderTopMM,
        borderRightMm: variant.borderRightMm ?? variant.BorderRightMM,
        borderBottomMm: variant.borderBottomMm ?? variant.BorderBottomMM,
        borderLeftMm: variant.borderLeftMm ?? variant.BorderLeftMM,
        priceExcludingVat:
          variant.priceExcludingVat ??
          variant.ImagePricingSizeConfiguration.PriceExcludingVat,
        substrateColourId:
          variant.substrateColourId ??
          variant.ImagePricingSizeConfiguration.SubstrateColourId,
        deliveryId:
          variant.deliveryId ??
          variant.ImagePricingSizeConfiguration.deliveryId,
        deliveryPriceJson:
          variant.deliveryPriceJson ??
          variant.ImagePricingSizeConfiguration.deliveryPriceJson,
      };

      try {
        await axios.post("/form/pms/ModifyCustomVariant", model);

        this.popupNotificationWidget?.show(
          "Custom Variant was saved",
          "success",
        );
      } catch {
        this.popupNotificationWidget?.show(
          "Custom Variant was not saved",
          "error",
        );
      }

      variant.isEdit = false;

      const variantsResponse = await axios.post(
        "/tps/Image/GetImagePropertiesForUser",
        {
          userId: this.$route.params.customerId,
          imageIds: [this.$route.params.productId],
        },
      );

      this.variants = JSON.parse(
        JSON.stringify(variantsResponse.data[0].Pricings),
      );
      this.initialVariants = JSON.parse(
        JSON.stringify(variantsResponse.data[0].Pricings),
      );
    },
    editVariant(variant: any): void {
      variant.isEdit = true;
    },
    cancelEditVariant(event: any): void {
      const variant = event.dataItem;

      variant.isEdit = false;

      const initialVariant = this.initialVariants.find(
        v => v.Id === variant.Id,
      );
      if (initialVariant) {
        variant.CustomFinishing = initialVariant.CustomFinishing;
        variant.CustomFinishingDetails = initialVariant.CustomFinishingDetails;
        variant.AdditionalPricing = initialVariant.AdditionalPricing;
      }

      if (!variant.Id) {
        this.variants.splice(event.dataIndex - 1, 1);
      }
    },
    openVariantConfigurationModal(dataItem: any): void {
      this.showVariantConfigurationModal = true;
      this.variantConfigurationModalOptions = {
        customFinishing: dataItem.CustomFinishing,
        widthInches: this.getWidth(dataItem),
        heightInches: this.getHeight(dataItem),
        variant: dataItem,
      };
    },
    closeVariantConfigurationModal(event: any): void {
      if (event) {
        const variant = this.variants.find(
          v => v === this.variantConfigurationModalOptions.variant,
        );
        variant.orderItemType = event.orderItemType;
        variant.substrateId = event.substrateId;
        variant.fixingId = event.fixingId;
        variant.substrateSizeId = event.substrateSizeId;
        variant.backingId = event.backingId;
        variant.laminationId = event.laminationId;
        variant.frameTypeId = event.frameTypeId;
        variant.mouldingId = event.mouldingId;
        variant.printSizeId = event.printSizeId;
        variant.glassId = event.glassId;
        variant.hangingOptionId = event.hangingOptionId;
        variant.windowMountId = event.windowMountId;
        variant.borderTopMm = event.borderTopMm ?? 0;
        variant.borderRightMm = event.borderRightMm ?? 0;
        variant.borderBottomMm = event.borderBottomMm ?? 0;
        variant.borderLeftMm = event.borderLeftMm ?? 0;
        variant.priceExcludingVat = event.priceExcludingVat;
        variant.CustomFinishingDetails = event.customFinishingDetails;
        variant.substrateColourId = event.substrateColourId;
        variant.deliveryId = event.deliveryId;
        variant.deliveryPriceJson = event.deliveryPriceJson;
      }

      this.showVariantConfigurationModal = false;
      this.variantConfigurationModalOptions = null;
    },
    sizeText(variant: any): string {
      if (this.isCanvas(variant) && variant.FrameTypeId > 0) {
        return this.frameType(variant)?.Name;
      }

      if (variant.FrameTypeId > 0 && variant.MouldingId > 0) {
        return this.frameType(variant)?.Keyword.replace("Premade", "");
      }

      const landscape =
        variant.Orientation != null
          ? variant.Orientation == Orientation.Landscape
          : this.image.PhysicalWidth >= this.image.PhysicalHeight;

      const printWidthMM = landscape ? variant.LongSideMM : variant.ShortSideMM;
      const printHeightMM = landscape
        ? variant.ShortSideMM
        : variant.LongSideMM;

      const width = this.isCanvas(variant)
        ? printWidthMM
        : printWidthMM +
          variant.BorderLeftMM +
          variant.BorderRightMM +
          variant.wmBorderLeft +
          variant.wmBorderRight;
      const height = this.isCanvas(variant)
        ? printHeightMM
        : printHeightMM +
          variant.BorderTopMM +
          variant.BorderBottomMM +
          variant.wmBorderTop +
          variant.wmBorderBottom;

      const long = this.roundOff(Math.max(width, height));
      const short = this.roundOff(Math.min(width, height));

      const twoMMInches = 2 / 25.4;
      const r = PresetSizes.find((size: PresetSizeModel) => {
        const longMatches = Math.abs(long - size.long) < twoMMInches;
        const shortMatches = Math.abs(short - size.short) < twoMMInches;
        return longMatches && shortMatches;
      });
      if (r != null) return r.name;

      return "Custom size";
    },
    frameText(variant: any): string {
      let frame = "Print only";
      if (this.isCanvas(variant)) {
        frame = variant.FrameTypeId > 0 ? "Stretched" : "Unstretched Canvas";
      } else if (variant.FrameTypeId > 0 && variant.MouldingId > 0) {
        const texture = this.moulding(variant)?.TextureKeyword;
        frame = `${texture} framed`;
        if (variant.ColourId != null && variant.ColourId > 0) {
          return `${frame}, ${this.colour(variant).Keyword} mount`;
        }
      }

      if (this.hasBorders(variant) && !this.isCanvas(variant)) {
        return `${frame}, with border`;
      }
      return frame;
    },
    roundOff(n: number): number {
      return Math.round((n / 25.4) * 10) / 10;
    },
    isCanvas(variant: any): boolean {
      if (this.getPrintType(variant) == null && variant.printTypeId != null) {
        const printType = this.options.printTypes.find(
          (pt: any) => pt.Id == variant.printTypeId,
        );

        return printType?.Keyword == "Canvas";
      }

      return this.getPrintType(variant)?.Keyword == "Canvas";
    },
    frameType(variant: any): any {
      if (!variant) {
        return null;
      }
      return this.options.frameTypes.find(
        (p: any) => p.Id === variant.FrameTypeId,
      );
    },
    moulding(variant: any): any {
      return this.frameType(variant)
        ? this.frameType(variant).mouldings.find(
            (p: any) => p.Id === variant.MouldingId,
          )
        : null;
    },
    hasBorders(variant: any): boolean {
      return (
        variant.BorderTopMM > 0 ||
        variant.BorderBottomMM > 0 ||
        variant.BorderLeftMM > 0 ||
        variant.BorderRightMM > 0
      );
    },
    getPrintType(variant: any): any {
      return this.options.printTypes.find(
        (p: any) => p.Id === variant.printTypeId,
      );
    },
    colour(variant: any): any {
      if (!variant) {
        return null;
      }
      return this.options.colours.find((p: any) => p.Id === variant.ColourId);
    },
    getCustomFinishing(variant: any): string {
      if (variant.ImagePricingSizeConfiguration?.OrderItemType === 2) {
        return "Frame";
      }
      if (variant.ImagePricingSizeConfiguration?.OrderItemType === 3) {
        return "Mounting";
      }

      return "";
    },
    getWidth(variant: any): number {
      const isPortrait = this.image.Width > this.image.Height;

      const sizeInMM =
        (isPortrait ? variant.LongSideMM : variant.ShortSideMM) +
        variant.BorderLeftMM +
        variant.BorderRightMM +
        variant.wmBorderLeft +
        variant.wmBorderRight;
      return mmToInches(sizeInMM, 1);
    },
    getHeight(variant: any): number {
      const isPortrait = this.image.Width > this.image.Height;

      const sizeInMM =
        (isPortrait ? variant.ShortSideMM : variant.LongSideMM) +
        variant.BorderTopMM +
        variant.BorderBottomMM +
        variant.wmBorderTop +
        variant.wmBorderBottom;
      return mmToInches(sizeInMM, 1);
    },
  },
});
