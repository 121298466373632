import {
  SummaryRevenueItemKind,
  CommissionRateKind,
  ClientManagerGroup,
  SALES_TEAM_NAME,
  BreakdownLine,
  BreakdownLineInit,
  BreakdownLineFmt,
  AuditedCommandParams,
  CommissionUserRole,
} from "./typesAndConstants";

import { AxiosResponse } from "axios";

export const JAN = 0;
export const FEB = 1;
export const MAR = 2;
export const APR = 3;
export const MAY = 4;
export const JUN = 5;
export const JUL = 6;
export const AGO = 7;
export const SEP = 8;
export const OCT = 9;
export const NOV = 10;
export const DEC = 11;

type BreakdownLineKeys = keyof Omit<BreakdownLineInit, "Client" | "Email">;

export function makeBreakdownLine(item: BreakdownLineInit): BreakdownLine {
  const isHighRate = (item: BreakdownLineInit): boolean =>
    item.CommissionRateKind == CommissionRateKind.SpecialRate ||
    item.CommissionRateKind == CommissionRateKind.HighRate ||
    item.CommissionRateKind == CommissionRateKind.AccountHandlerRate;

  const isLowRate = (item: BreakdownLineInit): boolean =>
    item.CommissionRateKind == CommissionRateKind.LowRate;

  const isTransferredRate = (item: BreakdownLineInit): boolean =>
    item.CommissionRateKind == CommissionRateKind.TransferredRate;

  const isNoRate = (item: BreakdownLineInit): boolean =>
    item.CommissionRateKind == CommissionRateKind.NoRate;

  const iif = (
    predicate: (key: BreakdownLineInit) => boolean,
    trueKey: BreakdownLineKeys,
    falseVal = 0,
  ): number => {
    return predicate(item) ? item[trueKey] : falseVal;
  };

  const lineItem = {
    Client: item.Client.toLowerCase(),
    Email: item.Email,
    //-
    StdCommRevLe12MoRevenues: iif(isHighRate, "Revenues"),
    StdCommRevLe12MoDeductions: iif(isHighRate, "Deductions"),
    StdCommRevLe12MoCommissionable: iif(isHighRate, "Commissionable"),
    StdCommRevLe12MoCommission: iif(isHighRate, "Commissions"),
    //-
    StdCommRevGt12MoRevenues: iif(isLowRate, "Revenues"),
    StdCommRevGt12MoDeductions: iif(isLowRate, "Deductions"),
    StdCommRevGt12MoCommissionable: iif(isLowRate, "Commissionable"),
    StdCommRevGt12MoCommission: iif(isLowRate, "Commissions"),
    //-
    TransferredRevenues: iif(isTransferredRate, "Revenues"),
    TransferredDeductions: iif(isTransferredRate, "Deductions"),
    TransferredCommissionable: iif(isTransferredRate, "Commissionable"),
    TransferredCommission: iif(isTransferredRate, "Commissions"),
    //-
    ZeroCommRevenues: iif(isNoRate, "Revenues"),
    ZeroCommCommission: iif(isNoRate, "Commissions"),
    //-
    TotalPayableCommissions: item.Commissions,
  };

  return lineItem;
}

// ----------------------------------------------------------------------------

export function makeBreakdownLineFmt(item: BreakdownLine): BreakdownLineFmt {
  return {
    Client: item.Client,
    Email: item.Email,
    StdCommRevLe12MoRevenues: moneyFmt(item.StdCommRevLe12MoRevenues),
    StdCommRevLe12MoDeductions: moneyFmt(item.StdCommRevLe12MoDeductions),
    StdCommRevLe12MoCommissionable: moneyFmt(
      item.StdCommRevLe12MoCommissionable,
    ),
    StdCommRevLe12MoCommission: moneyFmt(item.StdCommRevLe12MoCommission),
    //-
    StdCommRevGt12MoRevenues: moneyFmt(item.StdCommRevGt12MoRevenues),
    StdCommRevGt12MoDeductions: moneyFmt(item.StdCommRevGt12MoDeductions),
    StdCommRevGt12MoCommissionable: moneyFmt(
      item.StdCommRevGt12MoCommissionable,
    ),
    StdCommRevGt12MoCommission: moneyFmt(item.StdCommRevGt12MoCommission),
    //-
    TransferredRevenues: moneyFmt(item.TransferredRevenues),
    TransferredDeductions: moneyFmt(item.TransferredDeductions),
    TransferredCommissionable: moneyFmt(item.TransferredCommissionable),
    TransferredCommission: moneyFmt(item.TransferredCommission),
    //-
    ZeroCommRevenues: moneyFmt(item.ZeroCommRevenues),
    ZeroCommCommission: moneyFmt(item.ZeroCommCommission),
    //-
    TotalPayableCommissions: moneyFmt(item.TotalPayableCommissions),
  };
}
// ----------------------------------------------------------------------------

export function getCsvInfo(response: AxiosResponse<any, any>) {
  const disposition = response.headers["content-disposition"];
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  let fileName = "download.csv";
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, "");
  }

  const mimeType = response.headers["content-type"];

  return {
    fileName,
    mimeType,
    content: response.data,
  };
}

// ----------------------------------------------------------------------------

export function saveAsFile(
  fileContent: string,
  mimeType: string,
  fileName: string,
) {
  let a: HTMLAnchorElement | null = null;
  try {
    const bb = new Blob([fileContent], { type: mimeType });
    a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(bb);
    a.click();
  } finally {
    a?.remove();
  }
}

// ----------------------------------------------------------------------------

export function moneyFmt(amount: number): string {
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return `£\u00A0${formatter.format(amount)}`;
}

// ----------------------------------------------------------------------------

export function strOfSalesRepGroup(salesRepGroup: ClientManagerGroup): string {
  return salesRepGroup == ClientManagerGroup.InHouse
    ? "In house"
    : salesRepGroup == ClientManagerGroup.AgencyMember
    ? `Current ${SALES_TEAM_NAME}`
    : `Ex ${SALES_TEAM_NAME}`;
}

// ----------------------------------------------------------------------------

export function strOfCommissionRateKind(
  commissionRateKind: CommissionRateKind,
): string {
  if (commissionRateKind == CommissionRateKind.HighRate)
    return "Standard comm rev ≤ 12 months";

  if (commissionRateKind == CommissionRateKind.LowRate)
    return "Standard comm rev > 12 months";

  if (commissionRateKind == CommissionRateKind.NoRate) return "No rate";

  if (commissionRateKind == CommissionRateKind.TransferredRate)
    return "Transferred rate";

  if (commissionRateKind == CommissionRateKind.AccountHandlerRate)
    return "Account handling rate";

  return "Unknown";
}

// ----------------------------------------------------------------------------

export function strOfSummaryRevenueItemKind(
  summaryRevenueItemKind: SummaryRevenueItemKind,
  removeExplanation = false,
): string {
  const prefix = `${SALES_TEAM_NAME} revenue type`;
  const itemKindText =
    summaryRevenueItemKind == SummaryRevenueItemKind.HouseClients
      ? "House clients"
      : summaryRevenueItemKind == SummaryRevenueItemKind.AgencyRevenueType1
      ? `${prefix} 1`
      : summaryRevenueItemKind == SummaryRevenueItemKind.AgencyRevenueType2
      ? `${prefix} 2`
      : `${prefix} 3`;

  const itemKindExplanationText =
    summaryRevenueItemKind == SummaryRevenueItemKind.HouseClients
      ? ""
      : summaryRevenueItemKind == SummaryRevenueItemKind.AgencyRevenueType1
      ? `<span class="summary-revenue-info">Current ${SALES_TEAM_NAME} Reps and Current ${SALES_TEAM_NAME} Clients</span>`
      : summaryRevenueItemKind == SummaryRevenueItemKind.AgencyRevenueType2
      ? `<span class="summary-revenue-info">Current ${SALES_TEAM_NAME} Reps and Ex ${SALES_TEAM_NAME} Clients</span>`
      : `<span class="summary-revenue-info">Ex ${SALES_TEAM_NAME} Reps</span>`;

  return removeExplanation
    ? itemKindText
    : `${itemKindText}<br>${itemKindExplanationText}`;
}

// ----------------------------------------------------------------------------

export const dateOnly = (dt: Date | null = null): Date => {
  dt ??= new Date();
  const result = new Date(dt);
  result.setHours(0, 0, 0, 0);

  return result;
};

// ----------------------------------------------------------------------------

export const today = (): Date => dateOnly(new Date());

// ----------------------------------------------------------------------------

export const nextDay = (dt: Date | null = null): Date => {
  dt ??= new Date();
  const result = new Date(dt);
  result.setDate(result.getDate() + 1);

  return result;
};

// ----------------------------------------------------------------------------

export const endOfMonth = (dt: Date | null = null): Date => {
  dt ??= new Date();
  const firstNextMonth = new Date(dt);
  firstNextMonth.setMonth(firstNextMonth.getMonth() + 1);
  firstNextMonth.setDate(1);
  firstNextMonth.setDate(firstNextMonth.getDate() - 1);

  return firstNextMonth;
};

// ----------------------------------------------------------------------------

export const nextMonth = (dt: Date | null = null): Date => nextDay(endOfMonth(dt));

// ----------------------------------------------------------------------------

export const nthMonth = (dt: Date | null = null, n: number): Date => {
  dt ??= new Date();
  const result = new Date(dt);
  result.setMonth(dt.getMonth() + n);

  return result;
};

// ----------------------------------------------------------------------------

export const startOfMonth = (dt: Date | null): Date => {
  dt ??= new Date();
  const result = new Date(dt);
  result.setDate(1);

  return result;
};

// ----------------------------------------------------------------------------

const sameYearMonth = (dt1: Date, dt2: Date): boolean => {
  const a = startOfMonth(dateOnly(dt1)).getTime();
  const b = startOfMonth(dateOnly(dt2)).getTime();

  return b - a == 0;
};

// ----------------------------------------------------------------------------

export const monthList = (dt1: Date, dt2: Date): Date[] => {
  let dt = dt1;
  const months: Date[] = [];
  let ct = 1;
  while (!sameYearMonth(dt, dt2)) {
    months.push(startOfMonth(dt));
    dt = nthMonth(dt, 1);
    ct++;
    if (ct >= 20) break;
  }
  months.push(startOfMonth(dt));

  return months;
};

// ----------------------------------------------------------------------------

export function pushMonthlyHeadersDataRange(
  headers: string[],
  dateRangeFrom: Date,
  dateRangeTo: Date,
  monthLabels: string[],
): Date[] {
  const yearLabel = (dt: Date): string => `${dt.getFullYear()}`.slice(-2);
  const monthLabel = (dt: Date): string =>
    `${monthLabels[dt.getMonth()]}&nbsp;'${yearLabel(dt)}`;

  const ml = monthList(dateRangeFrom, dateRangeTo);
  ml.map(monthLabel).forEach(lbl => headers.push(lbl));

  return ml;
}

// ----------------------------------------------------------------------------

export function deepClone(obj: any): any {
  return !obj ? obj : JSON.parse(JSON.stringify(obj));
}

// ----------------------------------------------------------------------------

export function strOfDate(dt: Date | string | null): string {
  const dt2 =
    dt != null && typeof dt == "string" ? new Date(Date.parse(dt)) : dt;
  return !dt || !dt2
    ? "now"
    : `${dt2.getDate()}/${dt2.getMonth() + 1}/${dt2.getFullYear()}`;
}

// ----------------------------------------------------------------------------

export function strIsoOfDate(dt: Date | string | null): string {
  dt ??= new Date();
  const dt2 = typeof dt == "string" ? new Date(Date.parse(dt)) : dt;
  const yyyy = `${dt2.getFullYear()}`;
  const mm = `${dt2.getMonth() + 1}`.padStart(2, "0");
  const dd = `${dt2.getDate()}`.padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

// ----------------------------------------------------------------------------

export function hasAtLeastOneEntry(
  dateRanges: (readonly [Date | string, Date | string | null])[],
) {
  return dateRanges.length;
}

// ----------------------------------------------------------------------------

export function areContigous(
  dr1: readonly [Date | string, Date | string | null],
  dr2: readonly [Date | string, Date | string | null],
): boolean {
  const [_from1, to1] = dr1; //previous
  const [from2, _to2] = dr2; //head

  const diffDays = dateDiffDays(toDate(to1), toDate(from2));

  return diffDays == 1;
}

// ----------------------------------------------------------------------------

export function hasContigousDateRanges(
  dateRanges: (readonly [Date | string, Date | string | null])[],
): boolean {
  if (!isSorted(dateRanges)) throw Error("list is not sorted");
  const head = dateRanges.shift()!;
  let contigous = true;
  dateRanges.reduce((dr, previous) => {
    contigous &&= areContigous(previous, dr);

    return previous;
  }, head);

  return contigous;
}

// ----------------------------------------------------------------------------

export function isSorted(
  dateRanges: (readonly [Date | string, Date | string | null])[],
): boolean {
  const head = dateRanges.pop()!;
  let sorted = true;
  dateRanges.reduce((dr, previous) => {
    sorted &&= isDateRageGreaterThan(dr, previous);

    return dr;
  }, head);

  return sorted;
}

// ----------------------------------------------------------------------------

export function toDate(dt: Date | string | null): Date {
  if (dt == null) return new Date(2999, 1, 1);
  if (typeof dt == "string") return new Date(Date.parse(dt));

  return dt;
}

// ----------------------------------------------------------------------------

export function isValidDateRange(
  dr: readonly [Date | string, Date | string | null],
): boolean {
  const [from, to] = dr;

  return toDate(from) < toDate(to);
}

// ----------------------------------------------------------------------------

export function dateDiffDays(a: Date, b: Date): number {
  return Math.round((b.getTime() - a.getTime()) / (1000 * 60 * 60 * 24));
}

// ----------------------------------------------------------------------------

export function isDateRageGreaterThan(
  dr1: readonly [Date | string, Date | string | null],
  dr2: readonly [Date | string, Date | string | null],
): boolean {
  const [from1] = dr1;
  const [from2] = dr2;
  const dayDiff = dateDiffDays(toDate(from1), toDate(from2));

  return dayDiff > 0;
}

// ----------------------------------------------------------------------------

export function entriesAreValid(
  dateRanges: (readonly [Date | string, Date | string | null])[],
): boolean {
  return dateRanges.every(dr => isValidDateRange(dr));
}

// ----------------------------------------------------------------------------

export function validateModel(
  entries: { From: Date | string; To: Date | string | null }[],
): string[] {
  const dateRanges = entries.map(entry => {
    return [entry.From, entry.To] as const;
  });
  const errors: string[] = [];

  if (!hasAtLeastOneEntry(dateRanges)) {
    errors.push("The list must have at least one entry");

    return errors;
  }

  if (!entriesAreValid(dateRanges)) {
    errors.push(
      "The date ranges must have the end date greater than the start date",
    );

    return errors;
  }

  if (!hasContigousDateRanges(dateRanges)) {
    errors.push("The date ranges must have no gaps");
    return errors;
  }
  return ["OK"];
}

// ----------------------------------------------------------------------------

export function makeAuditedCommandParams<T>(
  userRole: CommissionUserRole,
  userId: number,
  oldValue: T | null,
  newValue: T,
  note: string,
): AuditedCommandParams<T> {
  return {
    UserRole: userRole,
    UserId: userId,
    OldValue: oldValue,
    NewValue: newValue,
    Note: note,
  };
}

// ----------------------------------------------------------------------------
